import React, { useReducer } from 'react';
import { toast } from 'react-toastify';

import { createCampaign } from './api';
import { COMMISSION_RATE_TYPE } from './constants';

import './affiliates-page.css';
import { useSelector } from 'react-redux';
import { affiliatesSelector } from './state/selectors';

function reducer(state, action) {
  switch (action.type) {
    case 'set_value': {
      return { ...state, [action.name]: action.value };
    }
    case 'clear': {
      return initialValue;
    }
    default: {
      return state;
    }
  }
}

const initialValue = {
  affiliate_id: '',
  code: '',
  commission_rate_type: '',
  commission_rate_value: '',
  cpa_for_hybrid_model: '',
};

function CreateCampaign(props) {
  const { selectedAffiliate } = useSelector(affiliatesSelector);
  if (!selectedAffiliate) {
    return <span>Please, select an affiliate first</span>
  }

  const [state, dispatch] = useReducer(reducer, initialValue);

  const setValue = ({ target }) => {
    const { name, value } = target;
    if (name) {
      dispatch({ type: 'set_value', name, value });
    }
  };

  const hasEmptyField = (state) => {
    return Object.values(state).some((value) => !value);
  };

  const handleCreateCampaign = async () => {
    try {
      if (hasEmptyField(state)) {
        throw new Error('Some fields are empty.');
      }
      await createCampaign(state);
      toast.success(`Campaign created for affiliate: ${state.affiliate_id}.`);
      dispatch({ type: 'clear' });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="affiliate-page">
      <div className='affiliate-page__container'>

        <div className='affiliate-page__group-input'>
          <label>

            Affiliate ID:
          </label>
          <input
            name="affiliate_id"
            value={selectedAffiliate.blaze_user_id}
            disabled
            onChange={setValue}
          />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Code:
          </label>
          <input name="code" value={state.code} onChange={setValue} />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Commision Rate Type:
          </label>
          <select name="commission_rate_type" onChange={setValue}>
            <option disabled selected value>
              Select
            </option>
            <option value={COMMISSION_RATE_TYPE.CPA}>
              {COMMISSION_RATE_TYPE.CPA}
            </option>
            <option value={COMMISSION_RATE_TYPE.WS}>
              {COMMISSION_RATE_TYPE.WS}
            </option>
            <option value={COMMISSION_RATE_TYPE.RS}>
              {COMMISSION_RATE_TYPE.RS}
            </option>
            <option value={COMMISSION_RATE_TYPE.HY}>
              {COMMISSION_RATE_TYPE.HY}
            </option>
          </select>
        </div>

        <div className='affiliate-page__group-input'>
          <label>
            Commision Rate value:
          </label>
          <input
            name="commission_rate_value"
            value={state.commission_rate_value}
            onChange={setValue}
          />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            CPA for Hybrid Model:
          </label>
          <input name="cpa_for_hybrid_model" value={state.cpa_for_hybrid_model} onChange={setValue} />
        </div>
        <button onClick={handleCreateCampaign}>Create Campaign</button>
      </div>
    </div>
  );
}

export default CreateCampaign;