import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import api from "./api";
import "./racesList.scss";
import { RACE_STATS } from "./constants";

const ListRacesWinners = (props) => {
  const { iteration } = props.match.params;
  const [listRacesIterationsWinners, setListRacesIterationsWinners] =
    useState();
  const [stats, setStats] = useState([]);

  const getData = useCallback(async () => {
    try {
      const [winnersData, statsData] = await Promise.all([
        api.getRaceIterationsWinnerById(iteration),
        api.getRaceIterationStatsById(iteration),
      ]);
      setListRacesIterationsWinners(winnersData);
      setStats(statsData);
    } catch (e) {}
  }, [iteration]);
  useEffect(() => {
    getData();
  }, [getData]);

  const handlePayAllWinners = async () => {
    await api.payallwinnersIteration(iteration);
  };

  const handlerSaveWinners = async () => {
    await api.getRaceIterationsSaveWinnersById(iteration);
  };

  const getStat = useCallback(
    (key, defaultValue = 0) => {
      const stat = stats?.find((item) => item.key === key);
      return stat?.value ? Number(stat.value).toFixed(2) : defaultValue;
    },
    [iteration]
  );

  return (
    <div>
      <div className="row-challenge between">
        <div className="amount-deviation-container">
          <span>Deviation Amount Wagered</span>
          <span className="amount-deviation">
            {getStat(RACE_STATS.deviationAmount)}%
          </span>
        </div>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>Position</Th>
            <Th>Username</Th>
            <Th>Prize</Th>
            <Th>Paid</Th>
            <Th>Pos. by wagered</Th>
            <Th>Refunds</Th>
            <Th>Amount Refund</Th>
          </Tr>
        </Thead>

        <Tbody className="referrals-body">
          {listRacesIterationsWinners?.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{item.position}</Td>
                <Td>{item.username}</Td>
                <Td>{item.eur_prize}</Td>
                <Td>{item.paid ? "true" : "false"}</Td>
                <Td className="text-center pr-30">
                  {item.position_by_race_wagered ?? "-"}
                </Td>
                <Td className="text-center pr-30">
                  {item.quantity_bets_refunded ?? "-"}
                </Td>
                <Td className="text-center pr-30">
                  {item.amount_bets_refunded
                    ? Number(item.amount_bets_refunded).toFixed(2)
                    : "-"}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <button onClick={() => window.history.back()}>Back</button>
    </div>
  );
};

export default withRouter(ListRacesWinners);
