

import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import ReactTable from 'react-table';
import { updateBoxes } from "./reward-actions";

import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { format } from '../shared/utils/dates';


const BASE = '/admin/rewards/reward_tiers';
class RewardTiers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      page: 1,
      results: {
        pagination: {
          total_pages: 1,
          records_per_page: 50
        },
        records: []
      },
      edit_row: {},
      creating: false
    }
  }

  componentDidMount() {
    this.update();
    this.props.dispatch(updateBoxes());
  }

  update() {
    let params = {};
    if (this.table) {
      params = {
        page: this.table.state.page + 1,
        sorting: this.table.state.sorted[0],
        filters: this.table.state.filtered
      }
    }

    window.api.get(BASE, {
      params: params
    }).then(data => {
      this.setState({
        results: data
      })
    });
  }

  save() {

    const { edit_row, creating } = this.state;
    if (creating) {
      window.api.post(BASE, edit_row)
        .then(data => {
          this.setState({
            edit_row: {},
            creating: false
          });
          this.update();
        });
    } else {
      window.api.put(`${BASE}/${edit_row.id}`, edit_row)
        .then(data => {
          this.setState({
            edit_row: {}
          });
          this.update();
        });
    }
  }

  cancel() {
    if (this.state.creating) {
      this.setState({
        creating: false
      })
    }

    this.setState({
      edit_row: {}
    })
  }

  render() {
    const { edit_row, creating } = this.state;
    let { pagination, records } = this.state.results;

    if (creating) {
      records = [edit_row].concat(records);
    }

    return (
      <div>

        <button
          onClick={
            e => this.setState({
              creating: true,
              edit_row: {
                id: null
              }
            })
          }
        >New

        </button>

        <ReactTable
          ref={el => this.table = el}
          manual
          showPageJump={false}
          pages={pagination['total_pages']}
          pageSize={pagination['records_per_page']}
          data={records}
          multiSort={false}
          TbodyComponent={(props) => (<Tbody>{props.children}</Tbody>)}
          TheadComponent={(props) => (<Thead>{props.children}</Thead>)}
          TdComponent={Td}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                whiteSpace: "pre"
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                minWidth: "100%"
              }
            }
          }}
          TrGroupComponent={(props) => (
            <>
              {props.children}
            </>
          )}
          TrComponent={Tr}
          ThComponent={Th}
          TableComponent={Table}
          showPageSizeOptions={false}
          onPageChange={(pageIndex) => {
            this.update();
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            this.update();
          }}
          onFilteredChange={(filtered, column, value) => {
            this.update();
          }}
          columns={[
            {
              Header: '',
              Cell: d => (
                edit_row.id === d.original.id ?
                  <div>
                    <a
                      onClick={
                        e => this.cancel()
                      }
                    >
                      Cancel
                    </a>
                    <a
                      onClick={
                        e => this.save()
                      }
                    >
                      Save
                    </a>
                  </div>
                  :
                  <a
                    onClick={
                      e => this.setState({
                        edit_row: d.original
                      })
                    }
                  >
                    Edit
                  </a>
              ),
            },
            {
              id: 'id',
              Header: 'ID',
              accessor: d => (
                <Link to={`/items/${d.id}`}>{d['id']}</Link>
              ),
            },
            {
              id: 'created_at',
              Header: 'Created',
              accessor: d => format.withMinutes(d['created_at']),
            },
            {
              id: 'name',
              Header: 'Rule Name',
              accessor: d => (
                edit_row.id === d.id ?
                  <input
                    value={edit_row.name}
                    onChange={
                      e => this.setState({
                        edit_row: {
                          ...edit_row,
                          name: e.target.value
                        }
                      })
                    }
                  />
                  :
                  <span>{d.name}</span>
              ),
            },
            {
              id: 'mystery_box_id',
              Header: 'Mystery Box',
              accessor: d => {

                const box = (this.props.boxes || []).find(
                  box => box.id === d.mystery_box_id
                );

                return (
                  edit_row.id === d.id ?
                    <select
                      name="type"
                      value={edit_row.mystery_box_id}
                      onChange={option => {
                        option = option || { value: null };

                        this.setState({
                          edit_row: {
                            ...edit_row,
                            mystery_box_id: option.target.value
                          }
                        })
                      }}
                    >
                      {this.props.boxes.map((box, i) => (
                        <option
                          key={i}
                          value={box.id}
                        >
                          {box.name}
                        </option>
                      ))}
                    </select>
                    :
                    box && <Link to={`/rewards/mystery-boxes/${box.id}`}>
                      {box.name}
                    </Link>
                )
              },
            },
            {
              id: 'min_level',
              Header: 'Min Level',
              accessor: d => (
                edit_row.id === d.id ?
                  <input
                    value={edit_row.min_level}
                    type="number"
                    onChange={
                      e => this.setState({
                        edit_row: {
                          ...edit_row,
                          min_level: e.target.value || null
                        }
                      })
                    }
                  />
                  :
                  <span>{d.min_level || 'N/A'}</span>
              ),
            },
            {
              id: 'max_level',
              Header: 'Max Level',
              accessor: d => (
                edit_row.id === d.id ?
                  <input
                    value={edit_row.max_level}
                    type="number"
                    onChange={
                      e => this.setState({
                        edit_row: {
                          ...edit_row,
                          max_level: e.target.value || null
                        }
                      })
                    }
                  />
                  :
                  <span>{d.max_level || 'N/A'}</span>
              ),
            }
          ]}
        />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  boxes: state.rewards.mystery_boxes.records
});


export default connect(mapStateToProps)(RewardTiers);