import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
  getDocument,
  getDocuments
} from "./actions";



class UserKYC extends Component {

  constructor(props) {
    super();

    this.state = {
    }
  }

  componentDidMount() {
    this.props.getDocuments(this.props.match.params.id);
  }

  render() {
    const user_id = this.props.match.params.id;
    return (

      <Fragment>
        <hr/>

        <h4>User Documents</h4>

        <br/>

        <div className="documents">
          {(this.props.documents || []).map((doc, index) => {
            return (
              <div
                className="document" key={index}>
                {doc.original_name}
                <br/>
                {doc.type}
                <br/>
                <button className="small" onClick={() => {
                  this.props.getDocument(user_id, doc);
                  this.props.openDocumentModal();
                }}>View</button>
              </div>
            )
          })}

        </div>

        <br/>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.users.user
});

const mapDispatchToProps = dispatch => ({
  getDocuments: user_id => dispatch(getDocuments(user_id)),
  getDocument: (user_id, document) => dispatch(getDocument(user_id, document)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserKYC);