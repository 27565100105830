import React, { useState, useEffect } from "react";
import Pagination from "../shared/pagination";

import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserBets } from "../originals/originals-games-api";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const UserTowerBets = () => {
  const [data, setData] = useState({ total_pages: 0, bets: [] });
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const { id } = useParams();

  const fetch = async () => {
    setIsLoading(true);
    try {
      const data = await getUserBets(id, { page, type: "tower" });
      setData({ total_pages: data.pages, bets: data.records });
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div style={{ position: "relative", minHeight: 500 }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait ...</h2>
          <div className="loader" />
        </div>
      )}

      <Table>
        <Thead>
          <Tr>
            <Th>Bet ID</Th>
            <Th>Game ID</Th>
            <Th>Game Started At</Th>
            <Th>Amount</Th>
            <Th>Free</Th>
            <Th>Winnings</Th>
            <Th>Status</Th>
            <Th>Profit</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.bets.map((b, i) => {
            const {
              remoteRoundId,
              created_at,
              id,
              amount,
              currency_type,
              winnings,
              status,
              profit,
              wallet_params,
              roundId
            } = b;

            return (
              <Tr key={i}>
                <Td>
                  {
                    wallet_params?.free_bet ?
                      (<span>{id}</span>) :
                      (<Link to={`/casino/game-provider-rounds/${remoteRoundId}`}>
                        {id}
                      </Link>)
                  }
                </Td>
                <Td>{roundId}</Td>
                <Td>{format.withSeconds(created_at)}</Td>
                <Td>
                  {amount} {currency_type}
                </Td>
                <Td>{wallet_params?.free_bet ? "Yes" : "No"}</Td>
                <Td className="good">
                  {winnings > 0
                    ? `${parseFloat(winnings).toFixed(4)} ${currency_type}`
                    : "-"}
                </Td>
                <Td>{status}</Td>
                <Td className={profit >= 0 ? "good" : "bad"}>
                  {profit} {currency_type}
                </Td>
                {/* <Td>{JSON.stringify(rest)}</Td> */}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Pagination
        total_pages={data.total_pages}
        update={(page) => setPage(page)}
      />
    </div>
  );
};
export default UserTowerBets;
