import { useDispatch, useSelector } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { currenciesSelector } from "../app/app-selectors";
import { rewardsCurrencyRatesSelector } from "./rewards-selector";
const types = require('./reward-types');

const RewardCurrencyRates = () => {
  const { currencyRates } = useSelector(rewardsCurrencyRatesSelector);
  const { currencies } = useSelector(currenciesSelector);

  const selectedCurrencies = currencyRates.map(cr => cr.currency);

  const freeCurrencies = currencies.filter(c => selectedCurrencies.indexOf(c.type) === -1).map(c => c.type);

  const dispatch = useDispatch();
  const addNewCurrencyRate = () => dispatch({ type: types.ADD_CURRENCY_RATE, payload: { currency: freeCurrencies[0] } });
  const updateCurrencyRate = (cr) => dispatch({ type: types.UPDATE_CURRENCY_RATE, payload: cr });

  return (
    <div className="currency-rates-container">
      <h3>Currency rates</h3>
      <button className="add-new-reward" disabled={freeCurrencies.length === 0} onClick={addNewCurrencyRate}>Add</button>
      <Table>
        <Thead>
          <Tr>
            <Th>Currency</Th>
            <Th>Rate</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            currencyRates.map((cr, index) =>
              <Tr key={index + cr.rate}>
                <Td>
                  <select onChange={(event) => updateCurrencyRate({ previous: cr.currency, currency: event.target.value })}>
                    {freeCurrencies.concat([cr.currency]).sort().map(c => {
                      return <option value={c} selected={cr.currency === c}>{c}</option>;
                    })}
                  </select>
                </Td>
                <Td><input type="number" onChange={(event) => updateCurrencyRate(
                  {
                    previous: cr.currency,
                    currency: cr.currency,
                    rate: +event.target.value,
                  }
                )} value={cr.rate || 0}></input></Td>
              </Tr>)
          }
        </Tbody>
      </Table>

    </div>
  );
};

export default RewardCurrencyRates;