import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Translator } from "../shared/translator";
import { toast } from "react-toastify";
import { permissionsConstants, PermissionsFragment } from "../app/permissions-fragment";
import RewardCurrencyRates from './reward-currency-rates';
import * as types from './reward-types';
import './form-edit-style.css'
class Reward extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reward: {
        name_translation: {},
        description_translation: {},
        mystery_manifest_description_translation: {},
        image_url: "",
        sellable: false,
        eur_value: 0,
        expiry_minutes: 0,
        currency_rates: {},
      },
      isLoading: true,
      isEditing: false,
    };

    this.nameRef = createRef();
    this.descriptionRef = createRef();
    this.imageUrlRef = createRef();
    this.sellableRef = createRef();
    this.eurValueRef = createRef();
    this.expiryMinutesRef = createRef();
    this.currencyRatesRef = createRef();
  }

  componentDidMount() {
    this.update();

  }

  update() {
    window.api.get(`/admin/rewards/${this.props.match.params.id}`)
      .then(reward => {
        this.setState({ reward });
        this.props.setCurrencyRates(reward.currency_rates);
        this.setState({ isLoading: false });
      });
  }

  handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        toast.info('Uploading image...');
        const image_url = await window.api.upload(
          file,
          "games",
          `${this.props.match.params.id}-new`
        );
        this.imageUrlRef.current.value = image_url;
        this.setState({
          reward: {
            ...this.state.reward,
            image_url
          }
        });
        toast.success('Image uploaded successfully.');
      } catch (error) {
        toast.error('Cannot upload image to bucket.');
      }
    }
  };

  save = async () => {
    const updatedReward = {
      name: this.nameRef.current.value,
      description: this.descriptionRef.current.value,
      image_url: this.imageUrlRef.current.value,
      sellable: this.sellableRef.current.checked,
      eur_value: parseFloat(this.eurValueRef.current.value),
      expiry_minutes: parseInt(this.expiryMinutesRef.current.value, 10),
      name_translation: this.state.reward.name_translation,
      description_translation: this.state.reward.description_translation,
      mystery_manifest_description_translation: this.state.reward.mystery_manifest_description_translation,
      currency_rates: this.props.currencyRates,
    };

    window.api.put(`/admin/rewards/${this.props.match.params.id}`, updatedReward)
      .then(() => {
        toast.success('Reward saved successfully.');
        setTimeout(() => {
          this.props.history.push('/casino/rewards');
        }, 300);
      })
      .catch(err => toast.error('Failed to save reward.'));
  };

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  render() {
    const { isEditing, isLoading } = this.state;
    if (isLoading) {
      return <h3>Loading...</h3>;
    }
    return (
      <div className='container'>
        <div className='button-container'>
          <button onClick={this.toggleEdit}>
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
        </div>
        <PermissionsFragment feature={permissionsConstants.REWARDS_UPDATE_REWARD}>
          <div >
            <label>Name:</label>
            <input type="text" ref={this.nameRef} defaultValue={this.state.reward.name} disabled={!isEditing} />
          </div>
          <div >
            <label>Description:</label>
            <input type="text" ref={this.descriptionRef} defaultValue={this.state.reward.description} disabled={!isEditing} />
          </div>
          <div className='file-container'>
            <div>
              <label>Image URL:</label>
            </div>
            <div className='file-input-container'>
              <input type="text" ref={this.imageUrlRef} defaultValue={this.state.reward.image_url} disabled={!isEditing} />
              <input type="file" onChange={this.handleFileChange} disabled={!isEditing} />
            </div>
          </div>
          <div>
            <label>Sellable:</label>
            <input type="checkbox" ref={this.sellableRef} defaultChecked={this.state.reward.sellable} disabled={!isEditing} />
          </div>
          <div>
            <label>EUR Value:</label>
            <input type="number" ref={this.eurValueRef} defaultValue={this.state.reward.eur_value} disabled={!isEditing} />
          </div>
          <div>
            <label>Expiry Minutes:</label>
            <input type="number" ref={this.expiryMinutesRef} defaultValue={this.state.reward.expiry_minutes} disabled={!isEditing} />
          </div>
          <Translator
            languages={this.props.languages}
            contents={this.state.reward.name_translation}
            onChange={name_translation => this.setState({ reward: { ...this.state.reward, name_translation } })}
            label={"Name Translation"}
            disabled={!isEditing}
          />
          <Translator
            languages={this.props.languages}
            contents={this.state.reward.description_translation}
            onChange={description_translation => this.setState({ reward: { ...this.state.reward, description_translation } })}
            label={"Description Translation"}
            disabled={!isEditing}
          />
          <Translator
            languages={this.props.languages}
            contents={this.state.reward.mystery_manifest_description_translation}
            onChange={mystery_manifest_description_translation => this.setState({ reward: { ...this.state.reward, mystery_manifest_description_translation } })}
            label={"Mystery Box Translation"}
            disabled={!isEditing}
          />

          <RewardCurrencyRates />

          {isEditing && <div className='button-container'><button onClick={this.save}>Save</button></div>}
        </PermissionsFragment>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.app.languages,
  currencyRates: state.rewards.currency_rates,
});

const mapDispatchToProps = dispatch => ({
  setCurrencyRates: (cr) => dispatch({
    type: types.LOAD_CURRENCY_RATES,
    payload: cr,
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(Reward);