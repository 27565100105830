import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import MobilePageTitle from "shared/MobilePageTitle";
import DataGrid, { TextEditor } from "react-data-grid";

const fetchRouteMappings = async () => {
  const data = await window.api({
    method: "GET",
    url: `/admin/users/route_mappings`,
  });
  return data.map((x) => {
    return { ...x, method: x.method.toString() };
  });
};

const RouteMappings = () => {
  const boolean = ["", "GET", "POST", "PUT", "DELETE"];
  const textEditor = "t16y9g8l700-beta7";
  const textEditorClassname = `rdg-text-editor ${textEditor}`;

  function DropDownEditor({ row, onRowChange }) {
    return (
      <select
        className={textEditorClassname}
        value={row.method}
        onChange={(event) =>
          onRowChange({ ...row, method: event.target.value }, true)
        }
        autoFocus
      >
        {boolean.map((bool) => (
          <option key={bool} value={bool}>
            {bool}
          </option>
        ))}
      </select>
    );
  }

  const columns = [
    { key: "id", name: "ID" },
    { key: "name", name: "Name", editor: TextEditor },
    { key: "url", name: "URL", editor: TextEditor },
    { key: "method", name: "Method", editor: DropDownEditor },
  ];

  const { id } = useParams();
  const { data } = useQuery(["route_mappings"], (context) =>
    fetchRouteMappings()
  );
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  const updateRows = (route_mappings, { indexes }) => {
    const tableIndex = indexes[0];
    const route_mapping = route_mappings[tableIndex];
    if (
      !route_mapping.name ||
      !route_mapping.url ||
      !route_mapping.method ||
      route_mapping.method === ""
    ) {
      setRows(route_mappings);
      return toast.error("Fill the remaining fields to save");
    }
    if (route_mapping.id) {
      window.api
        .put(`/admin/users/route_mapping/${route_mapping.id}`, route_mapping)
        .then((data) => {
          if (data.message) {
            toast.success(data.message);
            setRows(route_mappings);
          } else toast.error(data);
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      window.api
        .post("/admin/users/route_mapping", route_mapping)
        .then((data) => {
          if (data.message) {
            toast.success(data.message);
            route_mappings[tableIndex].id = data.id;
            setRows(route_mappings);
          } else toast.error(data);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  return (
    <div>
      <MobilePageTitle title="Route Mappings" />
      <button
        style={{ margin: 20 }}
        onClick={() =>
          setRows([
            ...rows,
            {
              race_id: id,
            },
          ])
        }
      >
        Add Route Map
      </button>
      {data && (
        <DataGrid columns={columns} rows={rows} onRowsChange={updateRows} />
      )}
    </div>
  );
};

export default RouteMappings;
