import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./races.scss";

import styles from "./tabRaceDetail.module.scss";
import ViewRace from "./viewRace";
import ListRacesIterations from "./listRacesIterations";
import api from "./api";

const TabRacesDetail = (props) => {
  const [tab, setTab] = useState("detail");
  const id = props.match.params.id;
  const history = props.history;
  const camcellIterations = async () => {
    await api.cancellRaceIterations(id);
  };

  return (
    <>
      <div>
        <h1 style={{ fontSize: "22px", marginLeft: "20px" }}>Race #{id}</h1>

        <div className={styles.tabbedNav}>
          <div
            className={tab === "detail" ? styles.selected : undefined}
            onClick={() => {
              setTab("detail");
            }}
          >
            Detail
          </div>
          <div
            className={tab === "iteration" ? styles.selected : undefined}
            onClick={() => {
              setTab("iteration");
            }}
          >
            Iteration
          </div>
        </div>

        {tab === "detail" && <ViewRace />}

        {tab === "iteration" && <ListRacesIterations />}
      </div>
      <button
        onClick={() => {
          history.push(`/casino/races`);
        }}
        style={{ marginTop: "20px", marginLeft: "50px" }}
      >
        back
      </button>
      <button
        onClick={() => {
          camcellIterations();
        }}
        style={{ marginTop: "20px", marginLeft: "50px" }}
      >
        Cancell Active Iterations
      </button>
    </>
  );
};

export default withRouter(TabRacesDetail);
