import * as types from './types';

const initial = {
  loading: false,
  bigWins: {
    count: 0,
    records: [],
  },
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.LOADING_BIG_WINS: {
      return { ...state, loading: true };
    }
    case types.BIG_WINS_LOADED: {
      return { loading: false, bigWins: action.payload };
    }
  }

  return state;
}
