import React, { useEffect, useState, useRef } from "react";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  getUsersByGroup,
  uploadFile,
  changeUserStatus,
  addUser,
  releaseGroup,
  rollbackRelease
} from "system/Groups/api.js";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

export default function Groups({ groupId }) {
  const [page, setPage] = useState(1);
  const [uploadForEnable, setUploadForEnable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [individualUserId, setIndividualUserId] = useState("");
  const [groupInfo, setGroupInfo] = useState({});
  const fileRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { users, ...groupInfo } = await getUsersByGroup({
        page,
        groupId,
        search,
      });
      setGroupInfo(groupInfo);
      setRows(users.sort((a, b) => a.username.localeCompare(b.username)));
    } catch (error) {
      console.error({ error });
    } finally {
      setLoading(false);
    }
  };

  const doReleaseOrRollback = async () => {
    const releaseAction = groupInfo.released ? rollbackRelease : releaseGroup;
    await releaseAction(groupId);
    setGroupInfo({ ...groupInfo, released: !groupInfo.released });
  }

  useEffect(() => {
    fetchData();
  }, [page, groupId]);

  const nextPage = () => setPage((prevState) => prevState + 1);
  const backPage = () => setPage((prevState) => prevState - 1);

  const onUpload = async (e) => {
    const file = e.target.files[0];

    try {
      setLoading(true);
      e.target.value = "";
      await uploadFile(
        file,
        groupId,
        uploadForEnable ? "enable" : "disable",
        page
      );

      fetchData();
    } catch (error) {
      console.log({ ["Uploading file error: "]: error });
    } finally {
      setLoading(false);
    }
  };

  const onSearch = () => {
    fetchData();
  };

  const onAddUser = async () => {
    await addUser(groupId, [individualUserId]);
    await fetchData();
    setIndividualUserId("");
  };

  const changeStatus = async (userId, status) => {
    const { users, ...groupInfo } = await changeUserStatus(
      groupId,
      userId,
      status,
      page
    );
    setGroupInfo(groupInfo);
    setRows(users.sort((a, b) => a.username.localeCompare(b.username)));
  };

  return groupInfo ? (
    <>
      <h1
        style={{
          width: "80%",
        }}
      >
        {groupInfo.name}
      </h1>
      <div
        style={{
          margin: "30px 0",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          <input
            style={{
              width: "75%",
            }}
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <button
            style={{
              marginLeft: 15,
            }}
            variant="contained"
            size="small"
            onClick={onSearch}
          >
            Search
          </button>
        </div>
        <div>
          <input
              type="number"
              onChange={(e) => {setIndividualUserId(e.target.value)}}
              placeholder={"insert an user id"}
              value={individualUserId}
          />
          <button
              variant="contained"
              size="small"
              onClick={onAddUser}
              disabled={!individualUserId}
          >
            Add user
          </button>
        </div>
        <div>
          <input
            style={{
              display: "none",
            }}
            ref={fileRef}
            type="file"
            onChange={onUpload}
            name="filename"
          />
          <button
            variant="contained"
            size="small"
            onClick={() => {
              fileRef.current.click();
            }}
          >
            Upload .CSV
          </button>
          <span
            style={{
              marginLeft: 15,
            }}
          >
            <span
              style={{
                color: uploadForEnable && "grey",
              }}
            >
              Disable
            </span>
            <FormControlLabel
              style={{
                margin: "0 15px",
              }}
              value={uploadForEnable ? "start" : "end"}
              control={
                <Switch
                  checked={uploadForEnable}
                  color="success"
                  onClick={() => setUploadForEnable((prevState) => !prevState)}
                />
              }
            />
            <span
              style={{
                color: !uploadForEnable && "grey",
              }}
            >
              Enable
            </span>
            <FormControlLabel
              style={{
                margin: "0 15px",
              }}
              value={groupInfo.released ? "start" : "end"}
              control={
                <Switch
                  checked={groupInfo.released}
                  color="success"
                  onClick={doReleaseOrRollback}
                />
              }
            />
            <span
              style={{
                color: !groupInfo.released && "grey",
              }}
            >
              {groupInfo.released ? 'Feature released!' : 'Release feature'}
            </span>
          </span>
        </div>
      </div>

      {loading ? (
        <div className={"loading-overlay"}>
          <h2>Fetching, please wait ...</h2>
          <div className="loader" />
        </div>
      ) : (
        <>
          <Table>
            <Thead>
              <Tr>
                <Th>User Name</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>

            <Tbody>
              {rows.map((row) => (
                <Tr key={row.id}>
                  <Td>
                    <Link to={`/users/${row.id}`}>{row.username}</Link>
                  </Td>
                  <Td>
                    <Switch
                      checked={row.enabled}
                      color="success"
                      onClick={() => changeStatus(row.id, !row.enabled)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <div
            style={{
              display: "flex",
              placeContent: "center flex-end",
              padding: "20px",
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            {page > 1 && <div onClick={backPage}>Back</div>}

            <div style={{ marginLeft: 10 }} onClick={nextPage}>
              Next
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <h3>No group found</h3>
  );
}
