

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import ReactTable from 'react-table';
import { updateBoxes, updateUserRanks } from "../casino/reward-actions";


const BASE = '/admin/rewards/user_ranks';
const UserRanks = (props) => {

  const { dispatch } = props

  const [state, setState] = React.useState({
    query: '',
    page: 1,
    results: {
      pagination: {
        total_pages: 1,
        records_per_page: 50
      },
      records: []
    },
    edit_row: {},
    creating: false
  });

  const update = useCallback(() => {
    dispatch(updateUserRanks());
  }, [dispatch])


  useEffect(() => {
    dispatch(updateBoxes());
    update();
  }, [dispatch, update])

  const save = useCallback(() => {

    const { edit_row, creating } = state;

    if (creating) {
      window.api.post(BASE, edit_row)
        .then(data => {
          setState({
            edit_row: {},
            creating: false
          })
          update();
        });
    } else {
      window.api.put(`${BASE}/${edit_row.id}`, edit_row)
        .then(data => {
          setState({
            edit_row: {}
          })
          update();
        });
    }
  }, [state, update])

  const cancel = useCallback(() => {
    if (state.creating) {
      setState({
        creating: false
      })
    }

    setState({
      edit_row: {}
    })
  }, [state.creating])

  const handleClick = () => {
    setState({
      creating: true,
      edit_row: {
        id: null,
        name: 'New'
      }
    })
  }

  const { edit_row, creating } = state;
  let records = props.user_ranks;

  if (creating) {
    records = [edit_row].concat(records);
  }


  const columns = React.useMemo(
    () => [
      {
        Header: '',
        Cell: d => (
          edit_row.id === d.original.id ?
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px'
              }}
            >
              <a

                onClick={cancel}
              >
                Cancel
              </a>
              <a
                onClick={save}
              >
                Save
              </a>
            </div>
            :
            <a
              onClick={() => setState({
                edit_row: d.original
              })}
            >
              Edit
            </a>
        ),
      },
      {
        id: 'type',
        Header: 'Type',
        accessor: d => d.type,
        maxWidth: 200
      },
      {
        id: 'mystery_box_id',
        Header: 'Daily Free Mystery Box',
        accessor: d => {

          const box = (props.boxes || []).find(
            box => box.id === d.daily_free_mystery_box_id
          );
          return (
            edit_row.id === d.id ?
              <select
                style={{ minWidth: '100px', maxWidth: '100%' }}
                name="type"
                value={edit_row.daily_free_mystery_box_id}
                onChange={option => {
                  option = option || { value: null };
                  setState({
                    edit_row: {
                      ...edit_row,
                      daily_free_mystery_box_id: option.target.value
                    }
                  })
                }}
              >
                {
                  props.boxes.map(
                    box => (
                      <option
                        key={box.id}
                        value={box.id}
                      >
                        {box.name}
                      </option>
                    )
                  )
                }
              </select>
              :
              box && <Link to={`/casino/mystery-boxes/${box.id}`}>
                {box.name}
              </Link>
          )
        },
        maxWidth: 500
      },
      {
        id: 'min_level',
        Header: 'Min Level',
        accessor: d => <span>{d.min_level}</span>,
        maxWidth: 200
      },
      {
        id: 'max_level',
        Header: 'Max Level',
        accessor: d => <span>{d.max_level}</span>,
        maxWidth: 200
      },
      {
        id: 'loyalty_rakeback_enabled',
        Header: 'Loyalty Rakeback?',
        accessor: d =>
          edit_row.id === d.id ?
            <input
              type={"checkbox"}
              checked={edit_row.loyalty_rakeback_enabled}
              onChange={
                e => setState({
                  edit_row: {
                    ...edit_row,
                    loyalty_rakeback_enabled: !d.loyalty_rakeback_enabled
                  }
                })
              }
            />
            :
            d.loyalty_rakeback_enabled ? <i className="fas fa-check" /> : null
        ,
        maxWidth: 200
      },
      {
        id: 'loyalty_rakeback_percentage',
        Header: 'Loyalty Rakeback Percentage',
        accessor: d =>
          edit_row.id === d.id ?
            <input
              type={"text"}
              value={edit_row.loyalty_rakeback_percentage}
              onChange={
                e => setState({
                  edit_row: {
                    ...edit_row,
                    loyalty_rakeback_percentage: e.target.value
                  }
                })
              }
            />
            :
            <span>{d.loyalty_rakeback_percentage}</span>
        ,
        maxWidth: 300
      }
    ],
    [cancel, edit_row, props.boxes, save]
  )

  return (
    <div>

      <button onClick={handleClick}>New</button>

      <ReactTable
        TbodyComponent={(props) => (<Tbody>{props.children}</Tbody>)}
        TheadComponent={(props) => (<Thead>{props.children}</Thead>)}
        TdComponent={Td}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              whiteSpace: "pre"
            }
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              minWidth: "100%"
            }
          }
        }}
        TrGroupComponent={(props) => (
          <>
            {props.children}
          </>
        )}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              paddingBottom: "44px"
            }
          }
        }}
        TrComponent={Tr}
        ThComponent={Th}
        TableComponent={Table}
        manual
        data={records}
        columns={columns}
        multiSort={false}
        showPageSizeOptions={false}
        showPagination={false}
      />
    </div >
  )
}
const mapStateToProps = state => ({
  boxes: state.rewards.mystery_boxes.records,
  user_ranks: state.rewards.user_ranks
});



export default connect(mapStateToProps)(UserRanks);