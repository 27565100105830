

import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import Select from "react-select";
import { Link } from "react-router-dom";
import {toast} from "react-toastify";
import {updateDepositBonuses} from "./actions";
import {checkPermissions, permissionsConstants, PermissionsFragment} from "../app/permissions-fragment";
import { format } from '../shared/utils/dates';

class BonusRoundWelcomePackages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      page: 1,
      results: {
        pagination: {
          total_pages: 1,
          records_per_page: 50
        },
        records: []
      },
      edit_row: {},
      creating: false
    }
  }

  componentDidMount() {
    this.update();
    if (checkPermissions(permissionsConstants.BONUSES_VIEW_LIST)) {
      this.props.dispatch(updateDepositBonuses());

    }
  }

  getBase() {
    return `/admin/bonus_round_welcome_packages`
  }

  update() {let params = {};
    if (this.table) {
      params = {
        page: this.table.state.page + 1,
        sorting: this.table.state.sorted[0],
        filters: this.table.state.filtered,
        show_inactive: this.state.show_inactive ? '1' : '0'
      }
    }

    window.api.get(this.getBase(), {
      params: params
    }).then(data => {
      this.setState({
        results: data
      });
    }).catch(err => {
      toast.error(err);
    });
  }

  save() {

    const { edit_row, creating } = this.state;
    if (creating) {
      window.api.post(this.getBase(), edit_row)
        .then(data => {
          this.setState({
            edit_row: {},
            creating: false
          });
          this.update();
          toast.success(`Package created successfully`);
        }).catch(err => {
        toast.error(err);
      });
    } else {
      window.api.put(`${this.getBase()}/${edit_row.id}`, edit_row)
        .then(data => {
          this.setState({
            edit_row: {}
          });
          this.update();
        }).catch(err => {
        toast.error(err);
      });
    }
  }

  cancel() {
    if (this.state.creating) {
      this.setState({
        creating: false
      })
    }

    this.setState({
      edit_row: {}
    })
  }

  render() {
    const { edit_row, creating } = this.state;
    let { pagination, records } = this.state.results;

    if (creating) {
      records = [edit_row].concat(records);
    }

    return (
      <div>

        <PermissionsFragment feature={permissionsConstants.BONUS_ROUND_WELCOME_PACKAGE_CREATE}>
          <button
            onClick={
              e => this.setState({
                creating: true,
                edit_row: {
                  id: null,
                  name: 'New'
                }
              })
            }
          >New

          </button>
        </PermissionsFragment>



        <ReactTable
          ref={el => this.table = el}
          manual
          showPageJump={false}
          pages={pagination['total_pages']}
          pageSize={pagination['records_per_page']}
          data={records}
          multiSort={false}
          showPageSizeOptions={false}
          onPageChange={(pageIndex) => {
            this.update();
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            this.update();
          }}
          onFilteredChange={(filtered, column, value) => {
            this.update();
          }}
          columns={[
            {
              Header: '',
              Cell: d => (
                <PermissionsFragment feature={permissionsConstants.BONUS_ROUND_WELCOME_PACKAGE_UPDATE}>
                  {
                    edit_row.id === d.original.id ?
                      <div>
                        <a
                          onClick={
                            e => this.cancel()
                          }
                        >
                          Cancel
                        </a>
                        <a
                          onClick={
                            e => this.save()
                          }
                        >
                          Save
                        </a>
                      </div>
                      :
                      <a
                        onClick={
                          e => this.setState({
                            edit_row: d.original
                          })
                        }
                      >
                        Edit
                      </a>
                  }
                </PermissionsFragment>

              ),
              maxWidth: 100
            },
            {
              id: 'id',
              Header: 'ID',
              accessor: d => (
                <Link to={`/items/${d.id}`}>{d['id']}</Link>
              ),
              maxWidth: 50
            },
            {
              id: 'created_at',
              Header: 'Created',
              accessor: d => format.withMinutes(d['created_at']),
              maxWidth: 200
            },
            {
              id: 'bonus_id',
              Header: 'Bonus',
              accessor: d => (
                edit_row.id === d.id ?
                  <Select
                    name="type"
                    value={edit_row.bonus_id}
                    onChange={option => {
                      option = option || { value: null };
                      this.setState({
                        edit_row: {
                          ...edit_row,
                          bonus_id: option.value
                        }
                      })
                    }}
                    options={
                      this.props.bonuses.map(
                        bonus => ({
                          label: <div style={{display: 'flex', alignItems: 'center'}}>
                            (ID: {bonus.id}) - {bonus.name}
                          </div>,
                          value: bonus.id
                        })
                      )
                    }
                  />
                  :
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Link to={`/bonuses/${d.id}`}>
                      {d.bonus.name}</Link>
                  </div>
              ),
              maxWidth: 400
            },

            {
              id: 'round_type',
              Header: 'Round Type',
              accessor: d => (
                edit_row.id === d.id ?
                  <Select
                    name="type"
                    value={edit_row.round_type}
                    onChange={option => {
                      option = option || { value: null };
                      this.setState({
                        edit_row: {
                          ...edit_row,
                          round_type: option.value || null
                        }
                      })
                    }}
                    options={[
                      { label: 'Crash', value: 'crash' },
                      { label: 'Roulette', value: 'roulette' }
                    ]}
                  />
                  :
                  <span>{d.round_type}</span>
              ),
              maxWidth: 200
            },

            {
              id: 'rounds_per_day',
              Header: 'Rounds Per Day',
              accessor: d => (
                edit_row.id === d.id ?
                  <input
                    value={edit_row.rounds_per_day}
                    onChange={
                      e => this.setState({
                        edit_row: {
                          ...edit_row,
                          rounds_per_day: e.target.value
                        }
                      })
                    }
                  />
                  :
                  <span>{d.rounds_per_day}</span>
              ),
              maxWidth: 200
            },

            {
              id: 'payout_days',
              Header: 'Payout Days',
              accessor: d => (
                edit_row.id === d.id ?
                  <input
                    value={edit_row.payout_days}
                    onChange={
                      e => this.setState({
                        edit_row: {
                          ...edit_row,
                          payout_days: e.target.value
                        }
                      })
                    }
                  />
                  :
                  <span>{d.payout_days}</span>
              ),
              maxWidth: 200
            }
          ]}
        />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  bonuses: state.bonuses.search_results.records
});


export default connect(mapStateToProps)(BonusRoundWelcomePackages);