import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, NavLink, Switch, withRouter, Redirect } from "react-router-dom";

import MethodFinder from "payments/cashier/method-finder";
import ProviderFinder from "payments/cashier/provider-finder";
import Provider from "payments/cashier/provider";
import CountryOrderFinder from "payments/cashier/country-order-finder";
import CountryOrder from "payments/cashier/country-order";
import AccountFinder from "payments/create-influencer-card";
import Withdrawals from "payments/withdrawals/page";
import Deposits from "payments/list-deposits";
import KYCVerifications from "payments/list-all-kyc-verifications";
import {
  GroupPermissionsFragment,
  permissionGroups,
  CheckPermissionGroupRoute,
} from "app/permissions-fragment";
import { BrowserView } from "react-device-detect";
import {
  AutomatedWithdrawalsLink,
  CountryOrderingsLink,
  DepositsLink,
  InfluencerCardLink,
  KYCVerificationsLink,
  MethodsLink,
  PixWithdrawalsLink,
  ProvidersLink,
  StuckWithdrawalsLink,
  WithdrawalsLink,
} from "./links.js";
import { ProviderRulesList } from "../cashier/provider-rules-list";

class Payments extends Component {
  render() {
    const { url } = this.props.match;
    return (
      <div className={"page"}>
        <BrowserView>
          <nav data-testid="tab-payments-links">
            <WithdrawalsLink />
            <PixWithdrawalsLink />
            <StuckWithdrawalsLink />
            <AutomatedWithdrawalsLink />
            <KYCVerificationsLink />
            <DepositsLink />
            <MethodsLink />
            <ProvidersLink />
            <CountryOrderingsLink />
            <InfluencerCardLink />
          </nav>
        </BrowserView>

        <Switch>
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.WITHDRAWALS}
            path={`${url}/withdrawals`}
            component={Withdrawals}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.DEPOSITS}
            path={`${url}/deposits`}
            component={Deposits}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.KYC_VERIFICATIONS}
            path="/payments/kyc-verifications"
            component={KYCVerifications}
          />

          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.METHODS}
            exact
            path={`${url}/methods`}
            component={MethodFinder}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.PROVIDERS}
            exact
            path={`${url}/providers`}
            component={ProviderFinder}
          />

          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.PROVIDERS}
            exact
            path={`${url}/providers/rules`}
            component={ProviderRulesList}
          />

          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.PROVIDERS}
            exact
            path={`${url}/providers/:id`}
            component={Provider}
          />

          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.COUNTRY_ORDERINGS}
            exact
            path={`${url}/country-orderings`}
            component={CountryOrderFinder}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.COUNTRY_ORDERINGS}
            exact
            path={`${url}/country-orderings/:id`}
            component={CountryOrder}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.ACCOUNTS}
            exact
            path={`${url}/influencer-card`}
            component={AccountFinder}
          />

          <Redirect from="/payments" to="/payments/withdrawals" />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payments)
);
