const BONUSES_UPDATE = 'bonuses/UPDATE';
const BONUS_UPDATE = 'bonus/UPDATE';

const BONUS_ACTIVATIONS_UPDATE = 'bonus/ACTIVATIONS/UPDATE';
const BONUS_ACTIVATIONS_ON_WITHDRAWAL_UPDATE = 'bonus/ACTIVATIONS_ON_WITHDRAWAL/UPDATE';

export {
  BONUSES_UPDATE,
  BONUS_UPDATE,
  BONUS_ACTIVATIONS_UPDATE,
  BONUS_ACTIVATIONS_ON_WITHDRAWAL_UPDATE,
};
