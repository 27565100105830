import React from "react";
import * as styles from './OffsetPagination.module.css'

export const OffsetPagination = ({ page, totalPages, setPage }) =>
  <div className={styles.pagination}>
    <div className={styles.state}>Page <span className={styles.page}>{page}</span> of <span className={styles.totalPages}>{totalPages}</span></div>
    <div
      onClick={
        () => setPage(Math.max(page - 1, 1))
      }
      className={styles.button}>
      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.600025 4.00001L4.30002 0.300007C4.68662 -0.0865927 5.31342 -0.0865934 5.70002 0.300006C6.08662 0.686606 6.08662 1.31341 5.70002 1.70001L3.40002 4.00001L5.70002 6.30001C6.08662 6.6866 6.08662 7.31341 5.70002 7.70001C5.31342 8.08661 4.68662 8.0866 4.30002 7.70001L0.600025 4.00001Z"
          fill="#8C9099"/>
      </svg>

    </div>
    <div
      onClick={
        () => setPage(Math.min(page + 1, totalPages))
      }
      className={styles.button}
    >
      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.39998 3.99999L1.69998 7.69999C1.31338 8.08659 0.686575 8.08659 0.299976 7.69999C-0.0866238 7.31339 -0.0866237 6.68659 0.299976 6.29999L2.59998 3.99999L0.299976 1.69999C-0.0866235 1.3134 -0.086624 0.686594 0.299976 0.299994C0.686575 -0.0866051 1.31338 -0.0866051 1.69998 0.299994L5.39998 3.99999Z"
          fill="#8C9099"/>
      </svg>
    </div>
  </div>