import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateRedeems } from "./actions";
import Pagination from '../../shared/pagination';
import './promo.css';
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

class PromoCodes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      order: 'id_desc',
      type: '',
      status: ''

    }
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.update({
      page: this.state.page,
      search: this.state.search,
      order: this.state.order
    });
  }

  render() {

    return (
      <div id={"promo"}>
        <h3>Promo Code Redeems</h3>

        <h4>Search (id, promo_code_id, user_id, reward_manifest_id)</h4>
        <input

          name="type"
          value={this.state.search}
          onChange={ev => {
            this.setState({ search: ev.target.value }, () => this.update());
          }}
        />

        <br />
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Code</Th>
              <Th>User</Th>
              <Th>Reward Manifest</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              this.props.records.map((r, i) => {

                const reward = r.reward || {}

                return (
                  <Tr key={i}>
                    <Td>{r.id}</Td>
                    <Td>
                      <Link to={`/rewards/promo-codes/${r.promo_code.id}`}>{r.promo_code.code}</Link>
                    </Td>
                    <Td className="user">
                      <Link to={`/users/${r.user_id}/reward-inventory`}>
                        <img src={r.user.avatar_url} />
                        {r.user.username}
                      </Link>
                    </Td>
                    <Td>{r.reward_manifest_id}</Td>

                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>


        <Pagination
          total_pages={this.props.meta.total_pages}
          update={page => {
            this.setState({ page }, () => this.update());
          }}
        />

      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.promo.redeem_data,
});

const mapDispatchToProps = dispatch => ({
  update: props => dispatch(updateRedeems(props)),

});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);