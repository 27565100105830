import * as types from './types';

const initial = {
  user: {
    permissions: []
  },
  currencies: [],
  languages: []
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.USER_UPDATE:
      return {...state, user: action.payload};

    case types.CURRENCIES_UPDATE:
      return {
        ...state,
        currencies: action.payload
      }
  }

  return state;
}