import { CHANGE_SUSPENSION_FORM, UPDATE_FORM_ERRORS } from './constants'

const reducer = (state, action) => {
    const actions = {
        [CHANGE_SUSPENSION_FORM]: (state, payload) => ({ ...state, form: { ...state.form, ...payload } }),
        [UPDATE_FORM_ERRORS]: (state, payload) => ({ ...state, formErrors: payload })
    }

    return actions[action.type] ? actions[action.type](state, action.payload) : state
};

export default reducer;