

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateBoxes, updateItems, createWheel, deleteWheel, calculatePrice, updateRewards, deleteBox, createBox } from "./reward-actions";
import Pagination from '../shared/pagination';
import Select from 'react-select';

import { Link } from 'react-router-dom';
import { updateDepositBonuses } from "./actions";
import { Translator } from "../shared/translator";
import { permissionsConstants, PermissionsFragment } from "../app/permissions-fragment";
import './style.css';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

class MysteryBoxes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      category: null,
      page: 1,
      mystery_box_manifests: [],
      name_translation: {},
    }
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.update(this.state.page);
    this.props.updateItems();

  }

  addItem(id) {
    this.setState({
      mystery_box_manifests: this.state.mystery_box_manifests.concat({
        reward_id: id
      })
    });
  }

  editItem(id, { chance }) {
    const copy = JSON.parse(JSON.stringify(this.state.mystery_box_manifests));
    const item = copy.find(i => i.reward_id === id);

    item.chance = chance || item.chance;

    this.setState({
      mystery_box_manifests: copy
    },
      () => this.props.calculatePrice({
        mystery_box_manifests: this.normalizeManifests()
      })
    );

  }

  removeItem(id) {
    this.setState({
      mystery_box_manifests: this.state.mystery_box_manifests.filter(
        manifest => manifest.reward_id !== id
      )
    });
  }

  normalizeManifests() {
    const copy = JSON.parse(JSON.stringify(this.state.mystery_box_manifests));

    return copy.map(
      manifest => {
        manifest.chance = (parseFloat(manifest.chance) / 100).toFixed(6);
        return manifest;
      }
    )
  }
  create() {

    this.props.create({
      name: this.name.value,
      name_translation: this.state.name_translation,
      mystery_box_manifests: this.normalizeManifests(),
      file: this.state.file
    })
  }

  render() {

    const total_odds = this.state.mystery_box_manifests.reduce(
      (t, item) => t + parseFloat(item.chance || 0)
      , 0).toFixed(6);

    return (
      <div className="wheels">
        <PermissionsFragment feature={permissionsConstants.MYSTERY_BOXES_CREATE_BOX}>
          <div className="input-group">
            <h4>Create Mystery Box</h4>

            <input
              placeholder="Name of box (admin use only, ex. Daily - 3 Box Bronze)"
              ref={el => this.name = el}
            />

            <Translator
              languages={this.props.languages}
              contents={this.state.name_translation}
              onChange={
                name_translation => this.setState({ name_translation })
              }
              label={"Name Translation"}
            />

            <hr />

            <h4>Upload Image</h4>
            <input type="file" onChange={e => {
              this.setState({
                file: e.target.files[0]
              })
            }} />

            <hr />


            <h3>Add Rewards to Box</h3>
            <div className="items-select">
              {
                this.props.items
                  .filter(i => !this.state.mystery_box_manifests.map(m => m.reward_id).includes(i.id))
                  .map(
                    item =>
                      <div
                        onClick={
                          () => this.addItem(item.id)
                        }
                        className="wheel-item">
                        <img src={item.image_url} />
                        <div className="name">{item.name} - ID: {item.id}</div>
                        <div className="name">{item.description}</div>
                        <div className="type">{item.type}</div>
                        <div className="value">€{item.eur_value}</div>
                      </div>
                  )
              }
            </div>
            <h3>Set Odds</h3>
            <Table className="odds">
              <Thead>
                <Tr>
                  <Th>Item Name</Th>
                  <Th>Chance</Th>
                  <Th>EV</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  this.state.mystery_box_manifests.map(
                    manifest => {
                      const item = this.props.items.find(
                        i => i.id === manifest.reward_id
                      );
                      const ev = parseFloat(manifest.chance) / 100 * parseFloat(item.eur_value)
                      return (
                        <Tr>
                          <Td>
                            <img style={{ width: '20px' }} src={item.image_url} />
                            {item.name} (€{item.eur_value})
                          </Td>
                          <Td>
                            <input
                              value={manifest.chance}
                              onChange={
                                e => this.editItem(manifest.reward_id, {
                                  chance: e.target.value
                                })
                              }
                            />
                          </Td>
                          <Td>
                            {ev ? ev.toFixed(6) : 0}
                          </Td>
                          <Td>
                            <a
                              onClick={() => this.removeItem(
                                manifest.reward_id
                              )}
                            >
                              <i className="fas fa-trash-alt" />
                            </a>
                          </Td>
                        </Tr>
                      )
                    }
                  )
                }
                <Tr>
                  <Td />
                  <Td>Total Odds: {total_odds || 0}</Td>
                  <Td>€ EV: {this.props.price}</Td>
                </Tr>
              </Tbody>
            </Table>
            <br />

            <button
              disabled={+total_odds !== 100 || this.state.mystery_box_manifests.length < 2}
              onClick={() => this.create()}
            >Submit
            </button>
          </div>
          <hr />
        </PermissionsFragment>
        <PermissionsFragment feature={permissionsConstants.MYSTERY_BOXES_VIEW_LIST}>

          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Enabled</Th>
                <Th>€ EV</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <tbody>
              {
                this.props.records.map((r, i) => {
                  return (
                    <Tr key={i}>
                      <Td>{r.id}</Td>
                      <Td>
                        <Link to={`/casino/mystery-boxes/${r.id}`}>
                          {r.name}
                        </Link>
                      </Td>
                      <Td>
                        {r.enabled ? <i className="fas fa-check" /> : null}
                      </Td>
                      <Td>
                        € {r.eur_price}
                      </Td>
                      <Td>
                        <a
                          onClick={() => this.props.delete(
                            r.id
                          )}
                        >
                          <i className="fas fa-trash-alt" />
                        </a>
                      </Td>
                    </Tr>
                  )
                })
              }
            </tbody>
          </Table>


          <Pagination
            total_pages={this.props.meta.total_pages}
            update={page => {
              this.setState({ page }, () => this.update());
            }}
          />
        </PermissionsFragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.rewards.mystery_boxes,
  price: state.rewards.price,
  items: state.rewards.items.records,
  languages: state.app.languages
});

const mapDispatchToProps = dispatch => ({
  updateBonuses: page => dispatch(updateDepositBonuses(page)),
  update: page => dispatch(updateBoxes()),
  calculatePrice: props => dispatch(calculatePrice(props)),
  updateItems: () => dispatch(updateRewards()),
  delete: id => dispatch(deleteBox(id)),
  create: props => dispatch(createBox(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MysteryBoxes);