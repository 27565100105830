import moment from "moment-timezone";

const FORMAT_PATTERN_WITH_SECONDS = "YYYY-MM-DD HH:mm:ss";
const FORMAT_PATTERN_ONLY_DATE = "YYYY-MM-DD";
const FORMAT_PATTERN_WITH_MINUTES = "YYYY-MM-DD HH:mm";

const getDateWithTimezone = (date) => {
  const timezone = localStorage.getItem("preferredTimezone") || "None";
  const momentValue = moment(date);
  if (timezone && timezone !== "None") momentValue.tz(timezone);
  return momentValue;
};

function withSeconds(date) {
  return getDateWithTimezone(date).format(FORMAT_PATTERN_WITH_SECONDS);
}

function withMinutes(date) {
  return getDateWithTimezone(date).format(FORMAT_PATTERN_WITH_MINUTES);
}

function onlyDate(date) {
  return getDateWithTimezone(date).format(FORMAT_PATTERN_ONLY_DATE);
}

export const format = {
  withSeconds,
  withMinutes,
  onlyDate,
};
