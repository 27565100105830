export const depositSchema = {
  "statuses": [
    {
      "status": "created"
    },
    {
      "status": "complete"
    },
    {
      "status": "cancelled"
    },
    {
      "status": "pending"
    }
  ]
};

