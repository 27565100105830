import React, { Component, Fragment } from 'react';
import Pagination from '../../shared/pagination';
import { Link } from 'react-router-dom';
import './style.css'
import Select from 'react-select';
import { toast } from "react-toastify";
import { format } from '../../shared/utils/dates';
import moment from 'moment';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

const schema = {
  types: [
    { "type": "pix_pagsmile" },
    { "type": "mbtc" },
    { "type": "skrill" },
    { "type": "credit_card" },
    { "type": "neteller" },
    { "type": "interac_etransfer" },
    { "type": "ecopayz" },
    { "type": "muchbetter" },
    { "type": "bank_iban" },
    { "type": "bank_domestic" },
    { "type": "qiwi" },

    { "type": "visa" },
    { "type": "yandex" },
    { "type": "bank_domestic_switch" }
  ]
  ,
  statuses: [{ "status": "created" }, { "status": "processing" }, { "status": "pending_review" }, { "status": "waiting_input" }, { "status": "complete" }, { "status": "cancelled" }, { "status": "refunded" }],
};

// TODO: add an endpoint to get them from server
const FLAG_TYPES = [
    "WAGERED_VS_LAST_DEPOSIT",
    "TOTAL_WAGERED_VS_DEPOSITED",
    "TOTAL_WAGERED_VS_DEPOSITED_REFUND_ID",
    "WITHDRAWAL_LIMIT",
    "NGR_VS_DEPOSITED_REFUND_ID",
    "TOTAL_BONUSES_TRANSFERRED",
    "TOTAL_BONUSES_TRANSFERRED_14",
    "TOTAL_BONUSES_TRANSFERRED_28",
    "PERCENTAGE_CARD_DEPOSITS",
    "CARD_DEPOSITS_VS_TOTAL_DEPOSITED_LAST_30_DAYS",
    "BONUS_BETS_OVER_LIMIT",
    "BET_ON_FLAGGED_GAME",
    "BETS_REFUNDED_TO_PLACED_RATIO",
    "EMAIL_CHANGED_LAST_30_DAYS",
    "TAX_ID_CHANGED_LAST_30_DAYS",
    "DELAY",
    "BONUSES_CREDITED_VS_TOTAL_DEPOSIT",
    "HAS_ZERO_AMOUNT_BET",
    "HAS_REFERRAL_CONVERSION_AFTER_LAST_COMPLETED_WITHDRAWAL",
    "HAS_RECENT_SPORTS_BET",
    "TOTAL_ROLLBACK_OVERDRAFT",
    "WEEKLY_CASHBACK_LIMIT",
    "HAS_REFERRAL_CONVERSION_AND_TOTAL_DEPOSITED_LESS_THAN_LIMIT",
    "REFERRAL_WITH_ENOUGH_WAGERING_VS_DEPOSITS",
    "RECENT_REFERRALS_OVER_LIMIT",
    "HAS_DEPOSIT_BONUS_HAS_ONE_DEPOSIT_COMPLETED_AND_HAS_NO_WITHDRAWAL"
]


export default class AutomatedWithdrawals extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      total_pages: 1,
      order: 'id_desc',
      type: '',
      status: '',
      withdrawals: [],
      details: null,
      approved: null,
      kyc_level: null,
      userId: null,
      withdrawalId: null,
      flag_type: null
    }
  }

  componentDidMount() {
    this.fetchWithdrawals();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pageChanged = this.state.page !== prevState.page;
    const filterChanged = this.state.type !== prevState.type;
    const statusChanged = this.state.status !== prevState.status;
    const approvedChanged = this.state.approved !== prevState.approved;
    const levelChanged = this.state.kyc_level !== prevState.kyc_level;
    const flagsTypeChanged = this.state.flag_type !== prevState.flag_type;
    const userIdChanged = this.state.userId !== prevState.userId;
    const withdrawaldIdChanged = this.state.withdrawalId !== prevState.withdrawalId;

    const stateChanged = pageChanged || filterChanged || statusChanged || approvedChanged || levelChanged || userIdChanged || withdrawaldIdChanged || flagsTypeChanged;

    if (stateChanged) {
      this.fetchWithdrawals();
    }
  }

  async fetchWithdrawals() {
    this.setState({ isLoading: true });
    try {
      const { records, meta } = await window.api.get('/admin/payments/withdrawals/automated', {
        params: {
          page: this.state.page,
          type: this.state.type,
          status: this.state.status,
          approved: this.state.approved,
          kyc_level: this.state.kyc_level,
          flag_type: this.state.flag_type,
          userId: this.state.userId,
          withdrawalId: this.state.withdrawalId,
        }
      });

      this.setState({ total_pages: meta.total_pages, withdrawals: records });
    } catch (err) {
      toast.error(err.message)
    }
    this.setState({ isLoading: false });
  }

  async editWithdrawal(id, data) {
    try {
      this.setState({ actionLoading: true });
      await window.api.put(`/admin/payments/withdrawals/${id}`, data);
      await this.fetchWithdrawals();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  render() {

    const loading = false;

    return (
      <div className="withdrawals automated-withdrawals" style={{ position: 'relative', 'min-height': '500px' }}>

        {this.state.isLoading &&
          <div className={"loading-overlay"}>
            <h2>Searching, please wait ...</h2>
            <div className="loader" />
          </div>
        }

        <div className="row">
          <div className="col-md-2">
            <h4>Type</h4>
            <Select
              name="type"
              value={this.state.type}
              onChange={option => {
                this.setState({ type: option ? option.value : null });
              }}
              options={
                [{ value: '', label: 'Any' }].concat(
                  schema.types.map(
                    val => {
                      return { value: val.type, label: val.type }
                    }
                  )
                )}
            />
          </div>
          <div className="col-md-2">
            <h4>Status</h4>
            <Select
              name="type"
              value={this.state.status}
              onChange={option => {
                this.setState({ status: option ? option.value : null });
              }}
              options={schema.statuses.map(
                val => {
                  return { value: val.status, label: val.status }
                }
              )}
            />
          </div>

          <div className="col-md-2">
            <h4>Approved</h4>
            <Select
              name="type"
              value={this.state.approved}
              onChange={option => {
                this.setState({ approved: option ? option.value : null });
              }}
              options={[
                { value: true, label: "Approved" },
                { value: false, label: "Flagged" }
              ]}
            />
          </div>

          <div className="col-md-2">
            <h4>KYC Level</h4>
            <Select
              name="type"
              value={this.state.kyc_level}
              onChange={option => {
                this.setState({ kyc_level: option ? option.value : null });
              }}
              options={[
                { value: 1, label: "Level 1" },
                { value: 2, label: "Level 2" },
                { value: 3, label: "Level 3" }
              ]}
            />
          </div>
          <div className="col-md-2">
            <h4>User ID</h4>
            <input
              name="userId"
              type='number'
              className='automated-input'
              onChange={(evt) => {
                this.setState({ userId: evt.target.value ? evt.target.value : null })
              }}
            />
          </div>
          <div className="col-md-2">
            <h4>Withdrawal ID</h4>
            <input
              name="withdrawalId"
              type='number'
              className='automated-input'
              onChange={(evt) => {
                this.setState({ withdrawalId: evt.target.value ? evt.target.value : null })
              }}
            />
          </div>
          <div className="col-md-2">
            <h4>Flag Type</h4>
            <Select
                name="type"
                value={this.state.flag_type}
                onChange={option => {
                  this.setState({ flag_type: option ? option.value : null });
                }}
                options={FLAG_TYPES.map(value => ({value, label: value}))}
            />
          </div>
        </div>

        <br />
        <br />
        <br />

        <Table>
          <Thead>
            <Tr>
              <Th>Requested At</Th>
              <Th>ID</Th>
              <Th>User</Th>
              <Th>KYC Level</Th>
              <Th>Type</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Evaluation</Th>
              <Th>View Details</Th>
              <Th>Last Scanned</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              this.state.withdrawals.map((r, i) => {

                return (
                  <Fragment>
                    <Tr key={r.id + "first"} style={{ borderBottom: this.state.details === r.id ? 'none' : '' }}>
                      <Td>{format.withMinutes(r.created_at)}</Td>

                      <Td>
                        <Link to={`/payments/withdrawals/${r.id}`}>
                          {r.id}
                        </Link>
                      </Td>
                      <Td>
                        <Link to={`/users/${r.user.id}`}>
                          {r.user.username}
                        </Link>
                      </Td>
                      <Td>
                        {r.fraud_checks.kycLevel}
                      </Td>
                      <Td>
                        <Link to={`/payments/withdrawals/${r.id}`}>
                          {r.type}
                        </Link>
                      </Td>
                      <Td>{Math.abs(r.amount)} {r.currency_type}</Td>
                      <Td>{r.status}</Td>
                      <Td>{r.approved ? "Approved" : "Flagged"}</Td>
                      <Td>
                        <button className={"small"} onClick={() => {
                          this.setState({
                            details: r.id
                          })
                        }}>View Details
                        </button>
                      </Td>
                      <Td>{format.withMinutes(r.last_scanned)}</Td>
                      <Td>
                        {
                          ['pending_review'].includes(r.status) &&
                          <div>
                            {
                              r.status === 'pending_review' &&
                              <button
                                className="small approve-button"
                                disabled={loading}
                                onClick={
                                  () => this.editWithdrawal(
                                    r.id,
                                    {
                                      status: 'processing',
                                      type: r.type
                                    }
                                  ).then(() => this.fetchWithdrawals())
                                }>
                                {
                                  loading ?
                                    'Loading...'
                                    :
                                    'Approve'
                                }
                              </button>
                            }

                            {
                              ['pending_review', 'created'].includes(r.status) &&
                              <button
                                className="small"
                                disabled={loading}
                                onClick={() =>
                                  this.editWithdrawal(
                                    r.id,
                                    {
                                      status: 'refunded'
                                    }
                                  ).then(() => this.fetchWithdrawals())
                                }>
                                {
                                  loading ?
                                    'Loading...'
                                    :
                                    'Refund'
                                }
                              </button>
                            }
                          </div>
                        }
                      </Td>
                    </Tr>


                    {this.state.details === r.id && !r.evaluation && (
                      <Tr key={r.id + "second"}>
                        <Td colSpan={11}>
                          Total Withdrawn: {r.fraud_checks.totalWithdrawn}
                          <span className={"separator"} /> Total Wagered VS Deposited: {r.fraud_checks.totalWageredVsDeposited}
                          <span className={"separator"} /> Wagered vs Last Deposit: {(+r.fraud_checks.wageredSinceLasTdeposit / +r.fraud_checks.lasTdeposit).toFixed(2)}

                          {r.fraud_checks.kycLevel === 1 ? (
                            <>
                              <span className={"separator"} /> Has Card Deposit: {r.fraud_checks.hasCardDeposit ? "true" : "false"}
                            </>
                          ) : (
                            <>
                              <span className={"separator"} /> Percentage Of Card Deposits: {r.fraud_checks.percentageCardDeposit}%
                            </>
                          )}

                          {r.fraud_checks.kycLevel === 1 ? (
                            <>
                              <span className={"separator"} /> Total Bonuses Transferred: {r.fraud_checks.totalTransferredBonus}
                            </>
                          ) : (
                            <>
                              {moment(r.last_scanned).diff(r.fraud_checks.lastBonusTransferAt, "days", true) > 30 ? (
                                <>
                                  <span className={"separator"} /> SKIPPED OLD Total Bonuses Transferred: {r.fraud_checks.totalTransferredBonus}
                                </>
                              ) : (
                                <>
                                  <span className={"separator"} /> Total Bonuses Transferred: {r.fraud_checks.totalTransferredBonus}
                                </>
                              )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )}


                    {this.state.details === r.id && r.evaluation && (
                      <Tr key={r.id + "second"}>
                        <Td colSpan={6} style={{ verticalAlign: 'top' }}>
                          <h3 style={{ margin: 0 }}>Flags</h3>
                          {r.flags.map(flag => {

                            return (
                              <div className={"flag"} style={{ color: flag.eval ? 'green' : 'red' }}>
                                {flag.title}
                              </div>

                            )

                          })}

                          <h3 style={{ marginTop: '16px', marginBottom: 0 }}>Evaluation</h3>
                          {r.evaluation.map(action => {

                            switch (action.type) {

                              case 'APPROVE':
                                return <div className={"eval"}>
                                  Approve

                                </div>

                              case 'REFUND':
                                return <div className={"eval"}>
                                  Refund

                                  <br />
                                  set note: {action.payload.note}
                                </div>


                              case 'CREATE_ID_CHECK':
                                return <div className={"eval"}>
                                  Create ID KYC verification
                                </div>

                            }
                            return (
                              <div className={"eval"}>
                                {action.type}

                              </div>

                            )

                          })}

                        </Td>
                        <Td colSpan={5} style={{ verticalAlign: 'top' }}>
                          <h3 style={{ margin: 0 }}>Calculation Values</h3>
                          {Object.keys(r.fraud_checks).map(key => {

                            if (key === 'requestedAt') {
                              return (
                                <div>{key}: {format.withMinutes(r.fraud_checks[key])}</div>
                              )
                            }

                            return (
                              <div>{key}: {r.fraud_checks[key]}</div>
                            )

                          })}
                        </Td>
                      </Tr>
                    )}
                  </Fragment>

                )
              })
            }
          </Tbody>
        </Table>


        <Pagination
          total_pages={this.state.total_pages}
          update={page => {
            this.setState({ page });
          }}
        />

      </div>
    )
  }
}