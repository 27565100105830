import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  getGroups,
  createGroup,
  changeGroupStatus,
} from "system/Groups/api.js";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { toast } from "react-toastify";

export default function Groups() {
  const [newGroup, setNewGroup] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const data = await getGroups({ page, search });
    setRows(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const nextPage = () => setPage((prevState) => prevState + 1);
  const backPage = () => setPage((prevState) => prevState - 1);

  const onCreate = async () => {
    const str = newGroup.replace(/\s/g, "_");
    if (str) {
      const newData = await createGroup(str);
      const sortedData = newData.sort((a, b) => a.name.localeCompare(b.name));
      setNewGroup("");
      setRows(sortedData);
    } else {
      toast.error(`The name of the group can't be empty`);
    }
  };

  const onSearch = () => {
    fetchData();
  };

  const changeStatus = async (groupId, name, status) => {
    const newData = await changeGroupStatus(
      groupId,
      name,
      status ? "enable" : "disable"
    );
    setRows(newData);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <input
          type="text"
          style={{ width: "50%" }}
          onChange={(e) => setNewGroup(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && onCreate()}
          value={newGroup}
          label="Create group"
          placeholder="Create group and press enter or click the Create button"
          id="outlined-size-small"
          size="small"
        />
        <button size="small" variant="contained" onClick={onCreate}>
          Create
        </button>
      </div>

      <div
        style={{
          width: "50%",
          margin: "30px 0",
        }}
      >
        <input
          style={{
            width: "75%",
          }}
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <button
          style={{
            marginLeft: 15,
          }}
          variant="contained"
          size="small"
          onClick={onSearch}
        >
          Search
        </button>
      </div>

      {loading ? (
        <div className={"loading-overlay"}>
          <h2>Fetching, please wait ...</h2>
          <div className="loader" />
        </div>
      ) : (
        rows.length > 0 && (
          <>
            <Table>
              <Thead>
                <Tr>
                  <Th>Group Name</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>

              <Tbody>
                {rows.map((row) => (
                  <Tr key={row.id}>
                    <Td>
                      <Link to={`groups/${row.id}`}>{row.name}</Link>
                    </Td>
                    <Td>
                      <Switch
                        checked={row.enabled}
                        color="success"
                        onClick={() =>
                          changeStatus(row.id, row.name, !row.enabled)
                        }
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <div
              style={{
                display: "flex",
                placeContent: "center flex-end",
                padding: "20px",
                flexFlow: "row nowrap",
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              {page > 1 && <div onClick={backPage}>Back</div>}

              <div onClick={nextPage}>Next</div>
            </div>
          </>
        )
      )}
    </>
  );
}
