import React, { Fragment, useState } from "react";
import { WithdrawalRefundControls } from "./withdrawal-refund-controls";
import Select from "react-select";
import { permissionsConstants, PermissionsFragment, ConditionalPermissionsFragment } from "../../app/permissions-fragment";

export const WithdrawalMiniCommands = ({ withdrawal, loading, edit, editPspAmount, fetchWithdrawal }) => {

  const isComplete = ['complete', 'refunded'].includes(withdrawal.status);
  const isIban = withdrawal.type === 'bank_iban';
  const isWaitingInput = withdrawal.status === 'waiting_input';
  const isPendingReview = withdrawal.status === 'pending_review';

  const [newKycLevel, setNewKycLevel] = useState(withdrawal.user.kyc_level)

  let psp_payload = {
    ...withdrawal.payload
  }
  if (withdrawal.psp_amount) {
    psp_payload.psp_amount = withdrawal.psp_amount;
  }

  return (
    <Fragment>

      <div className={"line-item kyc-level"}>
        <div className="label">KYC Level</div>

        <Select
          name="type"
          value={newKycLevel !== undefined ? newKycLevel : (withdrawal.user.kyc_level || 1)}
          onChange={option => {
            option = option || { value: withdrawal.user.kyc_level };
            setNewKycLevel(option.value)
          }}
          options={[
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
          ]}
        />
        <br />

        <PermissionsFragment feature={permissionsConstants.USER_UPDATE_KYC_LEVEL}>
          <button className={"small"} disabled={(withdrawal.user.kyc_level || 1) === newKycLevel || !newKycLevel} onClick={() => {
            window.api.post('/admin/users/' + withdrawal.user.id + '/customer/kyc_level', {
              kyc_level: newKycLevel
            }).then(() => {
              fetchWithdrawal()
            });
          }}>Save changes</button>
        </PermissionsFragment>


      </div>

      <div className={"line-item"}>
        <div className={"label"}>Current status</div>
        <div className={"value"}>{withdrawal.status}</div>

      </div>
      <div className={"line-item"}>
        <ConditionalPermissionsFragment feature={permissionsConstants.WITHDRAWALS_COMMANDS} condition={!isPendingReview}>
          {
            isIban && isWaitingInput &&
            <button
              disabled={loading}
              onClick={
                () => {

                  if (window.confirm('Are you sure you have sent this IBAN withdrawal?')) {
                    edit({
                      status: 'complete'
                    })
                  }
                }
              }>
              {
                loading ?
                  'Loading...'
                  :
                  'Complete IBAN Withdrawal'
              }
            </button>
          }
          {

            isPendingReview &&
            <button
              className="small approve-button"
              disabled={loading}
              onClick={
                () => {
                  edit({
                    status: 'processing',
                    type: withdrawal.type
                  })

                }
              }>
              {
                loading ?
                  'Loading...'
                  :
                  'Approve'
              }
            </button>
          }
        </ConditionalPermissionsFragment>

        <PermissionsFragment feature={permissionsConstants.WITHDRAWALS_EDIT_PSP_AMOUNT}>
          <button
            className="small"
            disabled={loading}
            onClick={() => {
              const amount = window.prompt('Enter amount to set withdrawal amount to:');

              if (amount) {
                edit({
                  amount: amount
                })
              }
            }}>
            {
              loading ?
                'Loading...'
                :
                'Edit Amount'
            }
          </button>

          <button
            className="small"
            disabled={loading}
            onClick={() => {
              const amount = window.prompt('Enter amount to set withdrawal psp_amount to: (leave blank to remove psp_amount)');

              if (amount) {
                editPspAmount({
                  psp_amount: amount
                })
              } else {
                editPspAmount({
                  psp_amount: "unset"
                })
              }
            }}>
            {
              loading ?
                'Loading...'
                :
                'Edit psp_amount'
            }
          </button>
        </PermissionsFragment>
        <ConditionalPermissionsFragment feature={permissionsConstants.WITHDRAWALS_COMMANDS} condition={!isPendingReview}>
          {!isPendingReview &&
            <button
              className="small"
              disabled={loading}
              onClick={() => {

                edit({
                  status: 'pending_review'
                }
                )

              }}>
              {
                loading ?
                  'Loading...'
                  :
                  'Set Pending'
              }
            </button>
          }
          {!isComplete &&
            <Fragment>
              <button
                className="small"
                disabled={loading}
                onClick={() => {
                  const note = window.prompt('Enter a note for why this withdrawal was cancelled (user visible):');

                  if (note) {
                    edit({
                      status: 'cancelled',
                      note: note
                    }
                    )
                  }
                }}>
                {
                  loading ?
                    'Loading...'
                    :
                    'Cancel'
                }
              </button>
              <button
                className="small"
                disabled={loading}
                onClick={() => {
                  const note = window.prompt('Enter a note for why this withdrawal was completed (ex. refunded 1123823,1123919 (paymentiq ids):');

                  if (note) {
                    edit({
                      status: 'complete',
                      note: note
                    })
                  }
                }}>
                {
                  loading ?
                    'Loading...'
                    :
                    'Complete'
                }
              </button>
            </Fragment>
          }
        </ConditionalPermissionsFragment>
      </div>
      <ConditionalPermissionsFragment feature={permissionsConstants.WITHDRAWALS_COMMANDS} condition={!isPendingReview}>

        <div className={"line-item"}>
          <WithdrawalRefundControls withdrawal={withdrawal} loading={loading} edit={edit} />

        </div>
      </ConditionalPermissionsFragment>
    </Fragment>
  )
}