import * as types from './types';

export const updateCurrencyLimits = () => {
  return async dispatch => {
    dispatch({
      type: types.CURRENCY_LIMITS_UPDATE,
      payload: await window.api.get('/admin/payments/providers')
    })
  }

}

export const updateCurrencyLimit = id => {
  return async dispatch => {
    dispatch({
      type: types.CURRENCY_LIMIT_UPDATE,
      payload: await window.api.get('/admin/payments/providers/' + id)
    })
  }
}



export const updateLimit = (limit_id, value) => {
  return async dispatch => {

    await window.api.put('/admin/payments/providers/' + limit_id, { currency_limit_mutation: value });
    dispatch(updateCurrencyLimit(limit_id))
  }
}


export const updateDisplayName = (id) => {
  return async dispatch => {
    dispatch({
      type: types.DISPLAY_NAME_UPDATE,
      payload: await window.api.get('/admin/payments/providers/' + id)
    })
  }
}


export const updateDisplayNameTranslations = (id, displayName, displayNameTranslations) => {
  return async dispatch => {
    await window.api.put('/admin/payments/providers/' + id, { display_name: displayName, display_name_translations: displayNameTranslations });
    dispatch(updateDisplayName(id))
  }
}