import React from "react";
import { NavLink } from "react-router-dom";

export const ListLink = () => (
  <NavLink exact to="/system/announcements">
    List
  </NavLink>
);
export const CreateLink = () => (
  <NavLink exact to="/system/announcements/new">
    Create
  </NavLink>
);
