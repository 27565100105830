import { SET_SELECTED_AFFILIATE, SET_SELECTED_CAMPAIGN } from './types';

export const setSelectedAffiliate = (campaign) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_AFFILIATE,
      payload: campaign,
    })
  }
}

export const setSelectedCampaign = (campaign) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CAMPAIGN,
      payload: campaign,
    })
  }
}
