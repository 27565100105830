import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
class MinesBetHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {},
      query: ''
    }
  }


  fetch() {
    this.setState({ loading: true })
    window.api.get(`/admin/mines_bets/` + this.state.query).then(
      data => this.setState({ data })
    ).finally(
      () => this.setState({ loading: false })
    );
  }

  render() {
    const data = this.state.data;

    if (this.state.loading) {
      return (
        <div className="bet-page">
          <h2>Loading...</h2>
        </div>
      )
    }

    return (
      <div className="bet-page">
        <div className="user-search-row">
          <input
            value={this.state.query}
            onChange={e => {
              this.setState({ query: e.target.value });
            }}
            placeholder="Type a Bet ID or Round ID"
          />

          <button
            disabled={this.state.isLoading}
            className="red"
            onClick={() => this.fetch()}
          >
            {this.state.isLoading ? 'Loading...' : 'Search'}
          </button>
        </div>

        <div className="row">
          <div className="col-md-6">
            <pre>
              {JSON.stringify(data, null, 4)}
            </pre>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(MinesBetHistory)