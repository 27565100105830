import { Stack, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";

export default function SuspendForm({ form, formErrors, onChange, suspendAccountReasonOptions, children }) {
    const handleOnChange = (evt) => {
        onChange(evt.target.name, evt.target.value)
    }

    return (
        <Stack direction='row' gap={2} autoComplete="off">
            <FormControl>
                <TextField
                    name="duration"
                    label="Duration (days)"
                    type="number"
                    value={form.duration}
                    onChange={handleOnChange}
                    InputProps={{
                        inputProps: {
                            min: 0,
                            pattern: '[0-9]*',

                        },
                    }}
                    autoComplete="off"
                    error={!!formErrors?.duration}
                    helperText={formErrors?.duration}
                />
            </FormControl>
            <FormControl sx={{ minWidth: 120 }} >
                <InputLabel htmlFor="reason">Reason</InputLabel>
                <Select
                    id="reason"
                    name="reason"
                    value={form.reason}
                    label="Reason"
                    onChange={handleOnChange}
                >
                    {suspendAccountReasonOptions.map(reason => (
                        <MenuItem key={reason.id} value={reason.id}>{reason.reason}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ flex: 1 }} >
                <TextField
                    name="note"
                    label="Note"
                    value={form.note}
                    onChange={handleOnChange}
                    autoComplete="off"
                />
            </FormControl>
            {children}
        </Stack>
    )
}