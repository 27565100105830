import React, { useState, useEffect } from 'react';
import Pagination from '../shared/pagination';

import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFortuneDoubleBets } from '../originals/originals-games-api';
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import TooltipFreeRoundID from './components/TooltipFreeRoundID';
import TooltipRoundIDExplanation from './components/TooltipRoundIDExplanation';

const UserFortuneDoubleBets = () => {
   const [data, setData] = useState({ total_pages: 0, bets: [] });
   const [isLoading, setIsLoading] = useState(false);

   const [page, setPage] = useState(1);

   const { id } = useParams();

   const fetch = async () => {
      setIsLoading(true);
      try {
         const data = await getFortuneDoubleBets(id, { page });
         setData({ total_pages: data.total_pages, bets: data.records });
      } catch (err) {
         toast.error(err.message);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      fetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [page]);

   return (
      <div style={{ position: 'relative', minHeight: 500 }}>
         {isLoading && (
            <div className={'loading-overlay'}>
               <h2>Loading, please wait ...</h2>
               <div className='loader' />
            </div>
         )}

         <Table>
            <Thead>
               <Tr>
                  <Th>
                     <TooltipRoundIDExplanation>
                        Round ID 
                     </TooltipRoundIDExplanation>
                  </Th>
                  <Th>Room Number</Th>
                  <Th>Game Started At</Th>
                  <Th>Game Settled At</Th>
                  <Th>Bet Placed At</Th>
                  <Th>Bet Settled At</Th>
                  <Th>Amount</Th>
                  <Th>Free bet</Th>
                  <Th>Bet Color</Th>
                  <Th>Winnings</Th>
                  <Th>Winning Color</Th>
                  <Th>Status</Th>
                  <Th>Multiplier</Th>
                  <Th>Profit</Th>
               </Tr>
            </Thead>

            <Tbody>
               {data.bets.map((b, i) => {
                  const {
                     remote_round_id,
                     game_started_at,
                     game_settled_at,
                     bet_placed_at,
                     bet_settled_at,
                     multiplier = 0,
                     free_bet,
                     winning_color,
                     status,
                     bet_color,
                     currency_type,
                     amount,
                     round_id,
                     fortune_double_room,
                  } = b;

                  const winnings =
                     +multiplier > 0 ? parseFloat(+amount * +multiplier).toFixed(4) : -amount;
                  const profit = winnings > 0 ? winnings - amount : winnings;

                  let player_bet_color = 'black';
                  switch (bet_color) {
                     case 'white':
                        player_bet_color = 'yellow';
                        break;
                     case 'red':
                        player_bet_color = 'red';
                        break;
                     default:
                        player_bet_color = 'purple';
                        break;
                  }

                  let win_color = 'black';
                  switch (winning_color) {
                     case 'white':
                        win_color = 'yellow';
                        break;
                     case 'red':
                        win_color = 'red';
                        break;
                     default:
                        win_color = 'purple';
                        break;
                  }

                  return (
                     <Tr key={i}>
                        <Td>
                           {
                              free_bet ? 
                                 (<TooltipFreeRoundID>{round_id}</TooltipFreeRoundID>) :
                                 (<Link to={`/casino/game-provider-rounds/${remote_round_id}`}>
                                    {remote_round_id}
                                 </Link>)
                           }
                        </Td>
                        <Td>{fortune_double_room}</Td>
                        <Td>{format.withSeconds(game_started_at)}</Td>
                        <Td>{format.withSeconds(game_settled_at)}</Td>
                        <Td>{format.withSeconds(bet_placed_at)}</Td>
                        <Td>{format.withSeconds(bet_settled_at)}</Td>
                        <Td>
                           {amount} {currency_type}
                        </Td>
                        <Td>{free_bet ? 'Yes' : 'No'}</Td>
                        <Td>{player_bet_color}</Td>
                        <Td className={profit >= 0 ? 'good' : ''}>
                           {winnings > 0 ? `${winnings} ${currency_type}` : '-'}
                        </Td>
                        <Td>{win_color}</Td>
                        <Td>{status}</Td>
                        <Td>{multiplier}</Td>
                        <Td className={winnings >= 0 ? 'good' : 'bad'}>
                           {parseFloat(profit).toFixed(4)} {currency_type}
                        </Td>
                     </Tr>
                  );
               })}
            </Tbody>
         </Table>

         <Pagination total_pages={data.total_pages} update={(page) => setPage(page)} />
      </div>
   );
};
export default UserFortuneDoubleBets;
