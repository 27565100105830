import React, { Fragment } from "react";
import { WithdrawalRefundControls } from "./withdrawal-refund-controls";
import { permissionsConstants, ConditionalPermissionsFragment, PermissionsFragment, GroupPermissionsFragment, permissionGroups } from "../../app/permissions-fragment";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

export const WithdrawalCommands = ({ withdrawal, loading, edit, editPspAmount }) => {

  const isComplete = ['complete', 'refunded'].includes(withdrawal.status);
  const isIban = withdrawal.type === 'bank_iban';
  const isWaitingInput = withdrawal.status === 'waiting_input';
  const isPendingReview = withdrawal.status === 'pending_review';

  let psp_payload = {
    ...withdrawal.payload
  }
  if (withdrawal.psp_amount) {
    psp_payload.psp_amount = withdrawal.psp_amount;
  }

  return (
    <section className="withdrawal-controls">
      <div className="row">
        <div className="col-md-6">
          <div>
            <h2>Commands</h2>

            <h5>PSP Payload</h5>
            <pre>{JSON.stringify(psp_payload, null, 4)}</pre>
            <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS_FULL_VIEW}>
              <h5>Current status: {withdrawal.status}</h5>
            </GroupPermissionsFragment>
          </div>
          <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS_FULL_VIEW}>
            <ConditionalPermissionsFragment feature={permissionsConstants.WITHDRAWALS_COMMANDS} condition={!isPendingReview}>
              {
                isIban && isWaitingInput &&
                <button
                  disabled={loading}
                  onClick={
                    () => {

                      if (window.confirm('Are you sure you have sent this IBAN withdrawal?')) {
                        edit({
                          status: 'complete'
                        })
                      }
                    }
                  }>
                  {
                    loading ?
                      'Loading...'
                      :
                      'Complete IBAN Withdrawal'
                  }
                </button>
              }
              {

                isPendingReview &&
                <button
                  className="small approve-button"
                  disabled={loading}
                  onClick={
                    () => {
                      edit({
                        status: 'processing',
                        type: withdrawal.type
                      })

                    }
                  }>
                  {
                    loading ?
                      'Loading...'
                      :
                      'Approve'
                  }
                </button>
              }
            </ConditionalPermissionsFragment>
            <PermissionsFragment feature={permissionsConstants.WITHDRAWALS_EDIT_PSP_AMOUNT}>
              <button
                className="small"
                disabled={loading}
                onClick={() => {
                  const amount = window.prompt('Enter amount to set withdrawal amount to:');

                  if (amount) {
                    edit({
                      amount: amount
                    })
                  }
                }}>
                {
                  loading ?
                    'Loading...'
                    :
                    'Edit Amount'
                }
              </button>
              <button
                className="small"
                disabled={loading}
                onClick={() => {
                  const amount = window.prompt('Enter amount to set withdrawal psp_amount to: (leave blank to remove psp_amount)');

                  if (amount) {
                    editPspAmount({
                      psp_amount: amount
                    })
                  } else {
                    editPspAmount({
                      psp_amount: "unset"
                    })
                  }
                }}>
                {
                  loading ?
                    'Loading...'
                    :
                    'Edit psp_amount'
                }
              </button>
            </PermissionsFragment>
            <ConditionalPermissionsFragment feature={permissionsConstants.WITHDRAWALS_COMMANDS} condition={!isPendingReview}>
              {!isPendingReview &&
                <button
                  className="small"
                  disabled={loading}
                  onClick={() => {

                    edit({
                      status: 'pending_review'
                    }
                    )

                  }}>
                  {
                    loading ?
                      'Loading...'
                      :
                      'Set Pending'
                  }
                </button>
              }
              {!isComplete &&
                <Fragment>
                  <button
                    className="small"
                    disabled={loading}
                    onClick={() => {
                      const note = window.prompt('Enter a note for why this withdrawal was cancelled (user visible):');

                      if (note) {
                        edit({
                          status: 'cancelled',
                          note: note
                        }
                        )
                      }
                    }}>
                    {
                      loading ?
                        'Loading...'
                        :
                        'Cancel'
                    }
                  </button>
                  <button
                    className="small"
                    disabled={loading}
                    onClick={() => {
                      const note = window.prompt('Enter a note for why this withdrawal was completed (ex. refunded 1123823,1123919 (paymentiq ids):');

                      if (note) {
                        edit({
                          status: 'complete',
                          note: note
                        })
                      }
                    }}>
                    {
                      loading ?
                        'Loading...'
                        :
                        'Complete'
                    }
                  </button>
                </Fragment>
              }
              <hr />
              <WithdrawalRefundControls withdrawal={withdrawal} loading={loading} edit={edit} />
            </ConditionalPermissionsFragment>
          </GroupPermissionsFragment>
        </div>
        <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS_FULL_VIEW}>
          <div className="col-md-6">
            <h2>Real Chart of Accounts</h2>
            <Table>
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Sum</Th>
                </Tr>
              </Thead>

              <Tbody>
                {
                  withdrawal.real_chart_of_accounts.map((r, i) => {
                    return (
                      <Tr key={i}>
                        <Td>{r.type}</Td>
                        <Td>{r.sum}</Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
            </Table>
          </div>
        </GroupPermissionsFragment>
      </div>

    </section >
  )
}