import React from "react";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";

const Breadcrumb = ({ path }) => {
  const paths = path.split("/");
  const links = paths.reduce((acc, curr, idx, arr) => {
    const path = idx === 0 ? curr : `${acc.at(-1).path}/${curr}`;
    return acc.concat([{ label: curr, path }]);
  }, []);
  return (
    <div>
      {links.map((link) => (
        <NavLink to={link.path} key={link.path} className={styles.parentPath}>
          {link.label}
        </NavLink>
      ))}
    </div>
  );
};

export default Breadcrumb;
