import React, {Component} from 'react';
import { connect } from 'react-redux';
import '../casino/promo-code/promo.css';
import {NavLink, Route, Switch} from "react-router-dom";
import Finder from '../casino/promo-code/create-promo-code';
import Promo from '../casino/promo-code/edit-promo-code';
import Redeems from '../casino/promo-code/list-promo-code-redemptions';

class PromoCodes extends Component {

  render() {

    return (
      <div>
        <nav className="sub-nav">
          <NavLink exact to="/casino/promo-codes">
            Promo Codes
          </NavLink>
          <NavLink exact to="/casino/promo-codes/redeems">
            Redeems
          </NavLink>
        </nav>
        <Switch>
          <Route exact path="/casino/promo-codes" component={Finder}/>
          <Route exact path="/casino/promo-codes/redeems" component={Redeems}/>
          <Route path="/casino/promo-codes/:id" component={Promo}/>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);