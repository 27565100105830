import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "shared/Calendar/Calendar";

export const WrapperCalendar = ({ setStart, setEnd }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").toDate()
  );

  const [endDate, setEndDate] = useState(new Date());
  const handlerStartDate = (date) => {
    if (moment.isDate(date)) {
      setStart(date);
    }
  };
  const handlerEndDate = (date) => {
    if (moment.isDate(date)) {
      setEnd(date);
    }
  };

  useEffect(() => {
    handlerStartDate(startDate);
  }, [startDate]);
  useEffect(() => {
    handlerEndDate(endDate);
  }, [endDate]);


  return (
    <div>
      <Calendar
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        disableOPtionFromJoinDate={true}
        disableOPtionFromLastDeposit={true}
      />
    </div>
  );
};
