const SEARCH_UPDATE = 'users/SEARCH_UPDATE';
const USER_UPDATE = 'users/USER_UPDATE';
const USER_OFFERS_UPDATE = 'users/USER_OFFERS_UPDATE';
const USER_FINGERPRINTS_UPDATE = 'users/USER_FINGERPRINTS_UPDATE';
const USER_NOTIFICATION_UPDATE = 'users/USER_NOTIFICATION_UPDATE';
const USER_BROADCAST_UPDATE = 'users/BROADCAST_UPDATE';
const USER_DEPOSITS_UPDATE = 'users/USER_DEPOSITS_UPDATE';
const USER_WITHDRAWALS_DOWNLOADING_REPORT = 'users/USER_WITHDRAWALS_DOWNLOADING_REPORT';
const USER_WITHDRAWALS_DOWNLOADED_REPORT = 'users/USER_WITHDRAWALS_DOWNLOADED_REPORT';
const USER_WITHDRAWALS_UPDATE = 'users/USER_WITHDRAWALS_UPDATE';
const USER_DOCUMENTS_UPDATE = 'users/USER_DOCUMENTS_UPDATE';
const USER_DOCUMENT_UPDATE = 'users/USER_DOCUMENT_UPDATE';
const USER_ANTI_FRAUD_UPDATE = 'users/ANTI_FRAUD/UPDATE';
const USER_NOTES_UPDATE = 'users/NOTES/UPDATE';
const USER_PAYMENT_ACCOUNTS_UPDATE = 'user/PAYMENT/ACCOUNTS/UPDATE';
const USER_VERIFICATION_DOCUMENT_UPDATE = 'users/USER_VERIFICATION_DOCUMENT_UPDATE';
const USER_BONUS_ROUNDS_UPDATE = 'users/BONUS_ROUNDS/UPDATE';
const USER_REFERRALS_UPDATE = 'user/REFERRALS/UPDATE';
const USER_UPDATE_LOADING = 'user/UPDATE/LOADING';
const USER_OTP_FINGERPRINTS_UPDATE = 'users/USER_OTP_FINGERPRINTS_UPDATE';

const SELECT_CURRENCY = 'user/SELECT_CURRENCY';

export {
  SEARCH_UPDATE,
  USER_NOTES_UPDATE,
  USER_UPDATE,
  USER_OFFERS_UPDATE,
  USER_NOTIFICATION_UPDATE,
  USER_BROADCAST_UPDATE,
  USER_FINGERPRINTS_UPDATE,
  USER_DEPOSITS_UPDATE,
  USER_WITHDRAWALS_DOWNLOADING_REPORT,
  USER_WITHDRAWALS_DOWNLOADED_REPORT,
  USER_WITHDRAWALS_UPDATE,
  USER_DOCUMENTS_UPDATE,
  USER_DOCUMENT_UPDATE,
  USER_ANTI_FRAUD_UPDATE,
  USER_PAYMENT_ACCOUNTS_UPDATE,
  USER_VERIFICATION_DOCUMENT_UPDATE,
  USER_BONUS_ROUNDS_UPDATE,
  USER_REFERRALS_UPDATE,
  USER_UPDATE_LOADING,
  SELECT_CURRENCY,
  USER_OTP_FINGERPRINTS_UPDATE
}