import React, { Component } from "react";
import CountryList from "country-list";
import EditGamesInProvider from "./EditGamesInProvider";
import UpdateGameProviderName from "./UpdateGameProviderName";
import Select from 'react-select';
import { toast } from "react-toastify";

const initialState = {
  provider: {
    aggregator: {},
    blocked_countries: [],
  },
  reportLoading: false,
  gameProviderReport: {
    data: {},
  },
};

class GameProviderPage extends Component {
  constructor(props) {
    super(props);
    this.countries = CountryList().getData();

    this.state = initialState;
    this.baseUrl = "/admin/game_providers/" + this.props.match.params.slug;
  }

  componentDidMount() {
    this.fetchProvider();
  }

  async fetchProvider() {
    await window.api
      .get(`/admin/game_providers/${this.props.match.params.slug}`)
      .then((provider) => this.setState({ provider }));
    this.formatBlockedCountries()
  }

  formatBlockedCountries() {
    const bc = this.state.provider.blocked_countries
    const countriesArray = Object.keys(bc).length === 0 ? [] : this.state.provider.blocked_countries.toString().replace(/[{}]/g, '').split(',');

    const formattedCountries = countriesArray.map((countryCode) => {
      const option = this.countries.find((opt) => opt.code === countryCode);
      return { value: option.code, label: option.name };
    });
    this.setState({
      provider: {
        aggregator: { name: this.state.provider.aggregator.name },
        blocked_countries: formattedCountries
      }
    });
    return formattedCountries;
  };


  handleInputChange = (selectedOption) => {
    this.setState(prevState => {
      const isOptionAlreadyBlocked = prevState.provider.blocked_countries.some(option => option.value === selectedOption.value);
      let updatedBlockedCountries;

      if (isOptionAlreadyBlocked)
        updatedBlockedCountries = prevState.provider.blocked_countries.filter(option => option.value !== selectedOption.value);
      else
        updatedBlockedCountries = [...prevState.provider.blocked_countries, selectedOption];

      return {
        provider: {
          ...prevState.provider,
          blocked_countries: updatedBlockedCountries
        }
      };
    });
  };

  formatCountries(countries) {
    return countries.map((country) => { return { value: country.code, label: country.name } })
  }

  updateBlockedCountries = async () => {
    const blockedCountriesString = this.state.provider.blocked_countries.map(country => country.value.trim());
    const formattedBlockedCountries = `{${blockedCountriesString.join(',')}}`;

    try {
      await window.api
        .put(`/admin/game_providers/${this.props.match.params.slug}/blocked_countries`, { blocked_countries: formattedBlockedCountries });
      toast.success('Updated correctly')
    } catch (err) {
      toast.error(err.message);
    }
  }


  render() {
    const { slug } = this.props.match.params;

    const { blocked_countries } = this.state.provider;

    return (
      <div>
        <div>
          <div className="col-md-12">
            <div className="data" style={{ lineHeight: "2" }}>
              <div>Aggregator: {this.state.provider.aggregator.name}</div>
              <div className="blocked-countries">

                <Select
                  isMulti
                  onChange={this.handleInputChange}
                  options={this.formatCountries(this.countries)}
                  placeholder="Select blocked countries"
                />

                <button
                  onClick={this.updateBlockedCountries}
                  className="small"
                >
                  Update blocked countries
                </button>
              </div>
            </div>
            {blocked_countries.length > 0 && (
              <div>
                <p>Blocked Countries:</p>
                <ul>
                  {blocked_countries.map((country) => (
                    <li key={country.value}>{country.label}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className={"col-md-12"}>
          <UpdateGameProviderName providerSlug={slug} />
        </div>
        <div className={"col-md-12"}>
          <EditGamesInProvider providerSlug={slug} />
        </div>
      </div>
    );
  }
}

export default GameProviderPage;
