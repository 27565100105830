import { Table } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getFortuneDoubleRoomsRollsLeft } from "./originals-games-api";
import { toast } from "react-toastify";
import { Thead, Tr, Th, Tbody, Td } from "react-super-responsive-table";

const FortuneDoubleRooms = () => {
    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getFortuneDoubleRoomsRollsLeft().then((res) => {
            setRooms(res)
        }).catch((error) => {
            setHasError(true);
            toast.error(error.message);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [])

    if (hasError) {
        return (
            <div>
                <h1>Something went wrong while fetching data</h1>
            </div>
        )
    }

    if (isLoading) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }

    return (
        <Table className="responsiveTable">
            <Thead>
                <Tr>
                    <Th>
                        Room ID
                    </Th>
                    <Th>
                        Name
                    </Th>
                    <Th>
                        Rolls left
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {
                    rooms?.map((room) => (
                        <Tr key={room.id}>
                            <Td>
                                {room.id}
                            </Td>
                            <Td>
                                {room.game_room}
                            </Td>
                            <Td>{room.rolls_left}</Td>
                        </Tr>
                    )) || <Tr><Td colSpan={3}>No rooms found</Td></Tr>
                }
            </Tbody>
        </Table>
    )
}

export default FortuneDoubleRooms;