import React, { Component } from 'react';
import moment from 'moment';
import Countdown from 'react-countdown'
import './GlobalXp.css'



export default class GlobalXp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      current: null,
      percentage: null,
      expiryMinutes: null
    }
  }

  componentDidMount() {
    this.getBonus();
  }

  async getBonus() {
    const data = await window.api.get('/admin/rewards/global_xp');


    console.log("DATA", data);
    this.setState({
      current: data
    });
  }


  async update() {
    await window.api.put('/admin/rewards/global_xp', {
      percentage: this.state.percentage,
      expiry_minutes: this.state.expiryMinutes
    });

    await this.getBonus();
  }


  async remove() {
    await window.api.put('/admin/rewards/global_xp/remove');
    await this.getBonus();
  }



  render() {

    const { current, percentage, expiryMinutes } = this.state;
    return (
      <div className='globalXp-container'>

        <h2 className='globalXp-title'>Current Global xp bonus</h2>
        {
          current ? (
            <div>
              <div>Percentage: {current.bonus_percentage}</div>
              <div>Expires in: <Countdown date={moment(current.created_at) + (current.expiry_minutes * 1000 * 60)} /></div>
            </div>
          ) : "none"
        }

        <hr />
        <div className='globalXp-section'>
          <div className='globalXp-group-inputs'>

            <label>percentage (ex. 1.2 == 20%)</label>
            <input value={percentage} onChange={ev => {
              this.setState({ percentage: ev.target.value })
            }} />
          </div>

          <div className='globalXp-group-inputs'>
            <label>minutes until expires</label>
            <input value={expiryMinutes} onChange={ev => {
              this.setState({ expiryMinutes: ev.target.value })
            }} />
          </div>

          <div className='globalXp-group-btn'>

            <button disabled={!expiryMinutes || +percentage <= 1} onClick={() => this.update()}>
              Save
            </button>

            <button style={{ marginLeft: 20 }} disabled={!current} onClick={() => this.remove()}>
              Remove
            </button>
          </div>
        </div>

      </div>
    )
  }

}