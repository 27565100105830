import React from "react";
import TreeMenu from "react-simple-tree-menu";
import Drawer from "react-modern-drawer";
import { useLocation } from "react-router-dom";
import "./drawer.scss";
import treeData from "./nodes";
import { MenuItem, Breadcrumb } from "./components";

import styles from "./styles.module.scss";

const MobilePanel = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [lastOpen, setLastOpen] = React.useState(location.pathname);
  const toggleDrawer = (item) => {
    setIsOpen((prevState) => !prevState);
    if (item.key) {
      setLastOpen(item.key);
    }
  };

  // const scroll = () => {
  //   console.log("scroll");
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  return localStorage.getItem("access_token") !== null ? (
    <>
      <span onClick={toggleDrawer} className={styles.burger}>
        <img src="/images/icons/burger.svg" alt="" />
      </span>

      {/* <span onClick={scroll} className={styles.goToTop}>
        <img src="/images/icons/arrow_upward.svg" alt="" />
      </span> */}

      <Breadcrumb path={lastOpen} />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        className={styles.drawer}
        size={window.innerWidth * 0.85}
      >
        <TreeMenu data={treeData} onClickItem={toggleDrawer}>
          {({ search, searchTerm, items, ...otherProps }) => (
            <>
              <div className={styles.search}>
                <input
                  onChange={(e) => search(e.target.value)}
                  placeholder="Go to"
                  value={searchTerm}
                />
                {searchTerm && <span onClick={() => search("")}>x</span>}
              </div>
              <ul className={styles.ul}>
                {items.map((props, idx) => (
                  <MenuItem key={props.key} {...props} />
                ))}
              </ul>
            </>
          )}
        </TreeMenu>
      </Drawer>
    </>
  ) : null;
};

export default MobilePanel;
