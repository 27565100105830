import * as types from './types';
import {toast} from "react-toastify";

export const updatePromo = props => {

  return async dispatch => {
    const promo = await window.api.get('/admin/rewards/promo_codes', {
      params: props
    })

    dispatch({
      type: types.PROMO_UPDATE,
      payload: promo
    })
  }
};


export const getPromo = (promo_id) => {
  return async dispatch => {
    const promo = await window.api.get('/admin/rewards/promo_codes/' + promo_id);

    dispatch({
      type: types.GET_PROMO,
      payload: promo
    })
  }
};


export const createPromo = props => {
  return async dispatch => {
    const promo = await window.api.post('/admin/rewards/promo_codes', props);

    toast.info(`Promo code id: ${promo.id} created`);
  }
};

export const editPromo = (promo_id, props) => {
  return async dispatch => {
    await window.api.put('/admin/rewards/promo_codes/' + promo_id, props);

    dispatch(getPromo(promo_id))
  }
};



export const updateRedeems = (props) => {
  return async dispatch => {
    const promo = await window.api.get('/admin/rewards/promo_codes/redeems', {
      params: props
    })

    dispatch({
      type: types.GET_REDEEMS,
      payload: promo
    })
  }
};
