import React, { useState } from 'react';
import './affiliates-page.css';

import { updatePostback } from './api';
import { toast } from 'react-toastify';
import { isValidUrl } from '../shared/utils/strings';
import { useSelector } from 'react-redux';
import { affiliatesSelector } from './state/selectors';

function UpdatePostback() {
  const { selectedAffiliate } = useSelector(affiliatesSelector);
  if (!selectedAffiliate) {
    return <span>Please, select an affiliate first</span>;
  }

  const [state, setState] = useState({
    affiliateId: selectedAffiliate.blaze_user_id,
    postbackUrl: selectedAffiliate.postback_url,
  });
  const [loading, setLoading] = useState(false);

  const validForm = () => {
    return state.affiliateId && isValidUrl(state.postbackUrl);
  };

  const setPostbackURL = async () => {
    setLoading(true);
    await updatePostback(state.affiliateId, state.postbackUrl);
    setLoading(false);
    toast.success('postback url updated');
  };

  return (
    <div className="affiliate-page">
      <div className='affiliate-page__container'>
        <div className='affiliate-page__group-input'>
          <label>
            Affiliate ID:
          </label>
          <input
            name="affiliate-id"
            value={selectedAffiliate.blaze_user_id}
            disabled
          />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Postback URL:
          </label>
          <input name="postback_url"
            value={state.postbackUrl}
            onChange={(event) => setState({ ...state, postbackUrl: event.target.value })} />
        </div>
        <button disabled={loading || !validForm()}
          onClick={setPostbackURL}>Update postback URL
        </button>
      </div>
    </div>
  );
}

export default UpdatePostback;