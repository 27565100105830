import React, { Component, createRef } from 'react';
import { toast } from "react-toastify";
import { permissionsConstants, PermissionsFragment } from "../app/permissions-fragment";
import { Translator } from "../shared/translator";
import './form-edit-style.css'
export default class Bonus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bonus: {
        name_translation: {},
        description_translation: {},
        banner_translation: {},
        terms_translation: {},
        image_url: "",
        maximum: "",
        deposit_ratio: "",
        amount: "",
        expires_at: "",
        globally_available: false,
        excluded_countries: "",
        currency_type: "",
        supported_countries: "",
        inventory_expiry_delay_minutes: "",
        activation_expiry_delay_minutes: "",
        url_slug: "",
        landing_page_type: "",
        banner: ""
      },
      isLoading: true,
      isEditing: false,
    };

    this.nameRef = createRef();
    this.descriptionRef = createRef();
    this.imageUrlRef = createRef();
    this.maximumRef = createRef();
    this.depositRatioRef = createRef();
    this.amountRef = createRef();
    this.expiresAtRef = createRef();
    this.globallyAvailableRef = createRef();
    this.excludedCountriesRef = createRef();
    this.currencyTypeRef = createRef();
    this.supportedCountriesRef = createRef();
    this.inventoryExpiryDelayMinutesRef = createRef();
    this.activationExpiryDelayMinutesRef = createRef();
    this.urlSlugRef = createRef();
    this.landingPageTypeRef = createRef();
    this.bannerRef = createRef();
  }

  componentDidMount() {
    this.update();
  }

  update() {
    window.api.get(`/admin/bonuses/${this.props.match.params.id}`).then(bonus => {
      let terms = {};
      for (let key in bonus.terms_translation) {
        terms[key] = bonus.terms_translation[key].join('\n');
      }

      this.setState({
        bonus: {
          ...bonus,
          terms_translation: terms
        },
        isLoading: false
      });
    });
  }

  handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        toast.info('Uploading image...');
        const image_url = await window.api.upload(
          file,
          "games",
          `${this.props.match.params.id}-new`
        );
        this.imageUrlRef.current.value = image_url;
        this.setState({
          bonus: {
            ...this.state.bonus,
            image_url
          }
        });
        toast.success('Image uploaded successfully.');
      } catch (error) {
        toast.error('Cannot upload image to bucket.');
      }
    }
  };

  getFormattedBonusTermsTranslation() {
    let translation = this.state.bonus.terms_translation;

    for (let key in translation) {
      translation[key] = translation[key].trim().split('\n');
    }

    return translation;
  }

  save = async () => {
    const updatedBonus = {
      name_translation: this.state.bonus.name_translation,
      description_translation: this.state.bonus.description_translation,
      banner_translation: this.state.bonus.banner_translation,
      terms_translation: this.getFormattedBonusTermsTranslation(),
      name: this.nameRef.current.value,
      description: this.descriptionRef.current.value,
      image_url: this.imageUrlRef.current.value,
      maximum: this.maximumRef.current.value,
      deposit_ratio: this.depositRatioRef.current.value,
      amount: this.amountRef.current.value,
      expires_at: this.expiresAtRef.current.value,
      globally_available: this.globallyAvailableRef.current.checked,
      currency_type: this.currencyTypeRef.current.value,
      excluded_countries: this.excludedCountriesRef.current.value.split(',').map(country => country.trim()),
      supported_countries: this.supportedCountriesRef.current.value.split(',').map(country => country.trim()),
      inventory_expiry_delay_minutes: this.inventoryExpiryDelayMinutesRef.current.value,
      activation_expiry_delay_minutes: this.activationExpiryDelayMinutesRef.current.value,
      url_slug: this.urlSlugRef.current.value,
      landing_page_type: this.landingPageTypeRef.current.value,
      banner: this.bannerRef.current.value,
    };

    window.api.put(`/admin/bonuses/${this.props.match.params.id}`, updatedBonus)
      .then(() => {
        toast.success('Bonus saved successfully.');
        setTimeout(() => {
          this.props.history.push('/casino/bonuses');
        }, 500);
      })
      .catch(err => toast.error('Failed to save bonus.'));
  };

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  render() {
    const { isEditing, isLoading } = this.state;
    if (isLoading) {
      return <h3>Loading...</h3>
    }
    return (
      <div className='container'>
        <div className='button-container'>
          <button onClick={this.toggleEdit}>
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
        </div>
        <PermissionsFragment feature={permissionsConstants.BONUSES_UPDATE_BONUS}>
          <div className='container'>
            <div >
              <label >Name:</label>
              <input type="text" ref={this.nameRef} defaultValue={this.state.bonus.name} disabled={!isEditing} />
            </div>
            <div >
              <label >Description:</label>
              <input type="text" ref={this.descriptionRef} defaultValue={this.state.bonus.description} disabled={!isEditing} />
            </div>
            <div className='file-container'>
              <div>
                <label >Image URL:</label>
              </div>
              <div className='file-input-container' >
                <input type="text" ref={this.imageUrlRef} defaultValue={this.state.bonus.image_url} disabled={!isEditing} />
                <input type="file" onChange={this.handleFileChange} disabled={!isEditing} />
              </div>
            </div>
            <div >
              <label >Maximum:</label>
              <input type="number" ref={this.maximumRef} defaultValue={this.state.bonus.maximum} disabled={!isEditing} />
            </div>
            <div >
              <label >Deposit Ratio:</label>
              <input type="number" ref={this.depositRatioRef} defaultValue={this.state.bonus.deposit_ratio} disabled={!isEditing} />
            </div>
            <div >
              <label >Amount:</label>
              <input type="number" ref={this.amountRef} defaultValue={this.state.bonus.amount} disabled={!isEditing} />
            </div>
            <div >
              <label >Expires At:</label>
              <input type="datetime-local" ref={this.expiresAtRef} defaultValue={this.state.bonus.expires_at} disabled={!isEditing} />
            </div>
            <div >
              <label >Globally Available:</label>
              <input type="checkbox" ref={this.globallyAvailableRef} defaultChecked={this.state.bonus.globally_available} disabled={!isEditing} />
            </div>
            <div >
              <label >Excluded Countries:</label>
              <input type="text" ref={this.excludedCountriesRef} defaultValue={this.state.bonus.excluded_countries} disabled={!isEditing} />
            </div>
            <div >
              <label >Currency Type:</label>
              <input type="text" ref={this.currencyTypeRef} defaultValue={this.state.bonus.currency_type} disabled={!isEditing} />
            </div>
            <div >
              <label >Supported Countries:</label>
              <input type="text" ref={this.supportedCountriesRef} defaultValue={this.state.bonus.supported_countries} disabled={!isEditing} />
            </div>
            <div >
              <label >Inventory Expiry Delay (Minutes):</label>
              <input type="number" ref={this.inventoryExpiryDelayMinutesRef} defaultValue={this.state.bonus.inventory_expiry_delay_minutes} disabled={!isEditing} />
            </div>
            <div >
              <label >Activation Expiry Delay (Minutes):</label>
              <input type="number" ref={this.activationExpiryDelayMinutesRef} defaultValue={this.state.bonus.activation_expiry_delay_minutes} disabled={!isEditing} />
            </div>
            <div >
              <label >URL Slug:</label>
              <input type="text" ref={this.urlSlugRef} defaultValue={this.state.bonus.url_slug} disabled={!isEditing} />
            </div>
            <div >
              <label >Landing Page Type:</label>
              <input type="text" ref={this.landingPageTypeRef} defaultValue={this.state.bonus.landing_page_type} disabled={!isEditing} />
            </div>
            <div >
              <label >Banner:</label>
              <input type="text" ref={this.bannerRef} defaultValue={this.state.bonus.banner} disabled={!isEditing} />
            </div>
          </div>
          <Translator
            languages={this.props.languages}
            contents={this.state.bonus.name_translation}
            onChange={name_translation => this.setState({ bonus: { ...this.state.bonus, name_translation } })}
            label={"Name Translation"}
          />
          <Translator
            languages={this.props.languages}
            contents={this.state.bonus.description_translation}
            onChange={description_translation => this.setState({ bonus: { ...this.state.bonus, description_translation } })}
            label={"Description Translation"}
            disabled={!isEditing}
          />
          <Translator
            languages={this.props.languages}
            contents={this.state.bonus.banner_translation}
            onChange={banner_translation => this.setState({ bonus: { ...this.state.bonus, banner_translation } })}
            label={"Banner Translation"}
            disabled={!isEditing}
          />
          <Translator
            languages={this.props.languages}
            contents={this.state.bonus.terms_translation}
            onChange={terms_translation => this.setState({ bonus: { ...this.state.bonus, terms_translation } })}
            label={"Terms Translation"}
            disabled={!isEditing}
          />
          {isEditing && <div className='button-container'><button onClick={this.save}>Save</button></div>}
        </PermissionsFragment>
      </div>
    );
  }
}