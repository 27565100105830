import React, { Component } from 'react';
import { WithdrawalOverview } from "./withdrawal-overview";
import { WithdrawalMutuals } from "./withdrawal-mutuals";
import { WithdrawalRefundableDeposits } from "./withdrawal-refundable-deposits";
import { WithdrawalCommands } from "./withdrawal-commands";
import { toast } from "react-toastify";
import '../../shared/reporting-section.css';
import KYC from '../../user/kyc';
import BonusActivationsNotReviewed from './bonus-activations-not-reviewed';
import { permissionsConstants, PermissionsFragment, GroupPermissionsFragment, permissionGroups } from "../../app/permissions-fragment";
import { format } from '../../shared/utils/dates';

const initialState = {
  report: {
    user: {},
    refundable_deposits: [],
    mutual_ips: [],
    mutual_payment_accounts: [],
    mutual_tax_ids: [],
    real_chart_of_accounts: [],
    kyc_verification_statuses: {
      id: {
        requested: false,
        status: null
      },
      address: {
        requested: false,
        status: null
      },
      payment_accounts: []
    },
    pep_sip_check: {},
    has_card_deposit: false,
    has_transferred_bonus: false,
    tax_ids: [],
    has_bonuses_transferred_after_last_withdrawal: false,
    automated: {}
  },
  actionLoading: false,
  isLoading: false
}

export default class Withdrawal extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;
  }


  componentDidMount() {
    this.fetchWithdrawal()
  }

  fetchWithdrawal() {
    window.api.get(`/admin/payments/withdrawals/${this.getId()}/basic`)
      .then(
        report => this.setState({ report: { ...this.state.report, ...report } })
      )
    window.api.get(`/admin/payments/withdrawals/${this.getId()}/financials`)
      .then(
        report => this.setState({ report: { ...this.state.report, ...report } })
      )
    window.api.get(`/admin/payments/withdrawals/${this.getId()}/antifraud`)
      .then(
        report => this.setState({ report: { ...this.state.report, ...report } })
      )
    /* temp disable to reduce db load
    window.api.get(`/admin/payments/withdrawals/${this.getId()}/bonus-over-limit`)
      .then(
        report => this.setState({ report: { ...this.state.report, ...report } })
      )*/
  }

  getId() {
    return this.props.match.params.id;
  }


  async edit(data, password) {
    try {
      this.setState({ actionLoading: true });
      await window.api({
        method: 'put',
        url: `/admin/payments/withdrawals/${this.getId()}`,
        data: data,
        headers: {
          'x-admin-password': password
        }
      });
      await this.fetchWithdrawal();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  async editPspAmount(data) {
    const password = window.password_prompt();
    if (password === null) return;

    try {
      this.setState({ actionLoading: true });
      await window.api({
        method: 'put',
        url: `/admin/payments/withdrawals/${this.getId()}/psp_amount`,
        data: data,
        headers: {
          'x-admin-password': password
        }
      });
      await this.fetchWithdrawal();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  async refundDeposit({ depositId, amount }) {
    try {
      this.setState({ actionLoading: true });
      await window.api.put(`/admin/payments/withdrawals/${this.getId()}/refund_deposit`, {
        deposit_id: depositId,
        user_id: this.state.report.user.id,
        amount
      });
    } catch (err) {
    }
    this.setState({ actionLoading: false });
  }

  render() {
    const withdrawal = this.state.report;
    const loading = this.state.actionLoading;

    const r = this.state.report.automated || {}
    return (
      <div className="withdrawal-page">
        {this.state.isLoading &&
          <div className={"loading-overlay"}>
            <h2>Loading, please wait ...</h2>
            <div className="loader" />
          </div>
        }

        <WithdrawalOverview withdrawal={withdrawal} loading={loading} fetchWithdrawal={this.fetchWithdrawal.bind(this)} edit={this.edit.bind(this)} editPspAmount={this.editPspAmount.bind(this)} />

        <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS_FULL_VIEW}>
          <section className="mutuals">
            <div className="row">
              <div className="col-md-4">
                <h3 style={{ margin: 0 }}>Flags</h3>
                {(r.flags || []).map(flag => {

                  return (
                    <div className={"flag"} style={{ color: flag.eval ? 'green' : 'red' }}>
                      {flag.title}
                    </div>

                  )

                })}
              </div>

              <div className="col-md-4">

                <h3 style={{ margin: 0 }}>Calculation Values</h3>
                {Object.keys(r.fraud_checks || {}).map(key => {

                  if (key === 'requestedAt') {
                    return (
                      <div>{key}: {format.withMinutes(r.fraud_checks[key])}</div>
                    )
                  }

                  return (
                    <div>{key}: {r.fraud_checks[key]}</div>
                  )

                })}
              </div>

              <div className="col-md-4">


                <h3 style={{ marginTop: '16px', marginBottom: 0 }}>Evaluation</h3>
                {(r.evaluation || []).map(action => {

                  switch (action.type) {

                    case 'APPROVE':
                      return <div className={"eval"}>
                        Approve

                      </div>

                    case 'REFUND':
                      return <div className={"eval"}>
                        Refund

                        <br />
                        set note: {action.payload.note}
                      </div>


                    case 'CREATE_ID_CHECK':
                      return <div className={"eval"}>
                        Create ID KYC verification
                      </div>

                  }
                  return (
                    <div className={"eval"}>
                      {action.type}

                    </div>

                  )

                })}

              </div>
            </div>
          </section>
          {this.state.report.user?.id && <BonusActivationsNotReviewed userId={this.state.report.user.id}></BonusActivationsNotReviewed>}
          <WithdrawalMutuals withdrawal={withdrawal} loading={loading} />
        </GroupPermissionsFragment>

        <WithdrawalCommands withdrawal={withdrawal} loading={loading} edit={this.edit.bind(this)} editPspAmount={this.editPspAmount.bind(this)} />

        <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS_FULL_VIEW}>
        <PermissionsFragment feature={permissionsConstants.WITHDRAWALS_VIEW_REFUNDABLE_DEPOSITS_LIST}>
          <WithdrawalRefundableDeposits withdrawal={withdrawal} loading={loading} refundDeposit={this.refundDeposit.bind(this)} />
        </PermissionsFragment>
        {!loading && withdrawal.user.id && (
          <section className={'user-page'}>
            <div className={"row"}>
              <div className={"col-xs-12"}>
                <KYC match={{ params: { id: withdrawal.user.id } }} />

              </div>
            </div>
          </section>
        )}
        </GroupPermissionsFragment>
      </div>
    )
  }
}