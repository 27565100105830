import React, { Component } from "react";
import { connect } from "react-redux";
import { updateBalance, requestRefund } from "../actions";
import Select from "react-select";
import {
  GroupPermissionsFragment,
  permissionGroups,
  permissionsConstants,
  PermissionsFragment,
} from "app/permissions-fragment";
import { toast } from "react-toastify";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import styles from "./financials.module.scss";
import { WeeklyCashback } from "./components/WeeklyCashback";

const AccountingSection = (keyValues) => {
  return (
    <div className="accounting-section">
      {Object.keys(keyValues).map((key) => (
        <div className="accounting-section-row">
          <span className="name">
            {key
              .replace(/_/g, " ")
              .split(" ")
              .reduce(
                (sentence, word) => [
                  ...sentence,
                  word[0].toUpperCase() + word.slice(1),
                ],
                []
              )
              .join(" ")}
          </span>
          <span className="value">{keyValues[key]}</span>
        </div>
      ))}
    </div>
  );
};

const ChartOfAccounts = ({ chartOfAccounts }) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Type</Th>
          <Th>Sum</Th>
        </Tr>
      </Thead>

      <Tbody>
        {chartOfAccounts.map((r, i) => {
          return (
            <Tr key={i}>
              <Td>{r.type}</Td>
              <Td>{r.sum}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const initialState = {
  page: 1,
  isLoading: false,
  repairable_wallets: [],
  report: {
    real_wagering_report: {
      wagered: "0.0000",
      refunded: "0.0000",
      in_play: "0.0000",
      winnings: "0.0000",
      return_to_player: "0.0000",
      net_profit: "0.0000",
    },
    bonus_wagering_report: {
      wagered: "0.0000",
      refunded: "0.0000",
      in_play: "0.0000",
      winnings: "0.0000",
      return_to_player: "0.0000",
      net_profit: "0.0000",
    },
    real_credits_report: {
      referral_conversion_payouts: "0.0000",
      bonuses_transferred: "0.0000",
      cashback_rewards_payed_out: "0.0000",
      weekly_loyalty_payouts: "0.0000",
      admin_credits: "0.0000",
      inventory_rewards_sold: "0.0000",
      softswiss_free_spins_winnings: "0.0000",
      net_real_credits: "0.0000",
    },
    bonus_money_report: {
      bonuses_credited: "0.0000",
      bonuses_forfeit_or_transferred: "0.0000",
      net_bonus_money_credited: "0.0000",
      net_bonus_money: "0.0000",
    },
    payments_report: {
      completed_deposits: "0.0000",
      withdrawals_completed: "0.0000",
      withdrawals_pending: "0.0000",
      withdrawals_refunded: "0.0000",
      withdrawals_debited: "0.0000",
      net_withdrawals: "0.0000",
      net_payments: "0.0000",
    },
    summary_report: {
      gross_gaming_revenue: "0.0000",
      net_real_credits: "0.0000",
      net_bonus_money_credited: "0.0000",
      net_gaming_revenue: "0.0000",

      net_payments: "0.0000",
      expected_balance: "0.0000",
      current_balance: "0.0000",
      deficiency: "0.0000",
      accuracy: "0.0000",
    },

    real_chart_of_accounts: [],
    bonus_chart_of_accounts: [],
  }
};

class UserFinancials extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { selected_currency } = this.props;
    window.api
      .get(`/admin/users/${id}/financials?currency_type=${selected_currency}`)
      .then((report) => this.setState({ report }));
  }

  getRepairableWallets() {
    const { id } = this.props.match.params;
    this.setState({
      repairable_loading: true,
    });

    window.api
      .get(`/admin/users/${id}/repairable_wallets`)
      .then((repairable_wallets) =>
        this.setState({
          repairable_wallets,
          repairable_loaded: true,
          repairable_loading: false,
        })
      )
      .catch((err) => {
        this.setState({
          repairable_loaded: true,
          repairable_loading: false,
        });
      });
  }
  repairWallet(walletId) {
    const { id } = this.props.match.params;
    window.api
      .post(`/admin/users/${id}/repair_wallet`, {
        wallet_id: walletId,
      })
      .then(async () => {
        toast.success(`Wallet balance repaired successfully`);
        this.getRepairableWallets();
      });
  }

  render() {
    const user_id = this.props.match.params.id;

    const {
      real_wagering_report,
      bonus_wagering_report,
      real_credits_report,
      bonus_money_report,
      payments_report,
      summary_report,

      real_chart_of_accounts,
      bonus_chart_of_accounts,
    } = this.state.report;

    return (
      <div id="user-info-financials" className={styles.main}>
        <WeeklyCashback id={user_id} />
        <h4>Balances</h4>
        <Table>
          <Thead>
            <Tr>
              <Th>Primary?</Th>
              <Th>ID</Th>
              <Th>Currency</Th>
              <Th>Deposit Currency</Th>
              <Th>Balance</Th>
              <Th>Real Balance</Th>
              <Th>Bonus Balance</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.wallets.map((w, i) => {
              return (
                <Tr key={i}>
                  <Td>{w.id}</Td>
                  <Td>{w.is_primary ? <i className="fa fa-check" /> : ""}</Td>
                  <Td>{w.currency_type}</Td>
                  <Td>{w.deposit_currency_type || "N/A"}</Td>
                  <Td>{w.balance}</Td>
                  <Td>{w.real_balance}</Td>
                  <Td>{w.bonus_balance}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <br />
        <br />
        <br />

        <h4>Reconcile</h4>
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Primary?</Th>
              <Th>Currency</Th>
              <Th>Deposit Currency</Th>
              <Th>Current Balance</Th>
              <Th>Replayed Balance</Th>
              <Th>Current Real Balance</Th>
              <Th>Replayed Real Balance</Th>
              <Th>Current Bonus Balance</Th>
              <Th>Replayed Bonus Balance</Th>
              <Th></Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.repairable_wallets.length === 0 &&
              !this.state.repairable_loaded && (
                <Tr>
                  <Td colSpan={13}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        disabled={this.state.repairable_loading}
                        onClick={() => this.getRepairableWallets()}
                      >
                        Load repairable wallets
                      </button>
                    </div>
                  </Td>
                </Tr>
              )}

            {this.state.repairable_wallets.map((w, i) => {
              /*
                      current_bonus_balance, replayed_bonus_balance, current_balance, replayed_balance, status
  
                      []
                      journal_id, journal_type, current_journal_balance, replayed_journal_balance
                 */
              const isExpanded = this.state.expanded_wallet_id === w.wallet_id;
              const rows = [
                <Tr key={i}>
                  <Td>{w.wallet_id}</Td>
                  <Td>{w.is_primary ? <i className="fa fa-check" /> : ""}</Td>
                  <Td>{w.currency_type}</Td>
                  <Td>{w.deposit_currency_type}</Td>
                  <Td>{w.current_balance}</Td>
                  <Td>{w.replayed_balance}</Td>
                  <Td>{w.current_real_balance}</Td>
                  <Td>{w.replayed_real_balance}</Td>
                  <Td>{w.current_bonus_balance}</Td>
                  <Td>{w.replayed_bonus_balance}</Td>
                  <Td
                    onClick={() => {
                      if (isExpanded) {
                        return this.setState({ expanded_wallet_id: null });
                      }

                      this.setState({ expanded_wallet_id: w.wallet_id });
                    }}
                  >
                    +
                  </Td>
                  <Td>{w.status}</Td>
                  <Td>
                    {w.status === "corrupted" && (
                      <button onClick={() => this.repairWallet(w.wallet_id)}>
                        Repair
                      </button>
                    )}
                  </Td>
                </Tr>,
              ];

              if (isExpanded) {
                for (let journal of w.journals) {
                  rows.push(
                    <Tr>
                      <Td>
                        Journal #{journal.journal_id} ({journal.journal_type})
                      </Td>
                      <Td>{journal.current_journal_balance}</Td>
                      <Td>{journal.replayed_journal_balance}</Td>
                    </Tr>
                  );
                }
              }
              return <>{rows.map((r) => r)}</>;
            })}
          </Tbody>
        </Table>

        <br />
        <br />
        <br />

        <div>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <h4>Real Chart of Accounts</h4>
              <ChartOfAccounts chartOfAccounts={real_chart_of_accounts} />
            </div>

            <div className="col-md-6 col-xs-12">
              <h4>Bonus Chart of Accounts</h4>
              <ChartOfAccounts chartOfAccounts={bonus_chart_of_accounts} />
            </div>
          </div>

          <br />
          <br />
          <br />

          <div className="row">
            <div className="col-md-3 col-xs-12">
              <h4>Real Wagering Report</h4>

              <AccountingSection {...real_wagering_report} />
            </div>
            <div className="col-md-3 col-xs-12">
              <h4>Payments</h4>
              <AccountingSection {...payments_report} />
            </div>
            <div className="col-md-3 col-xs-12">
              <h4>Real Credits</h4>
              <AccountingSection {...real_credits_report} />
            </div>
            <div className="col-md-3 col-xs-12">
              <h4>Bonus Money Report</h4>
              <AccountingSection {...bonus_money_report} />
            </div>
            <div className="col-md-3 col-xs-12">
              <h4>Bonus Wagering Report</h4>

              <AccountingSection {...bonus_wagering_report} />
            </div>
            <div className={styles.summary}>
              <h4>Summary</h4>

              <div className="accounting-section">
                <div className="row">
                  <div className={styles.column}>
                    <div className="summary-row">
                      <div className="line-item">
                        <div className="description">+ Real Wager Profit</div>
                        <div className="value">
                          {real_wagering_report.net_profit}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="description">+ Bonus Wager Profit</div>
                        <div className="value">
                          {bonus_wagering_report.net_profit}
                        </div>
                      </div>
                      <hr />
                      <div className="line-item">
                        <div className="description">GGR</div>
                        <div className="value">
                          {summary_report.gross_gaming_revenue}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.column}>
                    <div className="summary-row">
                      <div className="line-item">
                        <div className="description">+ GGR</div>
                        <div className="value">
                          {summary_report.gross_gaming_revenue}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="description">- Net Real Credits</div>
                        <div className="value">
                          {real_credits_report.net_real_credits}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="description">- Net Bonus Money</div>
                        <div className="value">
                          {bonus_money_report.net_bonus_money}
                        </div>
                      </div>
                      <hr />
                      <div className="line-item">
                        <div className="description">NGR</div>
                        <div className="value">
                          {summary_report.net_gaming_revenue}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className="summary-row">
                      <div className="line-item">
                        <div className="description">+ Net Payments</div>
                        <div className="value">
                          {payments_report.net_payments}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="description">- NGR</div>
                        <div className="value">
                          {summary_report.net_gaming_revenue}
                        </div>
                      </div>
                      <hr />
                      <div className="line-item">
                        <div className="description">Expected Balance</div>
                        <div className="value">
                          {summary_report.expected_balance}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className="summary-row">
                      <div className="line-item">
                        <div className="description">+ Expected Balance</div>
                        <div className="value">
                          {summary_report.expected_balance}
                        </div>
                      </div>
                      <div className="line-item">
                        <div className="description">- Current Balance</div>
                        <div className="value">
                          {summary_report.current_balance}
                        </div>
                      </div>
                      <hr />
                      <div className="line-item">
                        <div className="description">Deficiency</div>
                        <div className="value">
                          {summary_report.deficiency} ({summary_report.accuracy}
                          %)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <GroupPermissionsFragment
          group={permissionGroups.USERS.UPDATE_BALANCES}
          feature={permissionsConstants.USER_UPDATE_WALLET_BALANCE}
        >
          <h4>Add Balance</h4>
          <Select
            name="type"
            value={this.state.wallet}
            onChange={(option) => this.setState({ wallet: option })}
            options={this.props.wallets
              .filter((w) => !w.free_winnings)
              .map((w) => {
                w.value = w.id;
                w.label = w.currency_type;

                return w;
              })}
          />
          <br />
          <input
            value={this.state.amount}
            onChange={(e) => this.setState({ amount: e.target.value })}
            placeholder="ex. 4.20"
          />
          <br />
          <br />
          <div className={styles.buttons}>
            <PermissionsFragment
              feature={permissionsConstants.USER_UPDATE_WALLET_BALANCE}
            >
              <button
                onClick={() =>
                  this.props.updateBalance(
                    user_id,
                    this.state.wallet.value,
                    "real",
                    this.state.amount
                  )
                }
              >
                Submit
              </button>
            </PermissionsFragment>

            <PermissionsFragment
              feature={permissionsConstants.REQUEST_USER_UPDATE_WALLET_BALANCE}
            >
              <button
                onClick={() =>
                  this.props.updateBalance(
                    user_id,
                    this.state.wallet.value,
                    "real",
                    this.state.amount,
                    true
                  )
                }
              >
                Submit Request
              </button>
            </PermissionsFragment>
          </div>
        </GroupPermissionsFragment>

        <br />
        <br />
        <br />

        <GroupPermissionsFragment
          group={permissionGroups.USERS.UPDATE_BALANCES}
          feature={permissionsConstants.USER_UPDATE_WALLET_BALANCE}
        >
          <h4>Request Refund</h4>
          <Select
            name="refund_currency"
            value={this.state.refund_currency}
            onChange={(option) => this.setState({ refund_currency: option })}
            options={this.props.wallets
              .filter((w) => !w.free_winnings)
              .map((w) => {
                w.value = w.id;
                w.label = w.currency_type;

                return w;
              })}
          />
          <br />
          <input
            value={this.state.refund_amount}
            onChange={(e) => this.setState({ refund_amount: e.target.value })}
            placeholder="ex. 4.20"
            type="number"
          />
          <br />
          <br />
          <input
            value={this.state.refund_note}
            onChange={(e) => this.setState({ refund_note: e.target.value })}
            placeholder="note"
          />
          <br />
          <br />

          <PermissionsFragment
            feature={permissionsConstants.REQUEST_USER_UPDATE_WALLET_BALANCE}
          >
            <div className={styles.buttons}>
              <button
                onClick={() =>
                  this.props.requestRefund(
                    user_id,
                    this.state.refund_currency.value,
                    this.state.refund_amount,
                    this.state.refund_note,
                    true
                  )
                }
              >
                Submit Request
              </button>
            </div>
          </PermissionsFragment>
        </GroupPermissionsFragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selected_currency: state.users.user.selected_currency,
  wallets: state.users.user.wallets,
});

const mapDispatchToProps = (dispatch) => ({
  updateBalance: (user_id, id, sub_wallet_type, amount, request) =>
    dispatch(updateBalance(user_id, id, sub_wallet_type, amount, request)),
  requestRefund: (user_id, wallet_id, amount, note, request) =>
    dispatch(requestRefund(user_id, wallet_id, amount, note, request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFinancials);
