import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';


import { useQuery } from 'react-query'
import { useParams } from "react-router-dom";
import DataGrid, { TextEditor } from 'react-data-grid';

const fetchRoutePermissions = async (id) => {
    const data = await window.api({
        method: 'GET',
        url: `/admin/users/${id}/route_permissions`
    })
    return data.map(x => { return { ...x, is_allowed: x.is_allowed.toString() } })
}

const fetchRouteMappings = async () => {
    const data = await window.api({
        method: 'GET',
        url: `/admin/users/route_mappings`
    })
    return data.map(x => { return { ...x, method: x.method.toString() } })
}

const RoutePermissions = () => {
    const boolean = ['', 'true', 'false'];
    const textEditor = "t16y9g8l700-beta7";
    const textEditorClassname = `rdg-text-editor ${textEditor}`;

    const { id } = useParams();
    const { data } = useQuery(['route_permissions', id], context => fetchRoutePermissions(context.queryKey[1]))
    const route_mappingsObject = useQuery(['route_mappings'], context => fetchRouteMappings())
    const route_mappings = route_mappingsObject.data;

    function DropDownEditor({ row, onRowChange }) {
        return (
            <select
                className={textEditorClassname}
                value={row.is_allowed}
                onChange={(event) => onRowChange({ ...row, is_allowed: event.target.value }, true)}
                autoFocus
            >
                {boolean.map((bool) => (
                    <option key={bool} value={bool}>
                        {bool}
                    </option>
                ))}
            </select>
        );
    }

    function RouteMappingDropDownEditor({ row, onRowChange }) {
        return (
            <select
                className={textEditorClassname}
                value={row.name}
                onChange={(event) => onRowChange({ ...row, name: event.target.value, routemapping_id: event.target.value.split(':')[0] }, true)}
                autoFocus
            >
                <option key={''} value={``}>

                </option>
                {route_mappings.map((route_permission) => (
                    <option key={route_permission.id} value={`${route_permission.id}:${route_permission.name}`}>
                        {route_permission.name}
                    </option>
                ))}
            </select>
        );
    }

    const columns = [
        { key: 'id', name: 'ID' },
        { key: 'name', name: 'Route Mapping', editor: RouteMappingDropDownEditor },
        { key: 'is_allowed', name: 'Allowed', editor: DropDownEditor }
    ];

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (data) {
            setRows(data)
        }
    }, [data])

    const updateRows = (route_permissions, { indexes }) => {
        const tableIndex = indexes[0]
        const route_permission = route_permissions[tableIndex]
        if (!route_permission.routemapping_id || !route_permission.is_allowed || route_permission.is_allowed === '') {
            setRows(route_permissions)
            return toast.error("Fill the remaining fields to save");
        }
        if (route_permission.id) {
            window.api.put(`/admin/users/${id}/route_permission/${route_permission.id}`, route_permission).then(data => {
                if (data.message) {
                    toast.success(data.message);
                    setRows(route_permissions)
                }
                else toast.error(data);
            }).catch(err => {
                toast.error(err);
            });
        } else {
            window.api.post(`/admin/users/${id}/route_permission`, route_permission).then(data => {
                if (data.message) {
                    toast.success(data.message);
                    route_permissions[tableIndex].id = data.id
                    setRows(route_permissions)
                }
                else toast.error(data);
            }).catch(err => {
                toast.error(err);
            });
        }
    }

    return (
        <div>
            <button
                onClick={() => setRows([...rows, {
                    race_id: id
                }])}
            >Add Route Permission
            </button>
            {data && <DataGrid columns={columns} rows={rows} onRowsChange={updateRows} />}
        </div>

    )
}

export default RoutePermissions;