import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, Switch, withRouter } from 'react-router-dom';
import {
  permissionGroups,
  GroupPermissionsFragment,
  CheckPermissionGroupRoute,
} from '../app/permissions-fragment';

import Referrals from './referrals';
import Campaigns from './campaigns';
import Balance from './balance';

function Affiliate(props) {
  return (
    <div className="affiliate-page">
      <hr />
      <div className="page-wrapper">
        <nav className="user-nav">
          <GroupPermissionsFragment group={permissionGroups.AFFILIATES.INFO}>
            <NavLink to={props.match.url + '/info'}>Affiliate Info</NavLink>
          </GroupPermissionsFragment>

          <GroupPermissionsFragment group={permissionGroups.AFFILIATES.CAMPAIGNS}>
            <NavLink to={props.match.url + '/campaigns'}>Campaigns</NavLink>
          </GroupPermissionsFragment>

          <GroupPermissionsFragment group={permissionGroups.AFFILIATES.BALANCES}>
            <NavLink to={props.match.url + '/balance'}>
              Balance & Transactions
            </NavLink>
          </GroupPermissionsFragment>

          <GroupPermissionsFragment group={permissionGroups.AFFILIATES.REFERRALS}>
            <NavLink to={props.match.url + '/referrals'}>Referred users</NavLink>
          </GroupPermissionsFragment>
        </nav>

        <div className="nav-output">
          {!props.loading && (
            <Fragment>
              <Switch>
                <CheckPermissionGroupRoute
                  exact
                  group={permissionGroups.AFFILIATES.INFO}
                  path={'/affiliates/:id/info'}
                  component={() => 'Info'}
                />
                <CheckPermissionGroupRoute
                  group={permissionGroups.AFFILIATES.CAMPAIGNS}
                  path={'/affiliates/:id/campaigns'}
                  component={Campaigns}
                />
                <CheckPermissionGroupRoute
                  group={permissionGroups.AFFILIATES.BALANCES}
                  path={'/affiliates/:id/balance'}
                  component={Balance}
                />
                <CheckPermissionGroupRoute
                  group={permissionGroups.AFFILIATES.REFERRALS}
                  path={'/affiliates/:id/referrals'}
                  component={Referrals}
                />
                <Redirect
                  from={`*`}
                  to={`/affiliates/${props.match.params.id}/info`}
                />
              </Switch>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Affiliate);
