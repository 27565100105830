import React from "react";
import Select from "react-select";

import "./styles/SelectSort.scss";

const listOptions = [
  {
    id: "",
    label: "all",
  },
  {
    id: "starts_at",
    label: "start date",
  },
  {
    id: "prize",
    label: "prize",
  },
  {
    id: "eur_min_wagered",
    label: "min wager",
  },
  {
    id: "min_multiplier",
    label: "min multiplier",
  },
];
const SelectSort = ({ select, setSelect }) => {
  return (
    <div className="selectsort">
      <Select
        className="selectsort__select"
        classNamePrefix="selectsort__select"
        options={listOptions}
        value={
          listOptions.find((option) => option.id === select) || listOptions[0]
        }
        onChange={({ id }) => {
          setSelect(id);
        }}
        placeholder="Sort by"
      />
    </div>
  );
};

export default SelectSort;
