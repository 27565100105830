export const EMPTY_CLAIMABLE = {
    id: 1,
    status: 'created',
    level: 1,
    rank: 'bronze',
    percentage: '0',
    calculation_values: {
      amount: 0,
      real_bet: 0,
      real_won: 0,
      sell_amount: 0,
      currency_type: 'BRL',
      transfer_amount: 0,
      last_week_cashback: 0,
      withdrawals_amount: 0,
      daily_rakeback_amount: 0,
      cashback_reward_amount: 0,
      rakeback_reward_amount: 0,
      softswiss_free_spins_amount: 0,
    },
    created_at: '2024-02-05T04:41:35.459Z',
    updated_at: '2024-02-05T04:41:35.459Z',
    loyalty_period_id: 300,
    user_id: 1,
    transaction_id: null,
    loyalty_period: {
      id: 300,
      starts_at: '2024-01-29T00:00:00.000Z',
      ends_at: '2024-02-04T23:59:59.000Z',
      status: 'credited',
      created_at: '2024-02-05T04:24:41.714Z',
      updated_at: '2024-02-05T04:24:41.714Z',
    },
    periods: [],
  };
  