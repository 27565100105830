import { combineReducers } from "redux";

import app from "./app-reducers";
import users from "../user/reducers";
import settings from "../system/reducers";
import bonuses from "../casino/reducers";
import payments from "../payments/reducers";
import currencies from "../payments/cashier/reducers";
import rewards from "../casino/reward-reducers";
import promo from "../casino/promo-code/reducers";
import affiliates from "../affiliates/state/reducer";
import bigWins from "../casino/big-wins/state/reducer";

export default combineReducers({
  app,
  users,
  settings,
  bonuses,
  payments,
  currencies,
  rewards,
  promo,
  affiliates,
  bigWins,
});
