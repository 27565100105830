import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getBalanceByAffiliateId } from './api';

import './affiliates-page.css';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

function Balance(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {
      setLoading(true);

      const id = props.match?.params?.id;
      if (!id) throw new Error('No id provided');

      try {
        await new Promise((r) => setTimeout(r, 2000));
        const data = await getBalanceByAffiliateId(id);
        setData(data);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, []);

  return (
    <div className="affiliate-page">
      <div className="balances">
        <div>
          <h3>Available Balance: ${data.balance?.availableBalance}</h3>
        </div>
        <div>
          <h3>Pending Balance: ${data.balance?.pendingBalance}</h3>
        </div>
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th>Amount</Th>
            <Th>Status</Th>
            <Th>Withdrawal ID</Th>
            <Th>Closing Balance</Th>
          </Tr>
        </Thead>

        {loading && (
          <div className={'loading-overlay'}>
            <h2>Fetching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <Tbody>
          {data.transactions?.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{item.amount}</Td>
                <Td>{item.status.toUpperCase?.()}</Td>
                <Td>{item.blaze_withdrawal_id}</Td>
                <Td>{item.closing_balance}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
}

export default Balance;
