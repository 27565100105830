import { PaymentsLink } from "app/panel.js";

import {
  AutomatedWithdrawalsLink,
  CountryOrderingsLink,
  DepositsLink,
  InfluencerCardLink,
  KYCVerificationsLink,
  MethodsLink,
  PixWithdrawalsLink,
  ProvidersLink,
  StuckWithdrawalsLink,
  WithdrawalsLink,
} from "payments/tab-payments/links.js";

const paymentNodes = {
  key: "payments",
  label: "Payments",
  icon: "",
  component: () => <PaymentsLink />,
  nodes: [
    {
      key: "automated-withdrawals",
      label: "Automated Withdrawals",
      icon: "",
      component: () => <AutomatedWithdrawalsLink />,
      nodes: [],
    },
    {
      key: "country-orderings",
      label: "Country Orderings",
      icon: "",
      component: () => <CountryOrderingsLink />,
      nodes: [],
    },
    {
      key: "deposits",
      label: "Deposits",
      icon: "",
      component: () => <DepositsLink />,
      nodes: [],
    },
    {
      key: "influencer-card",
      label: "Influencer Card",
      icon: "",
      component: () => <InfluencerCardLink />,
      nodes: [],
    },
    {
      key: "kyc-verifications",
      label: "KYC Verifications",
      icon: "",
      component: () => <KYCVerificationsLink />,
      nodes: [],
    },
    {
      key: "methods",
      label: "Methods",
      icon: "",
      component: () => <MethodsLink />,
      nodes: [],
    },
    {
      key: "pix-withdrawals",
      label: "Pix Withdrawals",
      icon: "",
      component: () => <PixWithdrawalsLink />,
      nodes: [],
    },
    {
      key: "providers",
      label: "Providers",
      icon: "",
      component: () => <ProvidersLink />,
      nodes: [],
    },
    {
      key: "stuck-withdrawals",
      label: "Stuck Withdrawals",
      icon: "",
      component: () => <StuckWithdrawalsLink />,
      nodes: [],
    },
    {
      key: "withdrawals",
      label: "Withdrawals",
      icon: "",
      component: () => <WithdrawalsLink />,
      nodes: [],
    },
  ],
};

export default paymentNodes;
