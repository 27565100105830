import React, { Fragment, useState, useReducer } from "react";
import styles from "../styles.module.scss";
import { format } from "shared/utils/dates";

const Stats = (props) => {
  return (
    <div className={styles.panel} id="stats">
      <h2 className={styles.titlePanel}>Stats</h2>
      <div className={styles.stat}>
        <h3>Registered</h3>
        <span>{format.withMinutes(props.created_at)}</span>
      </div>
      <div className={styles.stat}>
        <h3>Accepted tos & privacy policy</h3>
        <span>{format.withMinutes(props.created_at)}</span>
      </div>
      <div className={styles.stat}>
        <h3>Last login</h3>
        <span>{format.withMinutes(props.last_seen)}</span>
      </div>

      <div className={styles.stat}>
        <h3>Signup channel</h3>
        <span>
          {props.signup_channel.type} ({props.signup_channel.code})
        </span>
      </div>

      <div className={styles.stat}>
        <h3>Level</h3>
        <span>{props.level}</span>
      </div>
    </div >
  );
};
export default Stats;
