import React, { Component, Fragment } from "react";
import Pagination from "shared/pagination.js";
import { toast } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import MobilePageTitle from "shared/MobilePageTitle";
import styles from "./styles.module.scss";

class StaffRequests extends Component {
  state = {
    page: 1,
    filter: "",
    records: [],
    meta: {},
    loading: false,
    fetch: false,
  };

  componentDidMount() {
    this.update();
  }

  async update() {
    const data = await window.api({
      method: "GET",
      url: "/admin/access_management/requests",
      params: {
        page: this.state.page,
        search: this.state.search,
        type: this.state.type,
        status: this.state.status,
      },
    });

    this.setState({ ...data, loading: false });
  }

  async approve(id) {
    await window.api({
      method: "post",
      url: "/admin/access_management/requests/" + id + "/approve",
    });
    toast.success(`Request Approved`);

    this.update();
  }
  async approveAll() {
    if (this.state.records.length > 1) {
      this.setState({ ...this.state, loading: true });
      this.state.records.map(async (record) => {
        if (record.status === "pending") {
          await window.api({
            method: "post",
            url: "/admin/access_management/requests/" + record.id + "/approve",
          });
        }
      });
    }

    this.update();
  }
  async deny(id) {
    const data = await window.api.post(
      "/admin/access_management/requests/" + id + "/deny"
    );
    toast.success(`Request Denied`);

    this.update();
  }

  render() {
    return (
      <div id={"staff-requests"}>
        <MobilePageTitle title="Staff request" />
        <div className={styles.container}>
          <div className={styles.field}>
            <label>Search</label>
            <input
              style={{ width: "100%" }}
              placeholder={"Search request payload values"}
              onChange={(e) => {
                this.setState({ search: e.target.value }, () => {
                  this.update();
                });
              }}
            />
          </div>

          <div className={styles.field}>
            <label>Type</label>
            <Select
              name="type"
              value={this.state.type}
              onChange={(option) => {
                this.setState({ type: option.value }, () => {
                  this.update();
                });
              }}
              options={[{ value: "", label: "Any" }].concat(
                [
                  "CreditDeposit",
                  "WalletEditBalance",
                  "CustomerInfoChange",
                  "EmailChange",
                ].map((val) => {
                  return { value: val, label: val };
                })
              )}
            />
          </div>

          <div className={styles.field}>
            <label>Status</label>
            <Select
              name="type"
              value={this.state.status}
              onChange={(option) => {
                this.setState({ status: option.value }, () => {
                  this.update();
                });
              }}
              options={[{ value: "", label: "Any" }].concat(
                ["pending", "approved", "failed", "denied"].map((val) => {
                  return { value: val, label: val };
                })
              )}
            />
          </div>

          <div></div>
        </div>
        <button
          style={{ marginTop: "20px", height: "30px !important" }}
          disabled={this.state.loading}
          onClick={() => this.approveAll()}
        >
          Approve all
        </button>
        <hr />
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Aggregate ID</Th>
              <Th>Type</Th>
              <Th>Requested By</Th>
              <Th>Data</Th>
              <Th>Status</Th>
              <Th>Note</Th>
              <Th>Target User</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.records.map((request) => {
              return (
                <Tr key={request.id}>
                  <Th>{request.id}</Th>
                  <Th>{request.aggregate_id}</Th>
                  <Th>{request.type}</Th>
                  <Th>{request.requester.username}</Th>
                  <Th>{JSON.stringify(request.data)}</Th>
                  <Th>{request.status}</Th>
                  <Th>{request.note}</Th>
                  <Th>
                    <Link to={`/users/${request.target.id}`}>
                      {request.target.username}
                    </Link>
                  </Th>
                  <Th>
                    {request.status === "pending" && (
                      <Fragment>
                        <button
                          onClick={() => {
                            if (request.type === "WalletEditBalance") {
                              this.approve(request.id);
                            } else {
                              this.approve(request.id);
                            }
                          }}
                        >
                          Approve
                        </button>
                        <button onClick={() => this.deny(request.id)}>
                          Deny
                        </button>
                      </Fragment>
                    )}
                  </Th>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          total_pages={this.state.meta.total_pages}
          update={(page) => {
            this.setState({ page }, () => {
              this.update();
            });
          }}
        />
      </div>
    );
  }
}

export default StaffRequests;
