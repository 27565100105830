import bettingApi from "../app/http-betting-api";

export async function getUserBets(userId, { page = 1, type = "" }) {
  const url = `/admin/users/${userId}/bets`;
  return await bettingApi.get(url, { page, type });
}

export async function getUserBetsPending(userId) {
  const url = `/admin/users/${userId}/bets_pending`;
  return await bettingApi.get(url);
}

export async function getSlideBets(userId, { page = 1 }) {
  const url = `/admin/users/${userId}/slide-bets`;
  return await bettingApi.get(url, { page });
}

export async function getSlideBetByRoundId(roundId) {
  return await bettingApi.get(`/admin/users/slide-bets/${roundId}`);
}
export async function getFortuneDoubleBets(userId, { page = 1 }) {
  const url = `/admin/users/${userId}/fortune-double-bets`;
  return await bettingApi.get(url, { page });
}
export async function getFortuneDoubleBetByRoundId(roundId) {
  return await bettingApi.get(`/admin/users/fortune-double-bets/${roundId}`);
}

export async function getFruitsBetByRoundId(roundId) {
  return await bettingApi.get(`/admin/users/fruits-bets/${roundId}`);
}

export async function getFruitsBets(userId, { page = 1 }) {
  const url = `/admin/users/${userId}/fruits-bets`;
  return await bettingApi.get(url, { page });
}

export async function getOriginalBet(betId) {
  return await bettingApi.get(`/admin/bets/${betId}`);
}

export async function getStatus() {
  return bettingApi.get(`/admin/status`);
}

export async function getFortuneDoubleRoomsRollsLeft() {
  return bettingApi.get(`/admin/multiplayer_games/rolls_left/fortune_double`);
}

export async function getGamingServiceSettings() {
  return bettingApi.get(`/admin/settings`);
}

export async function saveGamingServiceSettings(settings) {
  return bettingApi.put(`/admin/settings`, { settings });
}

export async function getMultiplayerGamesRooms() {
  return bettingApi.get(`/admin/multiplayer_games/rooms`);
}
export async function updateMultiplayerGamesRooms({ roomId, updatedData }) {
  return bettingApi.put(
    `/admin/multiplayer_games/rooms/${roomId}`,
    updatedData
  );
}
export async function fruitSliceUnlockStuckGame(userId) {
  return bettingApi.put(`/admin/bet-slip/fruit-slice/fix_last_game_locked/${userId}`);
}