import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { permissionsConstants, PermissionsFragment } from "../app/permissions-fragment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
class UserSoftswissFreeSpins extends Component {

  constructor(props) {
    super(props);

    this.state = {
      records: [],
      filter: 'all'
    }
  }

  componentDidMount() {
    this.update();
  }

  update() {
    window.api.get(`/admin/users/${this.props.match.params.id}/softswiss/freespins`).then(data => {
      this.setState({
        records: data
      })
    });

  }

  render() {
    return (
      <Fragment>
        <h4>Softswiss Free Spins</h4>
        <PermissionsFragment feature={permissionsConstants.SOFTSWISS_ISSUE_SPINS}>

          <div>

            Game Slug: <input placeholder={"game slug"} onChange={ev => this.setState({ game_slug: ev.target.value })} />
            <br />
            Amount: <input placeholder={"number of spins"} type={'number'}
              onChange={ev => this.setState({ amount: ev.target.value })} />
            <br />
            Bet Level: <input placeholder={"bet level"} type={'number'} max={5}
              onChange={ev => this.setState({ bet_level: ev.target.value })} />
            <br />
            Lifespan: <input placeholder={"lifespan (minutes)"} type={'number'} max={43200 /*1 month*/}
              onChange={ev => this.setState({ lifespan: ev.target.value })} />
            <br />


            <button disabled={
              !this.state.game_slug ||
              !(this.state.amount > 0) ||
              !this.state.bet_level ||
              !this.state.lifespan} onClick={() => {

                window.api.post(`/admin/users/${this.props.match.params.id}/softswiss/freespins`, {
                  game_slug: this.state.game_slug,
                  amount: this.state.amount,
                  bet_level: this.state.bet_level,
                  lifespan: this.state.lifespan
                }).then(() => {
                  toast.info('credited')

                  this.update();
                })


              }}>Credit
            </button>

            { /*<button onClick={() => {

              window.api.post(`/admin/users/${this.props.match.params.id}/softswiss/freespins`, {
                game_slug: 'acceptance-test',
                amount: 10,
                bet_level: 3,
                lifespan: 1440
              }).then(() => {
                toast.info('credited')

                this.update();
              })


            }}>Credit Acceptance Test
            </button>*/}
            <br />
            <hr />
          </div>
        </PermissionsFragment>

        <PermissionsFragment feature={permissionsConstants.SOFTSWISS_FREE_SPINS_VIEW_LIST}>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Status</Th>
                <Th>Game</Th>
                <Th>Amount</Th>
                <Th>Date</Th>
                <Th>Lifespan</Th>
                <Th>Winnings</Th>
                <Th>Note</Th>
              </Tr>
            </Thead>

            <Tbody>
              {this.state.records.filter(({ status }) => {
                if (this.state.filter === 'all') {
                  return true;
                } else {
                  return status === this.state.filter
                }
              }).map(spins => {

                return (
                  <Tr>
                    <Td>{spins.id}</Td>
                    <Td>{spins.status}</Td>
                    <Td><Link to={"/casino/games/" + spins.slug}>{spins.game_name}</Link></Td>
                    <Td>{spins.amount}</Td>
                    <Td>{format.withSeconds(spins.created_at)}</Td>
                    <Td>{spins.lifespan_minutes}</Td>
                    <Td>{spins.winnings_transaction_id ? `${spins.winnings} ${spins.currency_type}` : `null`}</Td>
                    <Td>{spins.note || `null`}</Td>
                  </Tr>
                )

              })}
            </Tbody>
          </Table>
        </PermissionsFragment>


        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.users.user
});


export default connect(mapStateToProps)(UserSoftswissFreeSpins);