import React, { Component, useState } from "react";
import { Translator } from "shared/translator";
import { toast } from "react-toastify";
import MobilePageTitle from "shared/MobilePageTitle";
import styles from "./create-announcement.module.scss";
import Select from "react-select";

const AnnouncementCreate = ({ languages }) => {
  const [contents, setContents] = useState({});
  const [descriptions, setDescriptions] = useState({});
  const [actionActive, setActionActive] = useState(false);
  const [actionHREF, setActionHREF] = useState("");
  const [target, setTarget] = useState({});
  const [type, setType] = useState("");

  const submitFrom = () =>
    window.api
      .post("/admin/announcements", {
        content: contents,
        action_active: actionActive,
        action_href: actionHREF,
        user_target: target,
        action_description: descriptions,
        type
      })
      .then(toast.success(`Created`))
      .catch((error) => toast.error(`Error`));

  return (
    <>
      <MobilePageTitle title="Create Announcements" />
      <h1 style={{ fontSize: "14px" }}>
        Remember if you want to create an announcement and show in all languages
        you must to create with the translation for en, es and pt or pt-br
      </h1>
      <div className={styles.inputGroup}>
        <div className={styles.block}>
          <Translator
            languages={languages}
            contents={contents}
            onChange={(contents) => setContents(contents)}
            label={"Contents"}
          />

          <div className={styles.inputField}>
            <div>
              <label>Action Active:</label>
              <input
                type="checkbox"
                checked={actionActive}
                onChange={() => setActionActive(!actionActive)}
              />
            </div>
          </div>
          <div className={styles.inputField}>
            <input
              style={{ width: "100%" }}
              value={actionHREF}
              placeholder="Action HREF"
              onChange={(e) => setActionHREF(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.block}>
          <Translator
            languages={languages}
            contents={descriptions}
            onChange={(action_descriptions) =>
              setDescriptions(action_descriptions)
            }
            label={"Action Descriptions"}
          />
        </div>

        <div className={styles.block}>
          <div className={styles.targetContainer}>
            <label>Choose user target</label>
            <Select
              name="type"
              value={target}
              onChange={(option) => {
                setTarget(option.value);
              }}
              className={styles.announcementsSelect}
              options={[
                { value: "signed_out", label: "User logout" },
                { value: "signed_in", label: "User logged" },
              ]}
            />
          </div>
        </div>
        <div className={styles.block}>
          <label>Announcement type</label>
          <div className={styles.inputField}>
            <input
              style={{ width: "100%" }}
              value={type}
              placeholder="Announcement type"
              onChange={(e) => setType(e.target.value.toLocaleLowerCase())}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={submitFrom}>Submit</button>
        </div>
      </div >
    </>
  );
};

export default AnnouncementCreate;
