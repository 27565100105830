import { createSelector } from "reselect";

const _bigWinsSelector = state => state.bigWins;

export const bigWinsSelector = createSelector(
  [
    _bigWinsSelector,
  ],
  (
    bigWins,
  ) => ({
    bigWins: bigWins.bigWins,
    loading: bigWins.loading,
  })
);
