

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import Select from "react-select";
import { updateBoxes } from "../casino/reward-actions";
import ReactTable from "react-table";
import { checkPermissions, permissionsConstants, PermissionsFragment } from "../app/permissions-fragment";
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
class UserMysteryBoxData extends Component {

  constructor(props) {
    super(props);

    this.state = {
      balances: [],
      transactions: {
        pagination: {
          total_pages: 1,
          records_per_page: 50
        },
        records: []
      }
    };

    this.base = `/admin/users/${props.match.params.id}/mystery_box_balances`
  }

  componentDidMount() {

    if (checkPermissions(permissionsConstants.USER_MYSTERY_BOXES_VIEW_LIST_BALANCES)) {
      this.getSpins();
    }

    if (checkPermissions(permissionsConstants.USER_MYSTERY_BOXES_ADD_SPINS)) {
      this.props.updateBoxes()
    }

    if (checkPermissions(permissionsConstants.USER_MYSTERY_BOXES_VIEW_LIST_TRANSACTIONS)) {
      this.updateLedger();

    }
  }

  getSpins() {
    window.api.get(this.base)
      .then(
        balances => this.setState({ balances })
      )
  }

  addSpins(mystery_box_id, spins) {
    window.api.post('/admin/credit_mystery_box_spins', {
      mystery_box_id, spins, user_id: this.props.match.params.id, note: 'admin'
    }).then(() => {
      this.getSpins();
      this.updateLedger();
    })
  }

  updateLedger() {
    let params = {};
    if (this.table) {
      params = {
        page: this.table.state.page + 1,
        sorting: this.table.state.sorted[0],
        filters: this.table.state.filtered,
        mystery_box_balance_id: this.state.mystery_box_balance_id_filter
      }
    }

    window.api.get(`/admin/users/${this.props.match.params.id}/mystery_box_transactions`, {
      params: params
    }).then(data => {
      this.setState({
        transactions: data
      })
    });

  }

  render() {
    return (
      <Fragment>
        <h4>Mystery Box Balances</h4>
        <PermissionsFragment feature={permissionsConstants.USER_MYSTERY_BOXES_VIEW_LIST_BALANCES}>

          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Mystery Box</Th>
                <Th>Spins Available</Th>
              </Tr>
            </Thead>

            <Tbody>
              {this.state.balances.map(balance => {

                return (
                  <Tr>
                    <Td>{balance.id}</Td>
                    <Td>
                      <Link to={`/rewards/mystery-boxes/${balance.mystery_box.id}`}>{balance.mystery_box.name}</Link>
                    </Td>
                    <Td>{balance.spins}</Td>
                  </Tr>
                )

              })}
            </Tbody>
          </Table>
        </PermissionsFragment>

        <PermissionsFragment feature={permissionsConstants.CREDIT_MYSTERY_BOX_SPINS}>
          <br />
          <hr />

          <h4>Modify spins</h4>
          <Select
            placeholder={"Select a mystery box"}
            name="type"
            value={this.state.mystery_box_id}
            onChange={option => this.setState({ mystery_box_id: option.value })}
            options={
              this.props.boxes.map(box => ({
                label: `ID: ${box.id} - ${box.name}`,
                value: box.id
              }))
            }
          />
          <br />
          <input
            placeholder="ex. 4"
            ref={el => this.spins_amount = el}
          />
          <br />
          <br />
          <button
            onClick={
              () => {
                let amount = this.spins_amount.value;

                if (isNaN(+amount)) {
                  return;
                }

                this.addSpins(
                  this.state.mystery_box_id,
                  amount
                )
              }
            }
          >
            Add Rounds
          </button>
          <br />
          <hr />
        </PermissionsFragment>

        <PermissionsFragment feature={permissionsConstants.USER_MYSTERY_BOXES_VIEW_LIST_TRANSACTIONS}>

          <h4>Transaction Ledger</h4>
          <Select
            placeholder={"Filter for box specific balance"}
            name="type"
            value={this.state.mystery_box_balance_id_filter}
            onChange={option => {
              this.setState(
                { mystery_box_balance_id_filter: option.value },
                () => this.updateLedger()
              )
            }}
            options={
              this.state.balances.map(balance => ({
                label: `ID: ${balance.id} - ${balance.mystery_box.name}`,
                value: balance.id
              }))
            }
          />
          <ReactTable
            ref={el => this.table = el}
            manual
            showPageJump={false}
            pages={this.state.transactions.pagination['total_pages']}
            pageSize={this.state.transactions.pagination['records_per_page']}
            data={this.state.transactions.records}
            multiSort={false}
            showPageSizeOptions={false}
            onPageChange={(pageIndex) => {
              this.updateLedger();
            }}
            onSortedChange={(newSorted, column, shiftKey) => {
              this.updateLedger();
            }}
            columns={[
              {
                id: 'id',
                Header: 'ID',
                accessor: d => d.id,
                maxWidth: 50
              },
              {
                id: 'created_at',
                Header: 'Created',
                accessor: d => format.withMinutes(d['created_at']),
                maxWidth: 200
              },
              {
                id: 'bonus_round_type',
                Header: 'Mystery Box Name',
                accessor: d => <Link to={`/rewards/mystery-boxes/${d.mystery_box_balance.mystery_box.id}`}>
                  {d.mystery_box_balance.mystery_box.name}
                </Link>,
              },
              {
                id: 'amount',
                Header: 'Amount',
                accessor: 'amount',
              },
              {
                id: 'closing_balance',
                Header: 'Closing Balance',
                accessor: 'closing_balance',
              },
              {
                id: 'note',
                Header: 'Note',
                accessor: 'note',
              }

            ]}
          />
        </PermissionsFragment>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.users.user,
  boxes: state.rewards.mystery_boxes.records
});

const mapDispatchToProps = dispatch => ({
  updateBoxes: () => dispatch(updateBoxes())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMysteryBoxData);