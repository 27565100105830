import React from "react";
import { NavLink } from "react-router-dom";

export const LookupCrashBetLink = () => (
  <NavLink exact to={`/blaze-originals/crash-bets`}>
    Lookup Crash Bet
  </NavLink>
);
export const LookupDoubleBetLink = () => (
  <NavLink exact to={`/blaze-originals/double-bets`}>
    Lookup Double Bet
  </NavLink>
);
export const LookupMinesBetLink = () => (
  <NavLink exact to={`/blaze-originals/mines-bets`}>
    Lookup Mines Bet
  </NavLink>
);
export const LookupBetsPendingLink = () => (
  <NavLink exact to={`/blaze-originals/bets-pending`}>
    Lookup Pending bets
  </NavLink>
);

export const LookupMinesBetV2Link = () => (
  <NavLink exact to={`/blaze-originals/new-originals-games`}>
    Lookup New OGs Bet
  </NavLink>
);
export const LookupDiceBetLink = () => (
  <NavLink exact to={`/blaze-originals/dice-bets`}>
    Lookup Dice Bet
  </NavLink>
);
export const LookupCrashRoomBetLink = () => (
  <NavLink to={`/blaze-originals/crash-II-bets`}>Lookup Crash II Bet</NavLink>
);
export const LookupPlinkoBetLink = () => (
  <NavLink to={`/blaze-originals/plinko-bets`}>Plinko</NavLink>
);

export const LookupLimboBetLink = () => (
  <NavLink to={`/blaze-originals/limbo-bets`}>Limbo</NavLink>
);
export const LookupSlideBetLink = () => (
  <NavLink to={`/blaze-originals/slide-bet`}>Slide</NavLink>
);
export const LookupFruitsBetLink = () => (
  <NavLink to={`/blaze-originals/fruits-bet`}>Fruits</NavLink>
);
export const LookupFortuneDoubleBetLink = () => (
  <NavLink to={`/blaze-originals/fortune-double-bet`}>Fortune Double Bet</NavLink>
);
export const LookupFortuneDoubleRoomsLink = () => {
  return (
    <NavLink to={`/blaze-originals/fortune-double-rooms`}>
      Fortune Double Rooms
    </NavLink>
  );
}

export const LookupMultiplayerGamesRoomsLink = () => {
  return (
    <NavLink to={`/blaze-originals/multiplayer-games-rooms`}>
      Multiplayer games rooms
    </NavLink>
  );
}
