import React, { useEffect, useState } from "react";
import CustomInput from "./CustomInput";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar-style.css";
import { useSelector } from "react-redux";
import { utc as moment } from "moment";

const Option = {
  Last24Hours: "Last24Hours",
  Last7Days: "Last7Days",
  Last30Days: "Last30Days",
  Last90Days: "Last90Days",
  FromJoinDate: "FromJoinDate",
  FromLastDeposit: "FromLastDeposit",
};
export default function Calendar({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  disableOPtionFromJoinDate,
  disableOPtionFromLastDeposit,
}) {
  const [activeOption, setActiveOption] = useState(Option.Last7Days);
  const classStyle = {
    background: "rgba(37, 47, 56, 0.5)",
    border: "1px solid #323b45",
  };

  const applyOnActive = (option) => (activeOption === option ? classStyle : {});
  const { created_at, last_deposit_occurred_at } = useSelector(
    (store) => store.users.user
  );

  const timespanResolver = {
    [Option.Last24Hours]: () => moment().subtract(1, "day").toDate(),
    [Option.Last7Days]: () => moment().subtract(7, "day").toDate(),
    [Option.Last30Days]: () => moment().subtract(30, "day").toDate(),
    [Option.Last90Days]: () => moment().subtract(90, "day").toDate(),
    [Option.FromLastDeposit]: () => moment(last_deposit_occurred_at).toDate(),
    [Option.FromJoinDate]: () => moment(created_at).toDate(),
  };
  useEffect(() => {
    setStartDate(timespanResolver[activeOption]);
    setEndDate(moment().toDate());
  }, [activeOption]);

  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer className={className}>
          <div className="calendar-container">
            <div style={{ position: "relative" }}>{children}</div>
            <ul className="calendar-presets">
              <li>Date Presets</li>
              <li
                style={applyOnActive(Option.Last24Hours)}
                onClick={() => {
                  setActiveOption(Option.Last24Hours);
                }}
              >
                Last 24 Hours
              </li>
              <li
                style={applyOnActive(Option.Last7Days)}
                onClick={() => {
                  setActiveOption(Option.Last7Days);
                }}
              >
                Last 7 Days
              </li>
              <li
                style={applyOnActive(Option.Last30Days)}
                onClick={() => {
                  setActiveOption(Option.Last30Days);
                }}
              >
                Last 30 Days
              </li>
              <li
                style={applyOnActive(Option.Last90Days)}
                onClick={() => {
                  setActiveOption(Option.Last90Days);
                }}
              >
                Last 90 Days
              </li>
              {!disableOPtionFromJoinDate && (
                <li
                  style={applyOnActive(Option.FromJoinDate)}
                  onClick={() => {
                    setActiveOption(Option.FromJoinDate);
                  }}
                >
                  From Join Date
                </li>
              )}
              {!disableOPtionFromLastDeposit && (
                <li
                  style={applyOnActive(Option.FromLastDeposit)}
                  onClick={() => {
                    setActiveOption(Option.FromLastDeposit);
                  }}
                >
                  From Last Deposit
                </li>
              )}
            </ul>
          </div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <DatePicker
      renderCustomHeader={({
        monthDate,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
      }) => (
        <div>
          <button
            aria-label="Previous Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--previous"
            }
            style={
              customHeaderCount === 1
                ? { visibility: "hidden" }
                : {
                    borderRadius: "50%",
                    border: "1px solid #323B45",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    left: "10px",
                    top: "-2px",
                  }
            }
            onClick={decreaseMonth}
          >
            <span
              style={{ left: "0px", top: "1px" }}
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              }
            >
              {"<"}
            </span>
          </button>
          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button
            aria-label="Next Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--next"
            }
            style={
              customHeaderCount === 0
                ? { visibility: "hidden" }
                : {
                    borderRadius: "50%",
                    border: "1px solid #323B45",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    right: "10px",
                    top: "-2px",
                  }
            }
            onClick={increaseMonth}
          >
            <span
              style={{ left: "0px", top: "1px" }}
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
          </button>
        </div>
      )}
      selected={startDate}
      onChange={(date) => {
        const [start, end] = date;
        setStartDate(start);
        setEndDate(end);
      }}
      calendarClassName="calendar"
      monthsShown={2}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      calendarContainer={MyContainer}
      customInput={<CustomInput />}
      dayClassName={(d) => {
        const date1 = new Date(d);
        const date2 = new Date(startDate);
        const date3 = new Date(endDate);

        const day1 = date1.getDate();
        const day2 = date2.getDate();
        const day3 = date3.getDate();

        const month1 = date1.getMonth();
        const month2 = date2.getMonth();
        const month3 = date3.getMonth();
        if (day1 === day2 && month1 === month2) return "start-range";
        if (day1 === day3 && month1 === month3) return "end-range";
      }}
    />
  );
}
