
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCurrencyLimits, updateLimit } from "./actions";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';


class CurrencyLimits extends Component {

  componentDidMount() {
    this.props.update();
  }

  render() {
    let { limits = [] } = this.props;
    return (
      <div>
        <div>
          <hr />
          <h4>Currencies</h4>
          <Table>
            <Thead>
              <Tr>
                <Th>Provider</Th>
                <Th>Method</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {limits.map((limit, index) => {
                return (
                  <Tr key={index}>
                    <Td>{limit.type}</Td>
                    <Td>{limit.method}</Td>
                    <Td>
                      <Link to={`/currency_limits/${limit.id}`}>
                        Edit
                      </Link>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.currencies
});

const mapDispatchToProps = dispatch => ({

  update: () => {
    dispatch(updateCurrencyLimits());
  },

  updateLimit(limit, value) {
    dispatch(updateLimit(limit, value))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyLimits)
