import { useState } from "react";

import "./check-cpf.css";
import { invalidCPF } from "utils/cpf";
import { startTypeCheck } from "utils/type-check";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const providers = ["exato", "serpro"];

const CheckCPF = () => {
  const [providerResponses, setProviderResponses] = useState({});
  const [checking, setChecking] = useState(false);
  const [cpf, setCpf] = useState("");

  const checkCpf = async () => {
    try {
      setChecking(true);
      const data = await window.api.get(`/admin/users/check-cpf?cpf=${cpf}`);
      setProviderResponses(data);
    } catch (err) {
      console.log(err);
    } finally {
      setChecking(false);
    }
  };

  return (
    <div className="check-cpf-container">
      <div className="cpf-input">
        <label htmlFor="cpf">CPF:</label>
        <input
          name="cpf"
          id="cpf"
          onChange={(e) => setCpf(e.currentTarget.value)}
        />
        <button disabled={checking || invalidCPF(cpf)} onClick={checkCpf}>
          {invalidCPF(cpf) ? "Invalid CPF Format" : "CHECK"}
        </button>
      </div>

      <div className="cpf-providers-response">
        {checking
          ? "Checking"
          : providers.map((provider) => {
              const providerResponse = providerResponses[provider];
              if (providerResponse) {
                const keys = Object.keys(providerResponse);
                return (
                  <div key={provider}>
                    <h2>{provider.toUpperCase()}</h2>
                    <Table>
                      <Thead>
                        <Tr>
                          <Td>Key</Td>
                          <Td>Value</Td>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {keys.map((key) => {
                          const keyValue = startTypeCheck(
                            providerResponse[key]
                          );
                          return (
                            <Tr>
                              <Td>{key.toUpperCase()}:</Td>
                              <Td>
                                {keyValue.is.aBoolean
                                  ? keyValue.returnSelf === true
                                    ? "YES"
                                    : "NO"
                                  : keyValue.returnSelf}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>
                );
              }
            })}
      </div>
    </div>
  );
};

export default CheckCPF;
