import React, { Fragment } from "react";
// import "./translator.style.scss";
import styles from "./translator.module.scss";
import Select from "react-select";
class Translator extends React.Component {
  state = {};

  render() {
    const { contents, onChange, label, disabled } = this.props;

    return (
      <div className={styles.container}>
        <h3>{label}</h3>
        {Object.keys(contents).map((lang, index) => {
          return (
            <Fragment key={index}>
              <div className={styles.contentEntry} key={index}>
                <h4 style={{ width: "200px" }}>{lang}</h4>
                <textarea
                  onChange={(ev) =>
                    onChange({ ...contents, [lang]: ev.target.value })
                  }
                  value={contents[lang]}
                />
                <button
                  type="button"
                  onClick={() => {
                    let newContents = { ...contents };
                    delete newContents[lang];
                    onChange(newContents);
                  }}
                >
                  Delete
                </button>
              </div>
              <hr />
            </Fragment>
          );
        })}

        <>
          <div className={`${styles.contentEntry} add-translation`}>
            <Select
              className={styles.textAreaLocale}
              value={this.state.locale}
              options={[
                { value: "pt", label: "pt" },
                { value: "en", label: "en" },
                { value: "es", label: "es" },
                { value: "es-cl", label: "es-cl" },
                { value: "pt-br", label: "pt-br" },
                { value: "es-pe", label: "es-pe" },
                { value: "es-ec", label: "es-ec" },
                { value: "no", label: "no" },
              ]}
              onChange={(e) => this.setState({ locale: e.value })}
              placeholder={
                "Enter language (ex. en-US, en-CA, pt-BR, pt, es, no)"
              }
            />
            <br />
            <textarea
              className={styles.textAreaContent}
              placeholder="Content"
              ref={(el) => (this.content = el)}
            />
          </div>
          {!disabled && (
            <button
              className="small"
              type="button"
              onClick={() => {
                onChange({
                  ...contents,
                  [this.state.locale]: this.content.value,
                });
              }}
            >
              Add translation
            </button>
          )}
        </>
      </div>
    );
  }
}

export { Translator };
