import { format } from "shared/utils/dates";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bigWinsSelector } from "./state/selectors";
import { loadBigWins } from './state/actions';
import { useEffect, useState } from "react";
import Pagination from "shared/pagination";
import Calendar from "shared/Calendar";
import { isValidPositiveNumber } from "shared/utils/numbers";
import moment from "moment";

const RECORDS_PER_PAGE = 50;

const BigWins = () => {
  const { bigWins, loading } = useSelector(bigWinsSelector);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [userId, setUserId] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [gameSlug, setGameSlug] = useState(null);

  useEffect(() => {
    loadData();
  }, [startDate, endDate]);

  const loadData = ({ page = 1 } = {}) => {
    dispatch(loadBigWins({
      page,
      limit: RECORDS_PER_PAGE,
      userId: userId && userId || null,
      startDate,
      endDate,
      gameId,
      gameSlug,
    }));
  };

  return (
    <div>
      <div className="filters-query" style={{ display: 'flex', flex: 1, margin: '0 -10px 25px' }}>
        <div className="filter-query">
          <h5>Search by Time</h5>
          <Calendar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
        <div className="filter-query">
          <h5>Search by user ID</h5>
          <input
            type="text"
            id="id"
            name="id"
            onChange={(e) => setUserId(e.target.value)}
            onBlur={loadData}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                loadData();
              }
              if (!isValidPositiveNumber(e.target.value)) {
                e.preventDefault();
                return;
              }
            }}
          />
        </div>
        <div className="filter-query">
          <h5>Search by game ID</h5>
          <input
            type="text"
            id="game_id"
            name="game_id"
            onChange={(e) => setGameId(e.target.value)}
            onBlur={loadData}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                loadData();
              }
            }}
          />
        </div>
        <div className="filter-query">
          <h5>Search by game slug</h5>
          <input
            type="text"
            id="game_slug"
            name="game_slug"
            onChange={(e) => setGameSlug(e.target.value)}
            onBlur={loadData}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                loadData();
              }
            }}
          />
        </div>
      </div>
      { loading && <>Loading...</>}
      {
        !loading && <Table>
          <Thead>
            <Tr>
              <Th>Created at</Th>
              <Th>Game Slug</Th>
              <Th>Game provider round id</Th>
              <Th>User</Th>
              <Th>Wager amount</Th>
              <Th>Win amount</Th>
              <Th>Multiplier</Th>
            </Tr>
          </Thead>

          <Tbody>
            {bigWins?.records?.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>
                    <span>{format.withMinutes(r.created_at)}</span>
                  </Td>
                  <Td>
                    <span>{r.game_slug}</span>
                  </Td>
                  <Td>
                    <Link to={`/casino/game-provider-rounds/${r.game_provider_round_id}`}>{r.game_provider_round_id}</Link>
                  </Td>
                  <Td>
                    <span>
                      <Link to={`/users/${r.user_id}/info`}>{r.username}</Link>
                    </span>
                  </Td>
                  <Td>
                    <span>
                      {r.wager_amount} {r.currency_type}
                    </span>
                  </Td>
                  <Td>
                    <span>{r.win_amount} {r.currency_type}</span>
                  </Td>
                  <Td>
                    <span>{r.multiplier}</span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      }
      <Pagination
        total_pages={bigWins.count / RECORDS_PER_PAGE}
        update={(page) => {
          dispatch(loadBigWins({ page }));
        }}
      />
    </div>
  );
};

export default BigWins;