import { Component } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { toast } from "react-toastify";
import { format } from "shared/utils/dates.js";

export default class AnnouncementList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      records: [],
    };
  }

  componentDidMount() {
    this.list();
  }

  list() {
    window.api
      .get("/admin/announcements")
      .then((records) => this.setState({ records }));
  }
  disable() {
    window.api.post(`/admin/announcements/disable`).then(() => {
      toast.success(`Disabled`);
      this.list();
    });
  }

  render() {
    let { selected_lang, contents, action_descriptions, selected_desc_lang } =
      this.state;
    return (
      <div id={"announcements"}>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>Type</Th>
              <Th>Content</Th>
              <Th>User Target</Th>
              <Th>Action Active</Th>
              <Th>Action HREF</Th>
              <Th>Action Description</Th>
              <Th>Active</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.records.map((r) => {
              return (
                <Tr key={r.id}>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                  <Td>{r.id}</Td>
                  <Td>{r.type}</Td>
                  <Td>{JSON.stringify(r.content)}</Td>
                  <Td>{r.user_target}</Td>
                  <Td>{r.action_active.toString()}</Td>
                  <Td>{r.action_href}</Td>
                  <Td>{JSON.stringify(r.action_description)} </Td>
                  <Td>{r.active.toString()}</Td>
                  <Td>
                    {r.active && (
                      <button className="small" onClick={() => this.disable()}>
                        Disable
                      </button>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    );
  }
}
