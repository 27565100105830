import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from '../shared/pagination';
import bettingApi from '../app/http-betting-api';
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import TooltipRoundIDExplanation from './components/TooltipRoundIDExplanation';
import TooltipFreeRoundID from './components/TooltipFreeRoundID';

import './bets-section.scss';

const CRASH_REWIRED_ROOM_ID = 4;

const UserCrashRewiredBets = (props) => {
  const [data, setData] = useState({ bets: [], total_pages: 0 });
  const [activatedOldCrash, setActivatedOldCrash] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const fetch = useCallback(
    async (currentPage) => {
    setIsLoading(true);
    try {
      const { total_pages, records } = await (activatedOldCrash
        ? window.api.get(`/admin/users/${props.match.params.id}/crash_bets?page=${currentPage}`)
        : bettingApi.get(`/admin/user/${props.match.params.id}/crash_bets?page=${currentPage}&room_id=${CRASH_REWIRED_ROOM_ID}`)
      );
      setData({ total_pages, bets: records });
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  }, 
  [props.match.params.id, activatedOldCrash],
);

  useEffect(() => {
    fetch(page);
  }, [page, activatedOldCrash]);

  const renderTableRows = (bet) => {
      
    const roundId = activatedOldCrash ? bet.round_id : bet.remote_round_id;
    
    return (
      <Tr key={roundId}>
        <Td>
          {bet.free_bet ? <TooltipFreeRoundID>{roundId}</TooltipFreeRoundID> : (
            <Link to={`/casino/game-provider-rounds/${roundId}`}>{roundId}</Link>
          )}
        </Td>
        <Td>{format.withSeconds(bet.game_started_at)}</Td>
        <Td>{format.withSeconds(bet.game_settled_at)}</Td>
        <Td>{bet.game_crash_point}</Td>
        <Td>{format.withSeconds(bet.bet_placed_at)}</Td>
        <Td>{format.withSeconds(bet.bet_settled_at)}</Td>
        <Td className={bet.profit > 0 ? 'good' : 'bad'}>
          {bet.amount} {bet.currency_type}
        </Td>
        <Td>{bet.auto_cashout_at}x</Td>
        <Td>{bet.cashed_out_at}x</Td>
        {!activatedOldCrash && <Td>{bet.free_bet ? 'yes' : 'no'}</Td>}
        <Td className={bet.profit > 0 ? 'good' : 'bad'}>
          {bet.win_amount} {bet.currency_type}
        </Td>
        <Td>{bet.status}</Td>
        <Td className={bet.profit > 0 ? 'good' : 'bad'}>
          {bet.profit} {bet.currency_type}
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        style={{ color: '#97a8ff', cursor: 'pointer', margin: '10px' }}
        onClick={() => setActivatedOldCrash(prev => !prev)}>
        {activatedOldCrash ? 'Crash History' : 'Old Crash History'}
      </span>
      <div className='bets-section'>
        {isLoading && (
          <div className='loading-overlay'>
            <h2>Loading, please wait...</h2>
            <div className='loader' />
          </div>
        )}
        <Table>
          <Thead>
            <Tr>
              <Th>
                <TooltipRoundIDExplanation>Round ID</TooltipRoundIDExplanation>
                </Th>
              <Th>Game Started At</Th>
              <Th>Game Settled At</Th>
              <Th>Game Crash Point</Th>
              <Th>Bet Placed At</Th>
              <Th>Bet Settled At</Th>
              <Th>Amount</Th>
              <Th>Autocashout At</Th>
              <Th>Cashed Out At</Th>
              {!activatedOldCrash && <Th>Free Bet</Th>}
              <Th>Winnings</Th>
              <Th>Status</Th>
              <Th>Profit</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.bets.map(renderTableRows)}
          </Tbody>
        </Table>

        <Pagination total_pages={data.total_pages} update={setPage} />
      </div>
    </>
  );
};

export default UserCrashRewiredBets;
