import React from "react";
import styles from "../styles.module.scss";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import _ from 'lodash'


const CPF_PROPS = {
  DOB: "dob",
  FULLNAME: "fullname",
  CPF: "cpf",
  LEGAL_SITUATION: "legal_situation",
  ANTERIOR_1990: "anterior_1990",
  OBITO: "obito",
  EMITTED: "emitted",
  ORIGIN: "origin",
  LAST_UPDATED: "date",
  PEP: "pep",
  DEPOSIT_ID: "deposit_id",
  EMAIL_CPF: "email_cpf"

}


const CpfInfo = ({ cpfInfo, userInfo }) => {
  const emptyMessage = "Not available";
  let finalParsedResponse = {};
  let initialized = false

  const setDefaultOrFailProps = () => {
    finalParsedResponse[CPF_PROPS.DOB] = emptyMessage
    finalParsedResponse[CPF_PROPS.CPF] = emptyMessage
    finalParsedResponse[CPF_PROPS.ANTERIOR_1990] = emptyMessage
    finalParsedResponse[CPF_PROPS.EMITTED] = emptyMessage
    finalParsedResponse[CPF_PROPS.FULLNAME] = emptyMessage
    finalParsedResponse[CPF_PROPS.LEGAL_SITUATION] = emptyMessage
    finalParsedResponse[CPF_PROPS.OBITO] = emptyMessage
    finalParsedResponse[CPF_PROPS.ORIGIN] = emptyMessage
    finalParsedResponse[CPF_PROPS.PEP] = emptyMessage
    finalParsedResponse[CPF_PROPS.DEPOSIT_ID] = emptyMessage;
    finalParsedResponse[CPF_PROPS.EMAIL_CPF] = emptyMessage;
  }


  const parseProviderResponse = (prop) => {
    if (!Object.values(CPF_PROPS).includes(prop)) return emptyMessage;
    if (initialized === true) return finalParsedResponse[prop];

    const rawParsedResponse = _.get(cpfInfo, 'response', {});

    if (!rawParsedResponse) {
      setDefaultOrFailProps()
      return emptyMessage;
    }

    finalParsedResponse = {}

    if (cpfInfo.provider === "exato") {
      let deceased = _.get(rawParsedResponse, 'Result.Obito', emptyMessage);
      if (deceased === true) {
        deceased = "Yes";
      } else if (deceased === false) {
        deceased = "No";
      }

      let anterior_1990 = _.get(rawParsedResponse, 'Result.DataInscricaoAnterior1990', emptyMessage);
      if (anterior_1990 === true) {
        anterior_1990 = "Yes";
      }
      else if (anterior_1990 === false) {
        anterior_1990 = "No";
      }



      finalParsedResponse[CPF_PROPS.DOB] = _.get(rawParsedResponse, 'Result.DataNascimento', emptyMessage);
      finalParsedResponse[CPF_PROPS.CPF] = _.get(rawParsedResponse, 'Result.NumeroCpf', emptyMessage);
      finalParsedResponse[CPF_PROPS.ANTERIOR_1990] = anterior_1990
      finalParsedResponse[CPF_PROPS.EMITTED] = _.get(rawParsedResponse, 'Result.DataEmissao', emptyMessage);
      finalParsedResponse[CPF_PROPS.FULLNAME] = _.get(rawParsedResponse, 'Result.NomePessoaFisica', emptyMessage);
      finalParsedResponse[CPF_PROPS.LEGAL_SITUATION] = _.get(rawParsedResponse, 'Result.SituacaoCadastral', emptyMessage);
      finalParsedResponse[CPF_PROPS.OBITO] = deceased
      finalParsedResponse[CPF_PROPS.ORIGIN] = _.get(rawParsedResponse, 'Result.Origem', emptyMessage);
      finalParsedResponse[CPF_PROPS.LAST_UPDATED] = _.get(rawParsedResponse, 'Date', emptyMessage);
      finalParsedResponse[CPF_PROPS.PEP] = emptyMessage;
      finalParsedResponse[CPF_PROPS.DEPOSIT_ID] = emptyMessage;
      finalParsedResponse[CPF_PROPS.EMAIL_CPF] = emptyMessage;

    }
    else if (cpfInfo.provider === "serpo") {
      finalParsedResponse[CPF_PROPS.DOB] = _.get(rawParsedResponse, 'nascimento', emptyMessage);
      finalParsedResponse[CPF_PROPS.CPF] = _.get(rawParsedResponse, 'ni', emptyMessage);
      finalParsedResponse[CPF_PROPS.ANTERIOR_1990] = emptyMessage;
      finalParsedResponse[CPF_PROPS.EMITTED] = emptyMessage;
      finalParsedResponse[CPF_PROPS.FULLNAME] = _.get(rawParsedResponse, 'nome', emptyMessage);
      finalParsedResponse[CPF_PROPS.LEGAL_SITUATION] = _.get(rawParsedResponse, 'situacao.descricao', emptyMessage);
      finalParsedResponse[CPF_PROPS.OBITO] = emptyMessage;
      finalParsedResponse[CPF_PROPS.ORIGIN] = emptyMessage;
      finalParsedResponse[CPF_PROPS.LAST_UPDATED] = emptyMessage;
      finalParsedResponse[CPF_PROPS.PEP] = emptyMessage;
      finalParsedResponse[CPF_PROPS.DEPOSIT_ID] = emptyMessage;
      finalParsedResponse[CPF_PROPS.EMAIL_CPF] = emptyMessage;
    }
    else if (cpfInfo.provider === "directa24") {
      let pep = _.get(rawParsedResponse, 'pep', emptyMessage);
      if (pep === true) {
        pep = "Yes";
      } else if (pep === false) {
        pep = "No";
      }
      finalParsedResponse[CPF_PROPS.DOB] = _.get(rawParsedResponse, 'dateOfBirth', emptyMessage);
      finalParsedResponse[CPF_PROPS.CPF] = _.get(rawParsedResponse, 'document', emptyMessage);
      finalParsedResponse[CPF_PROPS.ANTERIOR_1990] = emptyMessage;
      finalParsedResponse[CPF_PROPS.EMITTED] = emptyMessage;
      finalParsedResponse[CPF_PROPS.FULLNAME] = _.get(rawParsedResponse, 'fullName', emptyMessage);
      finalParsedResponse[CPF_PROPS.LEGAL_SITUATION] = _.get(rawParsedResponse, 'cadastralSituation', emptyMessage);
      finalParsedResponse[CPF_PROPS.OBITO] = emptyMessage;
      finalParsedResponse[CPF_PROPS.ORIGIN] = emptyMessage;
      finalParsedResponse[CPF_PROPS.LAST_UPDATED] = emptyMessage;
      finalParsedResponse[CPF_PROPS.PEP] = pep;
      finalParsedResponse[CPF_PROPS.DEPOSIT_ID] = _.get(rawParsedResponse, 'depositId', emptyMessage);
      finalParsedResponse[CPF_PROPS.EMAIL_CPF] = _.get(rawParsedResponse, 'email', emptyMessage);
    }
    else {
      finalParsedResponse[CPF_PROPS.DOB] = emptyMessage
      finalParsedResponse[CPF_PROPS.CPF] = emptyMessage
      finalParsedResponse[CPF_PROPS.ANTERIOR_1990] = emptyMessage;
      finalParsedResponse[CPF_PROPS.EMITTED] = emptyMessage;
      finalParsedResponse[CPF_PROPS.FULLNAME] = emptyMessage
      finalParsedResponse[CPF_PROPS.LEGAL_SITUATION] = emptyMessage
      finalParsedResponse[CPF_PROPS.OBITO] = emptyMessage;
      finalParsedResponse[CPF_PROPS.ORIGIN] = emptyMessage;
      finalParsedResponse[CPF_PROPS.LAST_UPDATED] = emptyMessage;
      finalParsedResponse[CPF_PROPS.PEP] = emptyMessage;
      finalParsedResponse[CPF_PROPS.DEPOSIT_ID] = emptyMessage;
      finalParsedResponse[CPF_PROPS.EMAIL_CPF] = emptyMessage;
    }

    initialized = true
    return finalParsedResponse[prop];
  };


  return (
    <div className={styles.panel} id="cpfinfo">
      <h2 className={styles.titlePanel}>CPF Info</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>Field</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr >
            <Td>Provider</Td>
            <Td>{_.get(cpfInfo, 'provider', 'not available')}</Td>
          </Tr>
          <Tr>
            <Td>Validated?</Td>
            <Td>{_.get(cpfInfo, 'is_valid', 'not available') === true ? "Validated" : "Not validated"}</Td>
          </Tr>
          <Tr>
            <Td>DOB</Td>
            <Td>{parseProviderResponse(CPF_PROPS.DOB)}</Td>
          </Tr>
          <Tr>
            <Td>Full name</Td>
            <Td>{parseProviderResponse(CPF_PROPS.FULLNAME)}</Td>
          </Tr>
          <Tr>
            <Td>CPF</Td>
            <Td>{parseProviderResponse(CPF_PROPS.CPF)}</Td>
          </Tr>
          <Tr>
            <Td>Legal situation</Td>
            <Td>{parseProviderResponse(CPF_PROPS.LEGAL_SITUATION)}</Td>
          </Tr>
          <Tr>
            <Td>Address</Td>
            <Td>{_.get(userInfo, 'address', 'not available')}</Td>
          </Tr>
          <Tr>
            <Td>Email</Td>
            <Td>{_.get(userInfo, 'email', 'not available')}</Td>
          </Tr>
          <Tr>
            <Td>Emitted</Td>
            <Td>{parseProviderResponse(CPF_PROPS.EMITTED)}</Td>
          </Tr>
          <Tr>
            <Td>Before 1990</Td>
            <Td>{parseProviderResponse(CPF_PROPS.ANTERIOR_1990)}</Td>
          </Tr>
          <Tr>
            <Td>Deceased</Td>
            <Td>{parseProviderResponse(CPF_PROPS.OBITO)}</Td>
          </Tr>
          <Tr>
            <Td>Origen</Td>
            <Td>{parseProviderResponse(CPF_PROPS.ORIGIN)}</Td>
          </Tr>
          <Tr>
            <Td>PEP</Td>
            <Td>{parseProviderResponse(CPF_PROPS.PEP)}</Td>
          </Tr>
          <Tr>
            <Td>Deposit id</Td>
            <Td>{parseProviderResponse(CPF_PROPS.DEPOSIT_ID)}</Td>
          </Tr>
          <Tr>
            <Td>Email CPF</Td>
            <Td>{parseProviderResponse(CPF_PROPS.EMAIL_CPF)}</Td>
          </Tr>
        </Tbody>
      </Table>

    </div >
  );
};
export default CpfInfo;
