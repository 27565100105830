import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { format } from '../shared/utils/dates';

const formatDate = (date) => format.onlyDate(date);

const Raffle = () => {
  const queryClient = useQueryClient();
  const [period, setPeriod] = useState(null)
  const [ticketNumber, setTicketNumber] = useState(null)

  const { isLoading, data: periods } = useQuery("raffles", async () =>
    window.api.get(`/admin/raffle`)
  )

  const { mutate: mutateTotalTickets } = useMutation("raffle-total-tickets", async () =>
      window.api.post(`/admin/raffle/${period?.value}/total_tickets`),
    { onSuccess: () => queryClient.invalidateQueries('raffles') }
  )

  const {
    mutate: mutateWinningUser,
    isLoading: winningUserLoading,
    data: winningUserData
  } = useMutation("raffle-winning-user",
    () => window.api.post(`/admin/raffle/${period?.value}/winning_user`, { ticket_number: ticketNumber }),
    { onSuccess: () => queryClient.invalidateQueries('raffles') }
  )

  const periodSelected = period && periods?.find(p => p.id === period.value);

  return (
    <div>
      <h3>Raffle</h3>
      <label>Period: </label>
      <Select
        name="type"
        value={period}
        onChange={option => {
          setPeriod(option)
          setTicketNumber(null)
        }}
        options={
          periods?.map(e =>
            ({ value: e.id, label: `ID: ${e.id}, Period: ${formatDate(e.starts_at)} - ${formatDate(e.ends_at)}` })
          )
        }
      />

      <br/>

      <label>Total Ticket: </label>
      {isLoading ? <label>Loading...</label> : <label>{periodSelected?.max_ticket_number}</label>}
      <br/>

      <button
        disabled={!period}
        onClick={() => {
          mutateTotalTickets()
        }}
      >Calculate Total Tickets
      </button>
      <br/>
      <br/>

      <label>Ticket Number: </label>
      <input
        style={{ width: '100%' }}
        value={ticketNumber}
        type={"number"}
        onChange={
          e => setTicketNumber(e.target.value)
        }
      />
      <br/>
      <br/>
      <h3>Winning User </h3>

      <label>User ID: {winningUserData?.winning_user_id}</label><br/>
      <label>Name: {winningUserData?.winning_user_name}</label><br/>
      <label>Username: {winningUserData?.winning_username}</label>

      <br/>
      <br/>

      <button
        onClick={() => {
          mutateWinningUser()
        }}
        disabled={!ticketNumber || winningUserLoading}
      >
        {winningUserLoading ? "Loading..." : "Calculate Winning User"}
      </button>
      <br/>

    </div>
  )
}


export default Raffle;