

import React, {Component} from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import { Link } from "react-router-dom";
import { updateRewards } from "../casino/reward-actions";
import {checkPermissions} from "../app/api";
import {permissionsConstants} from "../app/permissions-fragment";
import { format } from '../shared/utils/dates';
class UserMysteryBoxOpens extends Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      page: 1,
      results: {
        pagination: {
          total_pages: 1,
          records_per_page: 50
        },
        records: []
      },
      edit_row: {},
      creating: false
    }
  }

  componentDidMount() {
    this.update();
    if (checkPermissions(permissionsConstants.REWARDS_VIEW_LIST)) {
      this.props.dispatch(updateRewards());
    }
  }

  getBase() {
    return `/admin/users/${this.props.match.params.id}/mystery_box_opens`
  }

  update() {
    let params = {};
    if (this.table) {
      params = {
        page: this.table.state.page + 1,
        sorting: this.table.state.sorted[0],
        filters: this.table.state.filtered,
        show_inactive: this.state.show_inactive ? '1' : '0'
      }
    }

    window.api.get(this.getBase(), {
      params: params
    }).then(data => {
      this.setState({
        results: data
      })
    });
  }

  render() {
    const { edit_row, creating } = this.state;
    let { pagination, records } = this.state.results;

    if (creating) {
      records = [edit_row].concat(records);
    }

    return (
      <div>


        <ReactTable
          ref={el => this.table = el}
          manual
          showPageJump={false}
          pages={pagination['total_pages']}
          pageSize={pagination['records_per_page']}
          data={records}
          multiSort={false}
          showPageSizeOptions={false}
          onPageChange={(pageIndex) => {
            this.update();
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            this.update();
          }}
          onFilteredChange={(filtered, column, value) => {
            this.update();
          }}
          columns={[
            {
              id: 'id',
              Header: 'ID',
              accessor: d => d['id'],
              maxWidth: 50
            },
            {
              id: 'created_at',
              Header: 'Created',
              accessor: d => format.withMinutes(d['created_at']),
              maxWidth: 200
            },
            {
              id: 'mystery_box_id',
              Header: 'Mystery Box',
              accessor: d => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img style={{width: '30px'}} src={d.mystery_box.image_url}/>
                  <Link to={`/rewards/mystery-boxes/${d.mystery_box_id}`}>
                    {d.mystery_box.name}</Link>
                </div>
              ),
              maxWidth: 400
            },
            {
              id: 'reward_id',
              Header: 'Winnings',
              accessor: d => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img style={{width: '30px'}} src={d.reward.image_url}/>
                  <Link to={`/rewards/${d.reward.id}`}>
                    {d.reward.name}</Link>
                </div>
              ),
              maxWidth: 400
            },
            {
              id: 'roll',
              Header: 'Roll (1-1m)',
              accessor: d => (
                <span>{d.roll}</span>
              ),
              maxWidth: 200
            }
          ]}
        />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  rewards: state.rewards.items.records,
  user_ranks: state.app.user_ranks,
  user: state.users.user
});


export default connect(mapStateToProps)(UserMysteryBoxOpens);