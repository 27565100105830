import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { capitalizeFirstLetter, mapGameName } from 'shared/utils/strings';

const FEATURES_STATUS = {
   GLOBALLY_AVAILABLE: 'globally_available', // allows all players to play the game
   RESTRICTED_ACCESS: 'restricted_access', // allows players in the feature_access table with has_access set to true
   WAITLIST_MAINTENANCE: 'waitlist_maintenance', // disables access to the waitlist components
   GAME_MAINTENANCE: 'game_maintenance', // disables playing the game while keeping the joining the waitlist active
   GLOBAL_MAINTENANCE: 'global_maintenance', // disables both
};

const WaitListManagement = () => {
   const [features, setFeatures] = useState([]);

   const refresh = async () => {
      const { records } = await window.api.get('/admin/waitlist_feature/feature_status');
      setFeatures(records || []);
   };

   const updateStatus = async (featureName, status) => {
      await window.api.put('/admin/waitlist_feature/feature_status/' + featureName, {
         status,
      });

      refresh();
   };

   const getFeatureName = (feature_name, game_name) => {
      const isCrashFeature = feature_name.includes('crash');
      const gameName = capitalizeFirstLetter(game_name || feature_name);
      const mappedCrashFeature = isCrashFeature ? mapGameName(feature_name) : '';

      return isCrashFeature ? mappedCrashFeature : gameName;
   };

   const renderStatusSelect = (feature_name, status) => (
      <Select
         value={status}
         onChange={(option) => updateStatus(feature_name, option.value)}
         options={Object.keys(FEATURES_STATUS).map((key) => ({
            label: key,
            value: FEATURES_STATUS[key],
         }))}
      />
   );

   useEffect(() => {
      refresh();
   }, []);

   return (
      <div>
         <h1>Wait List Management</h1>

         <div>
            <h3>Statuses</h3>
            <ul>
               <li>GLOBALLY_AVAILABLE: allows all players to play the game</li>
               <li>
                  RESTRICTED_ACCESS: allows only players that have been granted access through the
                  waitlist
               </li>
               <li>WAITLIST_MAINTENANCE: disables access to the waitlist components</li>
               <li>
                  GAME_MAINTENANCE: disables playing the game while keeping the joining the waitlist
                  active
               </li>
               <li>GLOBAL_MAINTENANCE: disables both</li>
            </ul>
         </div>
         <Table>
            <Thead>
               <Tr>
                  <Th>Feature</Th>
                  <Th>Status</Th>
                  <Th>Actions</Th>
               </Tr>
            </Thead>
            <Tbody>
               {features.map(({ id, feature_name, status, game_name }) => (
                  <Tr key={id}>
                     <Td>{getFeatureName(feature_name, game_name)}</Td>
                     <Td>{renderStatusSelect(feature_name, status)}</Td>
                     <Td>
                        <a href={`/system/wait-list-management/${feature_name}`}>Details</a>
                     </Td>
                  </Tr>
               ))}
            </Tbody>
         </Table>
      </div>
   );
};

export default WaitListManagement;
