export const mockedObjectNew = {
  races: [
    {
      id: 1,
      name: "test 1",
      game_name: "Mines",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:05.850Z",
      current_iteration_end_date: "2022-12-26T17:44:05.850Z",
      created_at: "2022-12-23T17:44:05.851Z",
      updated_at: "2022-12-23T17:44:05.851Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 2,
      name: "test 1",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:06.386Z",
      current_iteration_end_date: "2022-12-26T17:44:06.386Z",
      created_at: "2022-12-23T17:44:06.386Z",
      updated_at: "2022-12-23T17:44:06.386Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 4,
      name: "test 2",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:07.460Z",
      current_iteration_end_date: "2022-12-26T17:44:07.460Z",
      created_at: "2022-12-23T17:44:07.469Z",
      updated_at: "2022-12-23T17:44:07.469Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 5,
      name: "test 1",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:07.460Z",
      current_iteration_end_date: "2022-12-26T17:44:07.460Z",
      created_at: "2022-12-23T17:44:07.468Z",
      updated_at: "2022-12-23T17:44:07.468Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 6,
      name: "test 4",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:07.460Z",
      current_iteration_end_date: "2022-12-26T17:44:07.460Z",
      created_at: "2022-12-23T17:44:07.469Z",
      updated_at: "2022-12-23T17:44:07.469Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 7,
      name: "test 3",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:07.460Z",
      current_iteration_end_date: "2022-12-26T17:44:07.460Z",
      created_at: "2022-12-23T17:44:07.469Z",
      updated_at: "2022-12-23T17:44:07.469Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 8,
      name: "test 2",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:07.982Z",
      current_iteration_end_date: "2022-12-26T17:44:07.982Z",
      created_at: "2022-12-23T17:44:07.982Z",
      updated_at: "2022-12-23T17:44:07.983Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 11,
      name: "test 4",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:08.512Z",
      current_iteration_end_date: "2022-12-26T17:44:08.512Z",
      created_at: "2022-12-23T17:44:08.512Z",
      updated_at: "2022-12-23T17:44:08.512Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 12,
      name: "test 1",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-25T17:44:08.512Z",
      current_iteration_end_date: "2022-12-26T17:44:08.512Z",
      created_at: "2022-12-23T17:44:08.512Z",
      updated_at: "2022-12-23T17:44:08.512Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 10,
      name: "test 3",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "active",
      iteration: 0,
      current_iteration_start_date: "2022-12-27T17:44:08.512Z",
      current_iteration_end_date: "2022-12-28T17:44:08.512Z",
      created_at: "2022-12-23T17:44:08.512Z",
      updated_at: "2022-12-23T17:44:08.512Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 9,
      name: "test 2",
      duration: "day",
      game_id: null,
      recurring_frequency: "day",
      status: "expired",
      iteration: 0,
      current_iteration_start_date: "2022-12-26T17:44:08.512Z",
      current_iteration_end_date: "2022-12-27T17:44:08.512Z",
      created_at: "2022-12-23T17:44:08.512Z",
      updated_at: "2022-12-23T17:44:08.512Z",
      eur_prize: "0",
      starts_at: "2023-04-12T21:27:01.662Z",
      currency_rates: [],
      full_count: "12",
    },
    {
      id: 14,
      name: "Racer for game",
      duration: "day",
      game_id: "1",
      recurring_frequency: "day",
      status: "created",
      iteration: 0,
      current_iteration_start_date: "2023-04-22T04:50:10.108Z",
      current_iteration_end_date: "2023-04-23T04:50:10.108Z",
      created_at: "2023-04-14T19:37:23.641Z",
      updated_at: "2023-04-14T19:37:23.641Z",
      eur_prize: "500",
      starts_at: "2023-04-22T04:50:10.108Z",
      currency_rates: [
        {
          currency: "BRL",
          rate: 1,
        },
        {
          currency: "BTC",
          rate: 0.000019967418778886552,
        },
        {
          currency: "CAD",
          rate: 1,
        },
        {
          currency: "ETH",
          rate: 0.0002949126924819308,
        },
        {
          currency: "EUR",
          rate: 1,
        },
        {
          currency: "LTC",
          rate: 0.007478140207860439,
        },
        {
          currency: "TRX",
          rate: 13.032070120678268,
        },
      ],
      full_count: "12",
    },
  ],
  total_count: 12,
  total_pages: 1,
};
