import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../shared/pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

class GameProvidersFinder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      records: [],
      meta: {},
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      this.update();
    }
  }

  async update() {
    const data = await window.api.get("/admin/game_providers", {
      params: {
        page: this.state.page,
      },
    });

    this.setState({
      records: data.records || [],
      meta: data.meta || {},
    });
  }

  async enable(slug) {
    await window.api.post(`/admin/game_providers/${slug}/enable`);
    this.update();
  }

  async disable(slug) {
    await window.api.post(`/admin/game_providers/${slug}/disable`);
    this.update();
  }

  render() {
    return (
      <div>
        <h2>Total Providers: {this.state.meta.total_records}</h2>

        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Slug</Th>
              <Th>Enabled</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.records.map((r, i) => {
              const isEnabled = r.enabled;
              return (
                <Tr key={i}>
                  <Td>
                    <Link to={`/casino/game-providers/${r.slug}`}>
                      {r.name}
                    </Link>
                  </Td>
                  <Td>{r.slug}</Td>
                  <Td>
                    <button
                      onClick={() =>
                        isEnabled ? this.disable(r.slug) : this.enable(r.slug)
                      }
                      className="small"
                    >
                      {isEnabled ? `Disable` : `Enable`}
                    </button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.state.meta.total_pages}
          update={(page) => {
            this.setState({ page });
          }}
        />
      </div>
    );
  }
}

export default GameProvidersFinder;
