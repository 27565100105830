import React, { Component } from "react";
import { NavLink, Switch, Route } from "react-router-dom";

import GamesFinder from "casino/game/search-for-game";
import GamePage from "casino/game/game";
import GameProvidersFinder from "casino/game/game-providers-list";
import permissionGroups from "../../app/permission-groups";
import {
  CheckPermissionGroupRoute,
  GroupPermissionsFragment,
} from "app/permissions-fragment";
import Loyalty from "casino/list-weekly-cashbacks";
import Bonuses from "casino/tab-bonuses";
import TabRewards from "casino/tab-rewards";
import Races from "casino/race/tabRaces";
import PromoCodes from "app/tab-promo-codes";
import MysteryBoxes from "casino/mystery-boxes";
import MysteryBox from "casino/mystery-box";
import RewardTiers from "casino/edit-reward-tiers";
import UserRanks from "user/user-ranks";
import GlobalXp from "casino/globalXp";
import Reward from "casino/update-reward";
import GameProviderPage from "casino/game/game-provider-show";
import GameCategoriesPage from "casino/game/page";
import GameProviderRound from "user/game-provider-round";
import AffiliateLinks from "casino/tab-affiliate-links";
import { BrowserView } from "react-device-detect";
import {
  GameProvidersLink,
  GameCategoriesLink,
  GamesLink,
  RewardsLink,
  MysteryBoxesLink,
  RewardTiersLink,
  UserRanksLink,
  GlobalXpLink,
  PromoCodesLink,
  BonusesLink,
  RacesLink,
  LoyaltyLink,
  AffiliateLinksLink,
  BigWinsLink,
  ChallengesLink,
  CurrenciesLink,
} from "./links";
import BigWins from "casino/big-wins";
import tabChallenges from "casino/challenges/tabChallenges";
import ViewChallenge from "casino/challenges/viewChallenge";
import tabRaceDetail from "casino/race/tabRaceDetail";
import ListRacesWinners from "casino/race/ListRacesWinners";
import Currencies from "casino/Currencies";

export default class Bets extends Component {
  render() {
    return (
      <div className="page" style={{ padding: 0 }}>
        <BrowserView>
          <nav>
            <AffiliateLinksLink />
            <BonusesLink />
            <GameCategoriesLink />
            <GameProvidersLink />
            <GamesLink />
            <GlobalXpLink />
            <LoyaltyLink />
            <MysteryBoxesLink />
            <PromoCodesLink />
            <RacesLink />
            <ChallengesLink />
            <RewardsLink />
            <RewardTiersLink />
            <UserRanksLink />
            <BigWinsLink />
            <CurrenciesLink />
          </nav>
        </BrowserView>

        <Switch>
          <CheckPermissionGroupRoute
            group={permissionGroups.BETS}
            path="/casino/game-provider-rounds/:id"
            component={GameProviderRound}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.BETS.GAMES}
            path="/casino/games/:slug"
            component={GamePage}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.BETS.GAMES}
            path="/casino/games"
            component={GamesFinder}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.BETS.GAMES}
            path="/casino/game-providers/:slug"
            component={GameProviderPage}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.GAMES.GAME_PROVIDERS}
            path="/casino/game-providers"
            component={GameProvidersFinder}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.BETS.GAMES}
            path="/casino/game-categories"
            component={GameCategoriesPage}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.GENERAL}
            path="/casino/rewards"
            component={TabRewards}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.LOYALTY}
            path="/casino/loyalty"
            component={Loyalty}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.BONUSES}
            path="/casino/bonuses"
            component={Bonuses}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.RACES}
            path="/casino/races"
            component={Races}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.RACES}
            path="/casino/race/view/:id"
            component={tabRaceDetail}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.RACES}
            path="/casino/race/iterations/winners/:iteration"
            component={ListRacesWinners}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.CHALLENGES}
            path="/casino/challenges"
            component={tabChallenges}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.CHALLENGES}
            path="/casino/challenge/view/:id"
            component={ViewChallenge}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.PROMO_CODES}
            path="/casino/promo-codes"
            component={PromoCodes}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.MYSTERY_BOXES}
            exact
            path="/casino/mystery-boxes"
            component={MysteryBoxes}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.MYSTERY_BOXES}
            path="/casino/mystery-boxes/:id"
            component={MysteryBox}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.REWARD_TIERS}
            path="/casino/reward-tiers"
            component={RewardTiers}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.USER_RANKS}
            path="/casino/user-ranks"
            component={UserRanks}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.GLOBAL_XP}
            path="/casino/global-xp"
            component={GlobalXp}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.MARKETING.AFFILIATE_LINKS}
            path="/casino/affiliate-links"
            component={AffiliateLinks}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.BIG_WINS}
            path="/casino/big-wins"
            component={BigWins}
          />
           <CheckPermissionGroupRoute
            group={permissionGroups.SUPPORTED_CURRENCIES}
            path="/casino/currencies"
            component={Currencies}
          />         
        </Switch>
      </div>
    );
  }
}
