import React, { useEffect, useState } from "react";
import Pagination from "../shared/pagination";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const houseEdge = 4.16;

const getPayoutMultiplierPercentage = (threshold, rollover) => {
  const finalThreshold = rollover ? threshold : 100 - threshold;
  const denominator = 100 - finalThreshold;
  const numerator = denominator * (1 + houseEdge / 100);

  if (!numerator) return 0;

  return 99.99 / numerator;
};

const getThresholdPercentage = (rollOver, threshold, gainOpportunity) =>
  rollOver ? threshold : gainOpportunity;

const getRollOverLabel = (rollOverStatus) =>
  `ROLL ${rollOverStatus ? "OVER" : "UNDER"}`;

const getValidValue = (value, digits = 2) => {
  if (isNaN(value) || value === null) return "";

  return (+value).toFixed(digits);
};

const UserDiceBets = (props) => {
  const [data, setData] = useState({ bets: [], total_pages: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetch(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetch = async (currentPage) => {
    setIsLoading(true);
    try {
      const { total_pages, records } = await window.api.get(
        `/admin/users/${props.match.params.id}/dice_bets?page=${currentPage}`
      );
      setData({ total_pages, bets: records });
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  };

  return (
    <div style={{ position: "relative", "min-height": "500px" }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait ...</h2>
          <div className="loader" />
        </div>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>Round ID</Th>
            <Th>Bet Placed At</Th>
            <Th>Amount</Th>
            <Th>Roll Status</Th>
            <Th>Multiplier</Th>
            <Th>Threshold</Th>
            <Th>Roll</Th>
            <Th>Profit</Th>
            <Th>Total Bets</Th>
            <Th>On Win %</Th>
            <Th>On Loss %</Th>
            <Th>Stop on Profit Amount</Th>
            <Th>Stop on Loss Amount</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.bets.map((r, i) => {
            const payout = r.win_amount > 0 ? r.profit : -1 * r.amount;

            const gainOpportunity = !r.rollover
              ? r.threshold
              : 100 - r.threshold;
            const thresholdPercentage = getThresholdPercentage(
              r.rollover,
              r.threshold,
              gainOpportunity
            );
            const multiplierPercentage = getPayoutMultiplierPercentage(
              r.threshold,
              r.rollover
            );
            const rollLabel = getRollOverLabel(r.rollover);

            const totalBets = getValidValue(r.total_bets, 0);
            const onWin = getValidValue(r.on_win);
            const onLoss = getValidValue(r.on_loss);
            const stopOnProfit = getValidValue(r.stop_on_profit);
            const stopOnLoss = getValidValue(r.stop_on_loss);
            return (
              <Tr key={r.round_id}>
                <Td>
                  <Link to={`/casino/game-provider-rounds/${r.round_id}`}>
                    {r.round_id}
                  </Link>
                </Td>
                <Td>{format.withSeconds(r.created_at)}</Td>
                <Td>
                  {r.amount} {r.currency_type}
                </Td>
                <Td>{rollLabel}</Td>
                <Td>{(+multiplierPercentage).toFixed(2)}</Td>
                <Td>{(+thresholdPercentage).toFixed(2)}</Td>
                <Td>{r.roll}</Td>
                <Td className={payout >= 0 ? "good" : "bad"}>
                  {(+payout).toFixed(2)} {r.currency_type}
                </Td>
                <Td>{totalBets}</Td>
                <Td>{onWin}</Td>
                <Td>{onLoss}</Td>
                <Td>{stopOnProfit}</Td>
                <Td>{stopOnLoss}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Pagination
        total_pages={data.total_pages}
        update={(page) => setPage(page)}
      />
    </div>
  );
};

export default UserDiceBets;
