import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./races.scss";

import styles from "./tabRaces.module.scss";
import CreateRaces from "./createRace";
import ListRaces from "./listRaces";
import api from "./api";

const TabRaces = () => {
  const [tab, setTab] = useState("ListRaces");
  const handlerCron = async () => {
    await api.callHandlerCron();
  };

  return (
    <div>
      <div className={styles.tabbedNav}>
        <div
          className={tab === "ListRaces" ? styles.selected : undefined}
          onClick={() => {
            setTab("ListRaces");
          }}
        >
          List Races
        </div>
        <div
          className={tab === "createRace" ? styles.selected : undefined}
          onClick={() => {
            setTab("createRace");
          }}
        >
          Create Race
        </div>
      </div>
      {tab === "ListRaces" && <ListRaces />}

      {tab === "createRace" && <CreateRaces />}
    </div>
  );
};

export default withRouter(TabRaces);
