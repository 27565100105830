import React from 'react';
import TooltipWithInfoIcon from './TooltipWithInfoIcon';

const TooltipFreeRoundID = ({ children }) => {
  return (
    <TooltipWithInfoIcon title="Server Round ID is not available since it is free round. We are displaying Gaming Services Round ID">
        {children}
    </TooltipWithInfoIcon>
  );
};

export default TooltipFreeRoundID;
