import React from "react";
import { NavLink } from "react-router-dom";
import {
  GroupPermissionsFragment,
  permissionGroups,
  permissionsConstants,
  PermissionsFragment,
} from "app/permissions-fragment";

export const ViewBonusLink = () => (
  <PermissionsFragment feature={permissionsConstants.BONUSES_VIEW_LIST}>
    <NavLink exact to="/casino/bonuses">
      View Bonuses
    </NavLink>
  </PermissionsFragment>
);

export const CreateBonusLink = () => (
  <PermissionsFragment feature={permissionsConstants.BONUSES_CREATE_BONUS}>
    <NavLink exact to="/casino/bonuses/create">
      Create Bonus
    </NavLink>
  </PermissionsFragment>
);

export const RoundWelcomePackagesLink = () => (
  <GroupPermissionsFragment
    group={permissionGroups.REWARDS.BONUS_WELCOME_PACKAGE}
  >
    <NavLink exact to="/casino/bonuses/round-welcome-packages">
      Round Welcome Packages
    </NavLink>
  </GroupPermissionsFragment>
);
