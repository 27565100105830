import React, { useState } from "react";


const NewCategory = ({ createNewCategory }) => {

  const [name, setName] = useState('');
  const [isLabel, setIsLabel] = useState(false);

  return (
    <div >
      <h3>New Category</h3>
      <div style={
        {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }
      }>
        <input placeholder={"Name"} onChange={e => setName(e.target.value)} value={name} />
        <input type="checkbox" placeholder={"Is label"} onChange={e => setIsLabel(e.target.checked)} checked={isLabel} /> Is label?
        <button onClick={() => createNewCategory({ name, isLabel })} className="small">
          Create
        </button>
      </div>
    </div>
  )
}
export default NewCategory;