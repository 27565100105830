import { StatusLink } from "app/panel.js";

import {
  AffiliateLinksLink,
  BonusesLink,
  ChallengesLink,
  GameCategoriesLink,
  GameProvidersLink,
  GamesLink,
  GlobalXpLink,
  LoyaltyLink,
  MysteryBoxesLink,
  PromoCodesLink,
  RacesLink,
  RewardsLink,
  RewardTiersLink,
  UserRanksLink,
} from "casino/tab-casino/links.js";

import {
  ViewBonusLink,
  CreateBonusLink,
  RoundWelcomePackagesLink,
} from "casino/tab-bonuses/links";
import { isAdmin } from "app/api";

const statusNodes = {
  key: "status",
  label: "Status",
  icon: "",
  component: () => <StatusLink />,
};

export default statusNodes;
