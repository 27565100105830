const exportButtonStyles = {
  float: "right",
  marginTop: "20px",
  marginRight: "30px",
};

const ExportDataButton = ({ onClick, styles = exportButtonStyles }) => {
  return (
    <button style={styles} onClick={onClick}>
      Export
    </button>
  );
};

export default ExportDataButton;
