import React, { Component } from 'react';
import { checkPermissions, checkPermissionsGroup } from "./api";
import { permissionsConstants } from './permissions-constants.js';
import permissionGroups from './permission-groups.js';
import { Route } from "react-router-dom";

export function GroupPermissionsFragment({ group, children, fallbackComponent = null }) {
  if (checkPermissionsGroup(group)) {
    return children;
  }

  return fallbackComponent;
}


export function PermissionsFragment({ feature, children, fallbackComponent = null }) {

  if (checkPermissions(feature)) {
    return children;
  }

  return fallbackComponent;
}

export function ConditionalPermissionsFragment({ feature, condition, children }) {
  if (!condition) return children
  if (checkPermissions(feature)) {
    return children;
  }

  return null;
}
export function CheckPermissionGroupRoute({ path, exact, component: Component, group }) {


  return <Route path={path} exact={exact} render={props => {
    if (checkPermissionsGroup(group)) {
      return <Component {...props} />
    }

    return null
  }} />
}


export function CheckPermissionsRoute({ path, exact, component: Component, feature }) {


  return <Route path={path} exact={exact} render={props => {
    if (checkPermissions(feature)) {
      return <Component {...props} />
    }

    return null
  }} />
}

export {
  permissionsConstants,
  permissionGroups,
  checkPermissionsGroup,
  checkPermissions
}