import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import "../app/user-style2.css";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import MobilePageTitle from "shared/MobilePageTitle";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
export default class Finder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      users: [],
      isLoading: false,
    };
  }

  async fetchUsers() {
    this.setState({ isLoading: true });
    try {
      await window.api
        .get(`/admin/users`, {
          params: {
            query: this.state.query,
          },
        })
        .then((users) => this.setState({ users }));
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ isLoading: false });
  }
  componentDidMount() {
    const searchQuery = this.props.location?.search?.split("?search=")[1];
    if (this.props.location.search) {
      try {
        window.api
          .get(`/admin/users`, {
            params: {
              query: searchQuery,
            },
          })
          .then((users) => this.setState({ users }));
      } catch (err) {
        toast.error(err.message);
      }

      this.setState({ isLoading: false });
    }
  }
  render() {
    return (
      <div className="user-finder">
        <MobilePageTitle title="Find User" />

        <div className="user-search-row">

          <input
            value={this.state.query}
            onChange={(e) => {
              this.setState({ query: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.fetchUsers();
              }
            }}
            placeholder="Search by ID, email, cpf, or phone number. Press Enter to search."
          />

          <button
            disabled={this.state.isLoading}
            className="red"
            onClick={() => this.fetchUsers()}
          >
            {this.state.isLoading ? "Loading..." : "Search"}
          </button>

        </div>


        <br />
        <br />
        <br />

        {
          this.state.isLoading && (
            <div className={"loading-overlay"}>
              <h2>Searching, please wait ...</h2>
              <div className="loader" />
            </div>
          )
        }
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Username</Th>
              <Th>Email</Th>
              <Th>Verified Number</Th>
              <Th>Created At</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.users.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>{r.id}</Td>
                  <Td className="user">
                    <Link to={`/users/${r.id}`}>
                      <img alt="" src={r.avatar_url} />
                      {r.username}
                    </Link>
                  </Td>
                  <Td>{r.email}</Td>
                  <Td>{r.status_detail === "pending" ? r.number + " (pending)" : r.number || "-"}</Td>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div >
    );
  }
}
