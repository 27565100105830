import * as types from './types';

const initial = {
  user: {
    selected_currency: null,
    wallets: [],
    documents: [],
    roles: [],
    authentication_methods: [],
    document: {
      type: null,
      data: null,
    },
    real_sums: {},
    bonus_sums: {},
    cashier_sums: {},
    user_suspension: {},

    payment_methods: [],
    verification_document: {
      type: null,
      data: null,
    },
    payment_emails: false,
    marketing_emails: false,
    general_emails: false,
    marketing_campaign_eligible: true,
    signup_channel: {
      type: 'N/A',
      code: 'N/A'
    },

    bonus_rounds: [],
    loading: true,
    currency_types: []
  },

  fingerprints: {
    meta: {
      total_pages: 1
    },
    records: []
  },

  notes: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  deposits: {
    meta: {
      total_pages: 1
    },
    records: []
  },

  withdrawals: {
    meta: {
      total_pages: 1
    },
    records: [],
    downloadingReport: false,
  },

  otp_fingerprints: {
    smsDelivery: [],
    userPhones: []
  },



  referrals: {

  }
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.SEARCH_UPDATE:
      return { ...state, search_results: action.payload };

    case types.USER_UPDATE:
      return {
        ...state, user: {
          ...state.user,
          ...action.payload,
          loading: false
        }
      };

    case types.USER_UPDATE_LOADING:
      return {
        ...state,
        user: {
          ...state.user,
          loading: action.payload
        }
      }

    case types.USER_OFFERS_UPDATE:
      return { ...state, offers: action.payload };

    case types.USER_FINGERPRINTS_UPDATE:
      return { ...state, fingerprints: action.payload };


    case types.USER_DEPOSITS_UPDATE:
      return { ...state, deposits: action.payload };

    case types.USER_WITHDRAWALS_DOWNLOADING_REPORT:
      return { ...state, withdrawals: { ...state.withdrawals, downloadingReport: true } };

    case types.USER_WITHDRAWALS_DOWNLOADED_REPORT:
      return { ...state, withdrawals: { ...state.withdrawals, downloadingReport: false } };

    case types.USER_WITHDRAWALS_UPDATE:
      return { ...state, withdrawals: action.payload };

    case types.USER_NOTES_UPDATE:
      return { ...state, notes: action.payload };

    case types.USER_DOCUMENTS_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          documents: action.payload
        }
      };

    case types.USER_DOCUMENT_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          document: action.payload
        }
      };

    case types.USER_VERIFICATION_DOCUMENT_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          verification_document: action.payload
        }
      };

    case types.USER_ANTI_FRAUD_UPDATE:
      return {
        ...state,
        user_anti_fraud: action.payload
      }

    case types.USER_PAYMENT_ACCOUNTS_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          payment_methods: action.payload
        }

      }

    case types.USER_BONUS_ROUNDS_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          bonus_rounds: action.payload
        }

      }


    case types.USER_REFERRALS_UPDATE:
      return {
        ...state,
        referrals: action.payload
      }

    case types.SELECT_CURRENCY:
      return {
        ...state,
        user: {
          ...state.user,
          selected_currency: action.payload
        }
      }

    case types.USER_OTP_FINGERPRINTS_UPDATE:
      return { ...state, otp_fingerprints: action.payload };
  }



  return state;
}