import React from "react";
import { MobileView } from "react-device-detect";

const MobilePageTitle = ({ title = "", size = 1 }) => (
  <MobileView>
    <div style={{ width: "100%", textAlign: "center" }}>
      {React.createElement(
        `h${size}`,
        {
          style: { margin: "5px 0" },
        },
        title
      )}
    </div>
  </MobileView>
);

export default MobilePageTitle;
