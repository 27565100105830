import React, { useEffect, useState } from "react";
import "./styles/WaitListDetail.css";
import Pagination from "../../shared/pagination";
import { Link } from "react-router-dom";
import querystring from "querystring";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { mapGameName } from "shared/utils/strings";

const arrayWithUsers = [
  {
    id: 1,
    username: "Jhon",
    approved: false,
  },
  {
    id: 2,
    username: "Myke",
    approved: false,
  },
  {
    id: 3,
    username: "Michael",
    approved: true,
  },
  {
    id: 4,
    username: "Brian",
    approved: false,
  },
  {
    id: 5,
    username: "Tom",
    approved: true,
  },
  {
    id: 6,
    username: "Jhon",
    approved: false,
  },
];
const WaitListDetail = (props) => {
  const feature = props.match.params.feature;
  const [username, setUsername] = useState("");
  const [countBulk, setCountBulk] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalPages, setPages] = useState(0);
  const [featureDetails, setFeatureDetails] = useState({ status: "" });
  const [showApproved, setShowApproved] = useState(false);

  const refresh = async (page = 1) => {
    const { records, pages, ...rest } = await window.api.get(
      `/admin/waitlist_feature/` +
        feature +
        "?" +
        querystring.stringify({
          approved: showApproved,
          search: username,
          page,
        })
    );

    setFilteredUsers(records);
    setPages(pages || 0);
    setFeatureDetails(rest);
  };

  useEffect(() => {
    refresh();
  }, [username, showApproved]);

  const handleBulkApprove = async (e) => {
    e.preventDefault();
    if (countBulk < 1) {
      alert("Count is less than 1");
      return;
    }

    await window.api.put(
      `/admin/waitlist_feature/${feature}/bulk_grant_access`,
      {
        number_of_users: countBulk,
      }
    );

    // TODO: send request to server

    return refresh();
  };
  const changeStatusUser = async (userId) => {
    if (!userId) return;

    await window.api.put(
      `/admin/waitlist_feature/${feature}/change_status/${userId}`
    );

    return refresh();
  };
  return (
    <div className="waitlistdetail">
      <h1>Wait List Management - {mapGameName(feature)}</h1>

      <h4>Status: {featureDetails.status}</h4>
      <h4>Waiting: {featureDetails.waitlist}</h4>
      <h4>Approved: {featureDetails.granted}</h4>

      <div className="">
        <h2>Bulk Approve Top Users</h2>
        <form onSubmit={handleBulkApprove}>
          <input
            type="number"
            min={1}
            onChange={(e) => setCountBulk(e.target.value)}
            value={countBulk}
            placeholder="number to approve"
          />
          <button type="submit">Approve</button>
        </form>
      </div>
      <div>
        <br />
        <hr />
        <div>
          <span
            style={{
              padding: 10,
              cursor: "pointer",
              borderBottom: !showApproved && "2px solid #f9305b",
            }}
            onClick={() => {
              setShowApproved(false);
            }}
          >
            Waitlist
          </span>

          <span
            style={{
              padding: 10,
              cursor: "pointer",
              borderBottom: showApproved && "2px solid #f9305b",
            }}
            onClick={() => {
              setShowApproved(true);
            }}
          >
            Approved
          </span>

          <input
            placeholder="Search by user ID"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <hr />
        <Table>
          <Thead>
            <Tr>
              <Th>Position</Th>
              <Th>User ID</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map(({ position, user_id, has_access }) => {
              return (
                <Tr>
                  <Td>{position}</Td>
                  <Td>
                    <Link to={`/users/${user_id}`}>{user_id}</Link>
                  </Td>
                  <Td>{has_access ? "Approved" : "Waiting"}</Td>
                  <Td>
                    <button onClick={() => changeStatusUser(user_id)}>
                      {has_access ? "Disapprove" : "Approve"}
                    </button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
      <Pagination total_pages={totalPages} update={(page) => refresh(page)} />
    </div>
  );
};

export default WaitListDetail;
