import React from "react";
import { NavLink } from "react-router-dom";
import { PermissionsFragment } from "app/permissions-fragment";

export const ViewRewardLink = () => (
  <PermissionsFragment>
    <NavLink exact to="/casino/rewards">
      View Rewards
    </NavLink>
  </PermissionsFragment>
);

export const CreateRewardLink = () => (
  <PermissionsFragment>
    <NavLink exact to="/casino/rewards/create">
      Create Rewards
    </NavLink>
  </PermissionsFragment>
);
