import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { updateUser, selectCurrency } from "./actions";
import { NavLink, Redirect, Switch, withRouter } from "react-router-dom";
import "../app/user-style.css";
import { CheckPermissionGroupRoute } from "../app/permissions-fragment";
import { BrowserView, MobileView } from "react-device-detect";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AppBar, Tab, Tabs, Box } from '@mui/material';
import { linksConfig, switchConfig } from "./sub-tabs-config";

class TabPanel extends Component {
  render() {
    const { children, value, index } = this.props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  }
}


class User extends Component {
  constructor(props) {
    super(props);


    this.state = {
      amount: "",
      wallet: "",
      show_payment_document: false,
      info: {},
      mounted: true,
      selectedMenu: "",
      selectedTab: "General",
      sections: [{ title: "General", links: linksConfig.general }, { title: "Game bets", links: linksConfig.gameBets }, { title: "Prize Free Money", links: linksConfig.prizeFreeMoney }],
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.remountChildren = this.remountChildren.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({ selectedTab: newValue });
  }
  componentDidMount() {
    this.props.updateUser(this.props.match.params.id);
  }

  componentWillReceiveProps(next) {
    if (this.props.match.params.id !== next.match.params.id) {
      this.props.updateUser(next.match.params.id);
    }
  }

  remountChildren() {
    this.setState(
      {
        mounted: false,
      },
      () => {
        this.setState({
          mounted: true,
        });
      }
    );
  }
  render() {
    const { selectedTab } = this.state;
    const rolesDisabled =
      this.props.appUserRole.includes("influencer") ||
      this.props.appUserRole.includes("support");
    return (

      <div className="user-page">
        <div className="header">
          <div className={"user-header-left"}>
            <img
              src={this.props.avatar_url || "/images/icons/avatar_default.svg"}
            />
            <div>
              <h3>{this.props.username}</h3>
              {!rolesDisabled &&
                this.props.roles.map((role) => (
                  <span
                    class="badge"
                    style={{
                      backgroundColor: "#602c2c",
                      color: "#fff",
                      padding: "4px 8px",
                      textAlign: "center",
                      borderRadius: 5,
                      marginRight: 5,
                    }}
                  >
                    {role}
                  </span>
                ))}
            </div>
          </div>
        </div>

        <hr />
        <div className="page-wrapper">
          <MobileView>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={linksConfig.mobileView}
              renderInput={(params) => (
                <TextField {...params} label="User info Pages" />
              )}
              renderOption={(props, option) =>
                React.createElement(
                  option.component,
                  { ...option.props, key: option.to },
                  <NavLink to={`${this.props.match.url}${option.to}`}>
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  </NavLink>
                )
              }
            />
          </MobileView>

          <BrowserView>
            <AppBar position="static">
              <Tabs indicatorColor="secondary" value={selectedTab} onChange={this.handleTabChange} aria-label="simple tabs example">
                {this.state.sections.map((section) => (
                  <Tab label={section.title} value={section.title} key={section.title} />
                ))}
              </Tabs>
            </AppBar>

            {this.state.sections.map((section) => (

              <TabPanel value={selectedTab} index={section.title} key={"panel_" + section.title}>
                <nav className="user-nav">
                  {section.links.map(({ component, props, to, label }) =>
                    React.createElement(
                      component,
                      { ...props, key: to },
                      <NavLink to={`${this.props.match.url}${to}`}>{label}</NavLink>
                    )
                  )}
                </nav>
              </TabPanel>

            ))}
          </BrowserView>
          <div className="nav-output">
            {!this.props.loading && this.state.mounted && (
              <Fragment>
                <Switch>
                  {switchConfig.map(({ component, props, path }) => (
                    <CheckPermissionGroupRoute
                      {...props}
                      key={path}
                      path={path}
                      component={component}
                    />
                  ))}
                  <Redirect
                    from={`*`}
                    to={`/users/${this.props.match.params.id}/info`}
                  />
                </Switch>
              </Fragment>
            )}
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.users.user,
  appUserRole: state.app.user.roles,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id) => dispatch(updateUser(id)),
  selectCurrency: (type) => dispatch(selectCurrency(type)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
