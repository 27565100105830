import { createSelector } from "reselect";

const selectedAffiliateSelector = state => state.affiliates.selectedAffiliate;
const selectedCampaignSelector = state => state.affiliates.selectedCampaign;

export const affiliatesSelector = createSelector(
  [
    selectedAffiliateSelector,
    selectedCampaignSelector,
  ],
  (
    selectedAffiliate,
    selectedCampaign,
  ) => ({
    selectedAffiliate,
    selectedCampaign,
  })
);
