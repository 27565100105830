
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, Route, Switch, withRouter } from "react-router-dom";
import Finder from "./Finder";
import Limit from "./Limit";
import { updateCurrencyLimit, updateLimit } from "./actions";
import Select from 'react-select';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

class CurrencyLimits extends Component {

  constructor(props) {
    super(props);

    this.state = {
      limits: [],
      minimum: '',
      maximum: '',
      currency: ''
    }
  }

  componentWillReceiveProps(next) {
    let { currency_limits = {} } = next.provider;

    let limits = [];
    for (let currency in currency_limits) {
      if (currency_limits.hasOwnProperty(currency)) {
        limits.push({
          currency,
          ...currency_limits[currency],
          original: currency_limits[currency],
        })
      }
    }

    console.log(limits);
    this.setState({ limits });
  }

  componentDidMount() {
    this.props.update(this.props.id);
  }

  render() {
    let limits = this.state.limits;
    let { id, type, method } = this.props.provider;

    return (
      <div>
        <hr />
        <h4>Currency Limits</h4>
        <Table>
          <Thead>
            <Tr>
              <Th>Currency</Th>
              <Th>Minimum</Th>
              <Th>Maximum</Th>
              <Th>Status</Th>
              <Th colSpan={2}>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {limits.map(({ currency, minimum, maximum, original }, index) => {

              let changed = !(minimum === original.minimum && maximum === original.maximum);
              return (
                <Tr key={index}>
                  <Td>{currency}</Td>
                  <Td><input value={minimum} onChange={ev => {
                    let next = ev.target.value;
                    next = next.toString();
                    next = next.replace(',', '.');
                    next = next.replace(' ', '');

                    if (isNaN(+next) && next !== "") {
                      return;
                    }

                    if (+next > maximum) {
                      next = maximum;
                    }

                    this.setState({
                      limits: [
                        ...limits.slice(0, index),
                        {
                          ...limits[index],
                          minimum: next === "" ? "" : +next
                        },
                        ...limits.slice(index + 1)
                      ]
                    })

                  }} /></Td>
                  <Td><input value={maximum} onChange={ev => {
                    let next = ev.target.value;
                    next = next.toString();
                    next = next.replace(',', '.');
                    next = next.replace(' ', '');

                    if (isNaN(+next) && next !== "") {
                      return;
                    }

                    if (+next < minimum) {
                      next = minimum;
                    }

                    this.setState({
                      limits: [
                        ...limits.slice(0, index),
                        {
                          ...limits[index],
                          maximum: next
                        },
                        ...limits.slice(index + 1)
                      ]
                    })

                  }} /></Td>

                  <Td><div style={{ width: 100 }}>{changed ? "Changed" : "Unchanged"}</div></Td>
                  <Td><button disabled={!changed} onClick={() => {
                    this.props.updateLimit(id, { currency, minimum, maximum })
                  }}>Update</button>

                  </Td>
                  <Td>
                    <button onClick={() => {
                      this.props.updateLimit(id, { currency, delete: true })
                    }}>Delete</button>
                  </Td>
                </Tr>
              )
            })}


          </Tbody>
        </Table>

        <br />

        <h4>Add Currency Limit</h4>
        <div style={{ display: "flex", justifyContent: "space-between", paddingRight: 200 }}>

          <Select
            style={{ minWidth: '300px' }}
            name="type"
            value={this.state.currency}
            onChange={option => this.setState({ currency: option })}
            options={
              this.props.currencies
                .filter(
                  currency => currency.fiat
                    && !this.state.limits.find(l => l.currency === currency)
                )
                .map(currency => {
                  return {
                    label: currency.type,
                    value: currency.type
                  }
                })}
          />
          <div>Minimum <input value={this.state.minimum} onChange={e => this.setState({ minimum: e.target.value })} /></div>
          <div>Maximum <input value={this.state.maximum} onChange={e => this.setState({ maximum: e.target.value })} /></div>

          <button onClick={() => {
            this.props.updateLimit(id, {
              currency: this.state.currency.value,
              minimum: this.state.minimum,
              maximum: this.state.maximum,
            })
          }}>Add Limit</button>
        </div>

        <br />


      </div>
    )
  }
}

const mapStateToProps = state => ({
  provider: state.currencies.provider,
  currencies: state.app.currencies
});

const mapDispatchToProps = dispatch => ({

  update: (id) => {
    dispatch(updateCurrencyLimit(id));
  },

  updateLimit(limit_id, value) {
    dispatch(updateLimit(limit_id, value))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyLimits)
