import React from "react";
import { Switch, Redirect } from "react-router-dom";

import {
  permissionsConstants,
  CheckPermissionsRoute,
} from "app/permissions-fragment.js";

import Finder from "affiliates/finder";
import Affiliate from "affiliates/sub-tabs";
import System from "affiliates/system-tabs";

import { BrowserView } from "react-device-detect";
import {
  AffiliatesFindUsersLink,
  AffiliatesSystemLink,
} from "affiliates/tab-affiliates-root/links";

function Affiliates(props) {
  const search = props.location.search;

  return (
    <div className="page">
      <BrowserView>
        <nav>
          <AffiliatesFindUsersLink />
          <AffiliatesSystemLink />
        </nav>
      </BrowserView>
      <Switch>
        <CheckPermissionsRoute
          feature={permissionsConstants.AFFILIATES_VIEW_LIST}
          exact
          path="/affiliates/find"
          component={Finder}
        />
        <CheckPermissionsRoute
          feature={permissionsConstants.AFFILIATES_VIEW_SYSTEM}
          path="/affiliates/system"
          component={System}
        />
        <CheckPermissionsRoute
          feature={permissionsConstants.AFFILIATES_VIEW_AFFILIATE}
          path="/affiliates/:id"
          component={Affiliate}
        />
        <Redirect from="/affiliates" to={"/affiliates/find/" + search} />
      </Switch>
    </div>
  );
}

export default Affiliates;
