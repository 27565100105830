import axios from "axios";

const baseURL = 'https://affiliates-api.blaze.com/api';
const client = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    common: {},
  },
});

client.interceptors.request.use(req => {
  req.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  return req;
});

export async function getAffiliates(params) {
  const payload = Object.entries(params);
  const queryParams = new URLSearchParams(payload);
  const { data } = await client.get(`/admin/affiliates?${queryParams.toString()}`);
  return data;
}

export async function getReferralsByAffiliateId(affiliateId) {
  const { data } = await client.get(`/admin/referrals?affiliate_id=${affiliateId}&per_page=30&page=1`);
  return data;
}

export async function getCampaignsByAffiliateId(affiliateId) {
  const { data } = await client.get(`/admin/campaigns?affiliate_id=${affiliateId}&per_page=30&page=1`);
  return data;
}

export async function getBalanceByAffiliateId(affiliateId) {
  const { data }  = await client.get(`/admin/balance?affiliate_id=${affiliateId}`);
  return data;
}

export function setAffiliateManager(payload) {
  const { affiliate_id, blaze_user_id, full_name, skype, whatsapp, telegram, email } = payload;
  
  return client.put('/admin/set-manager', {
    affiliate_id,
    blaze_user_id,
    full_name,
    skype,
    whatsapp,
    telegram,
    email,
  });
}

export function createCampaign(payload) {
  return client.post('/admin/campaigns', payload);
}

export function updateCampaign(payload) {
  return client.put('/admin/campaigns', payload);
}

export function updatePostback(affiliateId, postbackUrl) {
  return client.put('/admin/set-postback', { affiliate_id: affiliateId, postback_url: postbackUrl });
}
