const createInitialState = {
    form: {
        duration: '',
        reason: '',
        note: ''
    },
    formErrors: {}
};

export default createInitialState;
