import React from 'react';

const ConditionalComponent = ({
   condition,
   children,
   defaultComponent
}) => {
    if (condition) {
        return children;
    }
    return defaultComponent || null;
};

export default ConditionalComponent