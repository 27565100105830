import React, { Component } from "react";
import AddGamesToCategory from "./AddGamesToCategory";
import EditGamesInCategory from "./EditGamesInCategory";


export default class ViewCategory extends Component {
  state = {
    tab: 'add',
    newName: ''
  }

  render() {
    const { categorySlug, categoryName } = this.props;
    if (!categorySlug) {
      return null;
    }

    const { tab } = this.state;

    return (
      <div >
        <h2>{categoryName}</h2>
        <div className="view-category__new-category">
          <input placeholder={"new category name"} value={this.state.newName} onChange={(ev) => {
            this.setState({ newName: ev.target.value })
          }} />
          <button className="small" onClick={() => {
            this.props.changeName(this.props.categorySlug, this.state.newName)
          }}>Change Name</button>
        </div>

        <nav>
          <a className={tab === 'add' && 'active'} onClick={() => {
            this.setState({ tab: 'add' })
          }}>Add / Remove Games</a>
          <a className={tab === 'edit' && 'active'} onClick={() => {
            this.setState({ tab: 'edit' })
          }}>Edit positions</a>
        </nav>
        <div>
          {tab === 'add' && (
            <AddGamesToCategory categorySlug={categorySlug} />
          )}

          {tab === 'edit' && (
            <EditGamesInCategory categorySlug={categorySlug} />
          )}
        </div>
      </div>
    )
  }
}