import React from "react";
import { NavLink } from "react-router-dom";
import {
  GroupPermissionsFragment,
  permissionGroups,
} from "app/permissions-fragment";

export const RequestsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.STAFF_MANAGER.REQUESTS}>
    <NavLink exact to={`/system/staff-manager/requests`}>
      Requests
    </NavLink>
  </GroupPermissionsFragment>
);
export const RefundRequestsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS}>
    <NavLink exact to={`/system/staff-manager/refund_requests`}>
      Refund Requests
    </NavLink>
  </GroupPermissionsFragment>
);
