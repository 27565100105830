import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { useEffect, useState } from "react"
import WeekDatePicker from "shared/week-date-picker/WeekDatePicker";
import { EMPTY_CLAIMABLE } from "../constants";


export const WeeklyCashback = ({ id }) => {
    const [period, setPeriod] = useState(0);
    const [periods, setPeriods] = useState([]);
    const [claimable, setClaimable] = useState(EMPTY_CLAIMABLE);

    const round = (amount) => Math.floor(+amount * 100) / 100;

    useEffect(() => {
        if (period === 0)
            window.api
                .get(`/admin/users/${id}/loyalty-cashback`)
                .then((data) => {
                    if (data) setClaimable(data);
                });
        else
            window.api
                .get(`/admin/users/${id}/loyalty-cashback/${period}`)
                .then((data) => {
                    if (data) setClaimable(data);
                });
    }, [period]);

    useEffect(() => {
        window.api
            .get(`/admin/users/${id}/cashback-periods`)
            .then((data) => {
                if (data)
                    setPeriods(data);
            });
    }, []);

    return (
        <div className="weekly-summary">
            <div className="header">
                <h2>Cashback summary</h2>
                {periods.length > 0 && (
                    <WeekDatePicker
                        setPeriod={setPeriod}
                        periods={periods}
                    />
                )}
            </div>
            <Table>
                <Thead>
                    <Tr>   
                        <Th>Wagered</Th>
                        <Th>Withdrawals</Th>
                        <Th>Cashback percentage</Th>
                        <Th>Real Won</Th>
                        <Th>Rewards Received</Th>
                        <Th>Bonuses Transfered</Th>
                        <Th>Current week cashback</Th>
                        <Th>Last Week Cashback</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>{round(claimable?.calculation_values?.real_bet || 0)}</Td>
                        <Td>{round(claimable?.calculation_values?.withdrawals_amount || 0)}</Td>
                        <Td>{claimable.percentage}</Td>
                        <Td>{round(claimable?.calculation_values?.real_won || 0)}</Td>
                        <Td>{round(claimable?.calculation_values?.rewards_received || 0)}</Td>
                        <Td>{round(claimable?.calculation_values?.bonuses_transferred || 0)}</Td>
                        <Td>{round(claimable.amount || 0)}</Td>
                        <Td>{round(claimable?.calculation_values?.last_week_cashback || 0)}</Td>
                    </Tr>
                </Tbody>
            </Table>

        </div>
    )
}