import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "./styles/balance.scss";
import Selector from "./Selector";

const Balance = () => {
  const intervalRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [balances, setBalances] = useState([]);
  const [provider, setProvider] = useState("");
  const [currency, setCurrency] = useState("");

  const getBalance = async () => {
    try {
      let query = '/admin/monitoring/balance?';
      query = provider ? `${query}provider=${provider}&` : query;
      query = currency ? `${query}currency=${currency}&` : query;

      const balances = await window.api.get(query);
      setBalances(balances || []);
    } catch (e) {
      setBalances([]);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    getBalance();
    intervalRef.current = setInterval(getBalance, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [provider, currency]);

  return (
    <div>
      <div className="box-selects box-selects-status">
        <div className="box-select">
          <h1 className="text-box-select">Provider</h1>
          <Selector select={provider} setSelect={setProvider} type="providers" placeholder="Select provider" />
        </div>
        <div className="box-select">
          <h1 className="text-box-select">Currency</h1>
          <Selector select={currency} setSelect={setCurrency} type="currencies" placeholder="Select currency" />
        </div>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>Provider</Th>
            <Th>Merchant</Th>
            <Th>Currency</Th>
            <Th>Amount</Th>
            <Th>Last Updated</Th>
          </Tr>
        </Thead>

        {loading && (
          <div className={"loading-overlay"}>
            <h2>Fetching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <Tbody className="referrals-body">
          {balances.map((item) => {
            return (
              <Tr key={item?.provider + item?.currency_type}>
                <Td>{item?.provider}</Td>
                <Td>{item?.merchant}</Td>
                <Td>{item?.currency_type}</Td>
                <Td>{item?.amount}</Td>
                <Td>{item?.updated_at}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};

export default withRouter(Balance);
