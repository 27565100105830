import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import Modal from "react-modal";
import Calendar from "./Calendar";
import "./export-data-with-date-range-modal.css";

Modal.setAppElement("#root");

const PDF = "pdf";
const XLS = "xls";
const CSV = "csv";

const POSIBLE_FORMATS_TO_EXPORT = [PDF, XLS, CSV];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#131521",
  },
};

const ExportDataWithDateRangeModal = ({
  startDate = moment().subtract(1, "day").toDate(),
  endDate = moment().toDate(),
  onClose,
  onExport,
  isOpen = false,
  downloadingReport = false,
}) => {
  const [_startDate, setStartDate] = useState(startDate);
  const [_endDate, setEndDate] = useState(endDate);
  const [format, setFormat] = useState(PDF);

  return (
    <Modal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <Calendar
        startDate={_startDate}
        setStartDate={setStartDate}
        endDate={_endDate}
        setEndDate={setEndDate}
      />
      <div className="export-modal-container">
        <ToggleButtonGroup
          color="info"
          exclusive
          value={format}
          onChange={(_, value) => {
            if (value) setFormat(value);
          }}
          style={{ background: "white" }}
        >
          {POSIBLE_FORMATS_TO_EXPORT.map((format) => {
            return (
              <ToggleButton value={format} aria-label="bold" key={format}>
                {format.toUpperCase()}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        {downloadingReport ? (
          "Downloading report..."
        ) : (
          <button
            onClick={() =>
              onExport({
                startDate: _startDate,
                endDate: _endDate,
                format,
              })
            }
          >
            Export
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ExportDataWithDateRangeModal;
