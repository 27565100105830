import * as types from './types';

export const updateSettings = page => {

  return async dispatch => {
    const settings = await window.api.get('/admin/settings', {
      params: {
        page
      }
    });

    dispatch({
      type: types.SETTINGS_UPDATE,
      payload: settings
    })
  }
};

export const saveSettings = settings => {

  return async dispatch => {

    await window.api.put('/admin/settings', {
      settings
    });

    dispatch(updateSettings());
  }
};