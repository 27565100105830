import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { updateRewards } from "../../casino/reward-actions";
import api from "./api";
import "./createRace.scss";
import { listRecurring, listDuration, ADMIN_GAMES_API } from "./constants";

import { Translator } from "shared/translator";

const CreateRace = () => {

  const formRef = useRef(null);
  const [listGames, setListGames] = useState();
  const [recurringCheck, setRecurringCheck] = useState(false);
  const [nameTranslations, setNameTranslations] = useState({});
  const [selectedGames, setSelectedGames] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    starts_at: "",
    game_id: 0,
    duration: "day",
    recurring: 0,
    total_race_prize: 0,
    currency_rates: [],
    name_translation: nameTranslations
  });

  const debouncedSubmit = useCallback(
    _.debounce(async () => {
      try {
        if (formData.total_race_prize > 1000000) {
          toast.error("The prize needs to be lower than 1.000.000 EUR");
        } else if (formData.name === "") {
          toast.error("All the fields needs to completed");
        } else {
          const dataSend = {
            name: formData.name,
            eur_prize: formData.total_race_prize,
            starts_at: new Date(formData.starts_at).toISOString(),
            duration: formData.duration === "0" ? null : formData.duration,
            recurring_frequency:
              formData.recurring === "0" ? null : formData.recurring,
            currency_rates: formData.currency_rates,
            name_translation: formData.name_translation,
            games: selectedGames
          };
          const res = await api.createRace(dataSend);
          toast.success(res?.message);
          setTimeout(() => {
            window.location.href = "/casino/races";
          }, 1000);
        }
      } catch (error) {
        toast.error(error?.response?.data?.error?.message);
      }
    }, 500),
    [formData]
  );

  useEffect(() => {
    const fetchGamesFromApi = async () => {
      try {
        const response = await window.api.get(ADMIN_GAMES_API);
        return response.records;
      } catch (error) {
        console.error('Error fetching games:', error);
        return [];
      }
    };

    const formatGame = (record) => {
      return {
        label: `${record.name} - ${record.slug}`,
        value: record.id,
        name: record.name
      };
    };

    const getGames = async () => {
      try {
        const records = await fetchGamesFromApi();
        const games = records.map(formatGame);
        setListGames([{ label: 'All games', value: 0, name: 'All games' }, ...games]);
      } catch (error) {
        console.error('Error processing games:', error);
      }
    };

    getGames();
  }, []);

  function handleCheckboxRecurring(event) {
    setRecurringCheck(event.target.checked);
  }
  const handleCreateRace = async (e) => {
    e.preventDefault();
    debouncedSubmit();
  };

  const handleUpdateField = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const updateNameTranslations = (name_translations) => {
    setNameTranslations(name_translations)
    setFormData({ ...formData, name_translation: name_translations })
  }

  const handleAddGame = () => {
    if (selectedGames.includes(formData.game_id))
      return
    setSelectedGames([...selectedGames, formData.game_id]);
  };

  const handleRemoveGame = () => {
    setSelectedGames(selectedGames.filter((game) => game !== formData.game_id));
  };

  const {
    name,
    game_id,
    duration,
    recurring,
    starts_at,
    total_race_prize
  } = formData;

  return (
    <form
      ref={formRef}
      onSubmit={handleCreateRace}
      className="create-race"
    >
      <div className="race-create__row">
        <div>
          <h1 className="label-race ">Name</h1>
          <input
            placeholder="Name"
            value={name}
            name="name"
            onChange={handleUpdateField}
          />
        </div>
        <Translator
          contents={nameTranslations}
          onChange={updateNameTranslations}
          label={"Name Translation"}
        />
      </div>
      <div className="race-create__row row-challenge">
        <div>
          <h1 className="label-race ">Start Date</h1>
          <input
            value={starts_at}
            name="starts_at"
            type={"datetime-local"}
            onChange={handleUpdateField}
          />
        </div>
        <div>
          <h1 className="label-race "> Duration</h1>
          <Select
            name="duration"
            value={duration}
            onChange={(e) => {
              setFormData({ ...formData, duration: e.value });
            }}
            style={{ width: "300px" }}
            options={listDuration}
          />
        </div>

        <div>
          <div className="race-create__check">
            <h1 className="label-race ">Recurring</h1>
            <input
              type="checkbox"
              checked={recurringCheck}
              onChange={handleCheckboxRecurring}
            />
          </div>
          {recurringCheck && (
            <Select
              name="recurring"
              disabled={!recurringCheck}
              value={recurring}
              onChange={(e) => {
                setFormData({ ...formData, recurring: e.value });
              }}
              style={{ width: "300px" }}
              options={listRecurring}
            />
          )}
        </div>
      </div>
      <div className="race-create__row_no_box row-challenge">
        <div className="games-container">
          <h1 className="label-race ">Games</h1>
          <Select
            name="game_id"
            value={game_id}
            onChange={(e) => {
              setFormData({ ...formData, game_id: e.value });
            }}
            style={{ width: "300px" }}
            options={listGames}
          />
          <div>
            <button type="button" onClick={handleAddGame}>Add Game</button>
            <button type="button" onClick={handleRemoveGame}>Remove Game</button>
          </div>

          <div className="selected-games-container">
            <h2>Selected Games</h2>
            {selectedGames.map((gameId, index) => (
              <div key={index}>
                <p>{index + 1 + ")"} {listGames.find((game) => game.value === gameId).label}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="race-create__prize">
          <label>Total Race Prize</label>
          <input
            value={total_race_prize}
            name="total_race_prize"
            onChange={handleUpdateField}
          />
        </div>
      </div>
      <button className="race-create-button" type="submit">Create Race</button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  rewards: state.rewards.items.records,
});
const mapDispatchToProps = (dispatch) => ({
  updateRewards: () => dispatch(updateRewards()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateRace)
);
