import React, { useState } from "react";
import { Link } from "react-router-dom";
import { WithdrawalMiniCommands } from "./withdrawal-mini-commands";
import { GroupPermissionsFragment, permissionGroups } from "../../app/permissions-fragment";

export const WithdrawalOverview = ({ withdrawal, loading, edit, editPspAmount, fetchWithdrawal }) => {
  const paymentAccountsRequested = withdrawal.kyc_verification_statuses.payment_accounts;
  const stackedStyle = { 'flex-direction': 'column', 'align-items': 'flex-start' }

  const totalSentToReal = (withdrawal.bonus_into_real_transactions || []).reduce((acc, f) => acc + +f.amount, 0);

  const [loadingCpf, setLoadingCpf] = useState(true);
  const [cpfKycValidation, setKycRiskValidation] = useState(null)

  const fetchKycRiskValidation = () => {
    window.api.get(`/admin/payments/withdrawals/${withdrawal.id}/kyc_risk_validation`)
      .then(response => {
        setKycRiskValidation(response)
      })
      .catch(error => {
        console.error('Error fetching KYC risk validation:', error);
      })
      .finally(() => {
        setLoadingCpf(false);
      });
  }

  return (
    <section className="overview">
      <div className="row">
        <div className="col-md-4">
          <h2>Information</h2>

          <div className="line-item">
            <div className="label">Name</div>
            <div className="value">
              <Link target={"_blank"} to={`/users/${withdrawal.user.id}`}>{withdrawal.user.full_name}</Link>
            </div>
          </div>
          <div className="line-item">
            <div className="label">Age</div>
            <div className="value">{withdrawal.user.age}</div>
          </div>
          <div className="line-item">
            <div className="label">Tax ID</div>
            <div className="value">{withdrawal.tax_id}</div>
          </div>
          <div className="line-item">
            <div className="label">Withdrawal Status</div>
            <div className="value">{withdrawal.status}</div>
          </div>
          <div className="line-item">
            <div className="label">Withdrawal Amount</div>
            <div className="value">{withdrawal.type} - {withdrawal.amount} {withdrawal.currency_type}</div>
          </div>
          <div className="line-item">
            <div className="label">Last Deposit</div>
            <div
              className="value">{withdrawal.last_deposit_method} - {withdrawal.last_deposit_amount} {withdrawal.currency_type}</div>
          </div>
          <div className="line-item">
            <div className="label">Total Completed Deposits</div>
            <div className="value">{withdrawal.total_deposited} {withdrawal.currency_type}</div>
          </div>
          <div className="line-item">
            <div className="label">Total Completed Withdrawals</div>
            <div className="value">{withdrawal.total_withdrawn} {withdrawal.currency_type}</div>
          </div>
          <div className="line-item">
            <div className="label">Real Wagered</div>
            <div className="value">{withdrawal.real_wagered} {withdrawal.currency_type}</div>
          </div>
          <div className="line-item">
            <div className="label">Real Wagered Since Last Deposit</div>
            <div className="value">{withdrawal.real_wagered_since_last_deposit} {withdrawal.currency_type}</div>
          </div>
          <div className={`line-item ${Math.abs(withdrawal.real_net_profit) >= ((+withdrawal.total_deposited) * 10) && 'red'}`}>
            <div className="label">Real Net Profit (NGR)</div>
            <div className="value">{withdrawal.real_net_profit} {withdrawal.currency_type}</div>
          </div>
        </div>
        <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS_FULL_VIEW} fallbackComponent={
          <div className="col-md-4"></div>
        }>
          <div className="col-md-4">
            <h2>Fraud Checks</h2>
            <div className={`line-item ${+withdrawal.last_deposit_vs_wagered <= 3 && 'red'}`}>
              <div className="label">Last Deposit Vs Wagered Since Last Deposit</div>
              <div className="value">{withdrawal.last_deposit_vs_wagered}x</div>
            </div>
            <div className={`line-item ${+withdrawal.total_deposit_vs_wagered <= 3 && 'red'}`}>
              <div className="label">Total Deposit Vs Total Wagered</div>
              <div className="value">{withdrawal.total_deposit_vs_wagered}x</div>
            </div>
            <div className="line-item">
              <div className="label">Amount Deposited With Card</div>
              <div className="value">{withdrawal.amount_deposited_with_card} {withdrawal.currency_type}</div>
            </div>
            <div className="line-item">
              <div className="label">Percent Card Deposits</div>
              <div className="value">{withdrawal.percent_card_deposits}%</div>
            </div>

            <div className={`line-item ${(totalSentToReal) > 0 && 'red'}`}>
              <div className="label">Total Flagged Bonus Money Paid To Real</div>
              <div className="value">{totalSentToReal.toFixed(4)}</div>
            </div>

            <div className={`line-item ${withdrawal.has_transferred_bonus && 'red'}`}>
              <div className="label">Bonus Transferred</div>
              <div className="value">{withdrawal.has_transferred_bonus.toString()}</div>
            </div>
            <div className="line-item">
              <div className="label">Total Bonus Transferred</div>
              <div className="value">{withdrawal.total_transferred_bonus}</div>
            </div>

            <div className="line-item">
              <div className="label">Total Bonus Transferred after last completed withdrawal</div>
              <div className="value">{withdrawal.total_bonuses_transferred_after_last_withdrawal}</div>
            </div>

            <div className="line-item">
              <div className="label">Current Balance</div>
              <div className="value">{withdrawal.current_balance} {withdrawal.currency_type}</div>
            </div>
            <div className="line-item">
              <div className="label">Expected Balance</div>
              <div className="value">{withdrawal.expected_balance} {withdrawal.currency_type}</div>
            </div>

            <div className={`line-item ${withdrawal.has_pending_withdrawals && 'red'}`}>
              <div className="label">Additional Pending Withdrawals</div>
              <div className="value">{withdrawal.has_pending_withdrawals ? "true" : "false"}</div>
            </div>

            <div className={`line-item ${withdrawal.bets_refunded_to_placed_ratio > 0.2 && 'red'}`}>
              <div className="label">Bets refunded to placed ratio</div>
              <div className="value">{withdrawal.bets_refunded_to_placed_ratio}</div>
            </div>

            <div className={`line-item ${withdrawal.email_changed_last_30_days && 'red'}`}>
              <div className="label">Email changed last 30 days</div>
              <div className="value">{withdrawal.email_changed_last_30_days ? "true" : "false"}</div>
            </div>

            <div className={`line-item ${withdrawal.tax_id_changed_last_30_days && 'red'}`}>
              <div className="label">Tax Id changed last 30 days</div>
              <div className="value">{withdrawal.tax_id_changed_last_30_days ? "true" : "false"}</div>
            </div>

            <div className={`line-item ${withdrawal.has_zero_amount_bet && 'red'}`}>
              <div className="label">Has zero amount bets</div>
              <div className="value">{withdrawal.has_zero_amount_bet ? "true" : "false"}</div>
            </div>

            <div className={`line-item ${withdrawal.has_long_running_bets && 'red'}`}>
              <div className="label">Has long running bets</div>
              <div className="value">{withdrawal.has_long_running_bets ? "true" : "false"}</div>
            </div>

            <div className={`line-item`}>
              <div className="label">Eur Weekly cashback received</div>
              <div className="value">{withdrawal?.eur_weekly_cashback_received}</div>
            </div>

            <div className={`line-item`}>
              <div className="label">Real Eur Wagered in last weekly cashback period</div>
              <div className="value">{withdrawal?.real_wagered_in_last_weekly_cashback_period}</div>
            </div>
          </div>
        </GroupPermissionsFragment>

        <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS_FULL_VIEW} fallbackComponent={
          <div className="col-md-4"></div>}>
          <div className="col-md-4">
            <h2>Compliance</h2>
            <div className="line-item">
              <div className="label">Amount Withdrawn</div>
              <div className="value">{withdrawal.total_withdrawn} {withdrawal.currency_type}</div>
            </div>
            <div className="line-item">
              <div className="label">Amount Deposited</div>
              <div className="value">{withdrawal.total_deposited} {withdrawal.currency_type}</div>
            </div>
            <div className="line-item">
              <div className="label">ID</div>
              <div
                className="value">{withdrawal.kyc_verification_statuses.id.requested ? withdrawal.kyc_verification_statuses.id.status : 'Not requested'}</div>
            </div>
            <div className="line-item">
              <div className="label">Address</div>
              <div
                className="value">{withdrawal.kyc_verification_statuses.address.requested ? withdrawal.kyc_verification_statuses.address.status : 'Not requested'}</div>
            </div>
            <div className="line-item" style={stackedStyle}>
              <div className="label">Payment Methods</div>
              <div className="value">
                <ul>
                  {
                    paymentAccountsRequested.length > 0 ?
                      paymentAccountsRequested.map(
                        ({ type, masked_account, status }) => <li>{type} - {masked_account} - {status}</li>
                      )
                      : 'None requested'
                  }
                </ul>
              </div>
            </div>
            <div className="line-item" style={stackedStyle}>
              <div className="label">Tax Ids</div>
              <div className="value">
                <ul>
                  {withdrawal.tax_ids.map(result => <li>{result}<br /></li>)}
                </ul>
              </div>
            </div>

            <div className="line-item">
              <div className="label">PEP matches</div>
              <div className="value">{withdrawal.pep_sip_check?.number_of_pep_matches}</div>
            </div>
            <div className="line-item">
              <div className="label">SIP matches</div>
              <div className="value">{withdrawal.pep_sip_check?.number_of_sip_matches}</div>
            </div>
            <WithdrawalMiniCommands withdrawal={withdrawal} loading={loading} fetchWithdrawal={fetchWithdrawal} edit={edit} editPspAmount={editPspAmount} />

            <div>
              <h5 className="label">CPF KYC VALIDATION</h5>
              {
                loadingCpf &&
                <button className={"small"} onClick={() => fetchKycRiskValidation()} disabled={!loadingCpf}>
                  Validate
                </button>
              }
              {!loadingCpf && <pre style={{ overflow: 'auto' }}>{cpfKycValidation ? 'Valid' : 'Not valid'}</pre>}
            </div>
          </div>
        </GroupPermissionsFragment>
      </div>
    </section >
  )
}