import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import CrashBetHistory from "originals/search-crash-bet";
import DoubleBetHistory from "originals/search-double-bet";
import MinesBetHistory from "originals/search-mines-bet";
import PlinkoBetHistory from "originals/search-plinko-bet";
import OriginalsBetHistory from "originals/search-new-originals-bet";
import DiceBetHistory from "originals/search-dice-bet";
import LimboBetHistory from "originals/search-limbo-bet";
import CrashRoomBetHistory from "originals/search-crash-room-bet";
import { PermissionsFragment } from "app/permissions-fragment";
import { BrowserView } from "react-device-detect";

import {
  LookupCrashBetLink,
  LookupDoubleBetLink,
  LookupMinesBetLink,
  LookupMinesBetV2Link,
  LookupDiceBetLink,
  LookupCrashRoomBetLink,
  LookupPlinkoBetLink,
  LookupLimboBetLink,
  LookupSlideBetLink,
  LookupFruitsBetLink,
  LookupFortuneDoubleBetLink,
  LookupFortuneDoubleRoomsLink,
  LookupMultiplayerGamesRoomsLink,
} from "originals/tab-blaze-originals/links.js";
import searchSlideBet from "originals/search-slide-bet";
import searchFruitsBet from "originals/search-fruits-bet";
import searchFortuneDoubleBet from "originals/search-fortune-double-bet";
import FortuneDoubleRooms from "originals/fortune-double-rooms";
import MultiplayerGamesRooms from "originals/multiplayer-games-rooms";

class TabBlazeOriginals extends Component {
  render() {
    const { url } = this.props.match;
    return (
      <PermissionsFragment feature="BLAZE_ORIGINALS">
        <div className={"page"}>
          <BrowserView>
            <nav>
              <LookupCrashBetLink />
              <LookupDoubleBetLink />
              <LookupMinesBetLink />
              <LookupMinesBetV2Link />
              <LookupDiceBetLink />
              <LookupCrashRoomBetLink />
              <LookupPlinkoBetLink />
              <LookupLimboBetLink />
              <LookupSlideBetLink />
              <LookupFruitsBetLink />
              <LookupFortuneDoubleBetLink />
              <LookupFortuneDoubleRoomsLink />
              <LookupMultiplayerGamesRoomsLink />
            </nav>
          </BrowserView>
          <Switch>
            <Route
              path={`${url}/crash-II-bets`}
              component={CrashRoomBetHistory}
            />
            <Route path={`${url}/crash-bets`} component={CrashBetHistory} />
            <Route path={`${url}/double-bets`} component={DoubleBetHistory} />
            <Route path={`${url}/mines-bets`} component={MinesBetHistory} />
            <Route path={`${url}/bets-pending`} component={MinesBetHistory} />
            <Route path={`${url}/plinko-bets`} component={PlinkoBetHistory} />
            <Route path={`${url}/limbo-bets`} component={LimboBetHistory} />
            <Route
              path={`${url}/new-originals-games`}
              component={OriginalsBetHistory}
            />
            <Route path={`${url}/slide-bet`} component={searchSlideBet} />
            <Route
              path={`${url}/fortune-double-bet`}
              component={searchFortuneDoubleBet}
            />
            <Route
              path={`${url}/fortune-double-rooms`}
              component={FortuneDoubleRooms}
            ></Route>
            <Route
              path={`${url}/multiplayer-games-rooms`}
              component={MultiplayerGamesRooms}
            ></Route>
            <Route path={`${url}/fruits-bet`} component={searchFruitsBet} />
            <Route path={`${url}/dice-bets`} component={DiceBetHistory} />

            {/* <Redirect from="/blaze-originals" to={`${url}/crash-bets`} /> */}
          </Switch>
        </div>
      </PermissionsFragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TabBlazeOriginals)
);
