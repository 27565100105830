import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "./styles/currentStatus.scss";
import { SelectCategory } from "./SelectCategory";
import { SelectType } from "./SelectType";
import { Tooltip } from "@mui/material";
import { getStatus as getOriginalsStatuses } from "originals/originals-games-api";
import { ORIGINALS_CATEGORY } from "status/constants";

const CurrentStatus = () => {
  const intervalRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [pagesTotal, setPagesTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [services, setServices] = useState([]);

  const getListServices = async () => {
    try {
      if (category === ORIGINALS_CATEGORY) {
        const originalsStatuses = await getOriginalsStatuses()
        setServices(originalsStatuses || []);
      } else {
        let query = '/admin/monitoring?';
        if (type) {
          query = `${query}type=${type}&`;
        }
        if (category) {
          query = `${query}category=${category}&`;
        }
        const monolithStatuses = await window.api.get(query);
        if (category) {
          setServices(monolithStatuses);
        } else {
          const originalsStatuses = await getOriginalsStatuses();
          setServices([...monolithStatuses, ...originalsStatuses]);
        }
      }
    } catch {
      setServices([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListServices();
    intervalRef.current = setInterval(getListServices, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [page, type, category]);

  return (
    <div>
      <div className="box-selects box-selects-status">
        {/* <div className="box-select">
          <h1 className="text-box-select">Service</h1>
          <SelectServices
            data={servicesStatus}
            select={service}
            setSelect={setService}
          />
        </div> */}
        <div className="box-select">
          <h1 className="text-box-select">Category</h1>
          <SelectCategory select={category} setSelect={setCategory} />
        </div>
        <div className="box-select">
          <h1 className="text-box-select">Type</h1>
          <SelectType select={type} setSelect={setType} />
        </div>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>Service</Th>
            <Th>Type</Th>
            <Th>Last Transaction</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        {loading && (
          <div className={"loading-overlay"}>
            <h2>Fetching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <Tbody className="referrals-body">
          {services.map((item) => {
            return (
              <Tr key={item?.service}>
                <Td>{item?.service}</Td>
                <Td>{item?.external ? 'External' : 'Internal'}</Td>
                <Td>{item?.last_transaction_seen_at}</Td>
                <Td>
                  <Tooltip title={item?.status} placement="top-start">
                    <div className={`status-indicator ${item?.status}`} />
                  </Tooltip>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <div className="pagination">
        <button
          className="btn-pagination"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <span>{"<"}</span>
        </button>
        <div className="pagination_page">
          Page {page} of {pagesTotal}
        </div>
        <button
          className="btn-pagination"
          onClick={() => setPage(page + 1)}
          disabled={page >= pagesTotal}
        >
          <span>{">"}</span>

        </button>
      </div>
    </div>
  );
};

export default withRouter(CurrentStatus);
