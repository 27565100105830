import { DialogActions, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Translator } from "../../shared/translator";
import { withRouter } from "react-router-dom";

const typesLanding = [
  { label: "Landing-1 with timer and reward", value: "landing1" },
  { label: "Landing-2 with form and reward", value: "landing2" },
  { label: "Landing-3 with form and timer", value: "landing3" },
  { label: "Landing-4 width large text", value: "landing4" },
];
const typeCategories = [
  "crash",
  "sports",
  "casino",
  "double",
  "mines",
  "tiger",
  "neymar",
  "aviator",
  "fortunerabbit",
  "fortuneox",
  "neymarCasino",
  "fortuneDouble",
  "fortunedragon",
];

const Detail = (props) => {
  const idLink = props.match.params.id;
  const [state, setState] = useState();
  const [typeLayout, setTypeLayout] = useState();
  const [category, setCategory] = useState();
  const [bonuses, setBonuses] = useState([]);

  const [subTitleTranslation, setSubTitleTranslation] = useState();
  const [titleTranslation, setTitleTranslation] = useState();
  const [bannerTranslation, setBannerTranslation] = useState();

  const [loading, setLoading] = useState(true);

  const [mode, setMode] = useState();
  const [bonus, setBonus] = useState({});

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await window.api.get(`/admin/affiliate_links/${idLink}`);
        const { type, translate, bonus_id, title } = data;
        setState(data);
        setTypeLayout(type.split("-")[0]);
        setCategory(type.split("-")[1]);
        setMode(!!bonus_id ? "default" : "custom");
        setBonus({ value: bonus_id, label: title });
        handleTranslate(translate);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
      }
    };
    fetch();
  }, [idLink]);

  const handleTranslate = (translate) => {
    let translateObj;
    const isEmptyObject = Object.keys(translate).length === 0;
    if (!isEmptyObject) {
      translateObj = JSON.parse(translate);
    }
    const titleT = isEmptyObject ? {} : translateObj.title_translation;
    const bonusT = isEmptyObject ? {} : translateObj.bonus_translation;
    const bannerT = isEmptyObject ? {} : translateObj.banner_translation;
    setSubTitleTranslation(bonusT);
    setTitleTranslation(titleT);
    setBannerTranslation(bannerT);
  };

  useEffect(() => {
    const fetchBonus = async () => {
      try {
        const { records } = await window.api.get("/admin/bonuses");
        setBonuses(records);
        const bonus = records.find((b) => b.id === state.bonus_id);
        if (bonus) {
          setBonus({
            label: bonus.name,
            value: bonus.id,
          });
        }
      } catch (err) {
        toast.error(err.message);
      }
    };
    if (state) fetchBonus();
  }, [state]);

  const handleSubmit = () => {
    const { banner, code, bonus_title, title } = state;

    const data = {
      code: code,
      bonus_id: mode === "default" ? bonus.value : null,
      type: `${typeLayout}-${category}`,
      title: title || "",
      bonus_title: bonus_title || "",
      banner: banner || "",
      bonus_translation: subTitleTranslation,
      title_translation: titleTranslation,
      banner_translation: bannerTranslation,
    };

    window.api.put(`/admin/affiliate_links/${idLink}`, data).then((res) => {
      toast.success("Updated");
    });
  };

  const handleInputs = (event) => {
    const { value, name } = event.target;
    setState({ ...state, [name]: value });
  };

  if (!state || loading) return null;

  const isProd = process.env.NODE_ENV === "production";
  const isDev = process.env.NODE_ENV === "development";
  const getUrl = (code) => {
    if (isProd) {
      return `https://blaze.com/l/${code}`;
    }
    if (isDev) {
      return `http://localhost:21000/l/${code}`;
    }
    return `https://admin-staging.blaze-internal.com/l/${code}`;
  };
  return (
    <div>
      <div>
        <label>Code : {state.code}</label>
        <div onClick={() => window.open(getUrl(state.code), "_blak")}>
          URL : {getUrl(state.code)}
        </div>
      </div>
      <div>
        <div className="wrap-switch-affiliate">
          <p>Default bonus </p>
          <Switch
            checked={mode !== "default"}
            onChange={(e) => {
              setBonus({});
              setMode(e.target.checked ? "custom" : "default");
            }}
            size="medium"
          />
          <p>Custom bonus</p>
        </div>
        <div className="update-affiliate-groups-select">
          {mode === "default" && (
            <div>
              <h4>Add Bonus to Inventory (optional)</h4>
              <Select
                name="type"
                value={bonus}
                onChange={(option) => setBonus(option)}
                options={bonuses.map((bonus) => {
                  return {
                    label: bonus.name,
                    value: bonus.id,
                  };
                })}
              />
            </div>
          )}
          <div>
            <h4>Layout Landing</h4>
            <Select
              name="landing_page"
              value={typeLayout}
              onChange={({ value }) => setTypeLayout(value)}
              options={typesLanding.map(({ label, value }) => {
                return {
                  label,
                  value,
                };
              })}
            />
          </div>
          <div>
            <h4>Category Landing</h4>
            <Select
              name="category"
              value={category}
              onChange={({ value }) => setCategory(value)}
              options={typeCategories.map((c) => {
                return {
                  label: c,
                  value: c,
                };
              })}
            />
          </div>
        </div>
        {mode === "custom" && (
          <>
            <div className="update-affiliate-groups">
              {/* <div className="update-affiliate-group-input">
                <label>Banner</label>
              </div>
              <input
                name="banner"
                onChange={handleInputs}
                value={state.banner}
              />
              <Translator
                languages={[]}
                contents={bannerTranslation}
                onChange={(banner_translation) =>
                  setBannerTranslation(banner_translation)
                }
                label={"Banner"}
              /> */}
              <div className="update-affiliate-group-input">
                <label>Title</label>
                <input
                  name="title"
                  onChange={handleInputs}
                  value={state.title}
                />
              </div>
              <Translator
                languages={[]}
                contents={titleTranslation}
                onChange={(title_translation) =>
                  setTitleTranslation(title_translation)
                }
                label={"Title"}
              />
              <div className="update-affiliate-group-input">
                <label>Bonus title</label>
                <input
                  name="bonus_title"
                  onChange={handleInputs}
                  value={state.bonus_title}
                />
              </div>
              <Translator
                languages={[]}
                contents={subTitleTranslation}
                onChange={(bonus_translation) =>
                  setSubTitleTranslation(bonus_translation)
                }
                label={"SubTitle"}
              />
            </div>
          </>
        )}
        {/* <DialogActions>
          <button onClick={handleSubmit}>Update</button>
        </DialogActions> */}
      </div>
    </div>
  );
};

export default withRouter(Detail);
