import React, { Fragment } from "react";
import { Redirect, Switch, withRouter } from "react-router-dom";
import {
  permissionGroups,
  CheckPermissionGroupRoute,
} from "app/permissions-fragment.js";

import AffiliateManager from "affiliates/affiliate-manager.js";
import CreateCampaign from "affiliates/create-campaign";
import UpdateCampaign from "affiliates/update-campaign";
import UpdatePostback from "affiliates/update-postback";
import { BrowserView } from "react-device-detect";
import {
  CreateCampaignLink,
  UpdateCampaignLink,
  AffiliateManagerLink,
  UpdatePostbackUrlLink,
} from "affiliates/system-tabs/links";

function System(props) {
  return (
    <div className="affiliate-page">
      <div className="page-wrapper">
        <BrowserView>
          <nav className="user-nav">
            <CreateCampaignLink />
            <UpdateCampaignLink />
            <AffiliateManagerLink />
            <UpdatePostbackUrlLink />
          </nav>
        </BrowserView>

        <div className="nav-output">
          {!props.loading && (
            <Fragment>
              <Switch>
                <CheckPermissionGroupRoute
                  exact
                  group={permissionGroups.AFFILIATES.INFO}
                  path={"/affiliates/system/create-campaign"}
                  component={CreateCampaign}
                />
                <CheckPermissionGroupRoute
                  exact
                  group={permissionGroups.AFFILIATES.INFO}
                  path={"/affiliates/system/update-campaign"}
                  component={UpdateCampaign}
                />
                <CheckPermissionGroupRoute
                  group={permissionGroups.AFFILIATES.CAMPAIGNS}
                  path={"/affiliates/system/affiliate-manager"}
                  component={AffiliateManager}
                />
                <CheckPermissionGroupRoute
                  group={permissionGroups.AFFILIATES.INFO}
                  path={"/affiliates/system/set-postback"}
                  component={UpdatePostback}
                />
                <Redirect from={`*`} to={`/affiliates/system`} />
              </Switch>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(System);
