import React, { Component } from "react";
import Pagination from "../shared/pagination";
import moment from "moment";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
export default class UserMinesBets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      total_pages: 0,
      bets: [],
      queryFilter: {},
      hasChange: false,
      gameStartedAtStart: null,
      gameStartedAtEnd: null,
      gameSettledAtStart: null,
      gameSettledAtEnd: null,
      joinDateStarted: null,
      lastDepositStarted: null,
      joinDateSettled: null,
      lastDepositSettled: null,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      this.fetch();
    }
  }

  async fetch() {
    this.setState({ isLoading: true });
    try {
      const { total_pages, records } = await window.api.get(
        `/admin/users/${this.props.match.params.id}/mines_bets?page=${this.state.page}`
      );
      this.setState({ total_pages, bets: records });
    } catch (err) {
      toast.error(err.message);
    }
    this.setState({ isLoading: false });
  }

  async rollback(id) {
    try {
      await window.api.post(`/admin/mines_bets/${id}`);
      toast.success("Bet rolled back");
    } catch (err) {
      toast.error("Bet already rolled back.");
    }

    this.fetch();
  }

  render() {
    return (
      <div style={{ position: "relative", "min-height": "500px" }}>
        {this.state.isLoading && (
          <div className={"loading-overlay"}>
            <h2>Loading, please wait ...</h2>
            <div className="loader" />
          </div>
        )}
        <Table>
          <Thead>
            <Tr>
              <Th>Round ID</Th>
              <Th>Game Created At</Th>
              <Th>Bet Placed At</Th>
              <Th>Bet Settled At</Th>
              <Th>Amount</Th>
              <Th>Number of mines</Th>
              <Th>Tiles shown</Th>
              <Th>Winnings</Th>
              <Th>Status</Th>
              <Th>Profit</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.bets.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>
                    <Link to={`/casino/game-provider-rounds/${r.round_id}`}>
                      {r.round_id}
                    </Link>
                  </Td>
                  <Td>{format.withSeconds(r.created_at)}</Td>
                  <Td>{format.withSeconds(r.bet_placed_at)}</Td>
                  <Td>{format.withSeconds(r.bet_settled_at)}</Td>
                  <Td>
                    {r.amount} {r.currency_type}
                  </Td>
                  <Td>{r.number_of_mines}</Td>
                  <Td>{r.tiles_shown}</Td>
                  <Td className="good">
                    {r.win_amount} {r.currency_type}
                  </Td>
                  <Td>{r.status}</Td>
                  <Td className={r.profit >= 0 ? "good" : "bad"}>
                    {r.profit} {r.currency_type}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.state.total_pages}
          update={(page) => {
            this.setState({ page });
          }}
        />
      </div>
    );
  }
}
