import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import styles from "./anti-fraud.module.scss";

const initialState = {
  isLoading: false,
  report: {
    mutual_ips: [],
    mutual_payment_accounts: [],
    mutual_tax_ids: [],
    bonus_into_real_transactions: [],
    bonus_bets_over_limit_count: 0,
  },
};
class UserAntiFraud extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { selected_currency } = this.props;
    window.api
      .get(`/admin/users/${id}/anti_fraud?currency_type=${selected_currency}`)
      .then((report) => this.setState({ report }));
  }

  render() {
    const {
      referral,
      mutual_ips,
      mutual_payment_accounts,
      mutual_tax_ids,
      bonus_into_real_transactions = [],
      bonus_bets_over_limit_count = 0,
    } = this.state.report;

    return (
      <Fragment>
        <h4>Anti Fraud</h4>

        <Table>
          <Thead>
            <Tr>
              <Th>Key</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>Referral</Td>
              <Td>
                {referral && (
                  <Link to={`/users/${referral.user_id}`}>
                    {referral.username}
                  </Link>
                )}
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <br />
        <hr />

        <div className="row">
          <div className="col-xs-4">
            <h4>Mutual IPs</h4>

            {mutual_ips.map((f) => {
              return (
                <div className={"fingerprints-entry"} key={f.id}>
                  <Link to={`/users/${f.user_id}`}>
                    <span>
                      <img src={f.avatar_url} height={20} />
                      <span
                        style={{ color: f.has_bonus_transfer ? "red" : "" }}
                      >
                        {f.username} - {f.full_name} ({f.country})
                      </span>
                    </span>
                  </Link>

                  <ul>
                    {f.colliding_ips.map((account) => (
                      <li>{account}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="col-xs-4">
            <h4>Mutual payment accounts</h4>

            {mutual_payment_accounts.map((f, index) => {
              return (
                <div className={"fingerprints-entry"} key={index}>
                  <Link to={`/users/${f.user_id}`}>
                    <span>
                      <img src={f.avatar_url} height={20} />
                      <span
                        style={{ color: f.has_bonus_transfer ? "red" : "" }}
                      >
                        {f.username} - {f.full_name} ({f.country})
                      </span>
                    </span>
                  </Link>

                  <ul>
                    {f.colliding_accounts.map((account) => (
                      <li>{account}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="col-xs-4">
            <h4>Mutual Tax IDs</h4>
            {mutual_tax_ids.map((f) => {
              return (
                <div className={"fingerprints-entry"} key={f.id}>
                  <Link to={`/users/${f.user_id}`}>
                    <span>
                      <img src={f.avatar_url} height={20} />
                      <span
                        style={{ color: f.has_bonus_transfer ? "red" : "" }}
                      >
                        {f.username} - {f.full_name} ({f.country})
                      </span>
                    </span>
                  </Link>

                  <ul>
                    {f.colliding_accounts.map((account) => (
                      <li>{account}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>

          <div className={styles.info}>
            <h2>Flagged Bonus Transactions</h2>

            <h4>
              Total Amount transferred to real:{" "}
              {bonus_into_real_transactions
                .reduce((acc, f) => acc + +f.amount, 0)
                .toFixed(4)}
            </h4>

            <hr />
          </div>

          <div className="col-xs-6">
            <Table>
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>ID</Th>
                  <Th>Type</Th>
                  <Th>Wallet</Th>
                  <Th>Amount</Th>
                  <Th>Closing Balance</Th>
                </Tr>
              </Thead>

              <Tbody>
                {bonus_into_real_transactions.map((r, i) => {
                  return (
                    <Tr key={i}>
                      <Td>{format.withMinutes(r.created_at)}</Td>
                      <Td>{r.id}</Td>
                      <Td>{r.type}</Td>
                      <Td>{r.currency_type}</Td>
                      <Td>{r.amount}</Td>
                      <Td>{r.closing_balance}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  selected_currency: state.users.user.selected_currency,
});
export default connect(mapStateToProps)(UserAntiFraud);
