const createInitialState = (props) => ({
  info: {
    date_of_birth: props.date_of_birth,
    email: props.email,
    phone_number: props.phone_number,
    first_name: props.first_name,
    last_name: props.last_name,
    address: props.address,
    country: props.country,
    kyc_country: props.kyc_country,
    city: props.city,
    state: props.state,
    postal_code: props.postal_code,
    tax_id: props.tax_id,
    otp_phone_number: props.otp_phone_number,
    otp_phone_number_confirmed: props.otp_phone_number_confirmed,
    gender: props.gender,
  },
  customer: { tax_id: props.tax_id || "" },
  page: 1,
  kyc_level: undefined,
});

export default createInitialState;
