import {
  GroupPermissionsFragment,
  permissionGroups,
  PermissionsFragment,
} from "app/permissions-fragment.js";
import { Link } from "react-router-dom";
import nodes from "./nodes/index";

const {
  casinoNodes,
  systemNodes,
  paymentNodes,
  originalsNodes,
  usersNodes,
  affiliatesNodes,
  statusNodes
} = nodes;

const treeData = [
  {
    key: "home",
    label: "Home",
    icon: "",
    component: () => <Link to="/">Home</Link>,
    nodes: [],
  },
  systemNodes,
  casinoNodes,
  paymentNodes,
  originalsNodes,
  usersNodes,
  affiliatesNodes,
  statusNodes,
  {
    key: "logout",
    label: "Logout",
    icon: "",
    component: () => <Link to="/logout">Logout</Link>,
    nodes: [],
    divider: "top",
  },
];

export default treeData;
