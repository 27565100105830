import * as types from './types';

const initial = {
  selectedAffiliate: null,
  selectedCampaign: null,
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.SET_SELECTED_AFFILIATE: {
      return { ...state, selectedAffiliate: action.payload };
    }
    case types.SET_SELECTED_CAMPAIGN: {
      return { ...state, selectedCampaign: action.payload };
    }

  }

  return state;
}
