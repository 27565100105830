import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSettings, saveSettings } from "./actions";

const Settings = () => {
  const [settingsState, setSettingsState] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const data = useSelector((state) => state.settings.data);
  const roles = useSelector((state) => state?.app?.user?.roles || null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSettings());
  }, [dispatch]);

  useEffect(() => {
    const hasChanges = Object.keys(settingsState).some(
      (key) =>
        settingsState[key] !==
        data.find((setting) => setting.key === key)?.value,
    );
    setHasChanges(hasChanges);
  }, [settingsState, data]);

  const handleChange = (key, value) => {
    setSettingsState({ ...settingsState, [key]: { value, reason: "" } });
    setHasChanges(true);
  };

  const handleReasonChange = (key, reason) => {
    const updatedSettings = { ...settingsState };
    updatedSettings[key].reason = reason;
    setSettingsState(updatedSettings);
  };

  const handleSave = () => {
    let hasEmptyReason = false;

    Object.keys(settingsState).forEach((key) => {
      if (settingsState[key]?.value !== data.find((setting) => setting.key === key)?.value &&
          settingsState[key].reason.trim() === "") {
        hasEmptyReason = true;
      }
    });

    if (hasEmptyReason) {
      alert("Please provide a reason for all changes before saving.");
      return;
    }

    const settingsWithReason = Object.keys(settingsState).reduce((acc, key) => {
      if (settingsState[key].reason.trim() !== "") {
        acc[key] = settingsState[key];
      }
      return acc;
    }, {});

    dispatch(saveSettings(settingsWithReason));
    cleanState();
  };

  const cleanState = () => {
    setSettingsState({});
    setHasChanges(false);
  }

  return (
    <div>
      <h3>Settings</h3>

      {data.map((setting, index) => {
        const val =
          settingsState[setting.key]?.value !== undefined
            ? settingsState[setting.key]?.value
            : setting.value;
        const reason = settingsState[setting.key]?.reason || "";

        if (
          roles.includes("support_manager") &&
          setting.key === "chat_enabled"
        ) {
          return (
            <div key={index}>
              <label>{setting.key}</label>
              <br />
              <br />
              <input
                style={{ width: "100%" }}
                value={val}
                onChange={(e) => handleChange(setting.key, e.target.value)}
              />
              {val !== setting.value && (
                <>
                  <br />
                  <br />
                  <input
                    style={{ width: "100%" }}
                    placeholder="Reason for change"
                    value={reason}
                    onChange={(e) =>
                      handleReasonChange(setting.key, e.target.value)
                    }
                  />
                </>
              )}
              <hr />
            </div>
          );
        } else if (!roles.includes("support_manager")) {
          return (
            <div key={index}>
              <label>{setting.key}</label>
              <br />
              <br />
              <input
                style={{ width: "100%" }}
                value={val}
                onChange={(e) => handleChange(setting.key, e.target.value)}
              />
              {val !== setting.value && (
                <>
                  <br />
                  <br />
                  <input
                    style={{ width: "100%" }}
                    placeholder="Reason for change"
                    value={reason}
                    onChange={(e) =>
                      handleReasonChange(setting.key, e.target.value)
                    }
                  />
                </>
              )}
              <hr />
            </div>
          );
        }
        return null;
      })}

      <button onClick={handleSave} disabled={!hasChanges}>
        Save
      </button>
    </div>
  );
};

export default Settings;
