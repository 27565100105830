import { connect } from "react-redux";
import {
  saveUser,
  updateEmail,
  updateUserInfo,
  updateUser,
  updateCustomerInfo,
} from "user/actions.js";

import Info from "./Info";

const mapStateToProps = (state) => ({
  ...state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user_id, email_request_confirmed) => {
    dispatch(updateUser(user_id, email_request_confirmed));
  },

  saveUser: (user_id, data) => {
    dispatch(saveUser(user_id, data));
  },

  updateUserInfo: (user_id, info, email_request_confirmed) => {
    dispatch(updateUserInfo(user_id, info, email_request_confirmed));
  },

  updateEmail: (user_id, email, request, staff_note) => {
    dispatch(updateEmail(user_id, email, request, staff_note));
  },

  updateCustomerInfo: (user_id, info, request, staff_note) => {
    dispatch(updateCustomerInfo(user_id, info, request, staff_note));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Info);
