import _ from "lodash";

export function startTypeCheck(val) {
  return {
    is: {
      anArray: _.isArray(val),
      aPlainObject: _.isPlainObject(val),
      present: !_.isNil(val),
      aBoolean: typeof val === "boolean",
    },
    returnSelf: val,
  };
}
