import React, { Component } from 'react';
import querystring from 'querystring';
import Pagination from "../../shared/pagination";

class EditGamesInProvider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      page: 1,
      records: [],
      meta: {

      },

      ordinality: {},
      providerGames: [],
      inputs: {},
      terms: [],
      query: ""
    }
  }

  componentDidMount() {
    this.fetchProviderGames();
    this.fetchSearchTerms();
  }



  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.providerSlug !== this.props.providerSlug && this.props.providerSlug) {
      this.fetchProviderGames();
    }

    if (prevState.page !== this.state.page) {
      this.fetchProviderGames();
    }

  }

  async fetchProviderGames() {
    const providerGames = await window.api.get('/admin/games/search?' + querystring.stringify({
      order: 'popular',
      page: this.state.page,
      limit: 30,
      game_provider_slugs: this.props.providerSlug,
      search: this.state.query
    }));

    this.setState({ ...providerGames })
  }

  async fetchSearchTerms() {
    const terms = await window.api.get('/admin/search_terms');

    this.setState({ terms })
  }
  async lockAtPosition({ fixed_position, game_id }) {
    await window.api.post('/admin/search_terms/lock', {
      fixed_position,
      game_id,
      slug: this.props.providerSlug,
      type: 'provider'
    });


    this.fetchProviderGames();
    this.fetchSearchTerms()
  }


  async unlockTerm({ search_term_id }) {
    await window.api.post('/admin/search_terms/unlock', {
      search_term_id,
    });

    this.fetchProviderGames();
    this.fetchSearchTerms()
  }


  async pushUnlockedSlot({ fixed_position }) {
    await window.api.post('/admin/search_terms/push_unlocked', {
      fixed_position,
      slug: this.props.providerSlug,
      type: 'provider'
    });


    this.fetchProviderGames();
    this.fetchSearchTerms()
  }

  mergeTermsToGames() {
    let { records, terms } = this.state;

    return records.map(game => {
      const term = terms.find(term => term.type === 'provider' &&
        term.value.slug === this.props.providerSlug &&
        +term.value.game_provider_game_id === game.id
      )


      return {
        ...game,
        fixed_ordinality: term ? term.value.fixed_ordinality: null,
        term_id: term ? term.id: null,
      }
    })
  }


  render() {

    const records = this.mergeTermsToGames();

    return (
      <div style={{ width: '60%' }}>
        <h2>Edit game positions in provider</h2>
        <div>
          <button onClick={() => {
            const fixed = window.prompt(`What position do you want an unlocked slot at? (0 = first position)`);
            if (isNaN(+fixed) || fixed === null) {
              return null;
            }
            this.pushUnlockedSlot({ fixed_position: fixed })

          }}>Insert unlocked slot</button>

          <input style={{ marginLeft: 20, width: 700}} placeholder={"Search"} onChange={(ev) => { this.setState({ query: ev.target.value }, () => this.fetchProviderGames())}}/>

        </div>
        <br/>
        <div className={"row games"}>
          {
            records.map((game, i) => {
              return (
                <div className={"col-xs-2"} key={i}>
                  <div className={`game-box`}>
                    { game.fixed_ordinality !== null ? <p>Fixed: {game.fixed_ordinality}</p>: "default" }

                    <div className={"buttons row"}>
                      <div className={"col-xs-12"}>
                        <button onClick={() => {
                          const fixed = window.prompt(`What position do you want this game to be fixed to? (0 = first position)`);
                          if (isNaN(+fixed) || fixed === null) {
                            return null;
                          }

                          this.lockAtPosition({ fixed_position: fixed, game_id: game.id, search_term_id: game.term_id })

                        }}>Lock At</button>
                      </div>
                      <div className={"col-xs-12"}>

                        <button disabled={!game.term_id} onClick={() => {

                          this.unlockTerm({ search_term_id: game.term_id })

                        }}>Unlock</button>
                      </div>
                    </div>


                    <img src={game.image_url_new || game.image_url}/>
                    <p>{game.name}</p>
                    <p>{game.provider.name}</p>

                  </div>
                </div>
              )
            })
          }
        </div>

        <Pagination
          total_pages={this.state.meta.total_pages}
          update={page => {
            this.setState({ page });
          }}
        />
      </div>
    )
  }
}

export default EditGamesInProvider;