import React, { useState } from "react";
import { toast } from "react-toastify";
import TwoFactorActivation from "./two-factor-activation";
import CreationStep from "./creationStep";

const Create = ({}) => {
  const [step, setStep] = useState("creation")
  const [userId, setUserId] = useState(null)

  const nextStep = ({ enableTwoFactor }) => {
    switch (step) {
      case "creation": {
        if (enableTwoFactor) setStep("twoFactorActivation");
        return;
      }

      case "twoFactorActivation": {
        setStep("creation");
        return;
      }

      default: {
        return;
      }
    }
  }

  const onCreate = async ({email, password, dateOfBirth, enableTwoFactor}) => {
    const data = await window.api.post(
      '/admin/users',
      { email, password, date_of_birth: dateOfBirth }
    );

    setUserId(data?.user?.userId);

    toast.success(`User with ID: ${data?.user?.userId} was createad`);

    nextStep({ enableTwoFactor })
  }

  const onActivate = async (code) => {
    await window.api.post(
      `/admin/users/${userId}/two_factor/activate`,
      { code }
    );

    toast.success(`Two Factor enabled successfully for user with ID: ${userId}`);

    nextStep({});
  };

  switch (step) {
    case "creation": {
      return <CreationStep onCreate={onCreate} />;
    }
    case "twoFactorActivation": {
      return <TwoFactorActivation userId={userId} onActivate={onActivate}/>;
    }
    default: {
      return <div>Unexciting step</div>
    }
  }
}

export default Create;