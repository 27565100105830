import * as types from './types';

const initial = {
  search_results: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  categories: [],
  bonus_activations: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  bonus_activations_on_withdrawal: [],
};

export default (state = initial, action) => {

  switch(action.type) {
    case types.BONUSES_UPDATE:
      return {
        ...state,
        search_results: action.payload
      };


    case types.BONUS_ACTIVATIONS_UPDATE:
      return {
        ...state,
        bonus_activations: action.payload
      };

    case types.BONUS_ACTIVATIONS_ON_WITHDRAWAL_UPDATE:
      return {
        ...state,
        bonus_activations_on_withdrawal: action.payload,
      };
  }

  return state;
}
