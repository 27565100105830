import * as types from './types';

const INITIAL_STATE = {
  limits: [],
  provider: {}
}

export default function currenciesReducer(state = INITIAL_STATE, action = {}) {

  switch (action.type) {
    case types.CURRENCY_LIMITS_UPDATE:
      return {
        ...state,
        limits: action.payload
      };

    case types.CURRENCY_LIMIT_UPDATE:
      return {
        ...state,
        provider: action.payload
      };

    default:
      return state;
  }
}

