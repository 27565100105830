import React, { Component } from "react";
import { connect } from 'react-redux';
import { Translator } from "shared/translator";
import { updateDisplayName, updateDisplayNameTranslations } from "./actions";

class DisplayName extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayName: "",
            displayNameTranslations: {},
        }
    }

    componentDidMount() {
        this.props.update(this.props.id);
    }

    componentWillReceiveProps(next) {
        this.setState({
            displayName: next.displayName,
            displayNameTranslations: next.displayNameTranslations || {},
        })
    }

    render() {
        return (
            <div>
                <h2>Display Name Translation</h2>
                <div>
                    <div>
                        <h3>Default Display Name</h3>
                        <input
                            placeholder="DisplayName"
                            value={this.state.displayName}
                            onChange={(e) =>
                                this.updateState({
                                    displayName: e.target.value,
                                })
                            }
                        />
                    </div>
                    <br />
                    <Translator
                        languages={[]}
                        contents={this.state.displayNameTranslations}
                        onChange={(displayNameTranslations) =>
                            this.updateState({ displayNameTranslations })
                        }
                        label={"Name Translation"}
                    />
                    <br />
                    <button onClick={() =>
                        this.props.updateDisplayNameTranslations(this.props.id, this.state.displayName, this.state.displayNameTranslations)
                    }>Update Display Name</button>
                </div>
            </div>
        );
    }

    updateState(newState) {
        this.setState({
            ...this.state,
            ...newState,
        });
    }
}


const mapStateToProps = state => ({
    displayName: state.payments.provider.display_name,
    displayNameTranslations: state.payments.provider.display_name_translations,
})

const mapDispatchToProps = dispatch => ({
    update: (id) => {
        dispatch(updateDisplayName(id));
    },
    updateDisplayNameTranslations: (id, displayName, displayNameTranslations) => {
        dispatch(updateDisplayNameTranslations(id, displayName, displayNameTranslations));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayName)
