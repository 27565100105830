
import { useReducer } from "react";
import { Stack, Typography, Button, Divider } from "@mui/material";
import {
    permissionsConstants,
    PermissionsFragment,
} from "app/permissions-fragment";

import SuspendForm from "./components/SuspendForm";
import SuspendDetail from "./components/SuspendDetail";
import CloseAccountForm from "../close-account-forms/close-account-form";
import ReopenAccountForm from "../close-account-forms/reopen-account-form";

import createInitialState from "./initialState";
import reducer from "./reducer";

import { CHANGE_SUSPENSION_FORM, UPDATE_FORM_ERRORS } from "./constants";
import { CLOSE_ACCOUNT_REASON_OPTIONS } from '../constants';

const SUSPEND_ACCOUNT_REASON_OPTIONS = CLOSE_ACCOUNT_REASON_OPTIONS.concat({ id: 9, reason: "Other" }).map(reason => ({ ...reason, id: reason.id.toString() }))

const suspendFormValidation = (form) => {
    const formErrors = {}

    if (!Number(form.duration) || Number(form.duration) === 0) {
        formErrors.duration = "Duration must be greather than 0"
    }

    return formErrors
}

export default function Suspensions(props) {
    const closed = (props.user.roles || []).includes("closed");

    const [state, dispatch] = useReducer(reducer, createInitialState)

    const handleOnChangeForm = (field, value) => dispatch({ type: CHANGE_SUSPENSION_FORM, payload: { [field]: value } })

    const handleOnClickSuspend = () => {
        const formErrors = suspendFormValidation(state.form)

        if (Object.keys(formErrors).length > 0) {
            dispatch({ type: UPDATE_FORM_ERRORS, payload: formErrors })
            return
        }

        const { duration, reason, note } = state.form
        props.suspend({ user_id: props.user.id, duration, reason, note })
    }

    const handleOnClickUnsuspend = () => {
        props.cancelSuspend({ user_id: props.user.id })
    }

    return (
        <Stack direction='column' gap={2} px={2} pb={2}>
            <PermissionsFragment feature={permissionsConstants.USER_UPDATE_SUSPENSION}>
                <Typography variant="h4" component="h1">Suspend User</Typography>
                <Typography variant="body2" component='p'>After the suspension, the user will not be able to access the platform</Typography>
                <SuspendForm form={state.form} formErrors={state.formErrors} onChange={handleOnChangeForm} suspendAccountReasonOptions={SUSPEND_ACCOUNT_REASON_OPTIONS}>
                    <Button
                        variant='contained'
                        color='secondary'
                        size="small"
                        onClick={handleOnClickSuspend}
                        disabled={!!props.user.user_suspension}
                    >
                        Suspend
                    </Button>
                </SuspendForm>
            </PermissionsFragment>
            <Typography variant="h6" component="h2">Last Suspension</Typography>
            <SuspendDetail userSuspension={props.user.user_suspension} suspendAccountReasonOptions={SUSPEND_ACCOUNT_REASON_OPTIONS}>
                <PermissionsFragment feature={permissionsConstants.USER_CANCEL_SUSPENSION}>
                    <Button
                        variant='contained'
                        color='secondary'
                        size="small"
                        onClick={handleOnClickUnsuspend}
                    >
                        Unsuspend
                    </Button>
                </PermissionsFragment>
            </SuspendDetail>
            <Divider />
            <PermissionsFragment feature={permissionsConstants.USER_CLOSE_ACCOUNT}>
                {closed ? <ReopenAccountForm user_id={props.user.id} /> : <CloseAccountForm user_id={props.user.id} />}
            </PermissionsFragment>
        </Stack>
    )
}