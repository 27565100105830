import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

const twoFactorDataInitState = {
  base32: "",
  url: "",
  code: "",
}

const TwoFactorActivation = ({ userId, onActivate }) => {
  const [{ code, base32, url }, setTwoFactorData] = useState(twoFactorDataInitState)

  const isCodeValid = () => /^\d{6}$/.test(code);

  const generateTwoFactorSecret = async () => {
    const data = await window.api.post(
      `/admin/users/${userId}/two_factor/generate_secret`,
      {}
    );

    QRCode.toDataURL(data.otpauth_url, function(err, data_url) {
      setTwoFactorData(prevState => ({ ...prevState, url: data_url }))
    })

    setTwoFactorData(prevState => ({ ...prevState, base32: data.base32 }))
  }

  useEffect(() => {
    if (userId) generateTwoFactorSecret()
  }, [])

  const handleActivate = async () => {
    onActivate(code);
  };

  return (
    <div id="two-factor" className={"two-factor-activation"}>
      <div>{base32}</div>
      <img src={url} />
      <br />
      <div >
        <div >Enter authentication code</div>
        <input placeholder="Eg. 123 456" value={code} onChange={ev => {
          const code = ev?.target?.value?.replace(" ", "");
          setTwoFactorData(prevState => ({ ...prevState, code }));
        }}/>
      </div>
      <br />
      <button className="red" disabled={!isCodeValid()} onClick={handleActivate}>Activate</button>
    </div>
  )
};

export default TwoFactorActivation;
