import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createDepositBonus, updateDepositBonuses } from './actions';
import { updateRewards } from './reward-actions';
import Select from 'react-select';
import moment from 'moment';
import CountryList from 'country-list';
import { Translator } from '../shared/translator';
import { checkPermissions } from '../app/api';
import { permissionsConstants } from '../app/permissions-fragment';

class Bonuses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: null,
      page: 1,
      excluded_countries: [],
      supported_countries: [],
      excluded_country: '',
      supported_country: '',
      currency_type: 'EUR',
      globally_available: false,
      landing_page_type: {},
      type: { label: 'Deposit Bonus', value: 'deposit' },
      name_translation: {},
      description_translation: {},
      banner_translation: {},
      terms_translation: {},
      reward: {},
    };

    this.renderDepositRules = this.renderDepositRules.bind(this);
    this.renderRewardRules = this.renderRewardRules.bind(this);
    this.renderBonusTypeRules = this.renderBonusTypeRules.bind(this);

    this.getDepositRules = this.getDepositRules.bind(this);
    this.getRewardRules = this.getRewardRules.bind(this);
    this.getBonusRules = this.getBonusRules.bind(this);

    this.createBonusHandler = this.createBonusHandler.bind(this);
    this.onClickSubmitHandler = this.onClickSubmitHandler.bind(this);

    this.countries = CountryList();
  }

  componentDidMount() {
    this.update();
    if (checkPermissions(permissionsConstants.REWARDS_VIEW_LIST)) {
      this.props.updateRewards();
    }
  }

  update() {
    this.props.update(this.state.page);
  }

  getDepositRules() {
    return [
      this.amount.value,
      this.maximum_bonus.value,
      this.deposit_ratio.value,
      this.wager_ratio.value,
    ];
  }

  getRewardRules() {
    return [0, this.state.reward.value, this.total_deposited_required.value];
  }

  getBonusRules() {
    let amount,
      maximum,
      deposit_ratio,
      wager_ratio,
      reward_id,
      total_deposited_required;

    switch (this.state.type.value) {
      case 'deposit':
      case 'free_bet':
      case 'welcome_bonus':
      case 'deposit_sports':
        [amount, maximum, deposit_ratio, wager_ratio] = this.getDepositRules();
        break;
      case 'deposit_reward':
        [wager_ratio, reward_id, total_deposited_required] =
          this.getRewardRules();
        break;
    }

    return {
      amount,
      maximum,
      deposit_ratio,
      wager_ratio,
      reward_id,
      total_deposited_required,
    };
  }

  getFormattedBonusTerms() {
    let bonus_terms = this.bonus_terms.value;
    bonus_terms = bonus_terms.trim();
    return bonus_terms ? bonus_terms.split('\n') : [];
  }

  getFormattedBonusTermsTranslation() {
    let translation = this.state.terms_translation;

    for (let key in translation) {
      translation[key] = translation[key].trim().split('\n');
    }

    return translation;
  }

  createBonusHandler() {
    this.props.createDepositBonus({
      ...this.getBonusRules(),
      type: this.state.type.value,
      url_slug: this.url_slug.value,
      currency_type: this.state.currency_type.value,
      name: this.name.value,
      description: this.description.value,
      file: this.state.file,
      expires_at: moment.utc().add(this.expires_at.value, 'days'),
      excluded_countries: this.state.excluded_countries,
      supported_countries: this.state.supported_countries,
      globally_available: this.state.globally_available,
      landing_page_type: this.state.landing_page_type.value,
      inventory_expiry_delay_minutes: this.inventory_expiry_delay_minutes.value,
      activation_expiry_delay_minutes:
        this.activation_expiry_delay_minutes.value,
      banner: this.banner.value,
      name_translation: this.state.name_translation,
      banner_translation: this.state.banner_translation,
      description_translation: this.state.description_translation,
      terms: this.getFormattedBonusTerms(),
      terms_translation: this.getFormattedBonusTermsTranslation(),
      welcome_bonus:
        !!this.state.type.value &&
        this.state.type.value.toLowerCase() === 'welcome_bonus',
    });
  }

  onClickSubmitHandler(event) {
    event.preventDefault();
    this.createBonusHandler();
  }

  renderDepositRules() {
    return (
      <Fragment>
        <h4>Amount (flat and free bet only)</h4>
        <input
          placeholder='Amount to credit (leave blank for deposit bonus)'
          ref={(el) => (this.amount = el)}
        />

        <h4>Maximum (deposit bonus only)</h4>
        <input
          placeholder='Maximum Bonus (ex. 100 for $100 USD)'
          ref={(el) => (this.maximum_bonus = el)}
        />

        <h4>Deposit Ratio (deposit bonus only)</h4>
        <input
          placeholder='Deposit Ratio (ex. 0.5 will give $50 for a $100 Deposit, leave at 1 for non-deposit type)'
          ref={(el) => (this.deposit_ratio = el)}
        />

        <hr />

        <h4>Wager Ratio</h4>
        <input
          placeholder='Wager Ratio (35 means 35 * the amount credited must be wagered before its transferred)'
          ref={(el) => (this.wager_ratio = el)}
        />
      </Fragment>
    );
  }

  renderRewardRules() {
    const { rewards = [] } = this.props;
    return (
      <Fragment>
        <h4>Total Deposited Required</h4>
        <input
          placeholder='Total Deposited Required (ex. 100 for $100 USD)'
          ref={(el) => (this.total_deposited_required = el)}
        />
        <h4>Reward</h4>
        <Select
          name='type'
          value={this.state.reward}
          onChange={(option) => this.setState({ reward: option })}
          options={rewards.map((reward) => ({
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '30px' }} src={reward.image_url} />
                (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
              </div>
            ),
            value: reward.id,
          }))}
        />
      </Fragment>
    );
  }

  renderBonusTypeRules() {
    const mapBonusTypeToRenderer = {
      deposit: this.renderDepositRules,
      deposit_reward: this.renderRewardRules,
      deposit_sports: this.renderDepositRules,
      free_bet: this.renderDepositRules,
      welcome_bonus: this.renderDepositRules,
    };

    return mapBonusTypeToRenderer[this.state.type.value]();
  }

  render() {
    let { landing_page_types = [] } = this.props;

    return (
      <div>
        <h1 style={{ fontSize: '14px' }}>
          Remember if you want to create a bonus and show in all languages you
          must to create with the translation for en, es and pt or pt-br
        </h1>
        <div className='input-group'>
          <h4>Create Bonus</h4>

          <br />

          <Select
            name='type'
            value={this.state.type}
            onChange={(option) => this.setState({ type: option })}
            options={[
              { label: 'Deposit Bonus', value: 'deposit' },
              { label: 'Deposit Reward Bonus', value: 'deposit_reward' },
              { label: 'Deposit Bonus for Sports', value: 'deposit_sports' },
              { label: 'Welcome Bonus', value: 'welcome_bonus' },
            ]}
          />

          <input
            placeholder='Name (ex. Worlds 500% Bonus)'
            ref={(el) => (this.name = el)}
          />

          <Translator
            languages={this.props.languages}
            contents={this.state.name_translation}
            onChange={(name_translation) => this.setState({ name_translation })}
            label={'Name Translation'}
          />

          <h4>Landing Page type</h4>

          <br />

          <Select
            name='type'
            value={this.state.landing_page_type}
            onChange={(option) => this.setState({ landing_page_type: option })}
            options={landing_page_types.map((type) => {
              return {
                label: type,
                value: type,
              };
            })}
          />

          <h4>Banner (Banner for affiliate page)</h4>
          <input placeholder='Banner' ref={(el) => (this.banner = el)} />

          <Translator
            languages={this.props.languages}
            contents={this.state.banner_translation}
            onChange={(banner_translation) =>
              this.setState({ banner_translation })
            }
            label={'Banner Translation'}
          />

          <h4>Upload Image</h4>
          <input
            type='file'
            onChange={(e) => {
              this.setState({
                file: e.target.files[0],
              });
            }}
          />

          <br />

          <h4>Description</h4>
          <textarea
            placeholder='Description HTML'
            ref={(el) => (this.description = el)}
          />

          <Translator
            languages={this.props.languages}
            contents={this.state.description_translation}
            onChange={(description_translation) =>
              this.setState({ description_translation })
            }
            label={'Description Translation'}
          />

          <h4>URL Slug</h4>
          <input
            placeholder='Ex (weekend-500-eur-bonus will have a landing page at blaze.com/bonuses/weekend-500-bonus/{id}'
            ref={(el) => (this.url_slug = el)}
          />

          <hr />

          {this.renderBonusTypeRules()}

          <h4>Expire Days</h4>
          <input
            placeholder='Expire time in days (ex. 7)'
            ref={(el) => (this.expires_at = el)}
          />

          <h4>
            Inventory Expire Minutes (if left blank, defaults to the bonus
            expire date)
          </h4>
          <input
            placeholder='Expire time in minutes for the inventory object after its created (ex. 1440 for 1 day)'
            ref={(el) => (this.inventory_expiry_delay_minutes = el)}
          />

          <h4>
            Activation Expire Minutes (if left blank, defaults to the bonus
            expire date)
          </h4>
          <input
            placeholder='Expire time in minutes for the activation object after its created (ex. 1440 for 1 day)'
            ref={(el) => (this.activation_expiry_delay_minutes = el)}
          />

          <div className='input-group'>
            <div className='input-row'>
              <label>Globally Available (auto added to inventory)</label>
              <input
                type='checkbox'
                checked={this.state.globally_available}
                onChange={() =>
                  this.setState({
                    globally_available: !this.state.globally_available,
                  })
                }
              />
            </div>
          </div>

          <h4>Bonus Terms</h4>
          <textarea
            style={{ height: '15rem' }}
            placeholder='Bonus Terms'
            ref={(el) => (this.bonus_terms = el)}
          />

          <Translator
            languages={this.props.languages}
            contents={this.state.terms_translation}
            onChange={(terms_translation) =>
              this.setState({ terms_translation })
            }
            label={'Terms Translation'}
          />

          <hr />
          <div className='tags-wrapper'>
            <h5>Supported Countries</h5>
            <div className='draggable-tags'>
              <div className='row' style={{ width: '100%' }}>
                {this.state.supported_countries.map((country) => (
                  <div className='col-xs-2'>
                    <div className='tag'>
                      {country}

                      <i
                        onClick={() => {
                          this.setState({
                            supported_countries:
                              this.state.supported_countries.filter(
                                (c) => c !== country
                              ),
                          });
                        }}
                        className='fas fa-times-circle'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <br />

            <div className='input-group'>
              <Select
                name='type'
                value={this.state.supported_country}
                onChange={(option) =>
                  this.setState({ supported_country: option })
                }
                options={Object.keys(this.countries.getNameList())
                  .filter((c) => {
                    return (
                      this.state.excluded_countries.indexOf(
                        this.countries.getCode(c)
                      ) === -1
                    );
                  })
                  .map((country, index) => {
                    return {
                      label: country,
                      value: this.countries.getCode(country),
                    };
                  })}
              />

              <button
                onClick={() =>
                  this.setState({
                    supported_countries: this.state.supported_countries.concat([
                      this.state.supported_country.value,
                    ]),
                  })
                }
              >
                Add Country
              </button>
            </div>
          </div>
          <hr />

          <div className='tags-wrapper'>
            <h5>Excluded Countries</h5>
            <div className='draggable-tags'>
              <div className='row' style={{ width: '100%' }}>
                {this.state.excluded_countries.map((country) => (
                  <div className='col-xs-2'>
                    <div className='tag'>
                      {country}

                      <i
                        onClick={() => {
                          this.setState({
                            excluded_countries:
                              this.state.excluded_countries.filter(
                                (c) => c !== country
                              ),
                          });
                        }}
                        className='fas fa-times-circle'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <br />
            <div className='input-group'>
              <Select
                name='type'
                value={this.state.excluded_country}
                onChange={(option) =>
                  this.setState({ excluded_country: option })
                }
                options={Object.keys(this.countries.getNameList())
                  .filter((c) => {
                    return (
                      this.state.supported_countries.indexOf(
                        this.countries.getCode(c)
                      ) === -1
                    );
                  })
                  .map((country, index) => {
                    return {
                      label: country,
                      value: this.countries.getCode(country),
                    };
                  })}
              />

              <button
                onClick={() =>
                  this.setState({
                    excluded_countries: this.state.excluded_countries.concat([
                      this.state.excluded_country.value,
                    ]),
                  })
                }
              >
                Add Country
              </button>
            </div>
          </div>

          <button onClick={this.onClickSubmitHandler}>Submit</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.bonuses.search_results,
  rewards: state.rewards.items.records,
  currencies: state.app.currencies,
  landing_page_types: [
    'roulette',
    'gift',
    'guy_hands_down',
    'crash',
    'guy_hands_up',
    'trophy',
    'casino',
  ],
  languages: state.app.languages,
});

const mapDispatchToProps = (dispatch) => ({
  update: (page) => dispatch(updateDepositBonuses(page)),
  createDepositBonus: (props) => dispatch(createDepositBonus(props)),
  updateRewards: (props) => dispatch(updateRewards(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bonuses);
