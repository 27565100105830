import { downloadJSONAsCSV } from "utils";

/**
 * @description Button to Download json as CSV
 * @param {object} options
 * @param {React.CSSProperties} style
 * @param {Array<object>} records
 * @returns
 */
export function DownloadCSVButton({ style, records }) {
  return (
    <button style={style} onClick={() => downloadJSONAsCSV(records)}>
      Export to CSV
    </button>
  );
}
