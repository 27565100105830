import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { Translator } from "../../shared/translator";
import "./style.css";

const typesLanding = [
  { label: "Landing-1 with timer and reward", value: "landing1" },
  { label: "Landing-2 with form and reward", value: "landing2" },
  { label: "Landing-3 with form and timer", value: "landing3" },
  { label: "Landing-4 width large text", value: "landing4" },
];
const typeCategories = [
  "crash",
  "sports",
  "casino",
  "double",
  "mines",
  "tiger",
  "neymar",
  "aviator",
  "fortunerabbit",
  "fortuneox",
  "neymarCasino",
  "fortuneDouble",
  "fortunedragon",
  "fruitSlice",
  "gatesOfOlympus",
  "sweetBonanza",
  "bigBassSplash",
  "bigBassBonanza",
  "sugarRush",
  "gemsBonanza",
  "releaseTheKraken",
  "biggerBassBonanza",
  "wildWildRiches",
  "megaRoulette",
  "super7s",
  "floatingDragon",
  "spaceman",
  "juicyFruits",
  "sweetBonanzaXmas",
  "diamondStrike",
  "fruitParty",
  "biggerBassBlizzardChristmasCatch",
  "madameDestinyMegaways",
  "starlightPrincess",
  "theDogHouseMegaways",
  "fruitParty2",
  "buffaloKingMegaways",
  "mustangGold",
  "crazyTime",
  "roletaRilampago",
  "speedBaccaratQ",
  "ruletaEnVivo",
  "roletaAoVivo",
  "lightningRoulette",
  "bacBo",
  "xxxtremeLightningRoulette",
  "immersiveRoulette",
  "dragonTiger",
  "evolutionCrazyCoinflip",
  "monopolyBigBaller",
  "footballStudio",
  "monopoly",
  "lightningDice",
  "bingoGames",
];

const CreateLink = (props) => {
  const [mode, setMode] = useState("default");
  const [bonus, setBonus] = useState({});
  const [bonuses, setBonuses] = useState([]);
  const [search, setSearch] = useState("");
  const [typeLayout, setTypeLayout] = useState("");
  const [category, setCategory] = useState("");
  const [subTitleTranslation, setSubTitleTranslation] = useState({});
  const [titleTranslation, setTitleTranslation] = useState({});
  const [bannerTranslation, setBannerTranslation] = useState({});

  const [valueInputs, setValueInputs] = useState({
    title: "",
    banner: "",
    subTitle: "",
    code: " ",
    checked: false,
  });

  useEffect(() => {
    window.api
      .get(`/admin/bonuses?query=${search}`)
      .then(({ records }) => setBonuses(records));
  }, [search]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValueInputs((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const handleClick = () => {
    const { banner, code, subTitle, title, checked } = valueInputs;

    const data = {
      code: code,
      bonus_id: bonus.value,
      type: `${typeLayout}-${category}`,
      title: title,
      bonus_title: subTitle,
      banner: banner,
      bonus_translation: subTitleTranslation,
      title_translation: titleTranslation,
      banner_translation: bannerTranslation,
      fill_code: checked,
    };

    window.api
      .post("/admin/affiliate_links", data)
      .then(() => toast.success(`Created`));
  };

  return (
    <div className="affiliate-link-container">
      <div className="input-group">
        <div>
          <h4>Create Link</h4>
          <div className="wrap-switch-affiliate">
            <p>Bonus default</p>
            <Switch
              checked={mode !== "default"}
              onChange={(e) => {
                setBonus({});
                setMode(e.target.checked ? "custom" : "default");
              }}
              size="medium"
            />
            <p>Custom default</p>
          </div>
        </div>
        <div className="affiliate-link-flex">
          <div>
            <h4>Code (case insensitive , min 3 characters)</h4>
            <input
              placeholder="Code"
              name="code"
              value={valueInputs.code}
              onChange={handleChangeInput}
            />
          </div>

          {mode === "default" && (
            <div>
              <h4>Add Bonus to Inventory (optional)</h4>
              <Select
                name="type"
                value={bonus}
                inputValue={search}
                onInputChange={(v) => setSearch(v)}
                onChange={(option) => setBonus(option)}
                options={bonuses.map((bonus) => {
                  return {
                    label: `[${bonus.id}] ${bonus.name}`,
                    value: bonus.id,
                  };
                })}
              />
            </div>
          )}
        </div>
        <div className="affiliate-link-flex">
          <div>
            <h4>Choose layout landing</h4>
            <Select
              name="landing_page"
              value={typeLayout}
              onChange={({ value }) => setTypeLayout(value)}
              options={typesLanding.map(({ label, value }) => {
                return {
                  label,
                  value,
                };
              })}
            />
          </div>
          <div>
            <h4>Choose category</h4>
            <Select
              name="category"
              value={category}
              onChange={({ value }) => setCategory(value)}
              options={typeCategories.map((c) => {
                return {
                  label: c,
                  value: c,
                };
              })}
            />
          </div>
        </div>

        {mode === "custom" && (
          <div className="affiliate-translate">
            <div className="container-input-affiliate-link ">
              <h4>Title (Header for affiliate page)</h4>
              <input
                placeholder="Title"
                name="title"
                onChange={handleChangeInput}
              />
              <Translator
                languages={props.languages}
                contents={titleTranslation}
                onChange={(title_translation) =>
                  setTitleTranslation(title_translation)
                }
                label={"Title"}
              />
            </div>
            <div className="container-input-affiliate-link ">
              <h4>SubTitle affiliate page </h4>
              <input
                placeholder="Title"
                name="subTitle"
                onChange={handleChangeInput}
              />
              <Translator
                languages={props.languages}
                contents={subTitleTranslation}
                onChange={(bonus_translation) =>
                  setSubTitleTranslation(bonus_translation)
                }
                label={"SubTitle"}
              />
            </div>
            <div className="container-input-affiliate-link ">
              <h4>Banner (Banner for affiliate page)</h4>
              <input
                placeholder="Banner"
                name="banner"
                onChange={handleChangeInput}
              />
              <Translator
                languages={props.languages}
                contents={bannerTranslation}
                onChange={(banner_translation) =>
                  setBannerTranslation(banner_translation)
                }
                label={"Banner"}
              />
            </div>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <h4 style={{ marginRight: "20px" }}>Complete Autocode</h4>
          <input
            style={{ marginTop: "30px" }}
            type="checkbox"
            name="checked"
            value={valueInputs.code}
            onChange={() =>
              setValueInputs((prev) => ({
                ...prev,
                checked: !valueInputs.checked,
              }))
            }
          />
        </div>
      </div>

      <button onClick={handleClick}>Submit</button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: state.app.languages,
});

export default connect(mapStateToProps, {})(CreateLink);
