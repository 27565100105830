import React, { useEffect, useState } from "react";
import moment from "moment";

import { Link, useParams } from "react-router-dom";
import { PermissionsFragment } from "../app/permissions-fragment";
import { OffsetPagination } from "../shared/OffsetPagination";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Calendar from "../shared/Calendar";
import Select from "react-select";
import { useSelector } from "react-redux";
import { format } from "../shared/utils/dates";
import { downloadFile } from "../shared/utils/files";
import ExportDataWithDateRangeModal from "../shared/export-data-with-date-range-modal";
import ExportDataButton from "../shared/export-data-button";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import styles from "./transactions.module.scss";

const orderings = [
  { label: "Date - Newest first", value: "created_at_desc" },
  { label: "Date - Oldest first", value: "created_at_asc" },
  { label: "Winnings - Highest to lowest", value: "amount_desc" },
  { label: "Winnings - Lowest to highest", value: "amount_asc" },
  { label: "Profit - Highest to lowest", value: "profit_desc" },
  { label: "Profit - Lowest to highest", value: "profit_asc" },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let { id: userId } = useParams();
  const [page, setPage] = useState(1);
  const [gameNameQuery, setGameNameQuery] = useState("");
  const [gameId, setGameId] = useState(null);
  const [providerExternalId, setProviderExternalId] = useState(null);
  const [walletId, setWalletId] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(45, "days").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [order, setOrder] = useState(orderings[0].value);
  const wallets = useSelector((store) => store.users.user.wallets);
  const [openExportDataModal, setOpenExportDataModal] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);

  useEffect(() => {
    setWalletId(wallets.find((w) => w.primary).id);
  }, [wallets]);

  useEffect(() => {
    setPage(1);
  }, [gameId, startDate, endDate, order, providerExternalId, walletId]);

  const {
    data: { records, total_pages },
    isLoading,
    refetch,
  } = useQuery(
    [
      "game-history",
      page,
      gameId,
      startDate,
      endDate,
      order,
      providerExternalId,
      walletId,
    ],
    async () => {
      if (moment(endDate).diff(moment(startDate), "days") > 60) {
        toast.error(
          "The difference between end Date and Start date must be at most 60 days"
        );
        return { records: [], total_pages: 0 };
      }
      const data = await window.api.get(
        `/admin/users/${userId}/game_provider_rounds`,
        {
          params: {
            page,
            startDate,
            endDate,
            gameId,
            walletId,
            idSearch: providerExternalId,
            order,
          },
        }
      );
      return data;
    },
    {
      keepPreviousData: true,
      initialData: {
        records: [],
        total_pages: 1,
      },
    }
  );

  const downloadReport = (exportTo, startDate, endDate) => {
    setDownloadingReport(true);
    require("axios")({
      url: `/api/admin/users/${userId}/game_provider_rounds/export/${exportTo}`,
      method: "GET",
      responseType: "blob", // important,
      params: {
        startDate,
        endDate,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "X-RateLimit-Bypass": "1",
      },
    })
      .then((response) => {
        const extension = exportTo === "xls" ? "xlsx" : exportTo;
        downloadFile(response.data, extension);
      })
      .finally(() => setDownloadingReport(false));
  };

  return (
    <div style={{ position: "relative" }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait ...</h2>
          <div className="loader" />
        </div>
      )}
      <div className={styles.filtersQuery} style={{ display: "flex" }}>
        <div className={styles.filterQuery}>
          <h5>Search by Time</h5>
          <Calendar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Search by Provider External Id</h5>
          <input
            type="text"
            id="id"
            name="id"
            onChange={(e) => {
              setProviderExternalId(e.target.value);
            }}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Search by Wallet Currency</h5>
          <Select
            clearable={false}
            name="type"
            value={walletId}
            onChange={(option) => setWalletId(option.value)}
            options={wallets.map((wallet) => ({
              label: wallet.deposit_currency_type,
              value: wallet.id,
            }))}
          />
        </div>
      </div>
      <div className={styles.filterQuery}>
        <h5>Order</h5>
        <Select
          clearable={false}
          name="type"
          value={order}
          onChange={(option) => setOrder(option.value)}
          options={orderings}
        />
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>ID</Th>
            <Th>Game</Th>
            <Th>Status</Th>
            <Th>Wager</Th>
            <Th>Winnings</Th>
            <Th>Profit</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>

        <Tbody>
          {records.map((r, i) => {
            return (
              <Tr key={i}>
                <Td>{format.withMinutes(r.created_at)}</Td>
                <Td>
                  <Link to={`/casino/game-provider-rounds/${r.id}`}>
                    {r.id}
                  </Link>
                </Td>
                <Td>{r.game.name}</Td>
                <Td>
                  {r.bets} bet{r.bets > 1 ? "s" : ""} {r.wins} win
                  {r.wins > 1 ? "s" : ""}{" "}
                  {+r.rollbacks > 0 ? `${r.rollbacks} refunds` : ""}
                </Td>
                <Td>
                  {r.amount} {r.currency_type}
                </Td>
                <Td className="good">
                  {r.win_amount} {r.currency_type}
                </Td>
                <Td className={r.profit >= 0 ? "good" : "bad"}>
                  {r.profit} {r.currency_type}
                </Td>

                <Td>
                  <PermissionsFragment feature="REFUND_ROUND">
                    {+r.rollbacks === 0 && (
                      <button
                        onClick={() => {
                          window.api
                            .post(
                              `/admin/users/${userId}/game_provider_rounds/${r.id}/refund`
                            )
                            .then(() => {
                              toast.success("refunded.");

                              return refetch();
                            });
                        }}
                      >
                        Refund
                      </button>
                    )}
                  </PermissionsFragment>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <OffsetPagination
        page={page}
        setPage={setPage}
        totalPages={total_pages}
      />
      <div className={styles.buttons}>
        <ExportDataButton onClick={() => setOpenExportDataModal(true)} />
        <ExportDataWithDateRangeModal
          isOpen={openExportDataModal}
          onClose={() => setOpenExportDataModal(false)}
          downloadingReport={downloadingReport}
          startDate={startDate}
          endDate={endDate}
          onExport={(params) => {
            downloadReport(params.format, params.startDate, params.endDate);
          }}
        ></ExportDataWithDateRangeModal>
      </div>
    </div>
  );
};
