import React from "react";
import { NavLink } from "react-router-dom";

import {
  GroupPermissionsFragment,
  permissionGroups,
} from "app/permissions-fragment";

export const SettingsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.SETTINGS}>
    <NavLink to="/system/settings">Settings</NavLink>
  </GroupPermissionsFragment>
);

export const GamingServiceSettingsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.GAMING_SERVICE_SETTINGS}>
    <NavLink to="/system/gaming-service-settings">Gaming Service Settings</NavLink>
  </GroupPermissionsFragment>
);
export const WeeklyCashbackManagerLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.SETTINGS}>
    <NavLink to="/system/weekly">Weekly Cashback Manager</NavLink>
  </GroupPermissionsFragment>
);

export const WaitListManagementLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.WAITLIST_MANAGEMENT}>
    <NavLink to="/system/wait-list-management"> Wait List management </NavLink>
  </GroupPermissionsFragment>
);

export const GroupsManagementLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.GROUPS}>
    <NavLink to="/system/groups">Groups</NavLink>
  </GroupPermissionsFragment>
);

export const RaffleManagerLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.RAFFLE_MANAGER}>
    <NavLink to="/system/raffle">Raffle Manager</NavLink>
  </GroupPermissionsFragment>
);

export const AnnouncementsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.SETTINGS}>
    <NavLink to="/system/announcements">Announcements</NavLink>
  </GroupPermissionsFragment>
);

export const RouteMappingsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.SETTINGS}>
    <NavLink to="/system/routemappings">Route Mappings</NavLink>
  </GroupPermissionsFragment>
);

export const StaffManagerLink = () => (
  <GroupPermissionsFragment group={permissionGroups.STAFF_MANAGER.REQUESTS}>
    <NavLink to="/system/staff-manager">Staff Manager</NavLink>
  </GroupPermissionsFragment>
);

export const AdminAnalyticsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.CUSTOM_ANALYTICS}>
    <NavLink to="/system/admin-analytics">Admin Analytics</NavLink>
  </GroupPermissionsFragment>
);

export const TicketIALink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM.SETTINGS}>
    <NavLink to="/system/ticketia">Ticket JIRA</NavLink>
  </GroupPermissionsFragment>
);
