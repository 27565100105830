import { Configuration, OpenAIApi } from "openai";
import { useEffect, useState } from 'react';
import './TicketIA.css';
import apiJira from './apiJira';

const configuration = new Configuration({
	apiKey: process.env.OPEN_AI_API_KEY,
});
const openai = new OpenAIApi(configuration);

const PROJECT_IDS_WITH_ADMIN_PERMISSION = [
	'10030',
]

const TicketIA = () => {
	const [content, setContent] = useState('');
	const [projects, setProjects] = useState([]);
	const [users, setUsers] = useState([]);
	const [statusProject, setStatusProject] = useState([]);

	const [valuesSelected, setValuesSelected] = useState({
		project: null,
		reporter: null,
		assignee: null,
	});


	const [statusForIssue, setStatusForIssue] = useState([]);
	const [loadingGPT3, setLoadingGPT3] = useState(false);

	const [response, setResponse] = useState('')

	const getDescriptionGPT3 = async (e) => {
		// almost more than 100 characters for the description

		if (content.length < 100) {
			alert('Please write more than 100 characters');
			return;
		}
		setLoadingGPT3(true);
		try {
			const response = await openai.createChatCompletion({
				model: "gpt-3.5-turbo",
				messages: [
					{
						role: "assistant",
						content: `Write a jira story for this requirement: ${content} , please use emojis to make the formatting more fancy.`,
					},
				],
			});
			setLoadingGPT3(false);
			setResponse(response.data?.choices[0]?.message?.content || '');
		} catch (e) {
			setLoadingGPT3(false);
		}
		setLoadingGPT3(false);
	}

	useEffect(() => {
		const fetch = async () => {
			const respProject = await apiJira.getProjects();
			const respUser = await apiJira.getUsers();
			setProjects(respProject?.values)
			setUsers(respUser)
		}
		fetch();
	}, [])


	const handleSelectProject = async (e) => {
		const project = projects.find(p => p.id === e.target.value);
		setValuesSelected({
			...valuesSelected,
			project
		})
		const respStatus = await apiJira.getStatusesForProject(e.target.value);
		setStatusProject(respStatus)
	}

	const handleStatusChange = async (e) => {
		const status = statusProject.find(s => s.id === e.target.value);
		setStatusForIssue(status.statuses);
	}

	const handleSubmit = async (e) => {
		// create form data
		e.preventDefault();
		const formData = new FormData(e.target);
		const data = Object.fromEntries(formData.entries());

		// check if all fields are filled
		if (!data.project || !data.issue || !data.assignee || !data.summary || !data.description) {
			alert('Please fill all fields');
			return;
		}
		const body = {
			"fields": {
				"project": {
					"id": data.project
				},
				"summary": data.summary,
				"description": {
					"type": "doc",
					"version": 1,
					"content": [
						{
							"type": "paragraph",
							"content": [
								{
									"type": "text",
									"text": response
								}
							]
						}
					]
				},
				"issuetype": {
					"id": data.issue
				},
				"assignee": {
					"id": data.assignee
				}
			}
		}
		if (PROJECT_IDS_WITH_ADMIN_PERMISSION.includes(valuesSelected?.project?.id)) {
			body.fields.reporter = {
				"id": data.reporter
			}
		}
		const resp = await apiJira.createIssue(body);
		const { error } = resp
		if (error) {
			alert('Something went wrong, please try again');
			return;
		}

		alert('Ticket created successfully');
	}

	const handleChangeTextarea = (e) => {
		setContent(e.target.value);
	}

	const handleSelectUser = (e, type) => {
		const user = users.find(u => u.accountId === e.target.value);
		if (e.target.name === "reporter") {
			setValuesSelected({
				...valuesSelected,
				reporter: user
			})
		} else {
			setValuesSelected({
				...valuesSelected,
				assignee: user
			})
		}
	}


	return (
		<div className="ticketIA">
			<div className="ticketIA__container">
				<h2>📝 Create ticket with IA 🤖</h2>
				<div className='ticketIA__container-textarea'>
					<textarea placeholder="Write your requirement" className='ticketIA__textarea' value={content} onChange={(e) => setContent(e.target.value)}></textarea>
					<button type="button" onClick={getDescriptionGPT3}>Get preview ticket</button>
					{loadingGPT3 && <div className="ticketIA__loading">Loading...</div>}
				</div>
				<form onSubmit={handleSubmit} className='ticketIA__form' >
					{valuesSelected?.project && <ShowSelected img={valuesSelected.project?.avatarUrls["48x48"]} name={valuesSelected.project?.name} />}
					<select name="project" onChange={handleSelectProject} className='ticketIA__select' >
						<option value='' disabled selected>Project</option>
						{projects.map((project) => (
							<option key={project.key} value={project.id}>{project.name}</option>
						))}
					</select>
					<select name="issue" disabled={!statusProject.length} onChange={handleStatusChange} className='ticketIA__select' >
						<option value='' disabled selected>Issue type</option>
						{statusProject.map((status) => (
							<option key={status.id} value={status.id}>{status.name}</option>
						))}
					</select>
					{
						PROJECT_IDS_WITH_ADMIN_PERMISSION.includes(valuesSelected?.project?.id) &&
						(
							<>
								<label>
									Reporter (you)
								</label>
								<select onChange={handleSelectUser} name="reporter" className='ticketIA__select' >
									<option value='' disabled selected>Account to assign </option>
									{users.filter(u => u.accountType === "atlassian").map((user) => (
										<option key={user.accountId} value={user.accountId}>
											{user.displayName}
										</option>
									))}
								</select>
							</>
						)
					}
					<label>
						Assign to
					</label>
					<select onChange={handleSelectUser} name="assignee" className='ticketIA__select' >
						<option value='' disabled selected>Account to assign </option>
						{users.filter(u => u.accountType === "atlassian").map((user) => (
							<option key={user.accountId} value={user.accountId}>
								{user.displayName}
							</option>
						))}
					</select>
					<input name="summary" placeholder='Summary' type='text'></input>
					<textarea onChange={handleChangeTextarea} value={response} name="description" className="ticketIA__textarea ticketIA__textarea--preview" />
					<button type="submit" onClick={() => { }}>Create ticket on Jira</button>
				</form>
			</div>
		</div>

	)
};


const ShowSelected = ({ name, img }) => (
	<div>
		{
			<div style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				marginBottom: '10px',
				gap: '10px',
				backgroundColor: 'var(--red)'
			}}>
				<p>{name}</p>
				<img style={{
					borderRadius: '50%',
				}} width={"48px"} height={"48px"} src={img} alt="" />
			</div>
		}
	</div>
)

export default TicketIA;
