import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { editLabel, editRoles } from "./actions";
import Select from "react-select";
import {
  permissionsConstants,
  PermissionsFragment,
} from "../app/permissions-fragment";
import permissions from "../app/permissions.json";
import { isAdmin } from "../app/api";
import styles from "./roles.module.scss";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

class UserRoles extends Component {
  constructor(props) {
    super();

    this.state = {
      page: 1,
    };
  }

  getAvailableRoles() {
    let uniqRoles = [];
    const missingRolesInPermissions = [
      "influencer",
      "vip",
      "beta_tester",
      "sports_tester",
      "influencer_not_allowed_bet",
      "withdrawal_only_state",
      "fortune_games_enabled",
    ];

    uniqRoles = missingRolesInPermissions;

    const rolesInPermissions = Object.values(permissions).flat();
    rolesInPermissions.forEach((p) => {
      if (!uniqRoles.includes(p)) {
        uniqRoles.push(p);
      }
    });

    const canSetAnyRole = isAdmin();

    if (!canSetAnyRole) {
      if (window.roles.includes("role_changer")) {
        uniqRoles = [
          "influencer",
          "support",
          "mod",
          "influencer_not_allowed_bet",
          "withdrawal_only_state",
        ];
      } else if (window.roles.includes('support_manager')) {
        uniqRoles = [
          "withdrawal_only_state",
        ];
      }
    }

    const availableRoles = uniqRoles.filter((r) => {
      const userDontHaveTheRole = (this.props.roles || []).indexOf(r) === -1;

      return userDontHaveTheRole;
    });

    return availableRoles;
  }

  render() {
    const user_id = this.props.match.params.id;
    const closed = (this.props.roles || []).includes("closed");

    const availableRoles = this.getAvailableRoles().sort();

    return (
      <Fragment>
        <PermissionsFragment feature={permissionsConstants.USER_ADD_ROLE}>
          <div className={styles.form}>
            <h4>Edit Roles</h4>
            <div className={styles.editRoles}>
              <Select
                name="type"
                value={this.state.role}
                onChange={(option) => this.setState({ role: option })}
                options={availableRoles.map((role, index) => {
                  return {
                    label: role,
                    value: index,
                  };
                })}
              />

              <button
                onClick={() =>
                  this.props.editRoles(
                    user_id,
                    availableRoles[this.state.role.value]
                  )
                }
              >
                Add
              </button>
            </div>
          </div>
        </PermissionsFragment>

        <div className={styles.form}>
          <h4>Current Roles</h4>

          <Table className={styles.currentRoles}>
            <Thead>
              <Tr>
                <Th>Role</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {(this.props.roles || []).map((role, index) => {
                return (
                  <Tr key={role}>
                    <Td>{role}</Td>
                    <Td>
                      <PermissionsFragment
                        feature={permissionsConstants.USER_REMOVE_ROLE}
                      >
                        {role !== "closed" && (
                          <button
                            className={styles.actionButton}
                            onClick={() =>
                              this.props.editRoles(user_id, role, true)
                            }
                          >
                            Remove
                          </button>
                        )}
                      </PermissionsFragment>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>

        <PermissionsFragment feature={permissionsConstants.USER_UPDATE_LABEL}>
          <div className={styles.form}>
            <h4>Edit Label</h4>
            <div className={styles.editRoles}>
              <Select
                name="type"
                value={this.props.label}
                onChange={(option) =>
                  this.props.editLabel(user_id, option.value)
                }
                options={this.props.user_labels
                  .filter((r) => {
                    return r !== this.state.label;
                  })
                  .map((label) => {
                    return {
                      label: label,
                      value: label,
                    };
                  })}
              />
            </div>
          </div>
        </PermissionsFragment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.users.user,
  user_labels: [
    "mod",
    "support",
    "influencer",
    "vip",
    "affiliate_manager",
    "master_affiliate_manager",
  ],
});

const mapDispatchToProps = (dispatch) => ({
  editLabel: (user_id, label) => dispatch(editLabel(user_id, label)),
  editRoles: (user_id, rank, remove) =>
    dispatch(editRoles(user_id, rank, remove)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
