export const mockedObjectNew = {
  challenges: [
    {
      id: 1,
      starts_at: "2023-05-16T13:10:00.000Z",
      game_id: null,
      name_preset: "€1000 Diamond Rank Reward",
      reward_id: "53",
      reward_manifest_id: null,
      min_multiplier: "23.00",
      eur_min_wagered: "13.0000",
      currency_rates: [
        {
          currency: "BRL",
          rate: 1,
        },
        {
          currency: "BTC",
          rate: 0.000019967418778886552,
        },
        {
          currency: "CAD",
          rate: 1,
        },
        {
          currency: "ETH",
          rate: 0.0002949126924819308,
        },
        {
          currency: "EUR",
          rate: 1,
        },
        {
          currency: "LTC",
          rate: 0.007478140207860439,
        },
        {
          currency: "TRX",
          rate: 13.032070120678268,
        },
        {
          currency: "GBP",
          rate: 1,
        },
      ],
      status: "ongoing",
      signup_code: null,
      is_featured: true,
      winning_user_id: null,
      created_at: "2023-05-16T13:08:56.671Z",
      expires_at: "2023-05-17T13:12:00.000Z",
      updated_at: "2023-05-16T13:08:56.671Z",
      deleted_at: null,
      reward_name: "€1000 Diamond Rank Reward",
      reward_type: "free_real_money",
      prize: "1000.0000",
      slug: null,
      image_url: null,
      game_name: null,
      full_count: "2",
    },
    {
      id: 2,
      starts_at: "2023-05-16T13:15:00.000Z",
      game_id: "8000",
      name_preset: "€1000 Diamond Rank Reward",
      reward_id: "52",
      reward_manifest_id: null,
      min_multiplier: "3.00",
      eur_min_wagered: "1.0000",
      currency_rates: [
        {
          currency: "BRL",
          rate: 1,
        },
        {
          currency: "BTC",
          rate: 0.000019967418778886552,
        },
        {
          currency: "CAD",
          rate: 1,
        },
        {
          currency: "ETH",
          rate: 0.0002949126924819308,
        },
        {
          currency: "EUR",
          rate: 1,
        },
        {
          currency: "LTC",
          rate: 0.007478140207860439,
        },
        {
          currency: "TRX",
          rate: 13.032070120678268,
        },
        {
          currency: "GBP",
          rate: 1,
        },
      ],
      status: "created",
      signup_code: null,
      is_featured: false,
      winning_user_id: null,
      created_at: "2023-05-16T13:14:18.144Z",
      expires_at: "2023-05-26T13:14:00.000Z",
      updated_at: "2023-05-16T13:14:18.144Z",
      deleted_at: null,
      reward_name: "€250 Platinum Rank Reward",
      reward_type: "free_real_money",
      prize: "250.0000",
      slug: "mines",
      image_url: "/images/casino/mines.svg",
      game_name: "Mines",
      full_count: "2",
    },
  ],
  total_count: 2,
  total_pages: 1,
};
