import { createSelector } from "reselect";

const currencyRatesSelector = state => state.rewards.currency_rates;

export const rewardsCurrencyRatesSelector = createSelector(
  [
    currencyRatesSelector,
  ],
  (
    currencyRates,
  ) => ({
    currencyRates,
  })
);
