import React from "react";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { format } from "../../shared/utils/dates";

export const WithdrawalMutuals = ({ withdrawal }) => (
  <section className="mutuals">
    <div className="row">
      <div className="col-md-4">
        <h2>Mutual IPs</h2>
        {withdrawal.mutual_ips.map(f => {

          return (
            <div className={'fingerprints-entry'} key={f.id}>
              <Link to={`/users/${f.user_id}`}>
                <span>
                  <img src={f.avatar_url} height={20} />
                  <span style={{ color: f.has_bonus_transfer ? 'red' : '' }}>{f.username} - {f.full_name} ({f.country})</span>
                </span>
              </Link>

              <ul>
                {f.colliding_ips.map(ip => <li>{ip}</li>)}
              </ul>
            </div>
          )

        })}

      </div>
      <div className="col-md-4">

        <h2>Mutual Payment Accounts</h2>

        {withdrawal.mutual_payment_accounts.map((f, index) => {
          return (
            <div className={'fingerprints-entry'} key={index}>
              <Link to={`/users/${f.user_id}`}>
                <span>
                  <img src={f.avatar_url} height={20} />
                  <span style={{ color: f.has_bonus_transfer ? 'red' : '' }}>{f.username} - {f.full_name} ({f.country})</span>
                </span>
              </Link>

              <ul>
                {f.colliding_accounts.map(account => <li>{account}</li>)}
              </ul>
            </div>
          )
        })}

      </div>
      <div className="col-md-4">
        <h2>Mutual Tax IDs</h2>
        {withdrawal.mutual_tax_ids.map(f => {

          return (
            <div className={'fingerprints-entry'} key={f.id}>
              <Link to={`/users/${f.user_id}`}>
                <span>
                  <img src={f.avatar_url} height={20} />
                  <span style={{ color: f.has_bonus_transfer ? 'red' : '' }}>{f.username} - {f.full_name} ({f.country})</span>

                </span>
              </Link>

              <ul>
                {f.colliding_accounts.map(account => <li>{account}</li>)}
              </ul>
            </div>
          )

        })}
      </div>

      <div className="col-xs-6" style={{ borderTop: "3px solid #222" }}>

        <h2>Flagged Bonus Transactions</h2>

        <h4>Total Amount transferred to real: {(withdrawal.bonus_into_real_transactions || []).reduce((acc, f) => acc + +f.amount, 0).toFixed(4)}</h4>

        <Table >
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>Type</Th>
              <Th>Wallet</Th>
              <Th>Amount</Th>
              <Th>Closing Balance</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              (withdrawal.bonus_into_real_transactions || []).map((r, i) => {
                return (
                  <Tr key={i}>
                    <Td>{format.withMinutes(r.created_at)}</Td>
                    <Td>{r.id}</Td>
                    <Td>{r.type}</Td>
                    <Td>{r.currency_type}</Td>
                    <Td>{r.amount}</Td>
                    <Td>{r.closing_balance}</Td>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>
      </div>
    </div>
  </section>
)