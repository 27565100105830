import React from "react";
import Select from "react-select";
import permissionGroups from "../../app/permission-groups";
import { GroupPermissionsFragment } from "../../app/permissions-fragment";

const refundable_types = [
  { label: "Skrill", value: "skrill" },
  { label: "Bank (IBAN)", value: "bank_iban" },
  { label: "Neteller", value: "neteller" },
  { label: "VISA", value: "visa" },
  { label: "Debit / Creditcard", value: "credit_card" },
  { label: "Transferência Bancária", value: "pt_bank_transfer" },
  { label: "Transferencia Bancaria", value: "es_bank_transfer" },
];

export class WithdrawalRefundControls extends React.Component {
  state = {
    correct_method: {},
    incorrect_method: {},
    documents_required: {},
    refund_type: "",
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { loading, edit } = this.props;
    return (
      <GroupPermissionsFragment group={permissionGroups.USERS.UPDATE_BALANCES}>
        <div className="refund">
          <Select
            name="type"
            value={this.state.refund_type}
            onChange={(option) =>
              this.setState({ refund_type: option ? option.value : null })
            }
            options={[
              { label: "Incorrect Method", value: "incorrect_method" },
              { label: "Incorrect Documents", value: "incorrect_documents" },
              { label: "Other (add note)", value: "custom_message" },
            ]}
          />

          {this.state.refund_type === "incorrect_method" && (
            <div>
              <h5>Correct Method Type</h5>
              <Select
                name="type"
                value={this.state.correct_method}
                onChange={(option) => this.setState({ correct_method: option })}
                options={refundable_types}
              />
              <br />
              <h5>Incorrect Method Type</h5>
              <Select
                name="type"
                value={this.state.incorrect_method}
                onChange={(option) =>
                  this.setState({ incorrect_method: option })
                }
                options={refundable_types}
              />
            </div>
          )}
          {this.state.refund_type === "incorrect_documents" && (
            <div>
              <h5>Documents Required</h5>
              <Select
                name="type"
                value={this.state.documents_required}
                onChange={(option) =>
                  this.setState({ documents_required: option })
                }
                options={[
                  { label: "ID/Passport", value: "proof_of_identification" },
                  { label: "Proof of Address", value: "proof_of_address" },
                  { label: "ID/Passport + Proof of Address", value: "all" },
                ]}
              />
            </div>
          )}

          <br />
          <button
            className="small"
            disabled={loading}
            onClick={() => {
              let note;
              if (this.state.refund_type === "custom_message") {
                note = window.prompt(
                  "Enter a note for why this withdrawal was force refunded (user visible):"
                );
              }

              edit({
                status: "refunded",
                note: note,
                refund_type: this.state.refund_type,
                correct_method: this.state.correct_method.label,
                incorrect_method: this.state.incorrect_method.label,
                documents_required: this.state.documents_required.label,
              });
            }}
          >
            {loading ? "Loading..." : "Refund"}
          </button>
          <button
            className="small"
            disabled={loading}
            onClick={async () => {
              const { withdrawal } = this.props;

              const name = withdrawal.user?.full_name;
              const id = withdrawal.id;
              let iban;
              if (withdrawal?.payload?.iban) {
                iban = withdrawal.payload.iban;
              }
              let copyPaste = `${id},${name},${iban},,`;
              let amount;
              if (withdrawal.amount) {
                amount = Number(withdrawal.amount).toFixed(2);
              }
              copyPaste += amount + "," + id;
              if ("clipboard" in navigator) {
                return await navigator.clipboard.writeText(copyPaste);
              } else {
                return document.execCommand("copy", true, copyPaste);
              }
            }}
          >
            Copy for payout
          </button>
        </div>
      </GroupPermissionsFragment>
    );
  }
}
