import React, { useState } from "react";

const DiceBetHistory = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const fetch = () => {
    setLoading(true);
    window.api
      .get(`/admin/dice_bets/` + query)
      .then((data) => setData(data || {}))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <div className="bet-page">
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div className="bet-page">
      <div className="user-search-row">
        <input
          value={query}
          onChange={({ target }) => setQuery(target.value || "")}
          placeholder="Type a Round ID"
        />

        <button disabled={loading} className="red" onClick={fetch}>
          {loading ? "Loading..." : "Search"}
        </button>
      </div>

      <div className="row">
        <div className="col-md-6">
          <pre>{JSON.stringify(data, null, 4)}</pre>
        </div>
      </div>
    </div>
  );
  // }
};

export default DiceBetHistory;
