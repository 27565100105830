import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getUserBonusActivations, setReviewStatus } from "../../casino/actions";
import { format } from "../../shared/utils/dates";

const BonusActivationsNotReviewed = ({
  userId,
  bonusActivations,
  getBonusActivations,
  setActivationReviewStatus,
}) => {
  const loadBonusActivations = () => {
    getBonusActivations(userId);
  };

  useEffect(loadBonusActivations, []);

  return (
    <section className="mutuals">
      <div className="row">
        <div className="col-md-12">
          <h2>Bonus activations</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Table>
            <Thead>
              <Tr>
                <Th>Bonus Name</Th>
                <Th>Status</Th>
                <Th>Activation Status</Th>
                <Th>Activation Wager Requirement</Th>
                <Th>Activation Wagered</Th>
                <Th>Activation EUR transfer amount</Th>
                <Th>Activation Created</Th>
                <Th>Activation Last Update</Th>
                <Th>Action</Th>
                <Th>Bonus approval status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bonusActivations.map((ba, i) => {
                const timeFormat = "YYYY-MM-DD hh:mmA";
                return (
                  <Fragment>
                    <Tr key={i}>
                      <Td>
                        <Link to={`/rewards/bonuses/${ba.bonus_id}`}>
                          {ba.bonus_name}
                        </Link>
                      </Td>
                      <Td>{ba.bonus_status}</Td>
                      <Td>{ba.activation_status}</Td>
                      <Td>{ba.wager_requirement}</Td>
                      <Td>{ba.wagered}</Td>
                      <Td>{ba.eur_transfer_amount}</Td>
                      <Td>{format.withMinutes(ba.created_at)}</Td>
                      <Td>{format.withMinutes(ba.updated_at)}</Td>
                      <Td>
                        {
                          <>
                            <button
                              onClick={() => {
                                setActivationReviewStatus(
                                  userId,
                                  ba.id,
                                  "valid"
                                );
                              }}
                            >
                              Valid
                            </button>
                            <button
                              onClick={() => {
                                setActivationReviewStatus(
                                  userId,
                                  ba.id,
                                  "invalid"
                                );
                              }}
                            >
                              Invalid
                            </button>
                          </>
                        }
                      </Td>
                      <Td>{ba.review_status || "UNKNOWN"}</Td>
                    </Tr>
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  bonusActivations: state.bonuses.bonus_activations_on_withdrawal,
});

const mapDispatchToProps = (dispatch) => ({
  getBonusActivations: (userId) => dispatch(getUserBonusActivations(userId)),
  setActivationReviewStatus: (userId, activationId, reviewStatus) =>
    dispatch(setReviewStatus(userId, activationId, reviewStatus)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BonusActivationsNotReviewed);
