
class ApiJira {
  async getProjects() {
    const resp = await window.api.get(`/admin/jira/project`);
    return resp;
  }
  async getUsers() {
    const resp = await window.api.get(`/admin/jira/users`);
    return resp;
  }
  async getStatusesForProject(idProject) {
    const resp = await window.api.get(`/admin/jira/project/${idProject}/status`);
    return resp;
  }

  async createIssue(data) {
    const resp = await window.api.post(`/admin/jira/issue`, data);
    return resp;
  }
}

export default new ApiJira();