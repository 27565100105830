import React, { Component } from "react";
import Pagination from "../shared/pagination";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { depositSchema } from "./list-deposits-schema";
import {
  permissionsConstants,
  PermissionsFragment,
} from "../app/permissions-fragment";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { WrapperCalendar } from "shared/Calendar/WrapperCalendar";
import moment from "moment";

export default class Deposits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      type: "",
      status: "",
      id_filter: "",
      payload_search: null,
      total_pages: 0,
      deposits: [],
      isLoading: false,
      startDate: moment().subtract(7, "days").toDate(),
      endDate: new Date(),
      paymentMethods: [],
      clear: false,
    };
  }
  componentDidMount() {
    this.fetchPaymentMethods();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page && !this.state.clear)
      this.fetchDeposits();
  }
  update() {
    this.fetchDeposits();
  }

  // fetches payment methods
  fetchPaymentMethods() {
    const paymentMethods = [];
    window.api
      .get("/admin/payments/methods")
      .then(({ meta, records }) => {
        const pages = meta.total_pages;
        paymentMethods.push(...records);
        // get rest of pages
        const promises = [];
        for (let i = 2; i <= pages; i++) {
          promises.push(
            window.api.get("/admin/payments/methods", {
              params: { page: i },
            })
          );
        }
        return Promise.all(promises);
      })
      .then((responses) => {
        responses.forEach(({ records }) => {
          paymentMethods.push(...records);
        });

        // order by type
        paymentMethods.sort((a, b) => {
          if (a.type < b.type) {
            return -1;
          }
          if (a.type > b.type) {
            return 1;
          }
          return 0;
        });
        this.setState({ paymentMethods });
      });
  }

  fetchDeposits() {
    this.setState({ isLoading: true });
    const dateStart = this.state.startDate.toISOString();
    const dateEnd = this.state.endDate.toISOString();
    window.api
      .get("/admin/payments/deposits", {
        params: {
          deposit_id: this.state.id_filter,
          status: this.state.status,
          type: this.state.type,
          page: this.state.page,
          payload_search: this.state.payload_search,
          dateStart,
          dateEnd,
        },
      })
      .then(({ meta, records }) => {
        this.setState({ deposits: records, total_pages: meta.total_pages });
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  creditDeposit(id) {
    window.api.put(`/admin/payments/deposits/${id}/credit`).then(() => {
      toast.success(`Deposit credited`);
      this.fetchDeposits();
    });
  }

  requestCreditDeposit(id) {
    const staff_note = window.prompt("Please enter a note for this credit.");

    window.api
      .put(`/admin/payments/deposits/${id}/request_credit`, { staff_note })
      .then(() => {
        toast.success(`Deposit Credit Requested`);
        this.fetchDeposits();
      });
  }

  render() {
    const handlerSearch = () => {
      this.setState({ clear: false });
      this.state.page !== 1
        ? this.setState({ page: 1, total_pages: 0 })
        : this.fetchDeposits();
    };

    const clearInputs = () => {
      this.setState({
        page: 1,
        type: "",
        status: "",
        id_filter: "",
        payload_search: null,
        total_pages: 0,
        deposits: [],
        isLoading: false,
        clear: true,
      });
    };

    return (
      <div style={{ position: "relative", "min-height": "500px" }}>
        {this.state.isLoading && (
          <div className={"loading-overlay"}>
            <h2>Searching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <div className="row">
          <div className="col-md-6 col-xs-12">
            <h4>ID filter (ex. 666445)</h4>

            <input
              style={{ width: "100%" }}
              onChange={(e) => this.setState({ id_filter: e.target.value })}
              value={this.state.id_filter}
            />
          </div>

          <div className="col-md-3 col-xs-12">
            <h4>Type</h4>
            <Select
              name="type"
              value={this.state.type}
              onChange={(option) => {
                this.setState({ type: option.value });
              }}
              options={[{ value: "", label: "Any" }].concat(
                this.state.paymentMethods.map((val) => {
                  return { value: val.type, label: val.type };
                })
              )}
              clearable={false}
            />
          </div>

          <div className="col-md-3 col-xs-12">
            <h4>Status</h4>
            <Select
              name="type"
              value={this.state.status}
              onChange={(option) => {
                this.setState({ status: option.value });
              }}
              options={depositSchema.statuses.map((val) => {
                return { value: val.status, label: val.status };
              })}
              clearable={false}
            />
          </div>
          <div className="col-md-12 col-xs-12">
            <h4>Payload search</h4>

            <textarea
              style={{ width: "100%", height: "100px" }}
              onChange={(e) =>
                this.setState({ payload_search: e.target.value })
              }
              defaultValue={JSON.stringify({ qr_code_text: "" }, null, 4)}
              value={this.state.payload_search}
            />
          </div>
          <div style={{ marginTop: "20px" }} className="col-md-3 col-xs-12">
            <WrapperCalendar
              setStart={(date) => this.setState({ startDate: date })}
              setEnd={(date) => this.setState({ endDate: date })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-xs-12" style={{ marginTop: "20px" }}>
            <button style={{ width: "100%" }} onClick={handlerSearch}>
              Search
            </button>
          </div>
          <div className="col-md-3 col-xs-12" style={{ marginTop: "20px" }}>
            <button style={{ width: "100%" }} onClick={clearInputs}>
              Clear
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />

        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>User</Th>
              <Th>Type</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Note</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.deposits.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                  <Td>{r.id}</Td>
                  <Td className="user">
                    <Link to={`/users/${r.user.id}`}>{r.user.username}</Link>
                  </Td>
                  <Td>{r.type}</Td>
                  <Td>
                    {Math.abs(r.amount)} {r.currency_type}
                  </Td>
                  <Td>
                    <a
                      onClick={() =>
                        window.prompt("Statuses:", JSON.stringify(r.status_log))
                      }
                    >
                      {r.status}
                    </a>
                  </Td>
                  <Td>{r.note}</Td>
                  <Td>
                    {r.status !== "complete" && (
                      <PermissionsFragment
                        feature={permissionsConstants.DEPOSITS_CREDIT_DEPOSIT}
                      >
                        <button
                          className="small"
                          onClick={() => {
                            this.creditDeposit(r.id);
                          }}
                        >
                          Credit
                        </button>
                      </PermissionsFragment>
                    )}

                    {r.status !== "complete" && (
                      <PermissionsFragment
                        feature={
                          permissionsConstants.REQUEST_DEPOSITS_CREDIT_DEPOSIT
                        }
                      >
                        <button
                          className="small"
                          onClick={() => {
                            this.requestCreditDeposit(r.id);
                          }}
                        >
                          Request Credit
                        </button>
                      </PermissionsFragment>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.state.total_pages}
          update={(page) => {
            this.setState({ page });
          }}
        />
      </div>
    );
  }
}
