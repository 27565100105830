import * as types from './types';

export const updateUser = () => {

  return async dispatch => {
    try {
      const user = await window.api.get('/users/me');

      window.roles = user.roles
      window.permissions = user.permissions ?? []
      dispatch({
        type: types.USER_UPDATE,
        payload: user
      })

    } catch (err) {

    }
  }
};

export const login = (username, password, code) => {

  return async dispatch => {
    const res = await window.api.put('/auth/password/admin', {
      username, password, code
    });

    window.api.authenticate(res.access_token);
    window.location = window.location.origin;
  }
};


export const updateMeta = () => {
  return async dispatch => {
    try {
      const [meta, languages] = await Promise.all([
        window.api.get('/settings'),
        window.api.get('/languages')
      ])

      dispatch({
        type: types.META_UPDATE,
        payload: {
          ...meta,
          languages
        }
      })

    } catch (err) {
      if (err && err.code === 1008) {
        window.api.clearAuth();
      }
    }
  }
};

export const updateCurrencies = () => {
  return async dispatch => {
    try {
      const data = await window.api.get('/currencies');

      dispatch({
        type: types.CURRENCIES_UPDATE,
        payload: data
      })

    } catch (err) {

    }
  }
};
