import { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { toast } from "react-toastify";
import api from "./api";
import "./racesList.scss";
import { SelectSort } from "./SelectSort";
import { SelectStatus } from "./SelectStatus";

const ListRaces = (props) => {
  const [listRaces, setListRaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagesTotal, setPagesTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("starts_at");
  const [statusRace, setStatusRace] = useState("");
  useEffect(() => {
    getListRacesGames();
  }, [page, sort, statusRace]);

  useEffect(() => {
    setPage(1);
  }, [sort, statusRace]);

  const getListRacesGames = async () => {
    const res = await api.getRaces(page, statusRace, sort);
    const { races, total_pages } = res;
    setLoading(false);
    setListRaces(races);
    setPagesTotal(total_pages);
  };
  const handleDelete = async (id) => {
    await api.deleteRace(id);
    toast.success(`The race ${id} was deleted succesfully`);
    await getListRacesGames(page, statusRace, sort);
  };
  return (
    <div>
      <div className="box-selects">
        {/* <div className="box-select">
          <h1 className="text-box-select">Filter Status</h1>
          <SelectStatus select={statusRace} setSelect={setStatusRace} />
        </div> */}
        <div className="box-select">
          <h1 className="text-box-select">Sort by</h1>
          <SelectSort select={sort} setSelect={setSort} />
        </div>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>Race ID</Th>
            <Th>Name</Th>
            <Th>Start Date</Th>
            <Th>Duration</Th>
            <Th>Recurring</Th>
            <Th>Total Prize</Th>
            <Th>Status</Th>
            <Th>Game Name</Th>
            <Th>Options</Th>
          </Tr>
        </Thead>

        {loading && (
          <div className={"loading-overlay"}>
            <h2>Fetching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <Tbody className="referrals-body">
          {listRaces?.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{item.id}</Td>
                <Td>{item.name}</Td>
                <Td>{new Date(item.starts_at).toLocaleString()}</Td>

                <Td>{item.duration}</Td>
                <Td>{item.recurring_frequency}</Td>
                <Td>{item.eur_prize}</Td>
                <Td>{item.status}</Td>
                <Td>
                  {item.games != null && item.games.length > 1 ? 'multiple games' : item.game_name === null || item.game_name === undefined
                    ? "any game"
                    : item.game_name}
                </Td>
                <Td>
                  <button
                    onClick={() => {
                      props.history.push(`/casino/race/view/${item.id}`);
                    }}
                  >
                    View
                  </button>
                </Td>
                <Td>
                  {(item.status === "ongoing" || item.status === "created") && (
                    <button onClick={() => handleDelete(item.id)}>
                      Cancel
                    </button>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <div className="pagination">
        <button
          className="btn-pagination"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <span>{"<"}</span>
        </button>
        <div className="pagination_page">
          Page {page} of {pagesTotal}
        </div>
        <button
          className="btn-pagination"
          onClick={() => setPage(page + 1)}
          disabled={page >= pagesTotal}
        >
          <span>{">"}</span>
        </button>
      </div>
    </div>
  );
};

export default withRouter(ListRaces);
