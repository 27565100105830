import React, { Component } from "react";
import CountryList from "country-list";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const initialState = {
  categories: [],
  game: {
    categories: [{ name: null, slug: null }],
    provider: {
      slug: null,
    },
    aggregator: {},
    blocked_countries: [],
  },
  reportLoading: false,
  gameReport: {
    data: {
      provider: {},
    },
  },
  newCategoryName: "",
  newCategoryNameTranslation: {},
};

class GameProviderGamePage extends Component {
  constructor(props) {
    super(props);
    this.countries = CountryList();

    this.state = initialState;
  }

  componentDidMount() {
    this.fetchGame();
  }

  async fetchGame() {
    await window.api
      .get(`/admin/games/${this.props.match.params.slug}`)
      .then((game) => this.setState({ game }));
  }

  async changeRTP() {
    const rtp = window.prompt(`Enter RTP value for this game`);
    if (rtp) {
      await window.api
        .post(`/admin/games/${this.props.match.params.slug}/change_rtp`, {
          rtp,
        })
        .then(() => {
          toast.success(`Success`);
          this.fetchGame();
        })
        .catch((err) => toast.error(err));
    }
  }

  async changeSlug() {
    const new_slug = window.prompt(
      `Enter a new globally unique slug for this game`
    );
    if (new_slug) {
      await window.api
        .post(`/admin/games/${this.props.match.params.slug}/change_slug`, {
          new_slug,
        })
        .then(() => {
          toast.success(`Success`);
          this.props.history.push(`/casino/games/${new_slug}`);
          this.fetchGame();
        })
        .catch((err) => toast.error(err));
    }
  }

  async changeGameName() {
    const new_game_name = window.prompt(
      `Enter a new globally unique name for this game`
    );
    if (new_game_name) {
      await window.api
        .post(`/admin/games/${this.props.match.params.slug}/change_game_name`, {
          new_game_name,
        })
        .then(() => {
          toast.success(`Success`);
          this.fetchGame();
        })
        .catch((err) => toast.error(err));
    }
  }

  async moveToNewProvider() {
    const destination_provider_slug = window.prompt(
      `Enter the slug of the provider you would like to move this game to`
    );

    if (destination_provider_slug) {
      await window.api
        .post(
          `/admin/games/${this.props.match.params.slug}/move_to_new_provider`,
          {
            destination_provider_slug,
          }
        )
        .then(() => {
          toast.success(`Success`);
          this.fetchGame();
        })
        .catch((err) => toast.error(err));
    }
  }

  async changeImageUrl(file, gameId, newTempImage) {
    toast.info(`Uploading image...`);
    const image_url = await window.api.upload(
      file,
      "games",
      newTempImage ? `${gameId}-new` : gameId
    );

    await window.api
      .post(`/admin/games/${this.props.match.params.slug}/change_image`, {
        image_url,
        is_new_image: newTempImage,
      })
      .then(() => {
        toast.success(`Image changed`);
        this.fetchGame();
      })
      .catch((err) => toast.error(err));
  }

  render() {
    const {
      id,
      image_url,
      name,
      slug,
      provider,
      aggregator,
      blocked_countries,
      hit_rate,
      paylines,
      return_to_player,
      free_spins_allowed,
      desktop_allowed,
      mobile_allowed,
      volatility_rating,
      released_at,
      remote_id,
      image_url_new,
      demo_allowed,
    } = this.state.game;
    return (
      <div className="row game-page">
        <div className="col-md-12">
          <div
            className="data"
            style={{
              lineHeight: "2",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div>
              <img
                src={image_url}
                style={{ width: "250px", height: "250px", borderRadius: "5px" }}
              />
              <br />
              <h2>NEW IMAGE URL {image_url_new ? "👇" : "NOT SET"}</h2>
              {image_url_new && (
                <img
                  src={image_url_new}
                  style={{
                    width: "250px",
                    height: "250px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </div>

            <div className="info" style={{ marginLeft: "25px" }}>
              <div>
                Name: {name}{" "}
                <button onClick={() => this.changeGameName()} className="small">
                  Change
                </button>
              </div>
              <div>
                Slug: {slug}{" "}
                <button onClick={() => this.changeSlug()} className="small">
                  Change
                </button>
              </div>
              <div>Remote ID: {remote_id}</div>
              <div>Aggregator: {remote_id ? remote_id.split(":")[0] : ""}</div>
              <div>
                Provider:{" "}
                <Link to={`/casino/game-providers/${provider.slug}`}>
                  {provider.name}
                </Link>{" "}
                <button
                  onClick={() => this.moveToNewProvider()}
                  className="small"
                >
                  Move
                </button>
              </div>
              <div>Aggregator: {aggregator.name}</div>
              <div>Blocked countries: {blocked_countries.toString()}</div>
              <div>Hit rate: {hit_rate}</div>
              <div>Paylines: {paylines}</div>
              <div>
                RTP: {return_to_player}%{" "}
                <button onClick={() => this.changeRTP()} className="small">
                  Change
                </button>
              </div>
              <div>Volatility rating: {volatility_rating}</div>
              <div>Free spins allowed: {free_spins_allowed ? "yes" : "no"}</div>
              <div>Desktop allowed: {desktop_allowed ? "yes" : "no"}</div>
              <div>Mobile allowed: {mobile_allowed ? "yes" : "no"}</div>
              <div>Demo allowed: {demo_allowed ? "yes" : "no"}</div>
              <div>Release date: {released_at}</div>
              <div>Id: {id}</div>
              <h4>Upload Image</h4>
              <input
                type="file"
                onChange={async (e) => {
                  this.changeImageUrl(e.target.files[0], id);
                }}
              />

              <h4>Upload Image NEW WITH NEW FORMAT</h4>
              <input
                type="file"
                onChange={async (e) => {
                  this.changeImageUrl(e.target.files[0], id, true);
                }}
              />
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  languages: state.app.languages,
});

export default connect(mapStateToProps)(GameProviderGamePage);
