import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./styles/Selector.scss";

const Selector = ({ select, setSelect, type, placeholder }) => {
  const [listOptions, setListOptions] = useState([]);

  const useEffectCallback = () => {
    window.api.get(`/admin/monitoring/balance/${type}`).then((currencies) => {
      const balanceCurrenciesResponse = currencies.map((currency) => ({ id: currency, label: currency }));
      setListOptions([{ id: '', label: 'All' }, ...balanceCurrenciesResponse]);
    })
  };
  useEffect(useEffectCallback, [])

  return (
    <div className="selectCurrency">
      <Select
        className="selectsort__select"
        classNamePrefix="selectsort__select"
        options={listOptions}
        value={
          listOptions.find((option) => option.id === select) || listOptions[0]
        }
        onChange={({ id }) => {
          setSelect(id);
        }}
        placeholder={placeholder}
        clearable={false}
      />
    </div>
  );
};

export default Selector;
