import React, { Component, Fragment } from "react";
import Pagination from "../../shared/pagination";
import { toast } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import MobilePageTitle from "shared/MobilePageTitle";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

class RefundRequests extends Component {
  state = {
    page: 1,
    filter: "",
    records: [],
    meta: {},
  };

  componentDidMount() {
    this.update();
  }

  async update() {
    const data = await window.api({
      method: "GET",
      url: "/admin/refund_requests",
      params: {
        page: this.state.page,
        status: this.state.status,
      },
    });

    this.setState({
      ...data,
    });
  }

  async approve(id) {
    try {
      await window.api({
        method: "post",
        url: "/admin/refund_request/" + id + "/approve",
      });
      toast.success(`Request Approved`);
    } catch (error) {
      toast.error(error);
    }
    this.update();
  }

  async deny(id) {
    await window.api.post("/admin/refund_request/" + id + "/deny");
    toast.success(`Request Denied`);

    this.update();
  }

  render() {
    return (
      <div id={"staff-requests"}>
        <MobilePageTitle title="Refund Request" />
        <div className={"controller row"}>
          <div style={{ width: "50%", textAlign: "left" }}>
            <div>
              Status
              <Select
                name="type"
                value={this.state.status}
                onChange={(option) => {
                  this.setState({ status: option.value }, () => {
                    this.update();
                  });
                }}
                options={[{ value: "", label: "Any" }].concat(
                  ["pending", "approved", "failed", "denied"].map((val) => {
                    return { value: val, label: val };
                  })
                )}
              />
            </div>
          </div>
        </div>

        <hr />
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Currency</Th>
              <Th>Amount</Th>
              <Th>Requested By</Th>
              <Th>Status</Th>
              <Th>Target User</Th>
              <Th>Note</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.records.map((request) => {
              return (
                <Tr key={request.id}>
                  <Th>{request.id}</Th>
                  <Th>{request.wallet.currency_type}</Th>
                  <Th>{request.amount}</Th>
                  <Th>{request.requester.username}</Th>
                  <Th>{request.status}</Th>
                  <Th>
                    <Link to={`/users/${request.target.id}`}>
                      {request.target.username}
                    </Link>
                  </Th>
                  <Th>{request.note}</Th>
                  <Th>
                    {request.status === "pending" && (
                      <Fragment>
                        <button
                          onClick={() => {
                            this.approve(request.id);
                          }}
                        >
                          Approve
                        </button>
                        <button onClick={() => this.deny(request.id)}>
                          Deny
                        </button>
                      </Fragment>
                    )}
                  </Th>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          total_pages={this.state.meta.total_pages}
          update={(page) => {
            this.setState({ page }, () => {
              this.update();
            });
          }}
        />
      </div>
    );
  }
}

export default RefundRequests;
