import React from "react";
import { NavLink } from "react-router-dom";

export const AffiliatesFindUsersLink = () => (
  <NavLink exact to={`/affiliates/find`}>
    Find users
  </NavLink>
);
export const AffiliatesSystemLink = () => (
  <NavLink exact to={`/affiliates/system`}>
    System
  </NavLink>
);
