import React, { useState } from "react";
import { toast } from "react-toastify";

const UpdateGameProviderName = ({ providerSlug }) => {
  const [name, setName] = useState("");

  const updateName = () => {
    if (!name) {
      return;
    }
    window.api
      .post(`/admin/game_providers/${providerSlug}/rename`, { name })
      .then(() => {
        setName("");
        toast.info("Updated", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h2>Edit game provider name</h2>
      <input
        type="text"
        placeholder="Game Provider Name"
        value={name}
        onChange={(evt) => setName(evt.target.value || "")}
        onKeyDown={({ key }) => {
          key === "Enter" && updateName();
        }}
      />
      <button onClick={() => updateName()} disabled={name === ""}>
        Update Name
      </button>
    </div>
  );
};

export default UpdateGameProviderName;
