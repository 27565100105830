import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./styles/SelectCategory.scss";
import { ORIGINALS_CATEGORY } from "status/constants";

const SelectCategory = ({ select, setSelect }) => {
  const [listOptions, setListOptions] = useState([]);

  const useEffectCallback = () => {
    window.api.get('/admin/monitoring/categories').then((categories) => {
      const apiCategories = categories.map((category) => ({ id: category, label: category }));
      const options = [{ id: '', label: 'All' }].concat(apiCategories).concat([{ id: ORIGINALS_CATEGORY, label: ORIGINALS_CATEGORY }]);
      setListOptions(options);
    })
  };
  useEffect(useEffectCallback, [])

  return (
    <div className="selectcategory">
      <Select
        className="selectsort__select"
        classNamePrefix="selectsort__select"
        options={listOptions}
        value={
          listOptions.find((option) => option.id === select) || listOptions[0]
        }
        onChange={({ id }) => {
          setSelect(id);
        }}
        placeholder="Select category"
        clearable={false}
      />
    </div>
  );
};

export default SelectCategory;
