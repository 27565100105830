import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, NavLink, Switch, withRouter, Redirect } from "react-router-dom";
import StaffRequests from "system/support-manager/StaffRequests";
import RefundRequests from "system/support-manager/RefundRequests";
import { BrowserView } from "react-device-detect";

import {
  permissionGroups,
  CheckPermissionGroupRoute,
} from "app/permissions-fragment";

import {
  RequestsLink,
  RefundRequestsLink,
} from "system/tab-support-manager/links";

class StaffManager extends Component {
  render() {
    const { url } = this.props.match;
    return (
      <div>
        <BrowserView>
          <nav>
            <RequestsLink />
            <RefundRequestsLink />
          </nav>
        </BrowserView>
        <Switch>
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.WITHDRAWALS.concat(permissionGroups.STAFF_MANAGER.REQUESTS)}
            path={`${url}/requests`}
            component={StaffRequests}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.PAYMENTS.WITHDRAWALS}
            path={`${url}/refund_requests`}
            component={RefundRequests}
          />

          <Redirect from="/staff" to="/staff/requests" />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StaffManager)
);
