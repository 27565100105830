

import React, { Component } from 'react';
import { Route, NavLink, Switch, withRouter } from "react-router-dom";

import CreateLink from "./affiliate-links/create-affiliate-link";
import Detail from "./affiliate-links/Detail";
import ListAffiliateLinks from "./affiliate-links/affiliate-links";
import Cellxpert from "./affiliate-links/cellxpert";

export default class Affiliate extends Component {


  render() {
    return (
      <div>
        <nav className="sub-nav">
          <NavLink exact to="/casino/affiliate-links">
            List
          </NavLink>
          <NavLink exact to="/casino/affiliate-links/new">
            Create
          </NavLink>
          <NavLink exact to="/casino/affiliate-links/cellxpert">
            Cellxpert
          </NavLink>
        </nav>
        <Switch>
          <Route exact path="/casino/affiliate-links" component={ListAffiliateLinks}/>
          <Route exact path="/casino/affiliate-links/new" component={CreateLink}/>
          <Route exact path="/casino/affiliate-links/cellxpert" component={Cellxpert}/>
          <Route exact path="/casino/affiliate-links/detail/:id" component={Detail}/>
        </Switch>
      </div>
    )
  }
}