import {toast} from "react-toastify";

export async function getGroups({ page = 1, search = "" }) {
  const searchText = search ? `&search=${search}` : "";
  let data;
  try {
    data = await window.api.get(
      `/admin/feature-flag-group?perPage=10&page=${page}${searchText}`
    );
  } catch (error) {
    data = [];
  }

  return data;
}

export async function getUsersByGroup({ page = 1, groupId, search = "" }) {
  const searchText = search ? `&search=${search}` : "";
  let data;
  try {
    data = await window.api.get(
      `/feature-flag-group/${groupId}?usersPerPage=10&usersPage=${page}${searchText}`
    );
  } catch (error) {
    data = [];
  }

  return data;
}

export async function changeGroupStatus(groupId, name, status, page) {
  try {
    await window.api.put(`/admin/feature-flag-group/${groupId}`, {
      name,
      enabled: status === "enable",
    });
  } catch (error) {
    console.error({ error });
  }

  return getGroups({ page });
}

export async function changeUserStatus(groupId, userId, status, page = 0) {
  try {
    await window.api.put(
      `/admin/feature-flag-group/${groupId}/set-enabled-user-from-group`,
      {
        user_id: userId,
        enabled: status,
      }
    );
  } catch (error) {
    console.error({ error });
  }

  return getUsersByGroup({ groupId, page });
}

export async function addUser(groupId, userIds) {
  await window.api.put(
    `/admin/feature-flag-group/${groupId}/add-users`,
    userIds,
  );
  toast.success(`User Added Successfully`);
}

export async function createGroup(groupName) {
  try {
    await window.api.post(`/admin/feature-flag-group`, {
      name: groupName,
    });
  } catch (error) {
    console.error({ error });
  }

  return getGroups({});
}

export async function releaseGroup(id) {
  try {
    await window.api.put(`/admin/feature-flag-group/${id}/release`, { release: true });
  } catch (error) {
    console.error({ error });
  }

  return getGroups({});
}

export async function rollbackRelease(id) {
  try {
    await window.api.put(`/admin/feature-flag-group/${id}/release`, { release: false });
  } catch (error) {
    console.error({ error });
  }

  return getGroups({});
}

export async function uploadFile(file, groupId, action, page = 1) {
  try {
    let data = new FormData();
    data.append("usersFile", file);

    if (action === "disable") {
      await window.api.delete(
        `/admin/feature-flag-group/${groupId}/users-bulk`,
        {
          data,
        },
      );
    }

    if (action === "enable") {
      await window.api.post(
        `/admin/feature-flag-group/${groupId}/users-bulk`,
        data,
        {
          headers: {
            "Content-Type": file.type,
          },
        },
      );
    }
  } catch (error) {
    console.error({ error });
  }
}
