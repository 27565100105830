import { CasinoLink } from "app/panel.js";

import {
  AffiliateLinksLink,
  BonusesLink,
  ChallengesLink,
  GameCategoriesLink,
  GameProvidersLink,
  GamesLink,
  GlobalXpLink,
  LoyaltyLink,
  MysteryBoxesLink,
  PromoCodesLink,
  RacesLink,
  RewardsLink,
  RewardTiersLink,
  UserRanksLink,
} from "casino/tab-casino/links.js";

import {
  ViewRewardLink,
  CreateRewardLink
} from "casino/tab-rewards/links";

import {
  ViewBonusLink,
  CreateBonusLink,
  RoundWelcomePackagesLink,
} from "casino/tab-bonuses/links";
import { isAdmin } from "app/api";

const casinoNodes = {
  key: "casino",
  label: "Casino",
  icon: "",
  component: () => <CasinoLink />,
  nodes: [
    {
      key: "affiliate-links",
      label: "Affiliate Links",
      icon: "",
      component: () => <AffiliateLinksLink />,
      nodes: [],
    },
    {
      key: "bonuses",
      label: "Bonuses",
      icon: "",
      component: () => <BonusesLink />,
      nodes: [
        {
          key: "view-bonuses",
          label: "View Bonuses",
          icon: "",
          component: () => <ViewBonusLink />,
          nodes: [],
        },
        {
          key: "create-bonus",
          label: "Create Bonus",
          icon: "",
          component: () => <CreateBonusLink />,
          nodes: [],
        },
        {
          key: "round-welcome-packages",
          label: "Round Welcome Packages",
          icon: "",
          component: () => <RoundWelcomePackagesLink />,
          nodes: [],
        },
      ],
    },
    {
      key: "game-categories",
      label: "Game Categories",
      icon: "",
      component: () => <GameCategoriesLink />,
      nodes: [],
    },
    {
      key: "game-providers",
      label: "Game Providers",
      icon: "",
      component: () => <GameProvidersLink />,
      nodes: [],
    },
    {
      key: "games",
      label: "Games",
      icon: "",
      component: () => <GamesLink />,
      nodes: [],
    },
    {
      key: "global-xp",
      label: "Global Xp",
      icon: "",
      component: () => <GlobalXpLink />,
      nodes: [],
    },
    {
      key: "loyalty",
      label: "Loyalty",
      icon: "",
      component: () => <LoyaltyLink />,
      nodes: [],
    },
    {
      key: "mystery-boxes",
      label: "Mystery Boxes",
      icon: "",
      component: () => <MysteryBoxesLink />,
      nodes: [],
    },
    {
      key: "promo-codes",
      label: "Promo Codes",
      icon: "",
      component: () => <PromoCodesLink />,
      nodes: [],
    },
    {
      key: "races",
      label: "Races",
      icon: "",
      component: () => isAdmin() && <RacesLink />,
      nodes: [],
    },
    {
      key: "challenges",
      label: "Challenges",
      icon: "",
      component: () => <ChallengesLink />,
      nodes: [],
    },
    {
      key: "rewards",
      label: "Rewards",
      icon: "",
      component: () => <RewardsLink />,
      nodes: [
        {
          key: "view-rewards",
          label: "View Rewards",
          icon: "",
          component: () => <ViewRewardLink />,
          nodes: [],
        },
        {
          key: "create-reward",
          label: "Create Reward",
          icon: "",
          component: () => <CreateRewardLink />,
          nodes: [],
        },
      ],
    },
    {
      key: "reward-tiers",
      label: "Reward Tiers",
      icon: "",
      component: () => <RewardTiersLink />,
      nodes: [],
    },
    {
      key: "user-ranks",
      label: "User Ranks",
      icon: "",
      component: () => <UserRanksLink />,
      nodes: [],
    },
  ],
};

export default casinoNodes;
