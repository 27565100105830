import React, { useState } from 'react';

const WeekDatePicker = ({ periods = [], setPeriod }) => {
  const [option, setOption] = useState(0);

  const handleSelectChange = (event) => {
    setPeriod(Number(event.target.value));
    setOption(event.target.value);
  };

  const formattedDate = (date) => new Date(date).toISOString().replace('T', ' ').replace('Z', '').split('.')[0];

  return (
    <div className="date-range">
      <select value={option} onChange={handleSelectChange}>
        {periods.map((period) => (
          <option key={period.period_started_at} value={period.id}>
            {`${formattedDate(period.period_started_at)} / ${formattedDate(period.period_ended_at)}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default WeekDatePicker;
