const CURRENCY_LIMITS_UPDATE = 'currency_limits/UPDATE';
const CURRENCY_LIMIT_UPDATE = 'currency_limits/LIMIT/UPDATE';

const DISPLAY_NAME_UPDATE = 'display_name/UPDATE';
const DISPLAY_NAME_TRANSLATION_UPDATE = 'display_name/TRANSLATION/UPDATE';

export {
  CURRENCY_LIMITS_UPDATE,
  CURRENCY_LIMIT_UPDATE,
  DISPLAY_NAME_UPDATE,
  DISPLAY_NAME_TRANSLATION_UPDATE
}