import { Component } from "react";
import Pagination from "../../shared/pagination";
import "./AddGamesCategory.css";

class AddGamesToCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      page: 1,
      records: [],
      meta: {},

      showEnabled: true,
      showDisabled: true,
      showInCategory: true,
      showNotInCategory: true,

      categories: [],
      ordinality: {},
      categoryGames: [],
    };
  }

  componentDidMount() {
    this.search();
    this.fetchCategoryGames();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      this.search();
    }

    if (
      prevProps.categorySlug !== this.props.categorySlug &&
      this.props.categorySlug
    ) {
      this.fetchCategoryGames();
    }
  }

  async fetchCategoryGames() {
    const categoryGames = await window.api.get(
      "/admin/game_categories/" + this.props.categorySlug
    );

    this.setState({ categoryGames });
  }

  async search() {
    let params = {
      name: this.state.name,
      page: this.state.page,
    };

    if (this.state.showEnabled && !this.state.showDisabled) {
      params.enabled = true;
    } else if (!this.state.showEnabled && this.state.showDisabled) {
      params.enabled = false;
    }

    const data = await window.api({
      method: "GET",
      url: "/admin/games",
      params,
    });

    this.setState({
      records: data.records || [],
      meta: data.meta || {},
    });
  }

  async addCategory(game_id) {
    await window.api.post(
      `/admin/game_categories/${this.props.categorySlug}/add_game`,
      {
        game_id,
      }
    );
    this.fetchCategoryGames();
  }
  async removeCategory(game_id) {
    await window.api.post(
      `/admin/game_categories/${this.props.categorySlug}/remove_game`,
      {
        game_id,
      }
    );
    this.fetchCategoryGames();
  }

  render() {
    const { showInCategory, showNotInCategory, categoryGames } = this.state;

    let records = this.state.records;
    if (showInCategory && !showNotInCategory) {
      records = categoryGames;
    }

    return (
      <div>
        <div className="input-row">
          <input
            value={this.state.name}
            onChange={(ev) => {
              this.setState(
                {
                  name: ev.target.value,
                  page: 1,
                },
                () => {
                  clearTimeout(this.timeout);
                  this.timeout = setTimeout(() => {
                    this.search();
                  }, 400);
                }
              );
            }}
            placeholder={"Search (game name, provider name)"}
          />
        </div>
        <div className="group-checkbox">
          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState(
                {
                  showEnabled: !this.state.showEnabled,
                },
                this.search
              );
            }}
          >
            Show Enabled{" "}
            <input checked={this.state.showEnabled} type={"checkbox"} />
          </span>
          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState(
                {
                  showDisabled: !this.state.showDisabled,
                },
                this.search
              );
            }}
          >
            Show Disabled{" "}
            <input checked={this.state.showDisabled} type={"checkbox"} />
          </span>

          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState(
                {
                  showInCategory: !this.state.showInCategory,
                },
                this.search
              );
            }}
          >
            Show Games in Category{" "}
            <input checked={this.state.showInCategory} type={"checkbox"} />
          </span>

          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState(
                {
                  showNotInCategory: !this.state.showNotInCategory,
                },
                this.search
              );
            }}
          >
            Show Games not in Category{" "}
            <input checked={this.state.showNotInCategory} type={"checkbox"} />
          </span>
        </div>

        <div className={"games-category-container"}>
          {records.map((r, i) => {
            console.log(r);
            const inCategory = categoryGames.find((c) => c.id === r.id);
            if (!showInCategory) {
              if (inCategory) {
                return null;
              }
            }

            if (!showNotInCategory) {
              if (!inCategory) {
                return null;
              }
            }

            return (
              <div key={i}>
                <div
                  className={`game-box ${
                    inCategory ? "inCategory" : "outCategory"
                  }`}
                  onClick={() => {
                    if (inCategory) {
                      this.removeCategory(r.id);
                    } else {
                      this.addCategory(r.id);
                    }
                  }}
                >
                  <img alt={`game ${r.provider.name}`} src={r.image_url_new || r.image_url} />
                  <p>{r.name}</p>
                  <p>{r.provider.name}</p>
                  <p>{r.slug}</p>
                </div>
              </div>
            );
          })}
        </div>

        <Pagination
          total_pages={this.state.meta.total_pages}
          update={(page) => {
            this.setState({ page });
          }}
        />
      </div>
    );
  }
}

export default AddGamesToCategory;
