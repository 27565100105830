import React, { Component } from "react";
import { connect } from "react-redux";
import { updatePaymentProvider, editProvider } from "../actions";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import CountryList from "country-list";
import Limit from "./Limit";
import DisplayName from "./display-name";
import { ProviderRulesForm } from "./provider-rules-form";

class Provider extends Component {
  constructor(props) {
    super(props);

    this.countries = CountryList();
    this.state = {};
  }
  handleSetDepositorRule(values) {
    if (Object.keys(values.rules).length === 0) {
      values = {};
    }
    this.props.edit(this.props.provider.id, {
      disable_depositor_rule: values,
    });
  }

  componentDidMount() {
    this.props.update(this.props.match.params.id);
  }

  render() {
    const data = this.props.provider;

    return (
      <div>
        {Object.keys(data).map((key, i) => {
          let value = data[key];

          if (key === "disable_depositor_rule") {
            value = JSON.stringify(value, null, 2);
          }
          if (typeof value === "object") return null;
          return (
            <div key={i}>
              <span>{key}: </span>
              <span>
                {typeof value === "boolean" ? value.toString() : value}
              </span>
            </div>
          );
        })}
        <hr />
        <ProviderRulesForm
          values={data.disable_depositor_rule}
          onSetRules={(values) => this.handleSetDepositorRule(values)}
        />
        <hr />

        <div className="row">
          <div className="col-lg-6 col-xs-12">
            <h4>Countries Allowed</h4>

            <div className="draggable-tags">
              <div className="row" style={{ width: "100%" }}>
                {(data.supported_countries || []).map((country, i) => (
                  <div key={i} className="col-xs-2">
                    <div className="tag">
                      {country}

                      <i
                        onClick={() => {
                          this.props.edit(data.id, {
                            supported_countries:
                              data.supported_countries.filter(
                                (c) => c !== country
                              ),
                          });
                        }}
                        className="fas fa-times-circle"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <br />
            <div className="input-group">
              <Select
                name="type"
                value={this.state.supported_country}
                onChange={(option) =>
                  this.setState({ supported_country: option })
                }
                options={Object.keys(this.countries.getNameList())
                  .filter((c) => {
                    return (
                      (data.excluded_countries || []).indexOf(
                        this.countries.getCode(c)
                      ) === -1
                    );
                  })
                  .map((country, index) => {
                    return {
                      label: country,
                      value: this.countries.getCode(country),
                    };
                  })}
              />

              <button
                onClick={() =>
                  this.props.edit(data.id, {
                    supported_countries: data.supported_countries.concat([
                      this.state.supported_country.value,
                    ]),
                  })
                }
              >
                Add Supported Country
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-xs-12">
            <h4>Excluded Countries</h4>

            <div className="draggable-tags">
              <div className="row" style={{ width: "100%" }}>
                {(data.excluded_countries || []).map((country, i) => (
                  <div key={i} className="col-xs-2">
                    <div className="tag">
                      {country}

                      <i
                        onClick={() => {
                          this.props.edit(data.id, {
                            excluded_countries: data.excluded_countries.filter(
                              (c) => c !== country
                            ),
                          });
                        }}
                        className="fas fa-times-circle"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <br />
            <div className="input-group">
              <Select
                name="type"
                value={this.state.excluded_country}
                onChange={(option) =>
                  this.setState({ excluded_country: option })
                }
                options={Object.keys(this.countries.getNameList())
                  .filter((c) => {
                    return (
                      (data.supported_countries || []).indexOf(
                        this.countries.getCode(c)
                      ) === -1
                    );
                  })
                  .map((country, index) => {
                    return {
                      label: country,
                      value: this.countries.getCode(country),
                    };
                  })}
              />

              <button
                onClick={() =>
                  this.props.edit(data.id, {
                    excluded_countries: data.excluded_countries.concat([
                      this.state.excluded_country.value,
                    ]),
                  })
                }
              >
                Add Excluded Country
              </button>
            </div>
          </div>
        </div>
        <hr />
        <Limit id={this.props.match.params.id} />
        <hr />
        <DisplayName id={this.props.match.params.id} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  provider: state.payments.provider,
});

const mapDispatchToProps = (dispatch) => ({
  update: (id) => dispatch(updatePaymentProvider(id)),
  edit: (id, props) => dispatch(editProvider(id, props)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Provider)
);
