import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  updatePaymentProvider, editProvider, editCountryOrdering, updatePaymentMethods, updateCountryOrdering
} from "../actions";
import Select from 'react-select';
import {Link, NavLink, Route, Switch, withRouter} from "react-router-dom";
import CountryList from 'country-list';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder} from "../../app/api";


class CountryOrder extends Component {

  constructor(props) {
    super(props);

    this.countries = CountryList();
    this.state = {
      countries: [],
      country: '',
      methods: [],
      method: ''
    }
  }

  componentDidMount() {
    this.update();
  }
  update() {
    this.props.update(this.props.match.params.id);
    this.props.updateMethods();
  }

  render() {

    const data = this.props.country_order;

    if (!data.id) {
      return null;
    }

    return (
      <div>
        {
          Object.keys(data).map(key => {
            const value = data[key];

            if (typeof value === 'object') return null;
            return (
              <div>
                <span>{key}: </span>
                <span>{value}</span>
              </div>
            );
          })
        }
        <hr />


        <div className="tags-wrapper">
          <h5>Countries</h5>
          <div className="draggable-tags">
            <div className="row" style={{width: '100%'}}>
              {
                (data.countries).map(
                  country =>
                    <div className="col-xs-2">
                      <div className="tag">
                        {country}

                        <i
                          onClick={
                            () => {
                              this.props.edit(data.id, {
                                countries: data.countries.filter(
                                  c => c !== country
                                )
                              });
                            }
                          }
                          className="fas fa-times-circle"/>
                      </div>
                    </div>
                )
              }
            </div>
          </div>

          <br />
          <div className="input-group">
            <Select
              name="type"
              value={this.state.country}
              onChange={option => this.setState({ country: option })}
              options={
                Object.keys(this.countries.getNameList()).filter(c => {
                  return (data.countries).indexOf(
                    this.countries.getCode(c)
                  ) === -1;
                }).map((country, index) => {
                  return {
                    label: country,
                    value: this.countries.getCode(country)
                  }
                })}
            />

            <button
              onClick={
                () => this.props.edit(data.id, {
                  countries: data.countries.concat(
                    [this.state.country.value]
                  )
                })
              }
            >Add Country
            </button>
          </div>
        </div>


        <div className="tags-wrapper">
          <h5>Method Types</h5>
          <div className="draggable-tags">
            <DragDropContext
              onBeforeDragStart={() => {}}
              onDragStart={() => {}}
              onDragUpdate={() => {}}
              onDragEnd={result => {
                this.props.edit(data.id, {
                  methods: reorder(data.methods, result.source.index, result.destination.index)
                })
              }}
            >
              <Droppable
                style={{width: '100%'}}
                droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                  >
                    {
                      (data.methods).map(
                        (method, index) =>

                          <Draggable key={method} draggableId={method} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}

                                className="tag">
                                {method}

                                <i
                                  onClick={
                                    () => {
                                      this.props.edit(data.id, {
                                        methods: data.methods.filter(
                                          c => c !== method
                                        )
                                      });
                                    }
                                  }
                                  className="fas fa-times-circle"/>
                              </div>
                            )}
                          </Draggable>
                      )
                    }
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <br />
          <div className="input-group">
            <Select
              name="type"
              value={this.state.method}
              onChange={option => this.setState({ method: option })}
              options={
                this.props.methods.map(
                  row => row.type
                ).filter(
                  row =>
                    data.methods.indexOf(
                      row
                    ) === -1
                ).map(type => {
                  return {
                    label: type,
                    value: type
                  }
                })
              }
            />

            <button
              onClick={
                () => this.props.edit(data.id, {
                  methods: data.methods.concat(
                    [this.state.method.value]
                  )
                })
              }
            >Add Method
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  country_order: state.payments.country_ordering,
  methods: state.payments.method_search_results.records
});

const mapDispatchToProps = dispatch => ({
  update: id => dispatch(updateCountryOrdering(id)),
  edit: (id, props) => dispatch(editCountryOrdering(id, props)),
  updateMethods: () => dispatch(updatePaymentMethods())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CountryOrder)
);