import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../../shared/pagination";
import "./style.css";

import { format } from '../../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const initialState = {
  query: "",
  page: 1,
  reportLoading: false,
  total_pages: 1,
  links: [],
};

const ListAffiliateLinks = () => {
  const [state, setState] = useState(initialState);
  const [page, setPage] = useState(initialState.page);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { records, meta } = await window.api.get(
          `/admin/affiliate_links?page=${page}`
        );
        setState({
          links: records,
          total_pages: meta.total_pages,
        });
      } catch (err) {
        toast.error(err.message);
      }
    };
    fetch();
  }, [page]);

  // const buildProjection = (id) => {
  //   window.api
  //     .post(`/admin/affiliate_links/${id}/build_projection`)
  //     .then(() =>
  //       toast.success(
  //         `Projection being rebuilt in the background, check back soon`
  //       )
  //     );
  // };

  const handleDelete = (id) => {
    window.api
      .delete(`/admin/affiliate_links/${id}`)
      .then(() => {
        toast.success("Link deleted");
        setState({
          ...state,
          links: state.links.filter((link) => link.code !== id),
        });
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Created</Th>
            <Th>ID</Th>
            <Th>Code</Th>
            <Th>Options</Th>
          </Tr>
        </Thead>

        <Tbody>
          {state.links.map((r, i) => {
            return (
              <Tr key={i}>
                <Td>{format.withMinutes(r.created_at)}</Td>
                <Td>{r.id}</Td>
                <Td>{r.code}</Td>
                <Td>
                  <ul className="affialiate-link-actions">
                    <li>
                      <Link to={`/casino/affiliate-links/detail/${r.code}`}>
                        <PreviewIcon />
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={() => handleDelete(r.code)}
                        className="small"
                      >
                        <DeleteIcon />
                      </button>
                    </li>
                  </ul>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        total_pages={state.total_pages}
        update={(page) => {
          setPage(page);
        }}
      />
    </div>
  );
};

export default ListAffiliateLinks;
