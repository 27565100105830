import * as types from './reward-types';

const initial = {
  mystery_boxes: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  items: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  price: null,
  mystery_box: {
    win_metrics: {},
    rewards: []
  },

  user_ranks: [],
  currency_rates: [],
};

export default (state = initial, action) => {

  switch(action.type) {
    case types.CASES_UPDATE:
      return {
        ...state,
        mystery_boxes: action.payload
      };

    case types.CASE_ITEMS_UPDATE:
      return {
        ...state,
        items: action.payload
      };

    case types.CASE_PRICE_UPDATE:
      return {
        ...state,
        price: action.payload
      };

    case types.CASE_UPDATE:
      return {
        ...state,
        mystery_box: action.payload
      };

    case types.ADD_CURRENCY_RATE:
      return {
        ...state,
        currency_rates: [...state.currency_rates, { currency: action.payload.currency }],
      };

    case types.DELETE_CURRENCY_RATE:
      return {
        ...state,
        currency_rates: state.currency_rates.filter(cr => cr.currency !== action.payload.currency),
      };

    case types.UPDATE_CURRENCY_RATE:
      const previousIndex = state.currency_rates.findIndex(cr => cr.currency === action.payload.previous);
      const newCurrencyRates = [...state.currency_rates];
      newCurrencyRates.splice(previousIndex, 1, { currency: action.payload.currency, rate: action.payload.rate });

      return {
        ...state,
        currency_rates: newCurrencyRates,
      };

    case types.LOAD_CURRENCY_RATES:
      return {
        ...state,
        currency_rates: action.payload,
      };

    case types.USER_RANKS_UPDATE:
      return {
        ...state,
        user_ranks: action.payload.map(
          rank => ({
            id: rank.type, // our datagrid uses the id prop to edit rows
            ...rank
          })
        )
      };
  }

  return state;
}