import React, { useState } from "react";

const userDataInitState = {
  email: "",
  password: "",
  dateOfBirth: "",
  enableTwoFactor: false,
}

const CreationStep = ({ onCreate }) => {
  const [{ email, password, dateOfBirth, enableTwoFactor }, setUserData] = useState(userDataInitState);

  const cleanUserData = () => {
    setUserData(userDataInitState)
  }

  const setEmail = (email) => {
    setUserData(prevState => ({ ...prevState, email }))
  };
  const setPassword = (password) => {
    setUserData(prevState => ({ ...prevState, password }))
  };

  const setDateOfBirth = (dateOfBirth) => {
    setUserData(prevState => ({ ...prevState, dateOfBirth }))
  };

  const setEnableTwoFactor = () => {
    setUserData(prevState => ({ ...prevState, enableTwoFactor: !enableTwoFactor }))
  };


  const handleCreate = async () => {
    onCreate({ email, password, dateOfBirth, enableTwoFactor });
    cleanUserData();
  }

  return <div>
    Email:
    <input
      value={email}
      onChange={(e) => {
        setEmail(e.target.value)
      }}
    />
    <br/>
    <br/>
    Password:
    <input
      placeholder="Password"
      type="password"
      value={password}
      onChange={(e) => {
        setPassword(e.target.value)
      }}
    />
    <br/>
    <br/>
    Date of birth (YYYY-MM-DD):
    <input value={dateOfBirth} onChange={e => {
      setDateOfBirth(e.target.value)
    }}
    />

    <br/>
    <br/>
    <div onClick={() => {
      setEnableTwoFactor()
    }}>
      <label>Enable two-factor: </label>
      <input checked={enableTwoFactor} onChange={() => { }} type={"checkbox"}/>
    </div>

    <br/>
    <br/>
    <button onClick={handleCreate}>
      Create User
    </button>
  </div>
}

export default CreationStep;