import Axios from "axios";
import { mockedObjectNew } from "./mockedData";

class ApiChallenge {
  client = null;
  baseURL = "/api";

  constructor() {
    const client = Axios.create({
      baseURL: this.baseURL,
    });
    client.interceptors.request.use((req) => {
      req.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
      req.headers["X-RateLimit-Bypass"] = "1";
      return req;
    });
    this.client = client;
  }

  mocked = mockedObjectNew;

  async getChallengeById(id) {
    const res = await this.client.get(`/admin/challenge/${id}`);
    return res.data;
  }

  deleteChallenge(id) {
    return this.client.delete(`/admin/challenge/${id}`);
  }

  createChallenge(challenge) {
    return this.client
      .post("/admin/challenge", challenge)
      .then((response) => response.data);
  }

  async getPresets() {
    return this.client.get("/admin/challenges/preset").then((res) => res.data);
  }

  async getChallenges(page = 1, status, sort) {
    let buildStringSearch = "/admin/challenges?";
    let first = false;
    if (status) {
      first = true;
      buildStringSearch += `status=${status}`;
    }
    if (page) {
      if (first) {
        buildStringSearch += `&page=${page}`;
      } else {
        first = true;
        buildStringSearch += `page=${page}`;
      }
    }

    if (sort) {
      if (first) {
        buildStringSearch += `&sort_by=${sort}`;
      } else {
        first = true;
        buildStringSearch += `sort_by=${sort}`;
      }
    }

    const res = await this.client.get(buildStringSearch);
    return res.data;
  }
}

export default new ApiChallenge();
