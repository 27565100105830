import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, NavLink, Switch, withRouter } from "react-router-dom";
import Finder from "casino//list-deposit-bonuses";
import Create from "casino/create-deposit-bonus";
import Bonus from "casino/view-deposit-bonus";
import BonusRoundWelcomePackages from "casino/bonus-round-welcome-packages";
import {
  GroupPermissionsFragment,
  permissionGroups,
  permissionsConstants,
  PermissionsFragment,
  CheckPermissionGroupRoute,
  CheckPermissionsRoute,
} from "app/permissions-fragment.js";
import { BrowserView } from "react-device-detect";

import {
  ViewBonusLink,
  CreateBonusLink,
  RoundWelcomePackagesLink,
} from "casino/tab-bonuses/links";

class DepositBonuses extends Component {
  render() {
    return (
      <div>
        <BrowserView>
          <nav className="sub-nav">
            <ViewBonusLink />
            <CreateBonusLink />
            <RoundWelcomePackagesLink />
          </nav>
        </BrowserView>
        <Switch>
          <CheckPermissionsRoute
            feature={permissionsConstants.BONUSES_VIEW_LIST}
            exact
            path="/casino/bonuses"
            component={Finder}
          />
          <CheckPermissionsRoute
            feature={permissionsConstants.BONUSES_CREATE_BONUS}
            exact
            path="/casino/bonuses/create"
            component={Create}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.REWARDS.BONUS_WELCOME_PACKAGE}
            path="/casino/bonuses/round-welcome-packages"
            component={BonusRoundWelcomePackages}
          />
          <CheckPermissionsRoute
            feature={permissionsConstants.BONUSES_VIEW_LIST}
            path="/casino/bonuses/:id"
            component={Bonus}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DepositBonuses)
);
