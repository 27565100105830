import {
  LookupCrashBetLink,
  LookupDoubleBetLink,
  LookupMinesBetLink,
  LookupMinesBetV2Link,
  LookupDiceBetLink,
  LookupCrashRoomBetLink,
} from "originals/tab-blaze-originals/links.js";
import { OriginalsLink } from "app/panel.js";

const originalsNodes = {
  key: "blaze-originals",
  label: "Originals",
  icon: "",
  component: () => <OriginalsLink />,
  nodes: [
    {
      key: "lookup-crash-bet",
      label: "Lookup Crash Bet",
      icon: "",
      component: () => <LookupCrashBetLink />,
      nodes: [],
    },
    {
      key: "lookup-double-bet",
      label: "Lookup Double Bet",
      icon: "",
      component: () => <LookupDoubleBetLink />,
      nodes: [],
    },
    {
      key: "lookup-mines-bet",
      label: "Lookup Mines Bet",
      icon: "",
      component: () => <LookupMinesBetLink />,
      nodes: [],
    },
    {
      key: "lookup-mines-bet-v2",
      label: "Lookup Mines Bet V2",
      icon: "",
      component: () => <LookupMinesBetV2Link />,
      nodes: [],
    },
    {
      key: "lookup-dice-bet",
      label: "Lookup Dice Bet",
      icon: "",
      component: () => <LookupDiceBetLink />,
      nodes: [],
    },
    {
      key: "room-bet",
      label: "Room Bet",
      icon: "",
      component: () => <LookupCrashRoomBetLink />,
      nodes: [],
    },
  ],
};

export default originalsNodes;
