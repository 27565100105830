import React from "react";
import Select from "react-select";

import "./styles/SelectStatus.scss";
const listOptions = [
  {
    id: "",
    label: "all",
  },
  {
    id: "created",
    label: "created",
  },
  {
    id: "completed",
    label: "completed",
  },
  {
    id: "expired",
    label: "expired",
  },
  {
    id: "ongoing",
    label: "ongoing",
  },
  {
    id: "cancelled",
    label: "cancelled",
  },
];
const SelectStatus = ({ select, setSelect }) => {
  return (
    <div className="selectsort">
      <Select
        className="selectsort__select"
        classNamePrefix="selectsort__select"
        options={listOptions}
        value={
          listOptions.find((option) => option.id === select) || listOptions[0]
        }
        onChange={({ id }) => {
          setSelect(id);
        }}
        placeholder="filter by status"
      />
    </div>
  );
};

export default SelectStatus;
