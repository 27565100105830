import { withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const listCoins = [
  { code: "GBP", name: "British Pound" },
  { code: "NOK", name: "Norwegian Krone" },
  { code: "SEK", name: "Swedish Krona" },
  { code: "CAD", name: "Canadian Dollar" },
  { code: "NZD", name: "New Zealand Dollar" },
  { code: "AUD", name: "Australian Dollar" },
  { code: "JPY", name: "Japanese Yen" },
  { code: "CNY", name: "Chinese Yuan" },
  { code: "RUB", name: "Russian Ruble" },
  { code: "USD", name: "United States Dollar" },
  { code: "TRY", name: "Turkish Lira" },
  { code: "EUR", name: "Euro" },
  { code: "mBTC", name: "MilliBitcoin" },
  { code: "BTC", name: "Bitcoin" },
  { code: "IDR", name: "Indonesian Rupiah" },
  { code: "MYR", name: "Malaysian Ringgit" },
  { code: "THB", name: "Thai Baht" },
  { code: "VND", name: "Vietnamese Dong" },
  { code: "BRL", name: "Brazilian Real" },
  { code: "ARS", name: "Argentine Peso" },
  { code: "UYU", name: "Uruguayan Peso" },
  { code: "MXN", name: "Mexican Peso" },
  { code: "PEN", name: "Peruvian Sol" },
  { code: "CLP", name: "Chilean Peso" },
  { code: "COP", name: "Colombian Peso" },
  { code: "PLN", name: "Polish Złoty" },
  { code: "UAH", name: "Ukrainian Hryvnia" },
];

const ListCurrencies = () => {
  return (
    <div>
      <div className="box-selects">
        <h1 className="text-box-select">Currencies Supported</h1>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>Symbol</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>

        <Tbody className="referrals-body">
          {listCoins?.map((item) => {
            return (
              <Tr key={item.code}>
                <Td>{item.code}</Td>
                <Td>{item.name}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};

export default withRouter(ListCurrencies);
