import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateWithdrawals } from "./actions";
import Pagination from '../shared/pagination';
import { format } from '../shared/utils/dates';
import ExportDataWithDateRangeModal from '../shared/export-data-with-date-range-modal';
import ExportDataButton from '../shared/export-data-button';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

class UserWithdrawals extends Component {
  constructor(props) {
    super();

    this.state = {
      page: 1,
      openExportDataModal: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  update(exportTo, startDate, endDate) {
    this.props.updateWithdrawals(this.props.match.params.id, this.state.page, startDate, endDate, exportTo);
  }

  getEvaluationTypes(evaluation) {
    return evaluation?.map(ev => ev.type)?.join(", ");
  }

  render() {
    return (
      <div>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>Type</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Note</Th>
              <Th>Wallet Id</Th>
              <Th>Transaction id</Th>
              <Th>Evaluation</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.props.records.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                  <Td>
                    <Link to={`/payments/withdrawals/${r.id}`}>
                      {r.id}
                    </Link>
                  </Td>
                  <Td>{r.type}</Td>
                  <Td>
                    {r.currency_type} {Math.abs(r.amount)}
                  </Td>
                  <Td>{r.status}</Td>
                  <Td>{r.note}</Td>
                  <Td>{r.wallet_id}</Td>
                  <Td>{r.transaction_id}</Td>
                  <Td>{this.getEvaluationTypes(r.evaluation)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          total_pages={this.props.meta.total_pages}
          update={(page) => {
            this.setState({ page }, () => this.update());
          }}
        />
        <ExportDataButton onClick={() => this.setState({ ...this.state, openExportDataModal: true })} />
        <ExportDataWithDateRangeModal
          isOpen={this.state.openExportDataModal}
          onClose={() => this.setState({ ...this.state, openExportDataModal: false })}
          downloadingReport={this.props.downloadingReport}
          onExport={(params) => {
            this.update(params.format, params.startDate, params.endDate);
          }}
        ></ExportDataWithDateRangeModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.users.withdrawals,
});

const mapDispatchToProps = (dispatch) => ({
  updateWithdrawals: (user_id, page, start, end, exportTo) =>
    dispatch(updateWithdrawals(user_id, page, start, end, exportTo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserWithdrawals);