import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCampaignsByAffiliateId } from './api';
import { setSelectedCampaign } from './state/actions';

import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { format } from '../shared/utils/dates';

function Campaigns(props) {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCampaigns = async () => {
      setLoading(true);

      const id = props.match?.params?.id;
      if (!id) throw new Error('No id provided');

      try {
        await new Promise((r) => setTimeout(r, 2000));
        const data = await getCampaignsByAffiliateId(id);
        setCampaigns(data.campaigns);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, []);

  return (
    <div className="affiliate-page">
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Code</Th>
            <Th>Link</Th>
            <Th>Commission Type</Th>
            <Th>Commission</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
            <Th>Affiliate ID</Th>
            <Th>Click Count</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>

        {loading && (
          <div className={'loading-overlay'}>
            <h2>Fetching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <Tbody>
          {campaigns?.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{item.id}</Td>
                <Td>{item.code}</Td>
                <Td>
                  <a href={item.link}>{item.link}</a>
                </Td>
                <Td>{item.commission_rate_type}</Td>
                <Td>{item.commission_rate_value}</Td>
                <Td>{format.onlyDate(item.created_at)}</Td>
                <Td>{format.onlyDate(item.updated_at)}</Td>
                <Td>{item.affiliate_id}</Td>
                <Td>{item.click_count}</Td>
                <Td><Link to={'/affiliates/system/update-campaign'} onClick={() => props.setSelectedCampaign(item)}>Edit</Link></Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setSelectedCampaign: (campaign) => dispatch(setSelectedCampaign(campaign)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
