import React, { Fragment } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

export const WithdrawalRefundableDeposits = ({ withdrawal, loading, refundDeposit }) => (
  <section className="refundable-deposits">
    <div className="row">
      <div className="col-md-12">
        <h2>Refundable deposits</h2>
        <Table>
          <Thead>
            <Tr>
              <Th>Deposit ID</Th>
              <Th>Provider TX ID</Th>
              <Th>Status</Th>
              <Th>Amount</Th>
              <Th>Refundable Amount</Th>
              <Th>Currency Type</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {withdrawal.refundable_deposits.map(d => {

              let refundable = +d.amount;

              if (d.deposit_refunds.length > 0) {
                refundable -= d.deposit_refunds.reduce((acc, b) => {
                  return acc + b.amount
                }, 0);
              }

              return (
                <Tr key={d.id}>
                  <Td>{d.id}</Td>
                  <Td>{d.provider_transaction_id}</Td>
                  <Td>{d.status}</Td>
                  <Td>{d.amount}</Td>
                  <Td>{(refundable || 0).toFixed(4)}</Td>
                  <Td>{d.currency_type}</Td>
                  <Td>
                    {refundable < withdrawal.amount && <button onClick={() => {
                      const confirm = window.confirm(`Refunding this deposit will subtract ${d.amount} ${withdrawal.currency_type} from the remaining withdrawal amount. withdrawal psp_amount will set to ${((withdrawal.psp_amount || withdrawal.amount) - d.amount).toFixed(4)} ${withdrawal.currency_type}. continue?`)
                      if (confirm) {
                        refundDeposit({ depositId: d.id })
                      }
                    }}>{loading ? 'Loading...' : 'Refund'}</button>
                    }


                    <button onClick={() => {
                      const partial = window.prompt(`Enter partial refund amount (Max amount = ${Math.min(refundable, withdrawal.psp_amount ? withdrawal.psp_amount : withdrawal.amount)}): `);
                      if (partial <= refundable && !isNaN(+partial) && partial !== null) {
                        refundDeposit({ depositId: d.id, amount: +partial })
                      }
                    }}>{loading ? 'Loading...' : 'Partial Refund'}</button>

                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  </section>
)