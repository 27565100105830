import Axios from "axios";
import { mockedObjectNew } from "./mockedData";

class ApiRace {
  client = null;
  baseURL = "/api/admin";

  constructor() {
    const client = Axios.create({
      baseURL: this.baseURL,
    });
    client.interceptors.request.use((req) => {
      req.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
      req.headers["X-RateLimit-Bypass"] = "1";
      return req;
    });
    this.client = client;
  }

  mocked = mockedObjectNew;

  async getRaceById(id) {
    const res = await this.client.get(`/race/${id}`);
    return res.data;
  }
  async getRaceIterationsById(id) {
    const res = await this.client.get(`/race/iterations/${id}`);
    return res.data;
  }
  async cancellRaceIterations(id) {
    const res = await this.client.delete(`/race/cancelactiveiterations/${id}`);
    return res.data;
  }
  async getRaceIterationsWinnerById(iteration) {
    const res = await this.client.get(`/race/iterations/winners/${iteration}`);
    return res.data;
  }
  async getRaceIterationsSaveWinnersById(iteration) {
    const res = await this.client.get(
      `/race/iterations/savewinners/${iteration}`
    );
    return res.data;
  }
  payallwinnersIteration(iteration) {
    return this.client.put(`/race/iterations/payallwinners/${iteration}`);
  }
  deleteRace(id) {
    return this.client.delete(`/race/${id}`);
  }
  createRace(race) {
    return this.client.post("/race", race).then((response) => response.data);
  }
  async getRaceIterationStatsById(iteration) {
    const res = await this.client.get(`/race/iterations/stats/${iteration}`);
    return res.data;
  }

  async getRaces(page = 1, status, sort) {
    let buildStringSearch = "/race?";
    let first = false;
    if (status) {
      first = true;
      buildStringSearch += `status=${status}`;
    }
    if (page) {
      if (first) {
        buildStringSearch += `&page=${page}`;
      } else {
        first = true;
        buildStringSearch += `page=${page}`;
      }
    }

    if (sort) {
      if (first) {
        buildStringSearch += `&sort_by=${sort}`;
      } else {
        first = true;
        buildStringSearch += `sort_by=${sort}`;
      }
    }

    const res = await this.client.get(buildStringSearch);
    return res.data;
  }
}

export default new ApiRace();
