import React, { useEffect, useState } from "react";
import "./PresetLoad.css";
import api from "../api";

const PresetLoad = ({ setFormData, setIsFeatured }) => {
  const [presets, setPresets] = useState([]);

  const getPresets = async () => {
    try {
      const presets = await api.getPresets();
      const { challenge_presets } = presets;
      setPresets(challenge_presets);
    } catch (error) {}
  };

  useEffect(() => {
    getPresets();
  }, []);

  const getChallengeById = async (id) => {
    try {
      const response = await api.getChallengeById(id);
      const { challenge } = response;
      const challengeToSave = {
        starts_at: challenge[0].created_at,
        expires_at: challenge[0].expires_at,
        min_multiplier: challenge[0].min_multiplier,
        reward_id: challenge[0].reward_id,
        game_id: challenge[0].game_id,
        eur_min_wagered: challenge[0].eur_min_wagered,
        currency_rates: challenge[0].currency_rates,
        name: challenge[0].name,
      };
      setIsFeatured(challenge[0].is_featured);
      setFormData(challengeToSave);
    } catch (error) {}
  };

  const resetLoad = () => {
    setFormData({
      starts_at: "",
      expires_at: "",
      min_multiplier: "",
      reward_id: "",
      game_id: 0,
      eur_min_wagered: 1,
      currency_rates: [],
    });
  };

  return (
    <div className="column presetLoad">
      <div className="presetLoad__info">
        <p>You can load a preset challenge.</p>
        <p>This will load the challenge with the default values.</p>
      </div>
      <div className="presetLoad__btn">
        <select
          className="presetLoad__select"
          onChange={(e) => getChallengeById(e.target.value)}
        >
          <option value="" disabled selected>
            Select a preset
          </option>
          {presets.map((preset) => (
            <option key={preset.id} value={preset.id}>
              {preset.name}
            </option>
          ))}
        </select>
        <button onClick={resetLoad}>Clear preset</button>
      </div>
    </div>
  );
};

export default PresetLoad;
