import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

import Pagination from '../shared/pagination';
import { fruitSliceUnlockStuckGame, getUserBets } from '../originals/originals-games-api';
import { format } from '../shared/utils/dates';
import { Collapse } from '@mui/material';
import TooltipWithInfoIcon from './components/TooltipWithInfoIcon';
import './user-fruits-slice-bets.scss';
import { ChevronRight } from '@mui/icons-material';

const UserFruitsSliceBets = () => {
  const [data, setData] = useState({ total_pages: 0, bets: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [collapseLockedSection, setCollapseLockedSection] = useState(true);
  
  const lockedRound = useMemo(() => {
    if (!data?.bets?.length) return false;

    const lastRound = data?.bets?.[0];

    const { status, game } = lastRound;

    const isInvalidState = game.bombCut && status === "created" && !game.roundOpen;

    return isInvalidState ? lastRound : undefined;
  }, [data.bets])
  
  const fetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getUserBets(id, { page, type: 'fruitSlice' });

      setData({ total_pages: data.pages, bets: data.records });
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  }, [id, page]);

  const switchCollapseLockedSection = () => {
    setCollapseLockedSection((prev) => !prev);
  }
  
  const unlockStuckGame = useCallback(async () => {
    try {
      await fruitSliceUnlockStuckGame(id);
      toast.success('Game unlocked successfully');
      fetch();
    } catch (err) {
      toast.error(err.message);
    }
  }, [fetch, id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (isLoading) {
    return (
      <div className={'loading-overlay'}>
        <h2>Loading, please wait ...</h2>
        <div className="loader" />
      </div>
    )
  }

  return (
    <div style={{ position: 'relative', minHeight: 500 }}>
      {
        lockedRound && (
          <article className={`user_stuck${!collapseLockedSection ? " user_stuck_open" : "" }`}>
            <div className="user_stuck_header" onClick={switchCollapseLockedSection}>
              <TooltipWithInfoIcon title="User has a locked round. This means that the user has a game that is on a status that doesn't allow it cashout or start a new game. This happens when user cuts a bomb and status was not updated properly to lost.">
                  <h4 className="user_stuck_title">Locked Game</h4>
              </TooltipWithInfoIcon>
              <ChevronRight className='user_stuck_collapse_icon' />
            </div>
            {
              !collapseLockedSection && (
                <div className="user_stuck_container">
                  <div className='user_stuck_info_box'>
                    <h5>Game info</h5>
                    <span>
                      Bet ID:{" "}
                    </span>
                    <Link to={`/casino/game-provider-rounds/${lockedRound.id}`}>
                      {lockedRound.id}
                    </Link>
                    <br />
                    Bet Slip ID: {lockedRound.betSlipId}<br/>
                    Remote Round ID: {lockedRound.remoteRoundId}<br/>
                    Round ID: {lockedRound.roundId}<br/>
                    Multiplier: {lockedRound.multiplier}<br/>
                    Status: {lockedRound.status}<br/>
                    RoundOpen: {lockedRound.game.roundOpen ? 'true' : 'false'}<br/>
                    Can cashout: {lockedRound.game.canCashout ? 'true' : 'false' }<br/> 
                    Bomb Cut: {lockedRound.game.bombCut ? 'true' : 'false'}<br/>
                    Number of bombs cut: {lockedRound.game.history.filter(({ isBomb }) => isBomb).length}<br/>
                    Total slices: {lockedRound.game.history.length}<br/>
                    Created At: {format.withSeconds(lockedRound.created_at)}<br/>
                    Updated At: {format.withSeconds(lockedRound.updated_at)}<br/>

                  </div>
                  <div className='user_stuck_info_box'>
                    <h5>Slices history</h5>
                    <div>
                      {lockedRound.game.history.map(({ type, multiplier, isBomb }, index) =>
                        <React.Fragment key={index + type}>
                          <p style={{ margin: 0 }}><span className='bad'>{isBomb && 'bomb'}</span> {type} - x{multiplier} </p>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className='user_stuck_info_box'>
                    <h5>Game actions</h5>
                    <div>
                      <div>
                        Updates game status to lost. This will allows user to start a new game.
                      </div>
                      <button className='user_stuck_action_button' onClick={unlockStuckGame}>Unlock manually</button>
                    </div>
                  </div>
                </div>
              )
            }
          </article>
        )
      }
      <Table>
        <Thead>
          <Tr>
            <Th>Bet ID</Th>
            <Th>Game Started At</Th>
            <Th>Amount</Th>
            <Th>Multiplier</Th>
            <Th>Winnings</Th>
            <Th>Fruits <button style={{
              height: "fit-content",
              width: "fit-content",
              padding: "0 2px",
              fontSize: "12px"
            }} onClick={() => setShowMore(prev => !prev)}>{showMore ? 'Hidden ' : 'Show '} All Fruits Slides</button></Th>
            <Th>Status</Th>
            <Th>Profit</Th>
            <Th>Type Round</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.bets.map((b, i) => {
            const {
              amount,
              profit,
              multiplier,
              currency_type,
              winnings,
              game,
            } = b;
            return (
              <Tr key={i}>
                <Td>
                  <Link to={`/casino/game-provider-rounds/${b.id}`}>
                    {b.id}
                  </Link>
                </Td>
                <Td>{format.withSeconds(b.created_at)}</Td>
                <Td>
                  {Number(amount).toFixed(4)} {currency_type}
                </Td>
                <Td>{multiplier.toFixed(4)}</Td>
                <Td className={profit >= 0 ? 'good' : ''}>
                  {Number(winnings) > 0
                    ? `${Number(winnings).toFixed(4)} ${currency_type}`
                    : '-'}
                </Td>
                <Td>
                  <Collapse in={showMore} collapsedSize={30} >
                    {b.game.history.sort((a) => (!a.isBomb ? -1 : 1)).map(({ type, multiplier, isBomb }, index) =>
                      <React.Fragment key={index + type}>
                        <p style={{ margin: 0 }}><span className='bad'>{isBomb && 'bomb'}</span> {type} - x{multiplier} </p>
                      </React.Fragment>
                    )}
                  </Collapse>
                </Td>
                <Td>{b.status}</Td>
                <Td className={profit >= 0 ? 'good' : game.bombCut ? 'bad' : ''}>
                  {game.bombCut ? `${Number(profit).toFixed(4)} ${currency_type}` : profit > 0 ? `${Number(profit).toFixed(4)} ${currency_type}` : '-'}
                </Td>
                <Td className={!b.wallet_params.free_bet ? 'good' : 'bad'}>
                  {b.wallet_params.free_bet ? 'Free' : 'Real'}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        total_pages={data.total_pages}
        update={(page) => setPage(page)}
      />
    </div>
  );
};

export default UserFruitsSliceBets;
