

import React, { Component } from 'react';
import Pagination from '../shared/pagination';

import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

class UserLoyalty extends Component {

  constructor(props) {
    super();

    this.state = {
      page: 1,
      records: [],
      meta: {

      },

      calculate: null
    }
  }

  componentDidMount() {
    this.update();
  }

  update() {
    window.api.get(`/admin/users/${this.props.match.params.id}/loyalty?page=${this.state.page}`)
      .then(data => {
        this.setState({
          ...data
        })
      })
  }


  calculate() {
    window.api.post(`/admin/users/${this.props.match.params.id}/loyalty/calculate`)
      .then(data => {
        this.setState({
          calculate: data
        })
      })
  }


  credit() {
    window.api.post(`/admin/users/${this.props.match.params.id}/loyalty/credit`)
      .then(data => {
        toast.success(`Credited`)
        this.setState({
          calculate: null
        })
        this.update();
      })
  }


  render() {

    return (
      <div>

        <div>
          <button onClick={() => this.calculate()}>Calculate current</button>
        </div>

        {this.state.calculate && (
          <div className={"row"}>
            <div className={"col-xs-3"}>
              <h4>Calculation Values</h4>
              {Object.keys(this.state.calculate.calculationValues).map(key => {


                return (
                  <div>{key}: {this.state.calculate.calculationValues[key]}</div>
                )
              })}
            </div>

            <div className={"col-xs-3"}>
              <h4>Property Values</h4>
              <div>rank: {this.state.calculate.rank}</div>
              <div>level: {this.state.calculate.level}</div>
              <div>percentage: {this.state.calculate.percentage}</div>
              <div>period: {this.state.calculate.period_start_time} - {this.state.calculate.period_end_time}</div>

            </div>

            <div className={"col-xs-3"}>
              <h4>Credit</h4>

              <div>Status: {this.state.calculate.type}</div>
              <div>Amount: {this.state.calculate.calculationValues.amount} {this.state.calculate.calculationValues.currency_type}</div>

              <button onClick={() => this.credit()}>Credit cashback</button>
            </div>

          </div>
        )
        }

        <hr />

        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Period</Th>
              <Th>Status</Th>
              <Th>Level</Th>
              <Th>Percentage</Th>
              <Th>Amount</Th>
              <Th>Calculation variables</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              this.state.records.map((r, i) => {

                r.loyalty_period = r.loyalty_period || {};
                r.loyalty_period.starts_at = r.loyalty_period.starts_at || "";
                r.loyalty_period.ends_at = r.loyalty_period.ends_at || "";

                r.user = r.user || {};

                r.transaction = r.transaction || { wallet: {} };

                return (
                  <Tr key={i}>
                    <Td>{r.loyalty_period.starts_at.split('T')[0]} - {r.loyalty_period.ends_at.split('T')[0]}</Td>
                    <Td>{r.id}</Td>
                    <Td>{r.status}</Td>
                    <Td>{r.level}</Td>
                    <Td>{r.percentage}</Td>
                    <Td>{r.transaction.amount || 0} {r.transaction.wallet.currency_type}</Td>
                    <Td>{Object.keys(r.calculation_values || {}).map(m => {
                      return <span style={{ marginRight: 5 }}>{m} = {r.calculation_values[m]}</span>
                    })}</Td>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>
        <Pagination
          total_pages={this.state.meta.total_pages}
          update={page => {
            this.setState({ page }, () => this.update());
          }}
        />
      </div>
    )
  }
}

export default withRouter(UserLoyalty);