import casinoNodes from "./casino";
import systemNodes from "./system";
import paymentNodes from "./payments";
import originalsNodes from "./originals";
import usersNodes from "./users";
import affiliatesNodes from "./affiliates";
import statusNodes from "./status";

const nodes = {
  casinoNodes,
  systemNodes,
  paymentNodes,
  originalsNodes,
  usersNodes,
  affiliatesNodes,
  statusNodes
};

export default nodes;
