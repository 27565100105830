
import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom";

import Finder from './finder';
import PixFinder from './pixFinder';
import AutomatedWithdrawals from './automated-finder';
import Withdrawal from './withdrawal';
import StuckFinder from './stuckFinder';

export default class Withdrawals extends Component {


  render() {
    return (
      <div>

        <Switch>
          <Route exact path={`/payments/withdrawals`} component={Finder}/>
          <Route exact path={`/payments/withdrawals/pix/tier1`} component={PixFinder}/>
          <Route exact path={`/payments/withdrawals/pix/tier2`} component={PixFinder}/>
          <Route exact path={`/payments/withdrawals/pix/tier3`} component={PixFinder}/>
          <Route exact path={`/payments/withdrawals/pix/tier4`} component={PixFinder}/>
          <Route exact path={`/payments/withdrawals/pix`} component={PixFinder}/>
          <Route exact path={`/payments/withdrawals/stuck`} component={StuckFinder}/>
          <Route exact path={`/payments/withdrawals/automated`} component={AutomatedWithdrawals}/>
          <Route exact path={`/payments/withdrawals/:id`} component={Withdrawal}/>
          <Route exact path={`/payments/stuck`} component={StuckFinder}/>
        </Switch>
      </div>
    )
  }
}