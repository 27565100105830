import React, { Component } from "react";
import { Route, NavLink, Switch, withRouter } from "react-router-dom";
import { BrowserView } from "react-device-detect";
import List from "system/list-announcements.js";
import Create from "system/create-announcement";
import { ListLink, CreateLink } from "./links";

export default class AnnouncementsPage extends Component {
  render() {
    return (
      <div>
        <BrowserView>
          <nav>
            <ListLink />
            <CreateLink />
          </nav>
        </BrowserView>

        <Switch>
          <Route exact path="/system/announcements" component={List} />
          <Route exact path="/system/announcements/new" component={Create} />
        </Switch>
      </div>
    );
  }
}
