import React, { useEffect, useState } from "react";
import Pagination from "../shared/pagination";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getUserBets } from "originals/originals-games-api";

const houseEdge = 4.16;
const profitThreshold = 50;
const lossThreshold = 50;

const getPayoutMultiplierPercentage = (threshold, rollover) => {
  const finalThreshold = rollover ? threshold : 100 - threshold;
  const denominator = 100 - finalThreshold;
  const numerator = denominator * (1 + houseEdge / 100);

  if (!numerator) return 0;

  return 99.99 / numerator;
};

const getThresholdPercentage = (rollOver, threshold, gainOpportunity) =>
  rollOver ? threshold : gainOpportunity;

const getRollOverLabel = (rollOverStatus) =>
  `ROLL ${rollOverStatus ? "OVER" : "UNDER"}`;

const getValidValue = (value, digits = 2) => {
  if (isNaN(value) || value === null) return "";
  return (+value).toFixed(digits);
};

const calculateWinLossPercentage = (bets) => {
  const totalBets = bets.length;
  const wins = bets.filter((bet) => bet.game.won).length;
  const losses = totalBets - wins;

  return {
    winPercentage: ((wins / totalBets) * 100).toFixed(2),
    lossPercentage: ((losses / totalBets) * 100).toFixed(2),
  };
};

const calculateStopAmount = (bets, thresholdProfit, thresholdLoss) => {
  let totalProfit = 0;
  let totalLoss = 0;

  bets.forEach((bet) => {
    const profit = parseFloat(bet.payload.profit);
    if (profit > 0) totalProfit += profit;
    else totalLoss += Math.abs(profit);
  });

  return {
    stopOnProfit: totalProfit >= thresholdProfit ? thresholdProfit : null,
    stopOnLoss: totalLoss >= thresholdLoss ? thresholdLoss : null,
  };
};

const UserDiceRewiredBets = ({ match }) => {
  const [data, setData] = useState({ bets: [], total_pages: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [activatedOldHistory, setActivatedOldHistory] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchBets = async (currentPage) => {
      setIsLoading(true);
      try {
        const fetchedData = await (activatedOldHistory
          ? window.api.get(
              `/admin/users/${match.params.id}/dice_bets?page=${currentPage}`
            )
          : getUserBets(match.params.id, {
              page,
              type: "dice",
            }));
        if (!fetchedData || !fetchedData.pages || !fetchedData.records) {
          throw new Error("Unexpected response format");
        }

        setData({ total_pages: fetchedData.pages, bets: fetchedData.records });
      } catch (err) {
        toast.error(err.message || "Failed to load data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBets(page);
  }, [page, match.params.id, activatedOldHistory]);

  const { winPercentage, lossPercentage } = calculateWinLossPercentage(
    data.bets
  );
  const thresholds = calculateStopAmount(
    data.bets,
    profitThreshold,
    lossThreshold
  );

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        style={{ color: "#97a8ff", cursor: "pointer", margin: "10px" }}
        onClick={() => setActivatedOldHistory((prev) => !prev)}
      >
        {activatedOldHistory ? "Dice History" : "Old Dice History"}
      </span>
      <div style={{ position: "relative", minHeight: "500px" }}>
        {isLoading && (
          <div className="loading-overlay">
            <h2>Loading, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        {data.bets && data.bets.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Round ID</Th>
                <Th>Bet Placed At</Th>
                <Th>Amount</Th>
                <Th>Roll Status</Th>
                <Th>Multiplier</Th>
                <Th>Threshold</Th>
                <Th>Roll</Th>
                <Th>Profit</Th>
                <Th>Total Bets</Th>
                <Th>On Win %</Th>
                <Th>On Loss %</Th>
                <Th>Stop on Profit Amount</Th>
                <Th>Stop on Loss Amount</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.bets.map((bet) => {
                const {
                  remoteRoundId,
                  created_at,
                  wallet_params,
                  game,
                  profit,
                  winnings,
                } = bet;

                const { amount, currency } = wallet_params;
                const { rollover, threshold, roll, multiplier } = game;
                const payout =
                  parseFloat(winnings) > 0
                    ? parseFloat(profit)
                    : -parseFloat(amount);
                const gainOpportunity = !rollover ? threshold : 100 - threshold;
                const thresholdPercentage = getThresholdPercentage(
                  rollover,
                  threshold,
                  gainOpportunity
                );
                const multiplierPercentage = getPayoutMultiplierPercentage(
                  threshold,
                  rollover
                );
                const rollLabel = getRollOverLabel(rollover);

                return (
                  <Tr key={remoteRoundId}>
                    <Td>
                      <Link
                        to={`/casino/game-provider-rounds/${remoteRoundId}`}
                      >
                        {remoteRoundId}
                      </Link>
                    </Td>
                    <Td>{format.withSeconds(created_at)}</Td>
                    <Td>
                      {parseFloat(amount).toFixed(2)} {currency}
                    </Td>
                    <Td>{rollLabel}</Td>
                    <Td>{getValidValue(multiplier, 2)}</Td>
                    <Td>{getValidValue(thresholdPercentage, 2)}</Td>
                    <Td>{getValidValue(roll, 2)}</Td>
                    <Td className={payout >= 0 ? "good" : "bad"}>
                      {getValidValue(payout, 2)} {currency}
                    </Td>
                    <Td>{data.bets.length}</Td>
                    <Td>{winPercentage} %</Td>
                    <Td>{lossPercentage} %</Td>
                    <Td>
                      {thresholds.stopOnProfit
                        ? `${thresholds.stopOnProfit} CAD`
                        : "-"}
                    </Td>
                    <Td>
                      {thresholds.stopOnLoss
                        ? `${thresholds.stopOnLoss} CAD`
                        : "-"}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <p>No data available</p>
        )}

        <Pagination
          total_pages={data.total_pages}
          update={(page) => setPage(page)}
        />
      </div>
    </>
  );
};

export default UserDiceRewiredBets;
