import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Settings from "system/settings";
import GamingServiceSettings from "../gaming-service-settings";
import {
  GroupPermissionsFragment,
  permissionGroups,
  CheckPermissionGroupRoute,
} from "app/permissions-fragment";
import Cashback from "system/weekly-cashback-retry";
import Raffle from "system/raffle";
import Announcements from "system/tab-announcements";
import StaffManager from "system/tab-support-manager";
import RouteMapping from "system/tab-route-mapping";
import AdminAnalytics from "system/tab-admin-analytics";
import { WaitListManagement } from "system/WaitListManagement";
import WaitListDetail from "system/WaitListDetail/WaitListDetail";
import Groups from "system/Groups";
import { BrowserView } from "react-device-detect";

import {
  AdminAnalyticsLink,
  AnnouncementsLink,
  GamingServiceSettingsLink,
  GroupsManagementLink,
  RaffleManagerLink,
  RouteMappingsLink,
  SettingsLink,
  StaffManagerLink,
  TicketIALink,
  WaitListManagementLink,
  WeeklyCashbackManagerLink,
} from "system/tab-system/links.js";
import { TicketIA } from "system/TicketIA";
import { isAdmin } from "app/api";

export default class SystemPage extends Component {
  render() {
    return (
      <div className="page">
        <BrowserView>
          <nav className="system-nav">
            <SettingsLink />
            <GamingServiceSettingsLink />
            <WaitListManagementLink />
            <GroupsManagementLink />
            <WeeklyCashbackManagerLink />
            <RaffleManagerLink />
            <AnnouncementsLink />
            <RouteMappingsLink />
            <StaffManagerLink />
            <AdminAnalyticsLink />
            {isAdmin() && <TicketIALink />}
          </nav>
        </BrowserView>
        <Switch>
          {isAdmin() && (
            <CheckPermissionGroupRoute
              group={permissionGroups.SYSTEM.SETTINGS}
              path="/system/ticketia"
              component={TicketIA}
            />
          )}
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.SETTINGS}
            path="/system/settings"
            component={Settings}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.GAMING_SERVICE_SETTINGS}
            path="/system/gaming-service-settings"
            component={GamingServiceSettings}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.WAITLIST_MANAGEMENT}
            exact
            path="/system/wait-list-management"
            component={WaitListManagement}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.WAITLIST_MANAGEMENT}
            path="/system/wait-list-management/:feature"
            component={WaitListDetail}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.GROUPS}
            exact
            path="/system/groups"
            component={Groups}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.GROUPS}
            exact
            path="/system/groups/:groupId"
            component={Groups}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.SETTINGS}
            path="/system/weekly"
            component={Cashback}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.RAFFLE_MANAGER}
            path="/system/raffle"
            component={Raffle}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.MARKETING.ANNOUNCEMENTS}
            path="/system/announcements"
            component={Announcements}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.SETTINGS}
            path="/system/routemappings"
            component={RouteMapping}
          />
          <CheckPermissionGroupRoute
            group={permissionGroups.SYSTEM.CUSTOM_ANALYTICS}
            path="/system/admin-analytics"
            component={AdminAnalytics}
          />

          <GroupPermissionsFragment group={permissionGroups.STAFF_MANAGER}>
            <Route path="/system/staff-manager" component={StaffManager} />
          </GroupPermissionsFragment>

          <Redirect from="/system" to="/system/settings" />
        </Switch>
      </div>
    );
  }
}
