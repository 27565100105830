import { updateRewards } from "casino/reward-actions";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import Select from "react-select";
import api from "./api";
import "./challenges.scss";
import Currency from "./Currency";
import useSportList from "./useSportList";

const calculateDate = (date) => {
  const localTimestamp = new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: false,
    // Add a leading zero to the hour if it is single-digit
    hourCycle: "h23",
  });
  const formattedTimestamp = localTimestamp.replace(" ", "T").replace(",", "");
  return formattedTimestamp.replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
};
const ViewChallenge = ({ rewards, history, updateRewards }) => {
  const [currencyRates, setCurrencyRates] = useState();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [listGames] = useSportList();

  const getChallenge = useCallback(async () => {
    const { challenge } = await api.getChallengeById(id);
    setFormData({
      ...challenge[0],
    });
    setCurrencyRates(challenge[0].currency_rates);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getChallenge();
  }, [getChallenge]);
  useEffect(() => {
    updateRewards();
  }, [updateRewards]);

  const handleUpdateField = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  if (loading) {
    return <h1>... Loading</h1>;
  }
  const {
    game_id,
    expires_at,
    starts_at,
    min_multiplier,
    reward_id,
    eur_min_wagered,
    is_featured,
  } = formData;
  return (
    <div className="margin-left-form-challenge">
      <h1>Challenge Details</h1>
      <form className="form-challenge">
        <div className="row-challenge">
          <div className="column">
            <h1 className="label-challenge ">Start Date</h1>
            <input
              value={calculateDate(starts_at)}
              name="starts_at"
              type={"datetime-local"}
              disabled={true}
              onChange={handleUpdateField}
            />
          </div>
          <div className="column">
            <h1 className="label-challenge ">Expires Date</h1>
            <input
              value={calculateDate(expires_at)}
              name="expires_at"
              disabled={true}
              type={"datetime-local"}
              onChange={handleUpdateField}
            />
          </div>
          <div className="column">
            <h1 className="label-challenge padding-left-challenge">
              Multiplier
            </h1>
            <input
              name="min_multiplier"
              value={min_multiplier}
              disabled={true}
              placeholder={"Multiplier"}
              onChange={handleUpdateField}
            />
          </div>
          <div className="column">
            <h1 className="label-challenge padding-left-challenge">
              Minimum Wagered (EUR)
            </h1>
            <input
              type={"number"}
              disabled={true}
              name="eur_min_wagered"
              value={eur_min_wagered}
              onChange={handleUpdateField}
            />
          </div>
        </div>
        <span className="time-challenges"></span>
        <div className="row-challenge">
          <div className="column">
            <label>Games</label>
            <Select
              name="game_id"
              disabled={true}
              value={game_id}
              style={{ width: "300px" }}
              onChange={(e) => {
                setFormData({ ...formData, game_id: e.value });
              }}
              options={listGames}
            />
          </div>
          <div className="column">
            <label>Reward</label>
            <Select
              name="reward_id"
              disabled={true}
              value={reward_id}
              onChange={(e) => {
                setFormData({ ...formData, reward_id: e.value });
              }}
              className="rewardSelect"
              options={rewards.map((reward) => ({
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img style={{ width: "30px" }} src={reward.image_url} />
                    (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
                  </div>
                ),
                value: reward.id,
              }))}
            />
          </div>
          <div className="column featured-column">
            <label>Is featured?</label>
            <input
              className="featured-input"
              type="checkbox"
              disabled={true}
              checked={is_featured}
            />
          </div>
        </div>
        <div className="row-challenge">
          <div className="column">
            <Currency
              currencyRates={currencyRates}
              setCurrencyRates={setCurrencyRates}
              disabled={true}
            />
          </div>
        </div>
        <button
          onClick={() => {
            history.push(`/casino/challenges`);
          }}
          className="create-challenge-button"
        >
          Back
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rewards: state.rewards.items.records,
});
const mapDispatchToProps = (dispatch) => ({
  updateRewards: () => dispatch(updateRewards()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewChallenge)
);
