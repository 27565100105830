import { createSelector } from "reselect";

const _currenciesSelector = state => state.app.currencies;

export const currenciesSelector = createSelector(
  [
    _currenciesSelector,
  ],
  (
    currencies,
  ) => ({
    currencies,
  })
);
