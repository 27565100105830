import React, { Component } from "react";
import Panel from "./panel";
import { Route, Redirect, withRouter } from "react-router-dom";
import { login, updateUser, updateMeta, updateCurrencies } from "./actions";
import { isMobile } from "react-device-detect";

import "./style.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "casino/customTableCSS.scss";
import "react-select/dist/react-select.css";
import "react-toastify/dist/ReactToastify.css";
import "flexboxgrid/dist/flexboxgrid.min.css";
import "react-table/react-table.css";

import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { clientConfig } from "./config";
import { registerSessionExpiredCallback } from "./api";

const DevWarning = () =>
  process.env.NODE_ENV === "development" && (
    <h1
      style={{
        color: "yellow",
        textAlign: "center",
        position: "fixed",
        right: isMobile ? "50%" : "10%",
        zIndex: 10,
      }}
    >
      Dev
    </h1>
  );

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      sessionExpired: false,
    };
  }

  handleLogin = () => {
    this.props.login(this.username.value, this.password.value, this.state.code);
  };

  componentDidMount() {
    this.props.updateUser();
    this.props.updateMeta();
    this.props.updateCurrencies();

    window.api.get(`/version`).then(({ version }) => {
      console._log(
        `[Blaze.com] Admin version: ${clientConfig.version}, Server version: ${version}`
      );
    });

    registerSessionExpiredCallback(() => {
      console.log("session expired");
      this.setState({
        sessionExpired: true,
      });
    });
  }

  renderContent() {
    if (window.api.isAuthed()) {
      if (this.state.sessionExpired) {
        return (
          <div className="session-expired">
            <h1>Your session has expired</h1>
            <a>
              <button
                onClick={() => {
                  localStorage.clear("access_token");
                  window.location.reload();
                }}
              >
                Sign in
              </button>
            </a>
          </div>
        );
      }
      if (!this.props.app.user.id) {
        return <h1>Loading...</h1>;
      }
    }

    return window.api.isAuthed() ? (
      <Panel />
    ) : (
      <div>
        <h1>Please Login</h1>
        <input
          placeholder="Username"
          ref={(el) => (this.username = el)}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              this.handleLogin();
            }
          }}
        />
        <br />
        <br />
        <input
          placeholder="Password"
          type="password"
          ref={(el) => (this.password = el)}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              this.handleLogin();
            }
          }}
        />
        <br />
        <br />
        <input
          placeholder="Two factor code"
          value={this.state.code}
          onChange={(ev) => {
            this.setState({
              code: ev.target.value.replace(" ", ""),
            });
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              this.handleLogin();
            }
          }}
        />
        <br />
        <br />
        <button onClick={this.handleLogin}>Login</button>
      </div>
    );
  }

  render() {
    const mobileClassName = isMobile ? "mobileVersion" : "";
    return (
      <div id="container" className="tk-proxima-nova">
        <div className={`content ${mobileClassName}`}>
          <DevWarning />
          {this.renderContent()}
        </div>

        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: () => dispatch(updateUser()),
  updateMeta: () => dispatch(updateMeta()),
  login: (username, password, code) =>
    dispatch(login(username, password, code)),
  updateCurrencies: () => dispatch(updateCurrencies()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
