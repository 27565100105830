import React, { Component } from "react";
import Pagination from "../../shared/pagination";
import "./games.css";
import { Link } from "react-router-dom";
import { clientConfig } from "../../app/config";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import Select from "react-select";

class GamesFinder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      page: 1,
      records: [],
      meta: {},

      showEnabled: true,
      showDisabled: true,
      showNewlyReleased: true,
      onlyFreeSpinsAllowed: false,
      onlyDemoAllowed: false,

      categories: [],
      ordinality: {},
    };
  }

  componentDidMount() {
    this.search();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      this.search();
    }
  }

  async search() {
    let params = {
      name: this.state.name,
      page: this.state.page,
    };

    if (this.state.showEnabled && !this.state.showDisabled) {
      params.enabled = true;
    } else if (!this.state.showEnabled && this.state.showDisabled) {
      params.enabled = false;
    }
    if (this.state.showNewlyReleased) params.newlyReleased = true;
    else params.newlyReleased = false;

    const data = await window.api({
      method: "GET",
      url: "/admin/games",
      params,
    });

    this.setState({
      records: data.records || [],
      meta: data.meta || {},
    });
  }

  getItems() {
    let rows = this.state.records || [];
    if (this.state?.onlyFreeSpinsAllowed) {
      rows = rows.filter((i) => i?.free_spins_allowed);
    }

    if (this.state?.onlyDemoAllowed) {
      rows = rows.filter((i) => i?.demo_disabled === false);
    }
    return rows;
  }

  async enable(providerSlug, gameSlug) {
    await window.api.post(
      `/admin/game_providers/${providerSlug}/games/${gameSlug}/enable`
    );
    this.search();
  }

  async disable(providerSlug, gameSlug) {
    await window.api.post(
      `/admin/game_providers/${providerSlug}/games/${gameSlug}/disable`
    );
    this.search();
  }

  async enableDemo(gameSlug) {
    await window.api.post(`/admin/games/${gameSlug}/disable_demo`, {
      demo_disabled: false,
    });
    this.search();
  }

  async disableDemo(gameSlug) {
    await window.api.post(`/admin/games/${gameSlug}/disable_demo`, {
      demo_disabled: true,
    });
    this.search();
  }

  render() {
    return (
      <div>
        <h2>Matching Games: {this.state.meta.total_records}</h2>

        <div className="input-row">
          <input
            value={this.state.name}
            onChange={(ev) => {
              this.setState(
                {
                  name: ev.target.value,
                },
                () => {
                  clearTimeout(this.timeout);
                  this.timeout = setTimeout(() => {
                    this.search();
                  }, 400);
                }
              );
            }}
            placeholder={"Search (game name, provider name or category name)"}
          />
        </div>
        <br />
        <div>
          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState(
                {
                  showEnabled: !this.state.showEnabled,
                },
                this.search
              );
            }}
          >
            Show Enabled{" "}
            <input checked={this.state.showEnabled} type={"checkbox"} />
          </span>
          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState(
                {
                  showDisabled: !this.state.showDisabled,
                },
                this.search
              );
            }}
          >
            Show Disabled{" "}
            <input checked={this.state.showDisabled} type={"checkbox"} />
          </span>
          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState(
                {
                  showNewlyReleased: !this.state.showNewlyReleased,
                },
                this.search
              );
            }}
          >
            Newly Released{" "}
            <input checked={this.state.showNewlyReleased} type={"checkbox"} />
          </span>
          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState({
                onlyFreeSpinsAllowed: !this.state.onlyFreeSpinsAllowed,
              });
            }}
          >
            Show Free Spins Allowed{" "}
            <input
              checked={this.state.onlyFreeSpinsAllowed}
              type={"checkbox"}
            />
          </span>
          <span
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.setState({
                onlyDemoAllowed: !this.state.onlyDemoAllowed,
              });
            }}
          >
            Show Demo Allowed{" "}
            <input checked={this.state.onlyDemoAllowed} type={"checkbox"} />
          </span>
        </div>
        <br />
        <br />

        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Provider</Th>
              <Th>Aggregator</Th>
              <Th>Slug</Th>
              <Th>Enabled</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.getItems().map((r, i) => {
              const isEnabled = r.enabled;
              const demoIsDisabled = r.demo_disabled;
              return (
                <Tr key={i}>
                  <Td>
                    <Link to={`/casino/games/${r.slug}`}>{r.name}</Link>
                  </Td>
                  <Td>
                    <Link to={`/casino/game-providers/${r.provider.slug}`}>
                      {r.provider.name}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/casino/games/${r.slug}`}>
                      {r.remote_id ? r.remote_id.split(":")[0] : ""}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/casino/games/${r.slug}`}>{r.slug}</Link>
                  </Td>
                  <Td>
                    <button
                      onClick={() =>
                        isEnabled
                          ? this.disable(r.provider.slug, r.slug)
                          : this.enable(r.provider.slug, r.slug)
                      }
                      className="small"
                    >
                      {isEnabled ? `Disable` : `Enable`}
                    </button>

                    <button
                      onClick={() =>
                        demoIsDisabled
                          ? this.enableDemo(r.slug)
                          : this.disableDemo(r.slug)
                      }
                      className="small"
                    >
                      {demoIsDisabled ? `Enable Demo` : `Disable Demo`}
                    </button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.state.meta.total_pages}
          update={(page) => {
            this.setState({ page });
          }}
        />
      </div>
    );
  }
}

export default GamesFinder;
