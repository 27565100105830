import * as types from "./types";
import { toast } from "react-toastify";

export const updateDepositBonuses = (page, queryFilter = {}) => {
  let buildFilters;
  for (const [key, value] of Object.entries(queryFilter)) {
    let filter;
    if (value.trim("")) {
      filter = `${key}=${value}`;
    }
    if (filter) {
      if (buildFilters) {
        buildFilters += "&" + filter;
      } else {
        buildFilters = "&" + filter;
      }
    }
  }
  if (!buildFilters) buildFilters = "";
  return (dispatch) => {
    window.api.get("/admin/bonuses?page=" + (page || 1)+buildFilters).then((data) => {
      dispatch({
        type: types.BONUSES_UPDATE,
        payload: data,
      });
    });
  };
};

const upload_or_get_default = async(file) => {
  const default_image_url = process.env.REACT_APP_DEFAULT_IMAGE_URL || '';

  if (!default_image_url) {
    return await window.api.upload(file, "bonuses");
  }

  return await default_image_url;
}

export const createDepositBonus = (props) => {
  for (let key in props) {
    if (props[key] === "") {
      props[key] = null;
    }
  }

  return async (dispatch) => {
    props.image_url = await upload_or_get_default(props.file);
    delete props.file;

    window.api.post("/admin/bonuses", props).then((data) => {
      toast.success(`Bonus created (${data.id}).`);

      dispatch(updateDepositBonuses());
    });
  };
};

export const getUserBonusActivations = userId => {
  return async dispatch => {
    const url = `/admin/bonuses/bonus_activations?userId=${userId}&status=transferred`;
    const bonusActivations = await window.api.get(url);
    dispatch({
      type: types.BONUS_ACTIVATIONS_ON_WITHDRAWAL_UPDATE,
      payload: bonusActivations,
    })
  };
};

export const setReviewStatus = (userId, activationId, reviewStatus) => {
  return async dispatch => {
    const url = `/admin/bonuses/bonus_activations/${activationId}/review_status`;
    await window.api.put(url, { status: reviewStatus });
    dispatch(getUserBonusActivations(userId))
  };
};
