import { useState } from "react";
import { connect } from "react-redux";

import { Typography, Stack, FormControl, TextField, Button } from "@mui/material";

import { closeAccount } from "user/actions";

const ReopenAccountForm = ({ user_id, closeAccount }) => {
  const [reason, setReason] = useState('');

  const reopenAccountAction = (e) => {
    e.preventDefault();

    if (!reason) {
      alert("The reson field is required!");
      return;
    }

    closeAccount(user_id, true, reason.trim());
  };

  return (
    <Stack direction='column' gap={2}>
      <Typography variant="h4" component="h1">Reopen Account</Typography>
      <Stack direction='row' gap={2}>
        <FormControl fullWidth>
          <TextField
            name="reason"
            label="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            autoComplete="off"
            required
          />
        </FormControl>
        <Button
          variant='contained'
          color='secondary'
          size="small"
          onClick={reopenAccountAction}
        >Reopen Account</Button>
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  closeAccount(user_id, remove, reason) {
    dispatch(closeAccount(user_id, remove, reason));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReopenAccountForm);