import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from '../shared/utils/dates';
import Pagination from '../shared/pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import bettingApi from 'app/http-betting-api';

const TABLE_HEADERS = {
   oldDouble: [
      'Bet ID',
      'Game Started At',
      'Game Settled At',
      'Bet Placed At',
      'Bet Settled At',
      'Amount',
      'Bet Color',
      'Winning Color',
      'Winnings',
      'Status',
      'Profit',
   ],
   double: [
      'Round ID',
      'Bet ID',
      'Game Started At',
      'Game Settled At',
      'Bet Placed At',
      'Bet Settled At',
      'Amount',
      'Bet Color',
      'Winning Color',
      'Free',
      'Winnings',
      'Status',
      'Profit',
   ]
};

export default class UserDoubleBets extends Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 1,
         total_pages: 0,
         bets: [],
         queryFilter: {},
         hasChange: false,
         gameStartedAtStart: null,
         gameStartedAtEnd: null,
         gameSettledAtStart: null,
         gameSettledAtEnd: null,
         joinDateStarted: null,
         lastDepositStarted: null,
         joinDateSettled: null,
         lastDepositSettled: null,
         activatedOldHistory: false,
         isLoading: false,
      };
   }

   componentDidMount() {
      this.fetch();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (
         prevState.page !== this.state.page ||
         prevState.activatedOldHistory !== this.state.activatedOldHistory
      ) {
         this.fetch();
      }
   }

   async fetch() {
      this.setState({ isLoading: true });
      try {
         const { total_pages, records } =  await (this.state.activatedOldHistory 
            ? window.api.get(
               `/admin/users/${this.props.match.params.id}/roulette_bets?page=${this.state.page}`,
            )
            : bettingApi.get(
               `/admin/users/${this.props.match.params.id}/double-bets?page=${this.state.page}`,
            )); 
         this.setState({ total_pages, bets: records });
      } catch (err) {
         toast.error(err.message);
      } finally {
         this.setState({ isLoading: false });
      }
   }

   async rollback(id) {
      try {
         await window.api.post(`/admin/roulette_bets/${id}`);
         toast.success('Bet rolled back');
      } catch {
         toast.error('Bet already rolled back.');
      }
      this.fetch();
   }

   renderBetsTable() {
      const { bets, activatedOldHistory } = this.state;

      const headers = activatedOldHistory
         ? TABLE_HEADERS.oldDouble
         : TABLE_HEADERS.double;

      return (
         <Table>
            <Thead>
               <Tr>
                  {headers.map((header) => (
                     <Th key={header}>{header}</Th>
                  ))}
               </Tr>
            </Thead>
            <Tbody>
               {bets.map((bet, i) => (
                  <Tr key={i}>
                     <Td>
                        {bet.free_bet ? (
                           <span>{bet.round_id}</span>
                        ) : (
                           <Link
                              to={`/casino/game-provider-rounds/${
                                 activatedOldHistory
                                    ? bet.round_id
                                    : bet.remote_round_id
                              }`}
                           >
                              {activatedOldHistory ? bet.round_id : bet.remote_round_id}
                           </Link>
                        )}
                     </Td>
                     {!activatedOldHistory && <Td>{bet.round_id}</Td>}
                     <Td>{format.withSeconds(bet.game_started_at)}</Td>
                     <Td>{format.withSeconds(bet.game_settled_at)}</Td>
                     <Td>{format.withSeconds(bet.bet_placed_at)}</Td>
                     <Td>{format.withSeconds(bet.bet_settled_at)}</Td>
                     <Td className={bet.profit > 0 ? 'good' : 'bad'}>
                        {bet.amount} {bet.currency_type}
                     </Td>
                     <Td>{bet.bet_color}</Td>
                     <Td>{bet.winning_color}</Td>
                     {!activatedOldHistory && <Td>{bet.free_bet ? 'Yes' : 'No'}</Td>}
                     <Td className={bet.profit > 0 ? 'good' : 'bad'}>
                        {bet.win_amount} {bet.currency_type}
                     </Td>
                     <Td>{bet.status}</Td>
                     <Td className={bet.profit > 0 ? 'good' : 'bad'}>
                        {bet.profit} {bet.currency_type}
                     </Td>
                  </Tr>
               ))}
            </Tbody>
         </Table>
      );
   }

   render() {
      const { activatedOldHistory, total_pages, isLoading } = this.state;

      return (
         <>
            <span
               role="button"
               tabIndex={0}
               style={{ color: '#97a8ff', cursor: 'pointer', margin: '10px' }}
               onClick={() =>
                  this.setState((prevState) => ({
                     activatedOldHistory: !prevState.activatedOldHistory,
                  }))
               }
            >
               {activatedOldHistory ? 'Double V2 History' : 'Old Double History'}
            </span>

            <div style={{ position: 'relative', 'min-height': '500px' }}>
               {isLoading && (
                 <div className={'loading-overlay'}>
                     <h2>Loading, please wait ...</h2>
                     <div className='loader' />
                  </div>
               )}

               {this.renderBetsTable()}

               <Pagination
                  total_pages={total_pages}
                  update={(page) => this.setState({ page })}
               />
            </div>
         </>
      );
   }
}