import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../shared/pagination";
import { getUserBets } from "../originals/originals-games-api";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

export default class UserMinesBets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      total_pages: 0,
      bets: [],
      queryFilter: {},
      hasChange: false,
      gameStartedAtStart: null,
      gameStartedAtEnd: null,
      gameSettledAtStart: null,
      gameSettledAtEnd: null,
      joinDateStarted: null,
      lastDepositStarted: null,
      joinDateSettled: null,
      lastDepositSettled: null,
      activatedOldHistory: false,
      isLoading: false,
    };
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.page !== this.state.page ||
      prevState.activatedOldHistory !== this.state.activatedOldHistory
    ) {
      this.fetch();
    }
  }

  fetch = async () => {
    this.setState({ isLoading: true });
    try {
      const data = await (this.state.activatedOldHistory 
        ? window.api.get(
          `/admin/users/${this.props.match.params.id}/mines_bets?page=${this.state.page}`
        )
        : getUserBets(this.props.match.params.id, {
          page: this.state.page,
          type: "mines",
        }));  
      this.setState({ total_pages: data.pages, bets: data.records });
    } catch (err) {
      toast.error(err.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toggleHistoryType = () => {
    this.setState((prevState) => ({
      activatedOldHistory: !prevState.activatedOldHistory,
    }));
  };

  renderBetRow = (bet, isOldHistory) => {
    const roundId = isOldHistory ? bet.round_id : bet.remoteRoundId;
    return (
    <Tr key={bet.id}>
      <Td>
        {bet.wallet_params?.free_bet && !isOldHistory ? (
          bet.id
        ) : (
          <Link
            to={`/casino/game-provider-rounds/${roundId}`}
          >
            {roundId}
          </Link>
        )}
      </Td>
      <Td>{format.withSeconds(bet.created_at)}</Td>
      {isOldHistory && (
        <>
          <Td>{format.withSeconds(bet.bet_placed_at)}</Td>
          <Td>{format.withSeconds(bet.bet_settled_at)}</Td>
        </>
      )}
      <Td>
        {bet.amount} {bet.currency_type}
      </Td>
      <Td>{bet.number_of_mines}</Td>
      <Td>{bet.tiles_shown}</Td>
      {!isOldHistory && <Td>{bet.wallet_params?.free_bet ? "Yes" : "No"}</Td>}
      <Td className="good">
        {isOldHistory ? bet.win_amount : bet.winnings} {bet.currency_type}
      </Td>
      <Td>{bet.status}</Td>
      <Td className={bet.profit >= 0 ? "good" : "bad"}>
        {bet.profit} {bet.currency_type}
      </Td>
    </Tr>
  )
};

  renderTable = (isOldHistory) => (
    <Table>
      <Thead>
        <Tr>
          <Th>{isOldHistory ? "Round ID" : "Bet ID"}</Th>
          <Th>Game Created At</Th>
          {isOldHistory && (
            <>
              <Th>Bet Placed At</Th>
              <Th>Bet Settled At</Th>
            </>
          )}
          <Th>Amount</Th>
          <Th>Number of Mines</Th>
          <Th>Tiles Shown</Th>
          {!isOldHistory && <Th>Free</Th>}
          <Th>Winnings</Th>
          <Th>Status</Th>
          <Th>Profit</Th>
        </Tr>
      </Thead>
      <Tbody>
        {this.state.bets.map((bet) => this.renderBetRow(bet, isOldHistory))}
      </Tbody>
    </Table>
  );

  render() {
    const { activatedOldHistory, isLoading, total_pages } = this.state;

    return (
      <>
        <span
          role="button"
          tabIndex={0}
          style={{ color: '#97a8ff', cursor: 'pointer', margin: '10px' }}
          onClick={this.toggleHistoryType}
        >
          {this.state.activatedOldHistory ? 'Mines History' : 'Old Mines History'}
        </span>
        <div style={{ position: "relative", "min-height": "500px" }}>
          {isLoading ? (
            <div className={"loading-overlay"}>
              <h2>Loading, please wait ...</h2>
              <div className="loader" />
            </div>
          ) : (
            this.renderTable(activatedOldHistory)
          )}
          <Pagination
            total_pages={total_pages}
            update={(page) => this.setState({ page })}
          />
        </div>
      </>
    );
  }
}
