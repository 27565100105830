
import { useState } from "react";
import { connect } from "react-redux";

import { Typography, Stack, InputLabel, Select, FormControl, MenuItem, TextField, Button } from "@mui/material";

import { closeAccount } from "user/actions";


import { CLOSE_ACCOUNT_REASON_OPTIONS } from '../constants';

const CloseAccountForm = ({ user_id, closeAccount }) => {

  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');

  const closeAccountAction = (e) => {
    e.preventDefault();

    if (!reason) {
      alert("Must select a reson!");
      return;
    }

    closeAccount(user_id, false, `${reason}. ${comment}`.trim());
  };

  return (
    <Stack direction='column' gap={2}>
      <Typography variant="h4" component="h1">Close Account</Typography>
      <Stack direction='row' gap={2}>
        <FormControl sx={{ minWidth: 200 }} >
          <InputLabel htmlFor="reason">Select a reason</InputLabel>
          <Select
            id="reason"
            name="reason"
            value={reason}
            label="Select a reason"
            onChange={(e) => setReason(e.target.value)}
          >
            {CLOSE_ACCOUNT_REASON_OPTIONS.map(reason => (
              <MenuItem key={reason.id} value={reason.reason}>{reason.reason}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            autoComplete="off"
          />
        </FormControl>
        <Button
          variant='contained'
          color='secondary'
          size="small"
          onClick={closeAccountAction}
        >Close Account</Button>
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  closeAccount(user_id, remove, reason) {
    dispatch(closeAccount(user_id, remove, reason));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseAccountForm);
