import { useState } from "react";
import { format } from "../../shared/utils/dates";
import "./TimezoneSelect.css";

const TimezoneSelect = () => {
  const preferredTimezone = localStorage.getItem("preferredTimezone") || "None";
  const [timezones] = useState([
    { id: "GMT-3", value: "America/Sao_Paulo" },
    { id: "UTC", value: "UTC" },
    { id: "None", value: "None" },
  ]);
  const [selected, setSelected] = useState(preferredTimezone);

  const onChange = (event) => {
    if (event?.target?.value) {
      setSelected(event?.target?.value);
      localStorage.setItem("preferredTimezone", event?.target?.value);
      window.location.reload();
    }
  };

  return (
    <div className="timezone-select-container">
      <div className="timezone-select-input">
        <span>Now: {format.withMinutes(new Date())}</span>
        <select value={selected} onChange={onChange}>
          {timezones.map((timezone) => (
            <option value={timezone.value}>{timezone.id}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TimezoneSelect;
