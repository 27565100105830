

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateOTPFingerprints, deletePhoneNumber } from "./actions";
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { permissionsConstants, PermissionsFragment } from 'app/permissions-fragment';
class UserOTPFingerprints extends Component {

    constructor(props) {
        super();

        this.state = {
            page: 1
        }
    }

    componentDidMount() {
        this.update();
    }

    update() {
        this.props.updateFingerprints(this.props.match.params.id)
    }

    async deletePhone(userId, phoneNumber) {
        await deletePhoneNumber(userId, phoneNumber)
        this.props.updateFingerprints(this.props.match.params.id)
    }

    render() {
        return (
            <div>
                <h1>Phones</h1>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Updated at</Th>
                            <Th>Created at</Th>
                            <Th>Deleted at</Th>
                            <Th>Number</Th>
                            <Th>Country</Th>
                            <Th>Enabled</Th>
                            <Th>Status Detail</Th>
                            <Th>Validated</Th>
                            <PermissionsFragment feature={permissionsConstants.USER_OTP_DELETE_PHONE}>
                                <Th>Delete</Th>
                            </PermissionsFragment>

                        </Tr>
                    </Thead>

                    <Tbody>
                        {
                            this.props?.userPhones?.map((r, i) => {
                                return (
                                    <Tr key={i}>
                                        <Td>{r.id}</Td>
                                        <Td>{format.withMinutes(r.created_at)}</Td>
                                        <Td>{format.withMinutes(r.updated_at)}</Td>
                                        <Td>{format.withMinutes(r.deleted_at) === "Invalid date" ? '-' : format.withMinutes(r.deleted_at)}</Td>
                                        <Td>{r.number}</Td>
                                        <Td>{r.country}</Td>
                                        <Td>{r.enabled ? 'true' : 'false'}</Td>
                                        <Td>{r.status_detail}</Td>
                                        <Td>{r.validated ? 'true' : 'false'}</Td>
                                        <PermissionsFragment feature={permissionsConstants.USER_OTP_DELETE_PHONE}>
                                            {format.withMinutes(r.deleted_at) === "Invalid date" && 
                                                <Td>
                                                    <button onClick={() => {
                                                        if (window.confirm('Are you sure you want to delete this phone number?')) {
                                                            this.deletePhone(this.props.match.params.id, r.number);
                                                        }
                                                    }}>Delete</button>
                                                </Td>
                                            }
                                        </PermissionsFragment>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>

                <h1>Audit</h1>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Updated at</Th>
                            <Th>Created at</Th>
                            <Th>Expires at</Th>
                            <Th>Number</Th>
                            <Th>Country</Th>
                            <Th>Provider</Th>
                            <Th>Provider Tracking</Th>
                            <Th>Status</Th>
                            <Th>Status Detail</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {
                            this.props?.smsDelivery?.map((r, i) => {
                                return (
                                    <Tr key={i}>
                                        <Td>{r.id}</Td>
                                        <Td>{format.withMinutes(r.created_at)}</Td>
                                        <Td>{format.withMinutes(r.updated_at)}</Td>
                                        <Td>{format.withMinutes(r.expirated_at)}</Td>
                                        <Td>{r.number}</Td>
                                        <Td>{r.country}</Td>
                                        <Td>{r.name}</Td>
                                        <Td>{r.external_tracking_id}</Td>
                                        <Td>{r.status}</Td>
                                        <Td>{r.status_detail}</Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>




            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.users.otp_fingerprints
});

const mapDispatchToProps = dispatch => ({
    updateFingerprints: (user_id) => dispatch(updateOTPFingerprints(user_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOTPFingerprints);