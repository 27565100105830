

import React, { Component } from 'react';
import Select from 'react-select';
import {toast} from "react-toastify";

export default class AccountFinder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user_id: '',
      masked_account: '',
      card_payment_method_type: 'credit_card'
    }
  }

  renderCreate() {
    return (
      <div>
        <Select
          name="type"
          value={this.state.card_payment_method_type}
          onChange={option => {
            this.setState({ card_payment_method_type: option.value });
          }}
          options={
            [
              {
                value: 'credit_card',
                label: 'Credit Card (PIQ)'
              },
              {
                value: 'credit_card_switch',
                label: 'Credit Card (Switch)'
              }
            ]
          }
        />
        <h5>User ID (must be an influencer)</h5>
        <input
          value={this.state.user_id}
          onChange={
            e => this.setState({
              user_id: e.target.value
            })
          }
        />

        <h5>Masked Account (first 6, stars, last 4)</h5>
        <input
          maxLength={16}
          value={this.state.masked_account}
          onChange={
            e => this.setState({
              masked_account: e.target.value
            })
          }
        />
        <br />
        <br />

        <button
          disabled={
            this.state.masked_account.length !== 16
            || !this.state.user_id
          }
          onClick={
            () =>
              window.api.post(`/admin/payments/accounts/create_influencer_card`, {
                influencer_user_id: this.state.user_id,
                payment_method_type: this.state.card_payment_method_type,
                masked_account: this.state.masked_account
              }).then(() => toast.success(`Created`))
          }
        >
          Create
        </button>
      </div>
    )
  }

  render() {

    return (
      <div>
        <h4>Create New Influencer Card</h4>
        {this.renderCreate()}
      </div>
    )
  }
}