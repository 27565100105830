import { UsersLink } from "app/panel.js";

import { CreateUsersLink, FindUsersLink } from "user/tab-user-root/links.js";

const userNodes = {
  key: "users",
  label: "Users",
  icon: "",
  component: () => <UsersLink />,
  nodes: [
    {
      key: "find-users",
      label: "Find users",
      icon: "",
      component: () => <FindUsersLink />,
      nodes: [],
    },
    {
      key: "create-users",
      label: "Create users",
      icon: "",
      component: () => <CreateUsersLink />,
      nodes: [],
    },
  ],
};

export default userNodes;
