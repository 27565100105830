export function isValidUrl(url) {
   const urlRegex = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
   return url?.match(urlRegex);
}

export function capitalizeFirstLetter(string) {
   return string.charAt(0).toUpperCase() + string.slice(1);
}

const gamesNamesMap = new Map();
gamesNamesMap.set('crash_room_1', 'Crash (crash_room_1)');
gamesNamesMap.set('crash_room_2', 'Turbo Crash (crash_room_2)');
gamesNamesMap.set('crash_room_3', 'Crash Neymar (crash_room_3)');
gamesNamesMap.set('crash_room_4', 'Crash Rewired (crash_room_4)');

export const mapGameName = (value) => gamesNamesMap.get(value) || value;
