import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Pagination from '../../shared/pagination';
import { updateCountryOrderings, createCountryOrdering, editCountryOrdering, deleteCountryOrdering, updatePaymentMethods } from "../actions";
import { Link } from 'react-router-dom';
import CountryList from "country-list";
import { reorder } from "../../app/api";
import { format } from '../../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';


class CountryOrderFinder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      countries: [],
      country: '',
      methods: [],
      method: ''
    };

    this.countries = CountryList();
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.update(this.state.page);
    this.props.updateMethods();
  }

  render() {
    const { url } = this.props.match;


    return (
      <div>
        <h4>Create New</h4>


        <div className="tags-wrapper">
          <h5>Countries</h5>
          <div className="draggable-tags">
            <div className="row" style={{ width: '100%' }}>
              {
                (this.state.countries).map(
                  country =>
                    <div className="col-xs-2">
                      <div className="tag">
                        {country}

                        <i
                          onClick={
                            () => {
                              this.setState({
                                countries: this.state.countries.filter(
                                  c => c !== country
                                )
                              });
                            }
                          }
                          className="fas fa-times-circle" />
                      </div>
                    </div>
                )
              }
            </div>
          </div>

          <br />
          <div className="input-group">
            <Select
              name="type"
              value={this.state.country}
              onChange={option => this.setState({ country: option })}
              options={
                Object.keys(this.countries.getNameList()).filter(c => {
                  return (this.state.countries).indexOf(
                    this.countries.getCode(c)
                  ) === -1;
                }).map((country, index) => {
                  return {
                    label: country,
                    value: this.countries.getCode(country)
                  }
                })}
            />

            <button
              onClick={
                () => this.setState({
                  countries: this.state.countries.concat(
                    [this.state.country.value]
                  )
                })
              }
            >Add Country
            </button>
          </div>
        </div>


        <div className="tags-wrapper">
          <h5>Method Types</h5>
          <div className="draggable-tags">
            <DragDropContext
              onBeforeDragStart={() => { }}
              onDragStart={() => console.log('start')}
              onDragUpdate={() => { }}
              onDragEnd={result => {
                this.setState({
                  methods: reorder(this.state.methods, result.source.index, result.destination.index)
                })
              }}
            >
              <Droppable
                style={{ width: '100%' }}
                droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                  >
                    {
                      (this.state.methods).map(
                        (method, index) =>

                          <Draggable key={method} draggableId={method} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}

                                className="tag">
                                {method}

                                <i
                                  onClick={
                                    () => {
                                      this.setState({
                                        methods: this.state.methods.filter(
                                          c => c !== method
                                        )
                                      });
                                    }
                                  }
                                  className="fas fa-times-circle" />
                              </div>
                            )}
                          </Draggable>
                      )
                    }
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <br />
          <div className="input-group">
            <Select
              name="type"
              value={this.state.method}
              onChange={option => this.setState({ method: option })}
              options={
                this.props.methods.map(
                  row => row.type
                ).filter(
                  row =>
                    this.state.methods.indexOf(
                      row
                    ) === -1
                ).map(type => {
                  return {
                    label: type,
                    value: type
                  }
                })
              }
            />

            <button
              onClick={
                () => this.setState({
                  methods: this.state.methods.concat(
                    [this.state.method.value]
                  )
                })
              }
            >Add Method
            </button>
          </div>
        </div>

        <button
          onClick={
            () => this.props.create({
              countries: this.state.countries,
              methods: this.state.methods
            })
          }
        >Create
        </button>
        <hr />


        <Table>
          <Thead>
            <Tr>
              <Th>Created</Th>
              <Th>ID</Th>
              <Th>Countries</Th>
              <Th>Method Types</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              this.props.records.map((r, i) => {
                return (
                  <Tr key={i}>
                    <Td>{format.withMinutes(r.created_at)}</Td>
                    <Td>
                      <Link to={`${url}/` + r.id}>
                        {r.id}
                      </Link>
                    </Td>
                    <Td>
                      {r.countries.toString()}
                    </Td>
                    <Td>
                      {r.methods.toString()}
                    </Td>
                    <Td>
                      <a>
                        <i
                          onClick={() => this.props.delete(r.id)}
                          className="far fa-trash-alt" />
                      </a>
                    </Td>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>


        <Pagination
          total_pages={this.props.meta.total_pages}
          update={page => {
            this.setState({ page }, () => this.update());
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.payments.country_ordering_search_results,
  methods: state.payments.method_search_results.records
});

const mapDispatchToProps = dispatch => ({
  update: page => dispatch(updateCountryOrderings(page)),
  create: (props) => dispatch(createCountryOrdering(props)),
  edit: (id, props) => dispatch(editCountryOrdering(id, props)),
  delete: (id) => dispatch(deleteCountryOrdering(id)),
  updateMethods: () => dispatch(updatePaymentMethods())
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryOrderFinder);