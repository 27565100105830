import React, { Component, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { format } from '../shared/utils/dates';
import { LOADABLE_BET_HISTORY_PROVIDERS_SLUGS } from './constants';
import './game-provider-round.css';

class GameProviderRound extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {
        game: {
          name: 'Test'
        },
        provider: {
          name: 'Test'
        },
        aggregator: {
          name: 'Test'
        },
        bets: []
      },
      maximizedBetIds: [],
      betEventsHistory: null,
    }
  }

  load() {
    window.api.get(`/admin/game_provider_rounds/` + this.props.match.params.id).then(
      data => {
        this.setState({ data });
        if (this.isLoadableBetHistory(data)) {
          this.getBetEventsHistory(data.game_state.remote_round_id);
        }
      }
    ).finally(
      () => this.setState({ loading: false })
    );
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.load();
  }

  async getBetEventsHistory(remoteRoundId) {
    try {
      const response = await window.api.get(`/admin/betby_events/${remoteRoundId}`);
      this.setState({betEventsHistory: response});
    } catch (error) {
      console.error(error);
    }
  }

  isLoadableBetHistory(data) {
    return (
      data?.game_state?.remote_round_id && 
      LOADABLE_BET_HISTORY_PROVIDERS_SLUGS.includes(data?.provider?.slug) 
    );
  }

  closeRound(withSideEffects = false) {
    this.setState({ loading: true })
    window.api.put(`/admin/users/close_round/` + this.state.data.game_state.game_provider_round_id, { withSideEffects }).then(
      () => window.location.reload()
    ).finally(
      () => this.setState({ loading: false })
    );
  }

  render() {
    const { betEventsHistory, data, loading, maximizedBetIds } = this.state;
    const { game, provider, aggregator, game_state, bets = [], user } = data;

    if (loading) {
      return (
        <div className="bet-page">
          <h2>Loading...</h2>
        </div>
      )
    }
    return (
      <div className="bet-page">
        <div className="row">
          <div className="col-md-6">
            <h3>Round Information</h3>
            <div className="round-id">Round ID: {this.props.match.params.id}</div>
            <div className={"game"}>Game Name: <Link to={`/casino/games/${game?.slug}`}>{game?.name}</Link></div>
            <div className={"game"}>Game Provider: <Link to={`/casino/game-providers/${provider?.slug}`}>{provider?.name}</Link></div>
            <div className={"game"}>Game Aggregator: {aggregator?.name}</div>
            <div className={"game"}>User: <Link to={`/users/${user?.userId}/game-provider-rounds`}>{user?.username}</Link></div>
            <br />

            <pre>{JSON.stringify(game_state, null, 4)}</pre>
            {
              game_state?.status !== 'closed' && <div>
                <button onClick={() => this.closeRound(false)}>Mark as closed only</button>
                <button onClick={() => this.closeRound(true)}>Mark as closed WITH side effects</button>
              </div>
            }
          </div>
          <div className="col-md-6">
            <h3>Bets</h3>
            {
              bets.map(
                bet => {
                  const isMaximized = maximizedBetIds.includes(bet.id)
                  return (
                    <Fragment key={bet.id}>
                      <div
                        className="bet">
                        <div
                          className="top"
                          onClick={
                            () => this.setState({ maximizedBetIds: isMaximized ? maximizedBetIds.filter((id) => id !== bet.id) : [...maximizedBetIds, bet.id] })
                          }
                        >
                          <div className="left">
                            <div className="id">
                              ID: {bet.id}
                            </div>
                            <div className="amount">
                              Wager: {bet.amount} {bet.currency_type}
                            </div>
                            <div className="win-amount">
                              Winnings: {bet.win_amount} {bet.currency_type}
                            </div>
                          </div>
                          <div className="right">
                            <div className="amount status">
                              Status: {bet.status}
                            </div>
                            <div className="maximize">
                              <i className="fa fa-plus" />
                            </div>
                          </div>
                        </div>
                        {
                          isMaximized &&
                          <div className="bet-data">
                            <div className="stats">
                              <div className="win-amount">
                                Created at: {format.withMinutes(bet.created_at)}
                              </div>
                              <div className="win-amount">
                                Last updated: {format.withMinutes(bet.created_at)}
                              </div>
                              <div className="win-amount">
                                Game Data: {JSON.stringify(bet.game_data, null, 4)}
                              </div>
                            </div>
                            <div className="bet-journal-entries">
                              <h4>Journal Entries</h4>

                              <Table>
                                <Thead>
                                  <Tr>
                                    <Th>ID</Th>
                                    <Th>Amount</Th>
                                    <Th>Type</Th>
                                    <Th>Journal Name</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {
                                    bet.journal_entries.map(
                                      ({ id, amount, type, journal_name }) => {
                                        return (
                                          <Tr className="journal-entry" key={id}>
                                            <Td>
                                              {id}
                                            </Td>
                                            <Td className="amount">
                                              {amount}
                                            </Td>
                                            <Td className="type">
                                              {type}
                                            </Td>
                                            <Td className="journal">
                                              {journal_name}
                                            </Td>
                                          </Tr>
                                        )
                                      }
                                    )
                                  }
                                </Tbody>
                              </Table>

                            </div>
                          </div>
                        }
                      </div>
                    </Fragment>
                  )
                }
              )
            }
          </div>
          {
            this.isLoadableBetHistory(data) &&
            <div className="col-md-12 bet-data" style={{marginTop: '25px'}}>
              <div className="bet-journal-entries">
                <h3>Bet Events History</h3>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Created Date</Th>
                      <Th>Type</Th>
                      <Th>Tracking ID</Th>
                      <Th>Aggregate ID</Th>
                      <Th>Event Data</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      betEventsHistory?.map(
                        ({ tracking_id, aggregate_id, type, data, created_at }) => {
                          return (
                            <Tr key={`${tracking_id}`}>
                              <Td>
                                {created_at}
                              </Td>
                              <Td>
                                {type}
                              </Td>
                              <Td>
                                {tracking_id}
                              </Td>
                              <Td>
                                {aggregate_id}
                              </Td>
                              <Td>
                                {JSON.stringify(data, null, 4)}
                              </Td>
                            </Tr>
                          )
                        }
                      )
                    }
                  </Tbody>
                </Table>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(GameProviderRound)