import React, { Component } from "react";
import { connect } from "react-redux";
import { createDepositBonus, updateDepositBonuses } from "./actions";
import Pagination from "../shared/pagination";
import CountryList from "country-list";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { Link } from "react-router-dom";

import { format } from "../shared/utils/dates";

class Bonuses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: null,
      page: 1,
      excluded_countries: [],
      supported_countries: [],
      excluded_country: "",
      supported_country: "",
      currency_type: "EUR",
      globally_available: true,
      queryFilter: {},
      hasChange: false,
    };

    this.countries = CountryList();
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.update(this.state.page, this.state.queryFilter);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.queryFilter !== this.state.queryFilter &&
      this.state.hasChange === true
    ) {
      this.update();
      this.setState({ ...this.state, hasChange: false });
    }
  }

  render() {
    return (
      <div>
        <Table>
          <Thead>
            <Tr>
              <Th>Expires At</Th>
              <Th>ID</Th>
              <Th>Type</Th>
              <Th>Currency Type</Th>
              <Th>Name</Th>
              <Th>Amount</Th>
              <Th>Maximum Bonus</Th>
              <Th>Deposit Ratio</Th>
              <Th>Wager Ratio</Th>
              <Th>Banner</Th>
              <Th>Globally Available</Th>
              <Th>Excluded Countries</Th>
              <Th>Name translation</Th>
              <Th>Banner translation</Th>
              <Th>Description translation</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.props.records.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>
                    <span>{format.withMinutes(r.expires_at)}</span>
                  </Td>
                  <Td>
                    <span>
                      <Link to={`/casino/bonuses/${r.id}`}>{r.id}</Link>
                    </span>
                  </Td>
                  <Td>
                    <span>{r.type}</span>
                  </Td>
                  <Td>
                    <span>{r.currency_type}</span>
                  </Td>
                  <Td>
                    <span>
                      <Link to={`/casino/bonuses/${r.id}`}>{r.name}</Link>
                    </span>
                  </Td>
                  <Td>
                    <span>{r.amount}</span>
                  </Td>
                  <Td>
                    <span>{r.maximum}</span>
                  </Td>
                  <Td>
                    <span>{r.deposit_ratio}</span>
                  </Td>
                  <Td>
                    <span>{r.wager_ratio}</span>
                  </Td>
                  <Td>
                    <span>{r.banner}</span>
                  </Td>
                  <Td>
                    <span>
                      {r.globally_available && <i className="fas fa-check" />}
                    </span>
                  </Td>
                  <Td>
                    <span>
                      {(r.countries || []).map((c) => {
                        return c + ",";
                      })}
                    </span>
                  </Td>
                  <Td>
                    <span>{JSON.stringify(r.name_translation)}</span>{" "}
                  </Td>
                  <Td>
                    <span>{JSON.stringify(r.banner_translation)}</span>{" "}
                  </Td>
                  <Td>
                    <span>{JSON.stringify(r.description_translation)}</span>{" "}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.props.meta.total_pages}
          update={(page) => {
            this.setState({ page }, () => this.update());
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.bonuses.search_results,
  currencies: state.app.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  update: (page, queryFilter) =>
    dispatch(updateDepositBonuses(page, queryFilter)),
  createDepositBonus: (props) => dispatch(createDepositBonus(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bonuses);
