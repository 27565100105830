import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

const TooltipWithInfoIcon = ({ title, children }) => {
  return (
    <Tooltip title={title}>
      <span>
        {children} <InfoOutlined fontSize='string' sx={{verticalAlign: 'middle'}} />
      </span>
    </Tooltip>
  );
};

export default TooltipWithInfoIcon;
