import React, { Component } from "react";
import { connect } from "react-redux";
import { sendBroadcast } from "./actions";
import { NavLink, withRouter, Switch, Redirect } from "react-router-dom";

import Finder from "./finder";
import User from "./sub-tabs";

import {
  permissionsConstants,
  CheckPermissionsRoute,
} from "../app/permissions-fragment";
import Create from "./create";
import CheckCPF from "./check-cpf";

class Users extends Component {
  render() {
    const { url } = this.props.match;

    const search = this.props.location.search;
    return (
      <div className="page">
        <nav>
          <NavLink exact to={`${url}/find`}>
            Find users
          </NavLink>
          <NavLink exact to={`${url}/create`}>
            Create users
          </NavLink>
          <NavLink exact to={`${url}/check-cpf`}>
            Check CPF
          </NavLink>
        </nav>
        <Switch>
          <CheckPermissionsRoute
            feature={permissionsConstants.USER_CREATE}
            exact
            path="/users/create"
            component={Create}
          />
          <CheckPermissionsRoute
            feature={permissionsConstants.USER_VIEW_LIST}
            exact
            path="/users/find"
            component={Finder}
          />

          <CheckPermissionsRoute
            feature={permissionsConstants.CHECK_CPF}
            path="/users/check-cpf"
            component={CheckCPF}
          />

          <CheckPermissionsRoute
            feature={permissionsConstants.USER_VIEW_USER}
            path="/users/:id"
            component={User}
          />
          <Redirect from="/users" to={"/users/find/" + search} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  broadcast(message) {
    dispatch(sendBroadcast(message));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
