import React, { Fragment, useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const ProviderRulesList = () => {
  const [providers, setProviders] = useState([]);
  useEffect(() => {
    window.api
      .get("/admin/payments/providers/depositor-rules")
      .then(setProviders);
  }, []);
  return (
    <Fragment>
      <Typography variant="h5" component="div">
        All Depositor rules
      </Typography>
      <br />
      {providers.map((provider, i) => (
        <Card key={i} sx={{ mb: 5 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              <Link to={`/payments/providers/${provider.id}`}>
                {provider.type}
              </Link>
            </Typography>
            <pre>
              {JSON.stringify(provider.disable_depositor_rule, null, 4)}
            </pre>
          </CardContent>
        </Card>
      ))}
    </Fragment>
  );
};
