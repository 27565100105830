import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  addBonusToInventory,
  forceExpireBonusActivation,
  updateUserBonuses,
} from "./actions";
import { Link } from "react-router-dom";
import { updateDepositBonuses } from "../casino/actions";
import {
  checkPermissions,
  permissionsConstants,
} from "../app/permissions-fragment";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import styles from "./transactions.module.scss";

class UserBonuses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      page: 1,
      total_pages: 1,
      queryFilter: {},
      hasChange: false,
    };
  }

  componentDidMount() {
    if (checkPermissions(permissionsConstants.USER_BONUSES_ADD_BONUS)) {
      this.props.updateBonuses();
    }
    this.props.updateUserBonuses(this.props.match.params.id);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.queryFilter !== this.state.queryFilter &&
      this.state.hasChange === true
    ) {
      this.setState({ ...this.state, hasChange: false });
      this.props.updateBonuses(this.state.page, this.state.queryFilter);
    }
  }
  getBonusState(bonusInventory) {
    const consumed_bonuses = this.props.consumed_bonuses || [];

    if (
      consumed_bonuses.length &&
      consumed_bonuses.includes(bonusInventory.id)
    ) {
      return "consumed";
    }

    return bonusInventory.status;
  }
  render() {
    const user_id = this.props.match.params.id;

    return (
      <Fragment>
        <div className={styles.filtersQuery}>
          <div className={styles.filterQuery}>
            <h5>Search by Bonus Name</h5>
            <input
              type="text"
              id="bonusName"
              name="bonusName"
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  queryFilter: {
                    ...this.state.queryFilter,
                    bonusName: e.target.value,
                  },
                  hasChange: true,
                });
              }}
            />
          </div>
          <div className={styles.filterQuery}>
            <h5>Search by Status</h5>
            <input
              type="text"
              id="status"
              name="status"
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  queryFilter: {
                    ...this.state.queryFilter,
                    status: e.target.value,
                  },
                  hasChange: true,
                });
              }}
            />
          </div>
          <div className={styles.filterQuery}>
            <h5>Search by Activation Status</h5>
            <input
              type="text"
              id="activationStatus"
              name="activationStatus"
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  queryFilter: {
                    ...this.state.queryFilter,
                    activationStatus: e.target.value,
                  },
                  hasChange: true,
                });
              }}
            />
          </div>
          <div className={styles.filterQuery}>
            <h5>Search by Activation Wager Requirement</h5>
            <input
              type="text"
              id="activationWagerRequirement"
              name="activationWagerRequirement"
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  queryFilter: {
                    ...this.state.queryFilter,
                    activationWagerRequirement: e.target.value,
                  },
                  hasChange: true,
                });
              }}
            />
          </div>
        </div>
        <Table>
          <Thead>
            <Tr>
              <Th>Bonus Name</Th>
              <Th>Status</Th>
              <Th>Activation Status</Th>
              <Th>Activation Wager Requirement</Th>
              <Th>Activation Wagered</Th>
              <Th>Activation EUR transfer amount</Th>
              <Th>Activation Created</Th>
              <Th>Activation Last Update</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(this.props.bonus_inventory || []).map((bonus_inventory, i) => {
              let bonus = bonus_inventory.bonus;
              const activation = bonus_inventory.bonus_activation;
              const bonusStatus = this.getBonusState(bonus_inventory);

              return (
                <Fragment>
                  <Tr key={i}>
                    <Td>
                      <Link to={`/rewards/bonuses/${bonus.id}`}>
                        {bonus.name}
                      </Link>
                    </Td>
                    <Td>{bonusStatus}</Td>
                    <Td>{activation && activation.status}</Td>
                    <Td>{activation && activation.wager_requirement}</Td>
                    <Td>{activation && activation.wagered}</Td>
                    <Td>{activation && activation.eur_transfer_amount}</Td>
                    <Td>
                      {activation && format.withMinutes(activation.created_at)}
                    </Td>
                    <Td>
                      {activation && format.withMinutes(activation.updated_at)}
                    </Td>
                    <Td>
                      {activation && activation.active && (
                        <button
                          onClick={() => {
                            this.props.forceExpireBonusActivation(
                              user_id,
                              activation.id
                            );
                          }}
                        >
                          Force Expire Activation
                        </button>
                      )}
                    </Td>
                  </Tr>
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
        <br />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.users.user,
  bonuses: state.bonuses.search_results.records,
});

const mapDispatchToProps = (dispatch) => ({
  updateBonuses: (page, queryFilter) =>
    dispatch(updateDepositBonuses(page, queryFilter)),
  addBonusToInventory: (user_id, bonus_id) => {
    dispatch(addBonusToInventory(user_id, bonus_id));
  },

  forceExpireBonusActivation: (user_id, id) => {
    dispatch(forceExpireBonusActivation(user_id, id));
  },

  updateUserBonuses: (user_id) => {
    dispatch(updateUserBonuses(user_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBonuses);
