import React, { Component, Fragment } from 'react';
import { toast } from "react-toastify";
import { connect } from "react-redux";
import NewCategory from "./NewCategory";
import ViewCategory from "./ViewCategory";
import './Category.css';

const initialState = {
  categories: [],
  categorySlug: null
};

class GameCategoriesPage extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    this.fetchGame();
    this.fetchCategories();
  }

  async runReport() {

    this.setState({ reportLoading: true });
    try {
      const gameReport = await this.getReportApi().waitUntilNewReportGenerated()
      this.setState({ gameReport });
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ reportLoading: false });
  }


  async fetchGame() {
    await window.api.get(`/admin/games/${this.props.match.params.slug}`).then(
      game => this.setState({ game })
    )
  }

  async fetchCategories() {
    await window.api.get(`/admin/game_categories`).then(
      categories => this.setState({ categories, categorySlug: categories[0].slug, categoryName: categories[0].name })
    )
  }

  async changeCategoryName(slug, name) {
    await window.api.post('/admin/game_categories/change_name', {
      name,
      slug
    });

    await window.api.get(`/admin/game_categories`).then(
      categories => this.setState({ categories, categoryName: name })
    )
  }

  async createNewCategory({ name, isLabel }) {
    await window.api.post(`/admin/game_categories`, {
      name,
      isLabel,
    });
    toast.success(`Category created`);
    this.fetchCategories();
  }
  async addCategory(slug) {
    await window.api.post(`/admin/game_categories/${slug}/add_game`, {
      game_id: this.state.game.id
    });
    this.fetchGame();
  }
  async removeCategory(slug) {
    await window.api.post(`/admin/game_categories/${slug}/remove_game`, {
      game_id: this.state.game.id
    });
    this.fetchGame();
  }

  render() {

    return (
      <div className="category-page">
        <div className={"category-select"}>
          {this.state.categories.map(category => {

            return (
              <div className={`category-box ${this.state.categorySlug === category.slug && 'selected'}`} onClick={() => {
                this.setState({
                  categorySlug: category.slug,
                  categoryName: category.name
                })
              }}>
                {category.slug}
              </div>
            )

          })}
          
          <div className={`category-box ${this.state.categorySlug === 'all-games' && 'selected'}`} onClick={() => {
                this.setState({
                  categorySlug: 'all-games',
                  categoryName: 'all-games'
                })
              }}>
                all-games
          </div>
        </div>
        <div className="category-page__game">

          <NewCategory createNewCategory={(props) => this.createNewCategory(props)} />
        </div>
        <hr />
        <section>
          <ViewCategory changeName={(slug, name) => this.changeCategoryName(slug, name)} categorySlug={this.state.categorySlug} categoryName={this.state.categoryName} />
        </section>

      </div>
    )
  }
}
const mapStateToProps = state => ({
  languages: state.app.languages
});

export default connect(mapStateToProps)(GameCategoriesPage);