import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { updateRewards } from "../../casino/reward-actions";
import { listRecurring, listDuration, ADMIN_GAMES_API } from "./constants";
import api from "./api";
import "./races.scss";

const calculateDate = (date) => {
  const localTimestamp = new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: false,
    // Add a leading zero to the hour if it is single-digit
    hourCycle: "h23",
  });
  const formattedTimestamp = localTimestamp.replace(" ", "T").replace(",", "");
  return formattedTimestamp.replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
};

const ViewRaces = (props) => {
  const formRef = useRef(null);
  const id = props.match.params.id;
  const [listGames, setListGames] = useState();
  const [recurringCheck, setRecurringCheck] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    starts_at: "",
    game_id: 0,
    duration: 0,
    total_race_prize: 0,
    currency_rates: [],
    name_translation: {},
    recurring: 0,
    games: []
  });


  function handleCheckboxRecurring(event) {
    setRecurringCheck(event.target.checked);
  }

  const handleUpdateField = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getData = useCallback(async () => {
    try {
      const data = await api.getRaceById(id);
      setFormData({
        ...data,
        recurring: data?.recurring_frequency
      });

      setRecurringCheck(data?.recurring_frequency !== "0" && true)
    } catch (e) {
    }
  }, [id]);

  useEffect(() => {

    const fetchGamesFromApi = async () => {
      try {
        const response = await window.api.get(ADMIN_GAMES_API);
        return response.records;
      } catch (error) {
        console.error('Error fetching games:', error);
        return [];
      }
    };

    const formatGame = (record) => ({
      label: `${record.name} - ${record.slug}`,
      value: record.id,
    });

    const getGames = async () => {
      try {
        const records = await fetchGamesFromApi();
        const games = [
          { label: 'All games', value: 0 },
          ...records.map(formatGame),
        ];
        setListGames(games);
      } catch (error) {
        console.error('Error processing games:', error);
      }
    };

    getGames();
    getData();
  }, [getData]);

  const {
    name,
    game_id,
    duration,
    recurring,
    starts_at,
    eur_prize,
    currency_rates,
    name_translation,
    games
  } = formData;

  return (
    <div className="margin-left-form-challenge">
      <form ref={formRef} className="form-challenge">
        <div className="row-challenge">
          <hr />
          <div className="column">
            <h1 className="label-challenge ">Name</h1>
            <input
              value={name}
              name="name"
              onChange={handleUpdateField}
              disabled={true}
            />
          </div>
        </div>

        <div className="row-challenge">
          <hr />
          <div className="column">
            <h1 className="label-challenge ">Translations</h1>
          </div>
        </div>
        {name_translation &&
          Object.entries(name_translation).map(([key, value]) => {
            return (
              <div className="row-challenge" key={key}>
                <hr />
                <div className="column">
                  <h1 className="label-challenge">{key}</h1>
                  <input
                    value={value}
                    name="name"
                    onChange={handleUpdateField}
                    disabled={true}
                  />
                </div>
              </div>

            )
          })
        }

        <div className="row-challenge">
          <hr />
          <div className="column">
            <h1 className="label-challenge ">Start Date</h1>
            <input
              disabled={true}
              value={calculateDate(starts_at)}
              name="starts_at"
              type={"datetime-local"}
              onChange={handleUpdateField}
            />
          </div>
          <div className="column">
            <h1 className="label-challenge ">Duration</h1>
            <Select
              disabled={true}
              name="duration"
              value={duration}
              onChange={(e) => {
                setFormData({ ...formData, duration: e.value });
              }}
              style={{ width: "300px" }}
              options={listDuration}
            />
          </div>
          <div className="column">
            <div className="recurring-check-label">
              <h1 className="label-challenge ">Recurring</h1>
              <input
                disabled={true}
                className="featured-input"
                type="checkbox"
                checked={recurringCheck}
                onChange={handleCheckboxRecurring}
              />
            </div>
            {recurringCheck && (
              <Select
                disabled={true}
                name="recurring"
                value={recurring}
                onChange={(e) => {
                  setFormData({ ...formData, recurring: e.value });
                }}
                style={{ width: "300px" }}
                options={listRecurring}
              />
            )}
          </div>
        </div>

        <span className="time-challenges"></span>
        <div className="row-challenge">
          <div className="column">
            <label>Games</label>
            <Select
              disabled={true}
              name="game_id"
              value={game_id}
              onChange={(e) => {
                setFormData({ ...formData, game_id: e.value });
              }}
              style={{ width: "300px" }}
              options={listGames}
            />
          </div>
          <div className="column totalprize-box">
            <label>Total Race Prize</label>
            <input
              disabled={true}
              value={eur_prize}
              name="total_race_prize"
              onChange={handleUpdateField}
            />
          </div>
        </div>
        <div className="selected-games-container">
          <h2>Selected Games</h2>
          {listGames && games.map((game, index) => (
            <div key={index}>
              <p>{index + 1 + ")"} {game.name} - {game.slug}</p>
            </div>
          )
          )}
        </div>
        {/* <div className="row-challenge">
          <div className="column">
            <Currency
              currencyRates={currency_rates}
              setCurrencyRates={(currency_rates) =>
                setFormData({ ...formData, currency_rates })
              }
              disabled={true}
            />
          </div>
        </div> */}
      </form>

    </div>
  );
};

const mapStateToProps = (state) => ({
  rewards: state.rewards.items.records,
});
const mapDispatchToProps = (dispatch) => ({
  updateRewards: () => dispatch(updateRewards()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewRaces)
);
