export const downloadFile = (data, extension) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `file.${extension}`
  );
  document.body.appendChild(link);
  link.click();
};