import * as types from './types';

const initial = {

  data: []
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.SETTINGS_UPDATE:
      return {...state, data: action.payload};
  }

  return state;
}