import React, { useReducer, useState } from 'react';
import { toast } from 'react-toastify';

import { setAffiliateManager } from './api';

import './affiliates-page.css';
import { useSelector } from 'react-redux';
import { affiliatesSelector } from './state/selectors';

function reducer(state, action) {
  switch (action.type) {
    case 'set_value': {
      return { ...state, [action.name]: action.value };
    }
    default: {
      return state;
    }
  }
}

const initialValue = {
  affiliate_id: '',
  blaze_user_id: '',
  full_name: '',
  skype: '',
  whatsapp: '',
  telegram: '',
  email: '',
};

function AffiliateManager(props) {
  const { selectedAffiliate } = useSelector(affiliatesSelector);

  if (!selectedAffiliate) {
    return <span>Please, select an affiliate first</span>;
  }

  const [saving, setSaving] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    affiliate_id: selectedAffiliate.blaze_user_id,
    blaze_user_id: selectedAffiliate.affiliateManager?.blaze_user_id,
    full_name: selectedAffiliate.affiliateManager?.full_name,
    skype: selectedAffiliate.affiliateManager?.skype,
    whatsapp: selectedAffiliate.affiliateManager?.whatsapp,
    telegram: selectedAffiliate.affiliateManager?.telegram,
    email: selectedAffiliate.affiliateManager?.email,
  });

  const setValue = ({ target }) => {
    const { name, value } = target;
    if (name) {
      dispatch({ type: 'set_value', name, value });
    }
  };

  const hasEmptyField = state => {
    return !(state.blaze_user_id && state.full_name);
  };

  const handleSetAffiliateManager = async () => {
    try {
      if (hasEmptyField(state)) {
        throw new Error('Some fields are empty.');
      }
      setSaving(true);
      await setAffiliateManager(state);
      toast.success(`Affiliate Manager set for affiliate: ${state.affiliate_id}.`);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="affiliate-page">
      <div className='affiliate-page__container'>
        <div className='affiliate-page__group-input'>
          <label>
            Affiliate ID:
          </label>
          <input
            name="affiliate_id"
            value={state.affiliate_id}
            disabled
            onChange={setValue}
          />
        </div>
        <div className='affiliate-page__group-input'>
          <label>

            Affiliate Manager ID (a valid blaze user id with affiliate_manager role):
          </label>
          <input
            name="blaze_user_id"
            value={state.blaze_user_id}
            onChange={setValue}
          />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Full Name:
          </label>
          <input name="full_name" value={state.full_name} onChange={setValue} />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Skype:
          </label>
          <input name="skype" value={state.skype} onChange={setValue} />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Telegram:
          </label>
          <input name="telegram" value={state.telegram} onChange={setValue} />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Whatsapp:
          </label>
          <input name="whatsapp" value={state.whatsapp} onChange={setValue} />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Email:
          </label>
          <input type="email" name="email" value={state.email} onChange={setValue} />
        </div>
        <button disabled={saving} onClick={handleSetAffiliateManager}>Set Affiliate Manager</button>
      </div>
    </div>
  );
}

export default AffiliateManager;
