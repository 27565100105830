import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { OffsetPagination } from "../shared/OffsetPagination";

const UserPendingBets = (props) => {
  const [bets, setBets] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const fetchBets = async () => {
    setIsLoading(true);
    try {
      const { records, total_pages } = await window.api.get(
        `/admin/users/${props.match.params.id}/game_provider_rounds_open`,
        {
          params: {
            page,
            fullSearch: isChecked,
          },
        }
      );
      setBets(records);
      setTotalPages(total_pages);
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchBets();
  }, [page, isChecked]);

  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <div style={{ position: "relative", minHeight: "500px" }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait ...</h2>
          <div className="loader" />
        </div>
      )}
      <label>
        <input
          style={{
            marginLeft: "200px",
            marginBottom: "20px",
          }}
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        Full search with the amount wagered in the last 60 days. If not, it will
        return data since the origin of time
      </label>
      <Table>
        <Thead>
          <Tr>
            <Th>Round ID</Th>
            <Th>Game</Th>
            <Th>Game Created At</Th>
            <Th>Amount</Th>
          </Tr>
        </Thead>

        <Tbody>
          {bets.map((r, i) => (
            <Tr key={i}>
              <Td>
                <Link to={`/casino/game-provider-rounds/${r.id}`}>{r.id}</Link>
              </Td>
              <Td>{r.slug}</Td>
              <Td>{format.withSeconds(r.created_at)}</Td>
              <Td>{r.real_eur_wagered}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <OffsetPagination page={page} setPage={setPage} totalPages={totalPages} />
    </div>
  );
};

export default UserPendingBets;
