import React from "react";
import { Switch } from "react-router-dom";

import {
  CheckPermissionGroupRoute,
  permissionGroups,
} from "app/permissions-fragment";

import { BrowserView } from "react-device-detect";
import { BalanceLink, StatusLink } from "status/tab-status-root/links";
import CurrentStatus from "status/components/CurrentStatus";
import Balance from "status/balance/Balance";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function Status(props) {
  const search = props.location.search;
  const { url } = props.match;

  return (
    <div className="page">
      <BrowserView>
        <nav>
          <StatusLink />
          <BalanceLink />
        </nav>
      </BrowserView>

      <Switch>
        <CheckPermissionGroupRoute
          group={permissionGroups.MONITORING.GENERAL}
          exact
          path="/status/current"
          component={CurrentStatus}
        />
        <CheckPermissionGroupRoute
          group={permissionGroups.MONITORING.GENERAL}
          exact
          path="/status/balance"
          component={Balance}
        />

        <Redirect from="/status" to="/status/current" />
      </Switch>
    </div>
  );
}

export default Status;
