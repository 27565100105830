import { currenciesSelector } from "app/app-selectors";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const Currency = ({ setCurrencyRates, currencyRates, disabled }) => {
  const { currencies } = useSelector(currenciesSelector);

  const [valueRate, setValueRate] = useState(1);

  const freeCurrencies = currencies
    .filter((c) => currencyRates.indexOf(c.type) === -1)
    .map((c) => c.type);

    return (
    <div className="currency-rates-container">
      <h3>Currency rates</h3>
      {!disabled && (
        <button
          type="button"
          onClick={() => {
            setCurrencyRates([
              ...currencyRates,
              { currency: freeCurrencies[0], rate: 1 },
            ]);
          }}
          className="add-new-reward"
          disabled={freeCurrencies.length === 0}
        >
          Add
        </button>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>Currency</Th>
            <Th>Rate</Th>
            {!disabled && <Th>Delete</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {currencyRates.map((cr, index) => (
            <Tr key={cr.currency + cr.rate}>
              <Td>
                <select
                  disabled={disabled}
                  onChange={(e) => {
                    const newCurrency = e.target.value;
                    const newCurrencyRates = currencyRates.map((cr, i) => {
                      if (i === index) {
                        return { currency: newCurrency, rate: valueRate };
                      }
                      return cr;
                    });
                    setCurrencyRates(newCurrencyRates);
                  }}
                >
                  {freeCurrencies
                    .concat([cr.currency])
                    .sort()
                    .map((c, index) => {
                      return (
                        <option
                          value={c}
                          key={index}
                          selected={cr.currency === c}
                        >
                          {c}
                        </option>
                      );
                    })}
                </select>
              </Td>
              <Td>
                <input
                  disabled={disabled}
                  onChange={(e) => {
                    const newRate = e.target.value;
                    const newCurrencyRates = currencyRates.map((cr, i) => {
                      if (i === index) {
                        return { currency: cr.currency, rate: newRate };
                      }
                      return cr;
                    });
                    setValueRate(newRate);
                    setCurrencyRates(newCurrencyRates);
                  }}
                  type="number"
                  value={cr.rate || 0}
                ></input>
              </Td>
              {!disabled && (
                <Td
                  onClick={() => {
                    console.log(index, currencyRates);
                    const newCurrencyRates = currencyRates.filter(
                      (cr, i) => i !== index
                    );
                    setCurrencyRates(newCurrencyRates);
                  }}
                >
                  X
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default Currency;
