import React, { Fragment, useState, useReducer } from "react";
import {
  CHANGE_DATE_BIRTH,
  CHANGE_EMAIL,
  CHANGE_PHONE,
  CHANGE_FIRSTNAME,
  CHANGE_LASTNAME,
  CHANGE_ADDRESS,
  CHANGE_COUNTRY,
  CHANGE_KYC_COUNTRY,
  CHANGE_CITY,
  CHANGE_STATE,
  CHANGE_POSTAL_CODE,
  CHANGE_TAX_ID,
  CHANGE_CUSTOMER,
  CHANGE_PAGE,
  CHANGE_KYC_LEVEL,
  CHANGE_GENDER,
} from "./constants";
import {
  GroupPermissionsFragment,
  permissionGroups,
  permissionsConstants,
  PermissionsFragment,
} from "app/permissions-fragment";
import Select from "react-select";
import moment from "moment";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import createInitialState from "./initialState";
import reducer from "./reducer";
import styles from "./styles.module.scss";
import Stats from "./components/Stats";
import structuredClone from "@ungap/structured-clone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import CpfInfo from "./components/CpfInfo";

const GENDERS_OPTIONS = ["male", "female", "other"];

const Info = (props) => {
  const [newEmail, setNewEmail] = useState("");
  const [state, dispatch] = useReducer(reducer, props, createInitialState);
  const user_id = props.match.params.id;
  const saveInformation = () => {
    const info = structuredClone(state.info);
    let confirmMessage = `Are you sure you want to make these changes?\n`;
    for (let prop in info) {
      if (info.hasOwnProperty(prop)) {
        if (info[prop] !== props[prop]) {
          confirmMessage += `${prop}: ${props[prop]} ==> ${info[prop]}\n`;
        } else {
          delete info[prop];
        }
      }
    }
    if (window.confirm(confirmMessage)) {
      props.updateUserInfo(user_id, info);
    }
  };

  const requestChangesInformation = () => {
    const info = structuredClone(state.info);
    let confirmMessage = `Are you sure you want to make these changes?\n`;
    for (let prop in info) {
      if (info.hasOwnProperty(prop)) {
        if (info[prop] !== props[prop]) {
          confirmMessage += `${prop}: ${props[prop]} ==> ${info[prop]}\n`;
        } else {
          delete info[prop];
        }
      }
    }

    const staff_note = window.prompt("Please enter a note for this change.");

    if (window.confirm(confirmMessage)) {
      props.updateCustomerInfo(user_id, info, true, staff_note);
    }
  };

  const onSaveCustomerInfo = () => {
    const customer = structuredClone(state.customer);
    let confirmMessage = `Are you sure you want to make these changes?\n`;
    for (let prop in customer) {
      if (customer.hasOwnProperty(prop)) {
        if (customer[prop] !== props[prop]) {
          confirmMessage += `${prop}: ${props[prop]} ==> ${customer[prop]}\n`;
        } else {
          delete customer[prop];
        }
      }
    }
    if (window.confirm(confirmMessage)) {
      props.updateCustomerInfo(user_id, customer);
    }
  };

  const onRequestChangesCustomerInfo = () => {
    const customer = structuredClone(state.customer);
    let confirmMessage = `Are you sure you want to make these changes?\n`;
    for (let prop in customer) {
      if (customer.hasOwnProperty(prop)) {
        if (customer[prop] !== props[prop]) {
          confirmMessage += `${prop}: ${props[prop]} ==> ${customer[prop]}\n`;
        } else {
          delete customer[prop];
        }
      }
    }

    const staff_note = window.prompt("Please enter a note for this change.");

    if (window.confirm(confirmMessage)) {
      props.updateCustomerInfo(user_id, customer, true, staff_note);
    }
  };

  const onUpdateMail = () => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        newEmail
      )
    ) {
      props.updateEmail(user_id, newEmail);
    }
  };

  const onRequestUpdateMail = () => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        newEmail
      )
    ) {
      const staff_note = window.prompt("Please enter a note for this change.");

      props.updateEmail(user_id, newEmail, true, staff_note);
    }
  };

  const onSaveChangesKYCLevel = () => {
    let confirmMessage = `Are you sure you want to make these changes?\n`;

    confirmMessage += `kyc_level: ${props.kyc_level || "0"} ==> ${
      state.kyc_level
    }\n`;

    if (window.confirm(confirmMessage)) {
      window.api
        .post("/admin/users/" + user_id + "/customer/kyc_level", {
          kyc_level: state.kyc_level,
        })
        .then(() => {
          props.updateUser(user_id);
        });
    }
  };
  if (
    !(
      props.appUserRole.includes("admin") ||
      props.appUserRole.includes("support_manager")
    )
  ) {
    if (props.roles.includes("influencer")) {
      toast.error("Access Denied");

      props.history.push(`/users/find/`);
    }
  }

  if (props.roles)
    return (
      <div className={styles.main}>
        <Stats {...props} />

        <div className={styles.panel} id="information-compliance">
          <h2 className={styles.titlePanel}>Information/Compliance</h2>
          <div className={styles.form}>
            <div className={styles.field}>
              <label>Email</label>
              <div className={styles.input}>
                <input
                  value={state.info.email}
                  onChange={(e) =>
                    dispatch({ type: CHANGE_EMAIL, payload: e.target.value })
                  }
                />
              </div>
              <div className={styles.button}>
                {state.info.email?.includes("*") && (
                  <button
                    className="red small"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you wish to request this email?"
                        )
                      ) {
                        props.updateUser(user_id, true);
                      }
                    }}
                  >
                    Request
                  </button>
                )}
              </div>
              <div className={styles.message}>
                {props.email_confirmed ? "confirmed" : "not confirmed"}
              </div>
            </div>
            <div className={styles.field}>
              <label>Phone Number</label>
              <div className={styles.input}>
                <input
                  className={
                    state.info.phone_number !== props.phone_number
                      ? "changed"
                      : ""
                  }
                  value={state.info.phone_number}
                  onChange={(ev) =>
                    dispatch({ type: CHANGE_PHONE, payload: ev.target.value })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}>
                {props.phone_number_confirmed ? "confirmed" : "not confirmed"}
              </div>
            </div>

            <div className={styles.field}>
              <label>OTP Phone Number</label>
              <div className={styles.input}>
                <input value={state.info.otp_phone_number} readOnly />
              </div>
              <div className={styles.button}></div>
              <div
                className={styles.message}
                style={{
                  color: props.otp_phone_number_confirmed ? "green" : "red",
                }}
              >
                {props.otp_phone_number_confirmed
                  ? "confirmed with SMS code"
                  : "not confirmed"}
              </div>
            </div>

            <div className={styles.field}>
              <label>First name</label>
              <div className={styles.input}>
                <input
                  className={
                    state.info.first_name !== props.first_name ? "changed" : ""
                  }
                  value={state.info.first_name}
                  onChange={(ev) =>
                    dispatch({
                      type: CHANGE_FIRSTNAME,
                      payload: ev.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>Last name</label>
              <div className={styles.input}>
                <input
                  className={
                    state.info.last_name !== props.last_name ? "changed" : ""
                  }
                  value={state.info.last_name}
                  onChange={(ev) =>
                    dispatch({
                      type: CHANGE_LASTNAME,
                      payload: ev.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>Date of birth (YYYY-MM-DD)</label>
              <div className={styles.input}>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    state.info.date_of_birth
                      ? moment(state.info.date_of_birth).toDate()
                      : ""
                  }
                  onChange={(date) =>
                    dispatch({
                      type: CHANGE_DATE_BIRTH,
                      payload: moment.utc(date).format("yyyy-MM-DD"),
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>Gender</label>
              <div className={styles.input}>
                <select
                  onChange={(ev) => {
                    dispatch({
                      type: CHANGE_GENDER,
                      payload: ev.target.value,
                    });
                  }}
                  className={styles["select_gender"]}
                >
                  {GENDERS_OPTIONS.map((value) => (
                    <option selected={value === props.gender} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>Address</label>
              <div className={styles.input}>
                {" "}
                <input
                  className={
                    state.info.address !== props.address ? "changed" : ""
                  }
                  value={state.info.address}
                  onChange={(ev) =>
                    dispatch({
                      type: CHANGE_ADDRESS,
                      payload: ev.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>ISO Country</label>
              <div className={styles.input}>
                <input
                  className={
                    state.info.country !== props.country ? "changed" : ""
                  }
                  value={state.info.country}
                  onChange={(ev) =>
                    dispatch({
                      type: CHANGE_COUNTRY,
                      payload: ev.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>KYC Country</label>
              <div className={styles.input}>
                <input
                  className={
                    state.info.kyc_country !== props.kyc_country
                      ? "changed"
                      : ""
                  }
                  value={state.info.kyc_country}
                  onChange={(ev) =>
                    dispatch({
                      type: CHANGE_KYC_COUNTRY,
                      ayload: ev.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>City</label>
              <div className={styles.input}>
                <input
                  className={state.info.city !== props.city ? "changed" : ""}
                  value={state.info.city}
                  onChange={(ev) =>
                    dispatch({ type: CHANGE_CITY, ayload: ev.target.value })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>State</label>
              <div className={styles.input}>
                <input
                  className={state.info.state !== props.state ? "changed" : ""}
                  value={state.info.state}
                  onChange={(ev) =>
                    dispatch({ type: CHANGE_STATE, payload: ev.target.value })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.field}>
              <label>Postal code</label>
              <div className={styles.input}>
                <input
                  className={
                    state.info.postal_code !== props.postal_code
                      ? "changed"
                      : ""
                  }
                  value={state.info.postal_code}
                  onChange={(ev) =>
                    dispatch({
                      type: CHANGE_POSTAL_CODE,
                      payload: ev.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
          </div>
          <div className={styles.buttons}>
            <PermissionsFragment
              feature={permissionsConstants.USER_UPDATE_KYC_INFO}
            >
              <button onClick={saveInformation}>Save changes</button>
            </PermissionsFragment>
            <PermissionsFragment
              feature={permissionsConstants.REQUEST_USER_UPDATE_KYC_INFO}
            >
              <button onClick={requestChangesInformation}>
                Request changes
              </button>
            </PermissionsFragment>
          </div>
        </div>
        <div
          className={styles.panel}
          id="customer-info"
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <h2 className={styles.titlePanel}>Customer info</h2>
          <div className={styles.form}>
            <div className={styles.field}>
              <label>Tax ID</label>
              <div className={styles.input}>
                <input
                  className={
                    state.customer.tax_id !== props.tax_id ? "changed" : ""
                  }
                  value={state.customer.tax_id}
                  onChange={(ev) =>
                    dispatch({
                      type: CHANGE_TAX_ID,
                      payload: ev.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.buttons}>
              <PermissionsFragment
                feature={permissionsConstants.USER_UPDATE_KYC_INFO}
              >
                <button onClick={onSaveCustomerInfo}>Save changes</button>
              </PermissionsFragment>
              <PermissionsFragment
                feature={permissionsConstants.REQUEST_USER_UPDATE_KYC_INFO}
              >
                <button onClick={onRequestChangesCustomerInfo}>
                  Request changes
                </button>
              </PermissionsFragment>
            </div>
          </div>
          <div className={styles.formSelect}>
            <div className={styles.field}>
              <label>KYC Level</label>
              <div className={styles.input}>
                <Select
                  name="type"
                  value={
                    state.kyc_level !== undefined
                      ? state.kyc_level
                      : props.kyc_level || 1
                  }
                  onChange={(option) =>
                    dispatch({
                      type: CHANGE_KYC_LEVEL,
                      payload: option.value,
                    })
                  }
                  options={[
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                    { label: "4", value: 4 },
                    { label: "5", value: 5 },
                  ]}
                />
              </div>
              <div className={styles.button}></div>
              <div className={styles.message}></div>
            </div>
            <div className={styles.buttons}>
              <PermissionsFragment
                feature={permissionsConstants.USER_UPDATE_KYC_LEVEL}
              >
                <button
                  disabled={
                    (props.kyc_level || 1) === state.kyc_level ||
                    !state.kyc_level
                  }
                  onClick={onSaveChangesKYCLevel}
                >
                  Save changes
                </button>
              </PermissionsFragment>
            </div>
          </div>
        </div>
        <CpfInfo
          cpfInfo={props.cpf_info || {}}
          userInfo={{ email: props.email, address: props.address }}
        />
        <div className={styles.panel} id="preferences">
          <h2 className={styles.titlePanel}>Preferences</h2>
          <Table>
            <Thead>
              <Tr>
                <Th>Payment Emails</Th>
                <Th>Marketing Emails</Th>
                <Th>General Emails</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{props.payment_emails.toString()}</Td>
                <Td>{props.marketing_emails.toString()}</Td>
                <Td>{props.general_emails.toString()}</Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
        <div className={styles.panel} id="auth-methods">
          <h2 className={styles.titlePanel}>Authentication Methods</h2>
          <Table>
            <Thead>
              <Tr>
                <Th>Type</Th>
                <Th>Remote ID</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.authentication_methods.map((method) => {
                return (
                  <Tr key={method.remote_id}>
                    <Td>{method.type}</Td>
                    <Td>
                      {method.remote_id} (
                      {method.type === "password"
                        ? `Login username: ${props.password_account.username}`
                        : null}
                      )
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
        <div className={styles.panel} id="update-email">
          <GroupPermissionsFragment group={permissionGroups.USERS.UPDATE_EMAIL}>
            <h2 className={styles.titlePanel}>Update Email</h2>
            <input
              className={styles.singleInput}
              placeholder={"new email"}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />

            <div className={styles.buttons}>
              <PermissionsFragment
                feature={permissionsConstants.USER_UPDATE_EMAIL}
              >
                <button onClick={onUpdateMail}>Update</button>
              </PermissionsFragment>

              <PermissionsFragment
                feature={permissionsConstants.REQUEST_USER_UPDATE_EMAIL}
              >
                <button onClick={onRequestUpdateMail}>Request Update</button>
              </PermissionsFragment>
            </div>
          </GroupPermissionsFragment>
        </div>
      </div>
    );
};
const mapStateToProps = (state) => ({
  appUserRole: state.app.user.roles,
});

export default withRouter(connect(mapStateToProps, null)(Info));
