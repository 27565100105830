import { parseJSONToCSV } from "./json-to-csv-parser";

/**
 * @description Download array of objects as CSV
 * @param {Array<object>} json
 */
export const downloadJSONAsCSV = (json) => {
  const fileData = parseJSONToCSV(json);
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `${new Date().toISOString()}-export.csv`;
  link.href = url;
  link.click();
  URL.revokeObjectURL(url);
};
