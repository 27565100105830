import axios from 'axios';
import { toast } from 'react-toastify';
import permissions from './permissions.json';

const baseURL = '/api';
const client = axios.create({
  baseURL
});

client.interceptors.request.use(req => {
  req.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  req.headers['X-RateLimit-Bypass'] = '1';

  if (localStorage.getItem("x-block-bypass")) {
    req.headers["X-Block-Bypass"] = "1";
  }

  return req;
});

let sessionExpiredCallback = null;
export const registerSessionExpiredCallback = cb => {
  sessionExpiredCallback = cb;
}
const silenced = ['/admin/mystery_boxes/price'];
client.interceptors.response.use(res => {
  return res.data;
}, res => {

  if (res.response) {
    let error = res.response.data.error;

    if (error) {

      // unauthorized error
      if (error.code === 1008 && sessionExpiredCallback) {
        sessionExpiredCallback();
      }


      if (error.data && error.data.internal_message) {
        error.message += '\n' + error.data.internal_message;
      }

      if (!silenced.find(u => res.response.config.url.includes(u))) {
        toast.error(error.message);
      }
    }

    throw error;
  } else {
    throw res;
  }

});



client.authenticate = token => localStorage.setItem('access_token', token);
client.isAuthed = () => localStorage.getItem('access_token') !== null;
client.redirect = endpoint => window.location = baseURL + endpoint;
client.clearAuth = () => localStorage.removeItem('access_token');
client.getUrl = endpoint => baseURL + endpoint;
client.upload = async (file, path, prefixDiscriminator) => {

  if (!file) {
    return '';
  }

  const data = new FormData();
  data.append('file', file, file.name);
  data.append('path', path);

  let url = '/admin/uploads/' + path;
  if (prefixDiscriminator) {
    url = url + "?prefix_discriminator=" + prefixDiscriminator;
  }

  const res = await window.api.post(url, data);
  return res.url;
};


window.api = client;
window.password_prompt = () => window.prompt('A password is required for this resource.');


const requireRole = required => window.roles.includes(required);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getRolesRequiredByFeature = (feature) => {
  return permissions[feature] ?? [];
}

const isAdmin = () => window.roles.includes('admin');

const checkPermissions = (feature) => {
  if (isAdmin()) return true;

  const hasRoleRequired = window.roles.find(role => getRolesRequiredByFeature(feature).includes(role));

  if (hasRoleRequired) return true;

  const hasPermissionRequired = window.permissions.includes(feature);

  if (hasPermissionRequired) return true;

  return false;
};


const checkPermissionsGroup = (group) => {
  if (isAdmin()) {
    return true;
  }

  if (!Array.isArray(group)) {

    if (!group) {
      return false;
    }

    let subGroups = Object.values(group);

    for (let x = 0; x < subGroups.length; x++) {
      for (let i = 0; i < subGroups[x].length; i++) {
        if (checkPermissions(subGroups[x][i])) {
          return true;
        }
      }
    }

    return false;

  } else {
    for (let i = 0; i < group.length; i++) {
      if (checkPermissions(group[i])) {
        return true;
      }
    }

    return false;
  }
};


export { requireRole, reorder, checkPermissions, checkPermissionsGroup, isAdmin };