

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReferralData } from "./actions";

class UserReferrals extends Component {

  constructor(props) {
    super();

    this.state = {
      page: 1
    }
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.getReferralData(this.props.match.params.id)
  }

  render() {

    const { referral_data } = this.props;
    return (
      <div>
        { Object.keys(referral_data).map(key =>{
          return (
            <div style={{display: 'flex', justifyContent:'flex-start', alignItems:'center'}}><h3 style={{marginBottom: 0, marginRight: 10}}>{key}:</h3> {referral_data[key]}</div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  referral_data: state.users.referrals
});

const mapDispatchToProps = dispatch => ({
  getReferralData: (user_id) => dispatch(getReferralData(user_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserReferrals);