import { Typography } from "@mui/material";

import { format } from '../../../shared/utils/dates'

const suspendAccountReasonMapper = (reasonId, suspendAccountReasonOptions) => suspendAccountReasonOptions.find(reason => reason.id === reasonId)?.reason ?? '-'

export default function SuspendDetail({ userSuspension, suspendAccountReasonOptions, children }) {

    if (!userSuspension) {
        return <Typography variant="body2" component="p">No suspension to show</Typography>
    }

    return (
        <table>
            <thead>
                <tr>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Reason</th>
                    <th>Staft Id</th>
                    <th>Note</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{format.withSeconds(userSuspension.created_at)}</td>
                    <td>{format.withSeconds(userSuspension.expires_at)}</td>
                    <td>{suspendAccountReasonMapper(userSuspension.reason, suspendAccountReasonOptions)}</td>
                    <td>{userSuspension.staff_user_id}</td>
                    <td>{userSuspension.note}</td>
                    <td>{children}</td>
                </tr>
            </tbody>
        </table>
    )
}