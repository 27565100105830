import React, { useReducer, useState } from 'react';
import { toast } from 'react-toastify';

import { updateCampaign } from './api';
import { COMMISSION_RATE_TYPE } from './constants';

import './affiliates-page.css';
import { useSelector } from 'react-redux';
import { affiliatesSelector } from './state/selectors';

function reducer(state, action) {
  switch (action.type) {
    case 'set_value': {
      return { ...state, [action.name]: action.value };
    }
    default: {
      return state;
    }
  }
}

const initialValue = {
  campaign_id: '',
  commission_model: '',
  commission_model_value: '',
  cpa_for_hybrid_model: '',
};

function UpdateCampaign(props) {
  const { selectedCampaign } = useSelector(affiliatesSelector);

  if (!selectedCampaign) {
    return <span>Please, select a campaign first</span>
  }

  const [saving, setSaving] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    campaign_id: selectedCampaign.id,
    commission_model: selectedCampaign.commission_rate_type,
    commission_model_value: selectedCampaign.commission_rate_value,
    cpa_for_hybrid_model: selectedCampaign.cpa_for_hybrid_model,
  });

  const setValue = ({ target }) => {
    const { name, value } = target;
    if (name && value) {
      dispatch({ type: 'set_value', name, value });
    }
  };

  const hasEmptyField = state => {
    return Object.values(state).some(value => !value);
  };

  const handleUpdateCampaign = async () => {
    try {
      if (hasEmptyField(state)) {
        throw new Error('Some fields are empty.');
      }
      setSaving(true);
      await updateCampaign(state);
      toast.success(`Campaign updated for: ${state.campaign_id}.`);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="affiliate-page">
      <div className='affiliate-page__container'>
        <div className='affiliate-page__group-input'>
          <label>
            Campaign ID:
          </label>
          <input
            name="campaign_id"
            value={state.campaign_id}
            disabled={true}
            onChange={setValue}
          />
        </div>
        <div className='affiliate-page__group-input'>
          <label>

            Commision Rate Type:
          </label>
          <select name="commission_model" onChange={setValue} value={state.commission_model}>
            <option value={COMMISSION_RATE_TYPE.CPA}>{COMMISSION_RATE_TYPE.CPA}</option>
            <option value={COMMISSION_RATE_TYPE.WS}>{COMMISSION_RATE_TYPE.WS}</option>
            <option value={COMMISSION_RATE_TYPE.RS}>{COMMISSION_RATE_TYPE.RS}</option>
            <option value={COMMISSION_RATE_TYPE.HY}>{COMMISSION_RATE_TYPE.HY}</option>
          </select>
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            Commision Rate value:
          </label>
          <input name="commission_model_value" value={state.commission_model_value}
            onChange={setValue} />
        </div>
        <div className='affiliate-page__group-input'>
          <label>
            CPA for Hybrid Model:
          </label>
          <input name="cpa_for_hybrid_model" value={state.cpa_for_hybrid_model} onChange={setValue} />
        </div>
        <button disabled={saving} onClick={handleUpdateCampaign}>Update Campaign</button>
      </div>

    </div>
  );
}

export default UpdateCampaign;
