import { ADMIN_GAMES_API } from 'casino/race/constants';
import { useEffect, useState } from 'react';

const useDataSelect = () => {

  const [listGames, setListGames] = useState();


  const getGames = async () => {
    const res = await window.api.get(ADMIN_GAMES_API);
    const games = [];
    for (let i = 0; i < res.records.length; i++) {
      const record = res.records[i];
      games.push({
        label: record.name,
        value: record.id,
      });
    }
    setListGames(games);
  };

  useEffect(() => {
    getGames();
  }, []);

  return [listGames, setListGames];
}

export default useDataSelect