import * as types from './types';
import platforms from './payment-platforms-list.json'

const initial = {
  country_ordering_search_results: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  method_search_results: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  provider_search_results: {
    meta: {
      total_pages: 1
    },
    records: []
  },
  provider: {},
  method: {},
  country_ordering: {},
  platforms
};

export default (state = initial, action) => {

  switch(action.type) {
    case types.PAYMENT_METHODS_UPDATE:
      return {
        ...state,
        method_search_results: action.payload
      };
    case types.PAYMENT_PROVIDERS_UPDATE:
      return {
        ...state,
        provider_search_results: action.payload
      };
    case types.PAYMENT_COUNTRY_ORDERINGS_UPDATE:
      return {
        ...state,
        country_ordering_search_results: action.payload
      };

    case types.PAYMENT_ACCOUNTS_UPDATE:
      return {
        ...state,
        account_search_results: action.payload
      };

    case types.PAYMENT_METHOD_UPDATE:
      return {
        ...state,
        method: action.payload
      };
    case types.PAYMENT_PROVIDER_UPDATE:
      return {
        ...state,
        provider: action.payload
      };

    case types.PAYMENT_COUNTRY_ORDERING_UPDATE:
      return {
        ...state,
        country_ordering: action.payload
      };

  }

  return state;
}