import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, NavLink, Switch, withRouter } from "react-router-dom";
import {
  permissionsConstants,
  CheckPermissionsRoute,
} from "app/permissions-fragment.js";
import { BrowserView } from "react-device-detect";
import { ViewRewardLink, CreateRewardLink } from "./links";
import ViewRewards from "./view-reward";
import CreateRewards from "./create-reward";
import Reward from "../update-reward";

class TabRewards extends Component {
  render() {
    return (
      <div>
        <BrowserView>
          <nav className="sub-nav">
            <ViewRewardLink />
            <CreateRewardLink />
          </nav>
        </BrowserView>

        <Switch>
          <CheckPermissionsRoute
            feature={permissionsConstants.REWARDS_VIEW_LIST}
            exact
            path="/casino/rewards"
            component={ViewRewards}
          />
          <CheckPermissionsRoute
            feature={permissionsConstants.REWARDS_VIEW_LIST}
            exact
            path="/casino/rewards/create"
            component={CreateRewards}
          />
          <CheckPermissionsRoute
            feature={permissionsConstants.REWARDS_VIEW_REWARD}
            path="/casino/rewards/:id"
            component={Reward}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TabRewards)
);
