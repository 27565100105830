import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  permissionsConstants,
  PermissionsFragment,
} from "../app/permissions-fragment";
import Select from "react-select";
import { addPermission, removePermission } from "./actions";
import { useParams } from "react-router-dom";
import styles from "./roles.module.scss";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const UserPermissions = ({}) => {
  const { id: userId } = useParams();

  const permissions = useSelector((store) => store.users.user.permissions);
  const [permission, setPermission] = useState(null);
  const dispatch = useDispatch();

  return (
    <>
      <PermissionsFragment
        feature={permissionsConstants.USER_UPDATE_PERMISSIONS}
      >
        <div className={styles.form}>
          <h4>Edit Permissions</h4>
          <div className={styles.editRoles}>
            <Select
              name="type"
              value={permission}
              onChange={(permission) => {
                setPermission(permission?.value);
              }}
              options={Object.keys(permissionsConstants)
                .filter((permission) => !permissions.includes(permission))
                .map((permission) => ({
                  value: permission,
                  label: permission,
                }))}
            />

            <button
              onClick={() => {
                dispatch(addPermission(userId, permission));
              }}
              disabled={!permission}
            >
              Add
            </button>
          </div>
        </div>
      </PermissionsFragment>
      {permissions.length > 0 && (
        <div className={styles.form}>
          <h4>Current Permissions</h4>

          <Table className={styles.currentRoles}>
            <Thead>
              <Tr>
                <Th>Role</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {permissions.map((permission, index) => {
                return (
                  <Tr key={permission} className={"user-permission"}>
                    <Td>{permission}</Td>
                    <Td>
                      <PermissionsFragment
                        feature={permissionsConstants.USER_REMOVE_PERMISSIONS}
                      >
                        {
                          <button
                            className={styles.actionButton}
                            onClick={() => {
                              dispatch(removePermission(userId, permission));
                            }}
                          >
                            Remove
                          </button>
                        }
                      </PermissionsFragment>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default UserPermissions;
