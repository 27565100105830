import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserNotes, addNote } from "./actions";
import Pagination from "../shared/pagination";
import {
  permissionsConstants,
  PermissionsFragment,
} from "../app/permissions-fragment";
import { format } from "../shared/utils/dates";
import { Table, Td, Th, Thead, Tr } from "react-super-responsive-table";
import styles from "./roles.module.scss";

class UserCasinoBets extends Component {
  constructor(props) {
    super();

    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.updateNotes(this.props.match.params.id, this.state.page);
  }

  render() {
    return (
      <div>
        <PermissionsFragment feature={permissionsConstants.USER_NOTES_CREATE}>
          <div className={styles.form}>
            <h4>Add Note</h4>
            <div className={styles.editRoles}>
              <input placeholder={"User Note"} ref={(el) => (this.note = el)} />
              <button
                onClick={() => {
                  this.props.addNote(
                    this.props.match.params.id,
                    this.note.value
                  );
                }}
              >
                Add Note
              </button>
            </div>
          </div>
        </PermissionsFragment>

        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>Note</Th>
            </Tr>
          </Thead>

          <tbody>
            {this.props.records.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                  <Td>{r.id}</Td>
                  <Td>{r.note}</Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>

        <Pagination
          total_pages={this.props.meta.total_pages}
          update={(page) => {
            this.setState({ page }, () => this.update());
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.users.notes,
});

const mapDispatchToProps = (dispatch) => ({
  updateNotes: (user_id, page) => dispatch(updateUserNotes(user_id, page)),
  addNote: (user_id, note) => {
    dispatch(addNote(user_id, note));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCasinoBets);
