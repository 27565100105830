

import React, { useEffect, useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { toast } from "react-toastify";
import { format } from '../shared/utils/dates';

const OverdraftAlerts = (props) => {
    const [overdrafts, setOverdrafts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchOverdrafts = () => {
        setIsLoading(true);

        window.api.get(`/admin/users/${props.match.params.id}/overdraft-alerts`,).then(
            (records) => {
                setOverdrafts(records);
            }
        )
        .catch(error => toast.error(error.message))
        .finally(() => setIsLoading(false));

        setIsLoading(false)
    };

    useEffect(fetchOverdrafts, [props.match.params.id]);

    const formatPrice = (price) => {
        if (!Number(price)) {
            return 0;
        }

        return Number(price).toFixed(2);
    }

    return (
        <div style={{ position: 'relative', 'min-height': '500px' }}>

            {isLoading &&
                <div className={"loading-overlay"}>
                    <h2>Searching, please wait ...</h2>
                    <div className="loader" />
                </div>
            }

            <Table
                className='overflow-x-scroll'
            >
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Date</Th>
                        <Th>Currency</Th>
                        <Th>Overdraft Amount</Th>
                        <Th>Eur Overdraft</Th>
                        <Th>Target Rollback Amount</Th>
                        <Th>Bet ID</Th>
                        <Th>Round ID</Th>
                        <Th>Transaction ID</Th>
                        <Th>Remote Command ID</Th>
                        <Th>Target Remote Command ID</Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {
                        overdrafts.map((r, i) => {
                            return (
                                <Tr key={i}>
                                    <Td>{r.id}</Td>
                                    <Td>{format.withMinutes(r.created_at)}</Td>
                                    <Td>{r.currency_type}</Td>
                                    <Td className="bad">{formatPrice(r.overdraft)}</Td>
                                    <Td className="bad">{formatPrice(r.eur_overdraft)}</Td>
                                    <Td>{formatPrice(r.target_rollback_amount)}</Td>
                                    <Td>{r.bet_id}</Td>
                                    <Td>{r.round_id}</Td>
                                    <Td>{r.transaction_id}</Td>
                                    <Td>{r.remote_command_id}</Td>
                                    <Td>{r.target_remote_command_id}</Td>
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Table>
        </div>
    )
}

export default OverdraftAlerts;
