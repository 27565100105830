import React, { Component, Fragment } from 'react';
import Pagination from '../shared/pagination';
import { Link } from 'react-router-dom';
import { Table, Td, Th, Thead, Tr } from 'react-super-responsive-table';

export default class Loyalty extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      records: []
    }
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      this.update();
    }
  }

  update() {
    window.api.get(`/admin/loyalty`, {
      params: {
        page: this.state.page
      }
    }).then(
      data => this.setState({ records: data.records, total_pages: data.meta.total_pages })
    )
  }

  render() {

    return (
      <div>
        <h3>Loyalty</h3>

        <br />
        <Table>
          <Thead>
            <Tr>
              <Th>Period</Th>
              <Th>ID</Th>
              <Th>Status</Th>
              <Th>User</Th>
              <Th>Level</Th>
              <Th>Percentage</Th>
              <Th>Amount</Th>
              <Th>Calculation variables</Th>
            </Tr>
          </Thead>

          <tbody>
            {
              this.state.records.map((r, i) => {

                r.loyalty_period = r.loyalty_period || {};
                r.loyalty_period.starts_at = r.loyalty_period.starts_at || "";
                r.loyalty_period.ends_at = r.loyalty_period.ends_at || "";

                r.user = r.user || {};

                r.transaction = r.transaction || { wallet: {} };

                return (
                  <Tr key={i}>
                    <Td>{r.loyalty_period.starts_at.split('T')[0]} - {r.loyalty_period.ends_at.split('T')[0]}</Td>
                    <Td>{r.id}</Td>
                    <Td>{r.status}</Td>
                    <Td className="user">
                      <Link to={`/users/${r.user_id}/loyalty`}>
                        <img src={r.user.avatar_url} />
                        {r.user.username}
                      </Link>
                    </Td>
                    <Td>{r.level}</Td>
                    <Td>{r.percentage}</Td>
                    <Td>{r.transaction.amount} {r.transaction.wallet.currency_type}</Td>
                    <Td>{Object.keys(r.calculation_values || {}).map(m => {
                      return <span style={{ marginRight: 5 }}>{m} = {r.calculation_values[m]}</span>
                    })}</Td>
                  </Tr>
                )
              })
            }
          </tbody>
        </Table>


        <Pagination
          total_pages={this.state.total_pages}
          update={page => {
            this.setState({ page });
          }}
        />

      </div>
    )
  }
}