import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import Pagination from '../shared/pagination';

import { getAffiliates } from './api';

import './finder.css';
import { connect } from 'react-redux';
import { setSelectedAffiliate } from './state/actions';
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

function Finder({ setSelectedAffiliate }) {
  const [page, setPage] = useState(1);
  const [affiliateId, setAffiliateId] = useState('');
  const [blazeId, setBlazeId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [affiliates, setAffiliates] = useState([]);
  const [hasAffiliateManager, setHasAffiliateManager] = useState(false);

  const fetchAffiliates = async () => {
    try {
      setLoading(true);
      const data = await getAffiliates({
        page,
        affiliate_id: affiliateId,
        blaze_user_id: blazeId,
        with_affiliate_manager: hasAffiliateManager,
      });
      setAffiliates(data.affiliates);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAffiliates();
  }, [page, hasAffiliateManager]);

  return (
    <div className="affiliates-finder">
      <input
        type="checkbox"
        name="with_affiliate_manager"
        id="with_affiliate_manager"
        onChange={() => setHasAffiliateManager((v) => !v)}
      />
      <label for="with_affiliate_manager">
        Filter affiliates with affiliate manager assigned
      </label>
      <div className="affiliates-search-row">
        <input
          value={affiliateId}
          onChange={({ target }) => setAffiliateId(target.value)}
          placeholder="Type affiliate code"
        />
        <input
          value={blazeId}
          onChange={({ target }) => setBlazeId(target.value)}
          placeholder="Type Blaze user id"
        />

        <button
          className="red"
          disabled={isLoading}
          onClick={() => fetchAffiliates()}
        >
          {isLoading ? 'Loading...' : 'Search'}
        </button>
      </div>
      <br />
      <br />
      <br />
      {isLoading && (
        <div className={'loading-overlay'}>
          <h2>Searching, please wait ...</h2>
          <div className="loader" />
        </div>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Full name</Th>
            <Th>Payment Schedule</Th>
            <Th>Fiat Preference</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
            <Th>website</Th>
            <Th>Social Media Profile</Th>
            <Th>Skype / WhatsApp / Telegram</Th>
            <Th>Currency to Paid</Th>
          </Tr>
        </Thead>

        <Tbody>
          {affiliates?.map((item) => {
            return (
              <Tr key={item.blaze_user_id}>
                <Td>
                  <Link to={`/affiliates/${item.blaze_user_id}`} onClick={() => setSelectedAffiliate(item)}>
                    {item.blaze_user_id}
                  </Link>
                </Td>
                <Td>{item.full_name}</Td>
                <Td>{item.payment_schedule}</Td>
                <Td>{item.fiat_preference}</Td>
                <Td>{format.onlyDate(item.created_at)}</Td>
                <Td>{format.onlyDate(item.updated_at)}</Td>
                <Td>{item.website}</Td>
                <Td>{item.social_media_profile}</Td>
                <Td>{item.skype_whatsapp_telegram}</Td>
                <Td>{item.currency_to_paid}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        total_pages={100}
        update={setPage}
      />
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setSelectedAffiliate: (affiliate) => dispatch(setSelectedAffiliate(affiliate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Finder);
