import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  createKYCVerification,
  getDocument,
  updateKYCVerification,
  uploadVerificationFile,
  notifyKYCVerification,
  getDocuments,
  approvePaymentVerification,
  rejectPaymentVerification,
  updatePaymentAccounts,
} from "./actions";
import Select from "react-select";
import Modal from "react-modal";
import DocumentNotices from "./DocumentNotices";
import { KYC_TYPES } from "./constants";
import UserDocuments from "./documents";
import {
  GroupPermissionsFragment,
  permissionGroups,
  permissionsConstants,
  PermissionsFragment,
} from "../app/permissions-fragment";
import { toast } from "react-toastify";
import ConditionalComponent from "../shared/ConditionalComponent";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { format } from '../shared/utils/dates';

class UserKYC extends Component {
  constructor(props) {
    super();

    this.state = {
      page: 1,

      id_email: false,
      address_email: false,
      card_email: false,
      bank_email: false,
      proof_of_deposit: false,

      kyc_verifications: [],
      accountChanges: {},
      isLoading: false,
      deposits: [],
    };
  }

  fetchDeposits() {
    this.setState({ isLoading: true });
    window.api
      .get(`/admin/users/${this.props.match.params.id}/deposits`, {
        params: {
          page: 1,
        },
      })
      .then(({ meta, records }) => {
        this.setState({ deposits: records, total_pages: meta.total_pages });
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.updatePaymentAccounts(id);

    window.api
      .get(`/admin/users/` + id + "/kyc_verifications")
      .then((kyc_verifications) => this.setState({ kyc_verifications }));

    this.fetchDeposits();
  }

  renderDocument() {
    const document = this.props.document;
    if (document.type === "image") {
      return <img src={document.data} alt="" />;
    } else if (document.type === "pdf") {
      return (
        <iframe
          src={document.data}
          type="application/pdf"
          style={{
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        />
      );
      return <a href={document.data}>Click to open pdf</a>;
    }
  }

  render() {
    const user_id = this.props.match.params.id;

    const maskedAccountDropdown = (
      <>
        <span>Masked account: </span>
        <Select
          name="type"
          value={this.state.kyc_masked_account}
          onChange={(option) => this.setState({ kyc_masked_account: option })}
          options={[
            ...(this.props.payment_methods || [])
              .map((method) => {
                if (
                  this.state.kyc_type &&
                  method.type === this.state.kyc_type.value
                ) {
                  return method.accounts.map((account) => {
                    return {
                      value: account.masked_account,
                      label: method.type + ": " + account.masked_account,
                    };
                  });
                } else {
                  return [];
                }
              })
              .flat(),
          ]}
        />
      </>
    );

    return (
      <div className="documents-page">
        <Modal
          overlayClassName={"document-modal"}
          isOpen={this.state.document_modal_open}
          onRequestClose={(e) => this.setState({ document_modal_open: false })}
        >
          <div
            onClick={(e) => this.setState({ document_modal_open: false })}
            className="close"
            style={{ fontSize: "20px" }}
          >
            Close
          </div>
          {this.renderDocument()}
        </Modal>

        <Modal
          overlayClassName={"document-modal upload-modal"}
          isOpen={this.state.upload_modal_open}
          onRequestClose={(e) => this.setState({ upload_modal_open: false })}
        >
          <div
            onClick={(e) => this.setState({ upload_modal_open: false })}
            className="close"
            style={{ fontSize: "20px" }}
          >
            Close
          </div>

          <h3>Sub type</h3>
          <Select
            name="subtype"
            value={this.state.sub_type}
            onChange={(option) => this.setState({ sub_type: option })}
            options={[
              "passport",
              "drivers_license",
              "national_id_card",
              "bank_credit_statement",
              "utility_bill",
              "letter_from_government",
              "credit_card",
              "iban",
            ].map((m) => {
              return {
                value: m,
                label: m,
              };
            })}
          />

          <input type="file" id="document-upload-select" name="filename" />
          <button
            onClick={() => {
              let fileInput = document.getElementById("document-upload-select");

              this.props
                .upload(
                  fileInput.files,
                  this.state.upload_modal_open.verification_id,
                  this.state.sub_type.value,
                  user_id,
                  this.state.upload_modal_open.back
                )
                .then(() => {
                  this.setState({
                    upload_modal_open: false,
                  });
                });
            }}
          >
            Upload
          </button>
        </Modal>

        <Modal
          overlayClassName={"document-modal"}
          isOpen={this.state.document_modal_open}
          onRequestClose={(e) => this.setState({ document_modal_open: false })}
        >
          <div
            onClick={(e) => this.setState({ document_modal_open: false })}
            className="close"
            style={{ fontSize: "20px" }}
          >
            Close
          </div>
          {this.renderDocument()}
        </Modal>

        <PermissionsFragment
          feature={permissionsConstants.USER_KYC_SEND_NOTIFICATION}
        >
          <DocumentNotices user_id={user_id} />
        </PermissionsFragment>

        <PermissionsFragment
          feature={permissionsConstants.USER_KYC_CREATE_VERIFICATION}
        >
          <h5>Create</h5>

          <span>Type: </span>
          <Select
            name="type"
            value={this.state.kyc_type}
            onChange={(option) => this.setState({ kyc_type: option })}
            options={KYC_TYPES.map((m) => {
              return {
                value: m,
                label: m,
              };
            })}
          />

          <ConditionalComponent
            condition={["proof_of_deposit"].includes(
              this.state.kyc_type?.value
            )}
            defaultComponent={maskedAccountDropdown}
          >
            <span>Deposit: </span>
            <Select
              name="type"
              value={this.state.deposit_selected}
              onChange={(option) => this.setState({ deposit_selected: option })}
              options={(this.state.deposits || []).map((deposit) => ({
                value: deposit.id,
                label: `Deposit ID: ${deposit.id}`,
              }))}
            />
          </ConditionalComponent>

          <br />

          <button
            className="small"
            onClick={() => {
              const deposit_id = this.state.deposit_selected?.value;
              let masked_account = null;
              let type = null;
              if (this.state.kyc_type) {
                type = this.state.kyc_type.value;

                if (
                  ["bank_iban", "credit_card", "credit_card_switch"].includes(
                    type
                  )
                ) {
                  if (this.state.kyc_masked_account) {
                    masked_account = this.state.kyc_masked_account.value;
                  }
                }

                this.props.createKYCVerification(
                  user_id,
                  type,
                  masked_account,
                  deposit_id
                );
              }
            }}
          >
            Create
          </button>
        </PermissionsFragment>

        <hr />

        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Masked Account</Th>
              <Th>Type</Th>
              <Th>Sub type</Th>
              <Th>Related to</Th>
              <Th>Requested at</Th>
              <Th>Updated at</Th>
              <Th>Status</Th>
              <PermissionsFragment
                feature={permissionsConstants.USER_KYC_UPDATE_VERIFICATION}
              >
                <Th>Reference</Th>
                <Th>Front image</Th>
                <Th>Back image</Th>
                <Th>Note</Th>
              </PermissionsFragment>
            </Tr>
          </Thead>
          <Tbody>
            {(this.state.kyc_verifications || []).map((verification, index) => {
              return (
                <Tr key={index}>
                  <Td>{verification.id}</Td>
                  <Td>{verification.masked_account}</Td>
                  <Td>{verification.type}</Td>
                  <Td>{verification.sub_type}</Td>
                  <Td>{verification.deposit_id ? `Deposit #${verification.deposit_id}` : ""}</Td>
                  <Td>{format.onlyDate(verification.created_at)}</Td>
                  <Td>{format.onlyDate(verification.updated_at)}</Td>
                  <Td>{verification.status}</Td>
                  <PermissionsFragment
                    feature={permissionsConstants.USER_KYC_UPDATE_VERIFICATION}
                  >
                    <Td>{verification.reference_id}</Td>
                    <Td>
                      {verification.user_document_id && (
                        <div style={{ cursor: "pointer" }}>
                          <span
                            onClick={() => {
                              this.props.getDocument(
                                user_id,
                                this.props.documents.find((d) => {
                                  return d.id === verification.user_document_id;
                                })
                              );
                              this.setState({ document_modal_open: true });
                            }}
                          >
                            View Front
                          </span>
                        </div>
                      )}
                      <button
                        className="small"
                        onClick={() => {
                          this.setState({
                            upload_modal_open: {
                              verification_id: verification.id,
                            },
                          });
                        }}
                      >
                        Upload Front
                      </button>
                    </Td>

                    <Td>
                      {verification.back_document_id && (
                        <div style={{ cursor: "pointer" }}>
                          <span
                            onClick={() => {
                              this.props.getDocument(
                                user_id,
                                this.props.documents.find((d) => {
                                  return d.id === verification.back_document_id;
                                })
                              );
                              this.setState({ document_modal_open: true });
                            }}
                          >
                            View Back
                          </span>
                        </div>
                      )}
                      <button
                        className="small"
                        onClick={() => {
                          this.setState({
                            upload_modal_open: {
                              verification_id: verification.id,
                              back: true,
                            },
                          });
                        }}
                      >
                        Upload Back
                      </button>
                    </Td>
                    <Td>
                      <button
                        className="small"
                        onClick={() => {
                          this.props
                            .updateKYCVerification(
                              verification.id,
                              "invalid",
                              user_id
                            )
                            .then((data) => {
                              window.api
                                .get(
                                  `/admin/users/` +
                                  user_id +
                                  "/kyc_verifications"
                                )
                                .then((kyc_verifications) =>
                                  this.setState({ kyc_verifications })
                                );
                            });
                        }}
                      >
                        Set Invalid
                      </button>
                      <button
                        className="small"
                        onClick={() => {
                          this.props
                            .updateKYCVerification(
                              verification.id,
                              "complete",
                              user_id
                            )
                            .then((data) => {
                              window.api
                                .get(
                                  `/admin/users/` +
                                  user_id +
                                  "/kyc_verifications"
                                )
                                .then((kyc_verifications) =>
                                  this.setState({ kyc_verifications })
                                );
                            });
                        }}
                      >
                        Mark as complete
                      </button>

                      <button
                        className="small"
                        onClick={() => {
                          this.props
                            .updateKYCVerification(
                              verification.id,
                              "deleted",
                              user_id
                            )
                            .then((data) => {
                              window.api
                                .get(
                                  `/admin/users/` +
                                  user_id +
                                  "/kyc_verifications"
                                )
                                .then((kyc_verifications) =>
                                  this.setState({ kyc_verifications })
                                );
                            });
                        }}
                      >
                        Delete
                      </button>
                    </Td>
                    <Td>{verification.note}</Td>
                  </PermissionsFragment>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <h4>Payment Accounts</h4>

        <br />

        <div className="accounts">
          <Table>
            <Thead>
              <Tr>
                <PermissionsFragment
                  feature={permissionsConstants.USER_KYC_UPDATE_VERIFICATION}
                >
                  <Th>Update</Th>
                </PermissionsFragment>
                <Th>ID</Th>
                <Th>Masked Account</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>Data</Th>
                <PermissionsFragment
                  feature={permissionsConstants.USER_DOCUMENTS_VIEW_FILE}
                >
                  <Th>View Document</Th>
                </PermissionsFragment>
                <PermissionsFragment
                  feature={permissionsConstants.USER_KYC_UPDATE_VERIFICATION}
                >
                  <Th>Action</Th>
                </PermissionsFragment>
              </Tr>
            </Thead>
            <Tbody>
              {(this.props.payment_methods || []).map((method, index) => {
                return method.accounts.map((account) => {
                  const getAction = () => {
                    if (!account.verification) {
                      return null;
                    } else {
                      if (account.verification.status === "pending_review") {
                        return (
                          <Fragment>
                            <button
                              className="small"
                              onClick={() =>
                                this.props.approvePaymentVerification(
                                  user_id,
                                  account.verification.id
                                )
                              }
                            >
                              Accept
                            </button>
                            <button
                              className="small"
                              onClick={() => {
                                let note = window.prompt(
                                  "Enter reason for rejecting document to be displayed to user."
                                );

                                this.props.rejectPaymentVerification(
                                  user_id,
                                  account.verification.id,
                                  note
                                );
                              }}
                            >
                              Decline
                            </button>
                          </Fragment>
                        );
                      }
                    }
                  };

                  const changes = this.state.accountChanges[account.id] || {};
                  let maskedAccountChanged =
                    changes.masked_account &&
                    changes.masked_account !== account.masked_account;
                  let statusChanged =
                    changes.status && changes.status !== account.status;

                  return (
                    <Tr className="account" key={account.id}>
                      <PermissionsFragment
                        feature={
                          permissionsConstants.USER_KYC_UPDATE_VERIFICATION
                        }
                      >
                        <Td>
                          <button
                            disabled={!maskedAccountChanged && !statusChanged}
                            onClick={() => {
                              let confirmMessage = `Are you sure you want to update payment account ${account.id}? \nChanges: `;
                              let props = {};
                              if (maskedAccountChanged) {
                                confirmMessage += `${account.masked_account} => ${changes.masked_account} \n`;
                                props.masked_account = changes.masked_account;
                              }

                              if (statusChanged) {
                                confirmMessage += `${account.status} => ${changes.status} \n`;
                                props.status = changes.status;
                              }

                              let confirm = window.confirm(confirmMessage);

                              if (confirm) {
                                window.api
                                  .post(
                                    `/admin/users/${user_id}/payment_accounts/${account.id}`,
                                    props
                                  )
                                  .then((data) => {
                                    toast.info("Updated.");

                                    this.props.updatePaymentAccounts(user_id);
                                  });
                              }
                            }}
                          >
                            Update
                          </button>
                        </Td>
                      </PermissionsFragment>
                      <Td>{account.id}</Td>
                      <Td>
                        <input
                          onChange={(e) => {
                            this.setState({
                              accountChanges: {
                                ...this.state.accountChanges,
                                [account.id]: {
                                  ...changes,
                                  masked_account: e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            changes.masked_account || account.masked_account
                          }
                        />
                      </Td>
                      <Td>{method.type}</Td>
                      <Td>
                        <Select
                          style={{
                            width: 200,
                          }}
                          name="type"
                          value={changes.status || account.status}
                          onChange={(option) =>
                            this.setState({
                              accountChanges: {
                                ...this.state.accountChanges,
                                [account.id]: {
                                  ...changes,
                                  status: option.value,
                                },
                              },
                            })
                          }
                          options={["created", "deleted", "active"].map((m) => {
                            return {
                              value: m,
                              label: m,
                            };
                          })}
                        />
                      </Td>
                      <Td>{JSON.stringify(account.data, null, 4)}</Td>
                      <PermissionsFragment
                        feature={permissionsConstants.USER_DOCUMENTS_VIEW_FILE}
                      >
                        <Td>
                          {(account.verification || {}).user_document_id && (
                            <div>
                              <span
                                onClick={() => {
                                  this.props.getDocument(
                                    user_id,
                                    account.verification.user_document
                                  );
                                  this.setState({ document_modal_open: true });
                                }}
                              >
                                View Document
                              </span>

                              <span
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  this.setState({ document_modal_open: false });
                                }}
                              >
                                Hide Document
                              </span>
                            </div>
                          )}
                        </Td>
                      </PermissionsFragment>

                      <PermissionsFragment
                        feature={
                          permissionsConstants.USER_KYC_UPDATE_VERIFICATION
                        }
                      >
                        <Td>{getAction()}</Td>
                      </PermissionsFragment>
                    </Tr>
                  );
                });
              })}
            </Tbody>
          </Table>
        </div>
        <br />
        <br />

        <GroupPermissionsFragment group={permissionGroups.USERS.DOCUMENTS}>
          <UserDocuments
            {...this.props}
            openDocumentModal={() => {
              this.setState({
                document_modal_open: true,
              });
            }}
          />
        </GroupPermissionsFragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentAccounts(user_id) {
    dispatch(updatePaymentAccounts(user_id));
  },
  getDocuments: (user_id) => dispatch(getDocuments(user_id)),

  getDocument: (user_id, document) => dispatch(getDocument(user_id, document)),

  createKYCVerification: (user_id, type, masked_account, deposit_id) => {
    dispatch(createKYCVerification(user_id, type, masked_account, deposit_id));
  },

  updateKYCVerification: (verification_id, status, user_id) => {
    return dispatch(updateKYCVerification(verification_id, status, user_id));
  },

  notifyKYCVerification: (user_id, props) => {
    dispatch(notifyKYCVerification(user_id, props));
  },

  approvePaymentVerification: (user_id, id) => {
    dispatch(approvePaymentVerification(user_id, id));
  },

  rejectPaymentVerification: (user_id, id, note) => {
    dispatch(rejectPaymentVerification(user_id, id, note));
  },
  upload: (filelist, id, sub_type, user_id, back) => {
    return dispatch(
      uploadVerificationFile(filelist, id, sub_type, user_id, back)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserKYC);
