import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css'
import Select from 'react-select';
import { toast } from "react-toastify";
import { format } from '../../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const schema = {
  types: [
    { "type": "mbtc" },
    { "type": "skrill" },
    { "type": "credit_card" },
    { "type": "neteller" },
    { "type": "interac_etransfer" },
    { "type": "ecopayz" },
    { "type": "muchbetter" },
    { "type": "bank_iban" },
    { "type": "bank_domestic" },
    { "type": "qiwi" },

    { "type": "visa" },
    { "type": "yandex" },
    { "type": "bank_domestic_switch" }
  ]
  ,
  statuses: [{ "status": "created" }, { "status": "processing" }, { "status": "pending_review" }, { "status": "waiting_input" }, { "status": "complete" }, { "status": "cancelled" }, { "status": "refunded" }]
};



export default class Withdrawals extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      hasNextPage: false,
      hasPrevPage: false,
      order: 'id_desc',
      type: '',
      status: 'pending_review',
      withdrawals: []
    }
  }

  componentDidMount() {
    this.fetchWithdrawals();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pageChanged = this.state.page !== prevState.page;
    const filterChanged = this.state.type !== prevState.type;
    const statusChanged = this.state.status !== prevState.status;

    if (pageChanged || filterChanged || statusChanged) {
      this.fetchWithdrawals();
    }
  }

  nextPage() {
    if (!this.state.hasNextPage) {
      return;
    }
    this.setState({ page: this.state.page + 1 });
  }

  prevPage() {
    if (this.state.page == 0 || !this.state.hasPrevPage) {
      return;
    }
    this.setState({ page: this.state.page - 1 });
  }

  async fetchWithdrawals() {
    this.setState({ isLoading: true });
    try {
      const { records, meta, hasNextPage, hasPrevPage } = await window.api.get('/admin/payments/withdrawals', {
        params: {
          page: this.state.page,
          type: this.state.type,
          status: this.state.status
        }
      });

      this.setState({ hasNextPage, hasPrevPage, withdrawals: records });
    } catch (err) {
      toast.error(err.message)
    }
    this.setState({ isLoading: false });
  }

  async editWithdrawal(id, data) {
    try {
      this.setState({ actionLoading: true });
      await window.api.put(`/admin/payments/withdrawals/${id}`, data);
      await this.fetchWithdrawals();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  render() {
    // const mapTierValues = {
    //   tier1: {
    //     min: 0,
    //     max: 499.99,
    //   },
    //   tier2: {
    //     min: 500,
    //     max: 2499.99,
    //   },
    //   tier3: {
    //     min: 2500,
    //     max: 9999.99,
    //   },
    //   tier4: {
    //     min: 10000,
    //     max: Number.MAX_VALUE,
    //   },
    // };

    // const typeTier = this.props.match.path.split('/').pop();
    // let withdrawalsFilterByTier = [...this.state.withdrawals];

    // if (['tier1', 'tier2', 'tier3', 'tier4'].includes(typeTier)) {
    //   withdrawalsFilterByTier = this.state.withdrawals.filter(({ amount }) => {
    //     const { min, max } = mapTierValues[typeTier];
    //     return Math.abs(amount) >= min && Math.abs(amount) <= max;
    //   });
    // }

    const loading = false;

    return (
      <div className="withdrawals" style={{ position: 'relative', 'min-height': '500px' }}>

        {this.state.isLoading &&
          <div className={"loading-overlay"}>
            <h2>Searching, please wait ...</h2>
            <div className="loader" />
          </div>
        }

        <div className="row">
          <div className="col-md-6">
            <h4>Type</h4>
            <Select
              name="type"
              value={this.state.type}
              onChange={option => {
                this.setState({ type: option.value });
              }}
              options={
                [{ value: '', label: 'Any' }].concat(
                  schema.types.map(
                    val => {
                      return { value: val.type, label: val.type }
                    }
                  )
                )}
            />
          </div>
          <div className="col-md-6">
            <h4>Status</h4>
            <Select
              name="type"
              value={this.state.status}
              onChange={option => {
                this.setState({ status: option.value });
              }}
              options={schema.statuses.map(
                val => {
                  return { value: val.status, label: val.status }
                }
              )}
            />
          </div>
        </div>

        <br />
        <br />
        <br />


        <div className="row"  >
          <div className="col-xs-6" >
            <button
              className="small"
              disabled={loading || !this.state.hasPrevPage}
              onClick={() =>
                this.prevPage()
              }>
              {
                loading ?
                  'Loading...'
                  :
                  <ArrowCircleLeftIcon />
              }
              <br />
              Previous page
            </button>
          </div>

          <div className="col-xs-6  end-xs" >
            <button
              className='small'
              disabled={loading || !this.state.hasNextPage}
              onClick={() =>
                this.nextPage()
              }>
              {
                loading ?
                  'Loading...'
                  :
                  <ArrowCircleRightIcon />
              }
              <br />
              Next page
            </button>
          </div>

          <Table style={{ 'margin-top': '10px' }}>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>ID</Th>
                <Th>User</Th>
                <Th>Type</Th>
                <Th>Amount</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
                <Th>Note</Th>
              </Tr>
            </Thead>

            <Tbody>
              {
                this.state.withdrawals.map((r, i) => {

                  return (
                    <Tr key={i}>
                      <Td>{format.withMinutes(r.created_at)}</Td>

                      <Td>
                        <Link to={`/payments/withdrawals/${r.id}`}>
                          {r.id}
                        </Link>
                      </Td>
                      <Td>
                        <Link to={`/users/${r.user.id}`}>
                          {r.user.username}
                        </Link>
                      </Td>
                      <Td>
                        <Link to={`/payments/withdrawals/${r.id}`}>
                          {r.type}
                        </Link>
                      </Td>
                      <Td>{Math.abs(r.amount)} {r.currency_type}</Td>
                      <Td>{r.status}</Td>
                      <Td>
                        {
                          ['pending_review'].includes(r.status) &&
                          <div>
                            {
                              r.status === 'pending_review' &&
                              <button
                                className="small approve-button"
                                disabled={loading}
                                onClick={
                                  () => this.editWithdrawal(
                                    r.id,
                                    {
                                      status: 'processing',
                                      type: r.type
                                    }
                                  ).then(() => this.fetchWithdrawals())
                                }>
                                {
                                  loading ?
                                    'Loading...'
                                    :
                                    'Approve'
                                }
                              </button>
                            }

                            {
                              ['pending_review', 'created'].includes(r.status) &&
                              <button
                                className="small"
                                disabled={loading}
                                onClick={() =>
                                  this.editWithdrawal(
                                    r.id,
                                    {
                                      status: 'refunded'
                                    }
                                  ).then(() => this.fetchWithdrawals())
                                }>
                                {
                                  loading ?
                                    'Loading...'
                                    :
                                    'Refund'
                                }
                              </button>
                            }
                          </div>
                        }
                      </Td>
                      <Td>{r.note}</Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
          <div className="col-xs-12 text-center">
            Current page: {this.state.page}
          </div>


        </div>

      </div>
    )
  }
}