import React, { Component } from 'react';
import Pagination from '../../shared/pagination';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './style.css'
import { toast } from "react-toastify";
import { format } from '../../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
export default class StuckFinder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      total_pages: 1,
      order: 'id_desc',
      type: '',
      status: '',
      withdrawals: [],
      selected: {}
    }
  }

  componentDidMount() {
    this.fetchWithdrawals();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pageChanged = this.state.page !== prevState.page;

    if (pageChanged) {
      this.fetchWithdrawals();
    }
  }

  async fetchWithdrawals() {
    if (this.state.withdrawals.length === 0) {
      this.setState({ isLoading: true });
    }
    try {
      const { records, meta } = await window.api.get('/admin/payments/withdrawals/stuck', {
        params: {
          page: this.state.page
        }
      });

      this.setState({ total_pages: meta.total_pages, withdrawals: records });
    } catch (err) {
      toast.error(err.message)
    }
    this.setState({ isLoading: false });
  }

  async editWithdrawal(id, data, password) {
    try {
      this.setState({ actionLoading: true });
      await window.api({
        method: 'put',
        url: `/admin/payments/withdrawals/${id}`,
        data: data,
        headers: {
          'x-admin-password': password
        }
      });
      await this.fetchWithdrawals();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  async retryWithdrawal(id) {
    try {
      this.setState({ actionLoading: true });
      await window.api({
        method: 'put',
        url: `/admin/payments/withdrawals/retry/withdrawal/${id}`
      });
      await this.fetchWithdrawals();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  async refundAll() {
    try {
      let selectedIds = Object.keys(this.state.selected).filter(id => this.state.selected[id])

      if (selectedIds.length <= 0) {
        return;
      }

      const password = '';/*window.password_prompt();
      if (password === null) return;*/



      this.setState({ actionLoading: true });
      const res = await window.api({
        method: 'post',
        url: `/admin/payments/withdrawals/refund_all`,
        data: {
          ids: selectedIds
        },
        headers: {
          'x-admin-password': password
        }
      });


      console.log("PIX_WITHDRAWALS_BULK_REFUND_REPORT", res);

      toast.info(`Request completed with ${res.refunded.length} refunded and ${res.failed.length} failed. Check console log for more info.`)

      await this.fetchWithdrawals();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  render() {

    const loading = false;

    return (
      <div className="withdrawals" style={{ position: 'relative', 'min-height': '500px' }}>

        {this.state.isLoading &&
          <div className={"loading-overlay"}>
            <h2>Searching, please wait ...</h2>
            <div className="loader" />
          </div>
        }

        <div className={"pix-controller"}>
          <button onClick={() => {
            let selected = {};
            this.state.withdrawals.forEach(r => {
              selected[r.id] = true;
            });

            this.setState({
              selected
            })
          }}>Select All</button>

          <button onClick={() => {
            let selected = {};
            this.state.withdrawals.forEach(r => {
              selected[r.id] = false;
            });

            this.setState({
              selected
            })
          }}>Deselect All</button>

          <button onClick={() => {
            this.refundAll()
          }}>Refund Selected</button>
        </div>


        <br />
        <br />
        <br />

        <Table>
          <Thead>
            <Tr>
              <Th>Select</Th>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>User</Th>
              <Th>Type</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
              <Th>Note</Th>
              <Th>Copy ID</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              this.state.withdrawals.map((r, i) => {

                return (
                  <Tr key={i}>
                    <Td><input type={'checkbox'} checked={this.state.selected[r.id]} onClick={e => {
                      this.setState({
                        selected: {
                          ...this.state.selected,
                          [r.id]: e.target.checked
                        }
                      })
                    }} /></Td>
                    <Td>{format.withMinutes(r.created_at)}</Td>

                    <Td>
                      <Link to={`/payments/withdrawals/${r.id}`}>
                        {r.id}
                      </Link>
                    </Td>
                    <Td>
                      <Link to={`/users/${r.user.id}`}>
                        {r.user.username}
                      </Link>
                    </Td>
                    <Td>
                      <Link to={`/payments/withdrawals/${r.id}`}>
                        {r.type}
                      </Link>
                    </Td>
                    <Td>{Math.abs(r.amount)} {r.currency_type}</Td>
                    <Td>{r.status}</Td>
                    <Td>
                      <div>
                        <button
                          className="small"
                          disabled={loading}
                          onClick={() =>
                            this.editWithdrawal(
                              r.id,
                              {
                                status: 'refunded'
                              }
                            ).then(() => this.fetchWithdrawals())
                          }>
                          {
                            loading ?
                              'Loading...'
                              :
                              'Refund'
                          }
                        </button>
                        <button
                          className="small"
                          disabled={loading}
                          onClick={
                            () => {
                              this.retryWithdrawal(
                                r.id
                              ).then(() => this.fetchWithdrawals())
                            }
                          }>
                          {
                            loading ?
                              'Loading...'
                              :
                              'Retry'
                          }
                        </button>
                      </div>
                    </Td>
                    <Td>{r.note}</Td>
                    <Td>{`Blaze Withdrawal ${r.id}`}</Td>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>


        <Pagination
          total_pages={this.state.total_pages}
          update={page => {
            this.setState({ page });
          }}
        />

      </div>
    )
  }
}
