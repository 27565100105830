import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getReferralsByAffiliateId } from './api';
import { format } from '../shared/utils/dates';

import './affiliates-page.css';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

function Referrals(props) {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchReferrals = async () => {
      setLoading(true);

      const id = props.match?.params?.id;
      if (!id) throw new Error('No id provided');

      try {
        await new Promise((r) => setTimeout(r, 2000));
        const data = await getReferralsByAffiliateId(id);
        setReferrals(data.referrals);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReferrals();
  }, []);

  return (
    <div className="affiliate-page">
      <Table>
        <Thead>
          <Tr>
            <Th>Referred ID</Th>
            <Th>Campaign ID</Th>
            <Th>Country</Th>
            <Th>Registration At</Th>
            <Th>Deposit</Th>
            <Th>Total Deposited</Th>
            <Th>Currency</Th>
            <Th>Last Deposit At</Th>
            <Th>Commission Paid</Th>
            <Th>Campaign Code</Th>
            <Th>Commission Type</Th>
          </Tr>
        </Thead>

        {loading && (
          <div className={'loading-overlay'}>
            <h2>Fetching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <Tbody className="referrals-body">
          {referrals?.map((item) => {
            return (
              <Tr key={item.referred_blaze_user_id}>
                <Td>{item.referred_blaze_user_id}</Td>
                <Td>{item.campaign_id}</Td>
                <Td>{item.referred_country}</Td>
                <Td>{format.onlyDate(item.registration_date)}</Td>
                <Td>{item.deposit_count}</Td>
                <Td>{item.total_deposited}</Td>
                <Td>{item.total_deposited_currency}</Td>
                <Td>{item.last_deposit_date}</Td>
                <Td>{item.commission_for_cpa_paid ? 'Yes' : 'No'}</Td>
                <Td>{item.campaign_code}</Td>
                <Td>{item.commission_rate_type}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
}

export default Referrals;
