import React, { Component } from "react";
import { connect } from "react-redux";
import { notifyKYCVerification, resendEmailConfirmation } from "./actions";
import Select from "react-select";

import {
  KYC_NOTICE_TYPES,
  KYC_INVALID_REASONS,
  KYC_ACCEPTED,
} from "./constants";
import styles from "./DocumentNotices.module.scss";

class DocumentNotices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "created",
      reasons: {},
    };
  }

  render() {
    const { user_id } = this.props;
    const { tab } = this.state;

    return (
      <div id={"kyc-emails"}>
        <h3>Kyc Emails</h3>

        <div className={styles.tabbedNav}>
          <div
            className={tab === "created" ? styles.selected : undefined}
            onClick={() => {
              this.setState({ tab: "created" });
            }}
          >
            Created
          </div>
          <div
            className={tab === "approved" ? styles.selected : undefined}
            onClick={() => {
              this.setState({ tab: "approved" });
            }}
          >
            Approved
          </div>
          <div
            className={tab === "declined" ? styles.selected : undefined}
            onClick={() => {
              this.setState({ tab: "declined" });
            }}
          >
            Declined
          </div>
          <div
            className={tab === "email_confirmation" ? styles.selected : ''}
            onClick={() => {
              this.setState({ tab: "email_confirmation" });
            }}
          >
            Email Confirmation
          </div>
        </div>

        {tab === "created" && (
          <div className={styles.kycCreatedEmail}>
            <div className={styles.inputs}>
              <div
                onClick={() => {
                  this.setState({
                    id_email: !this.state.id_email,
                  });
                }}
              >
                <label>Id:</label>
                <input checked={this.state.id_email} type={"checkbox"} />
              </div>

              <div
                onClick={() => {
                  this.setState({
                    address_email: !this.state.address_email,
                  });
                }}
              >
                <label>Address:</label>
                <input checked={this.state.address_email} type={"checkbox"} />
              </div>

              <div
                onClick={() => {
                  this.setState({
                    card_email: !this.state.card_email,
                  });
                }}
              >
                <label>Credit card:</label>
                <input checked={this.state.card_email} type={"checkbox"} />
              </div>

              <div
                onClick={() => {
                  this.setState({
                    bank_email: !this.state.bank_email,
                  });
                }}
              >
                <label>Bank IBAN:</label>
                <input checked={this.state.bank_email} type={"checkbox"} />
              </div>
              <div
                onClick={() => {
                  this.setState({
                    proof_of_deposit: !this.state.proof_of_deposit,
                  });
                }}
              >
                <label>Proof of deposit</label>
                <input
                  checked={this.state.proof_of_deposit}
                  type={"checkbox"}
                />
              </div>
              <div
                onClick={() => {
                  this.setState({
                    source_of_funds: !this.state.source_of_funds,
                  });
                }}
              >
                <label>Source of Funds</label>
                <input checked={this.state.source_of_funds} type={"checkbox"} />
              </div>
            </div>

            <button
              onClick={() => {
                let res = window.confirm(
                  `Are you sure you want to email this user?`
                );
                if (res) {
                  let props = {
                    status: "created",
                    id_type: this.state.id_email,
                    address_type: this.state.address_email,
                    card_type: this.state.card_email,
                    iban_type: this.state.bank_email,
                    proof_of_deposit: this.state.proof_of_deposit,
                    source_of_funds: this.state.source_of_funds,
                  };

                  Object.keys(props).forEach((prop) => {
                    if (!props[prop] || !KYC_ACCEPTED.includes(prop)) {
                      delete props[prop];
                    }
                  });
                  this.props.notifyKYCVerification(user_id, props);
                }
              }}
            >
              Send created email
            </button>
          </div>
        )}

        {tab === "approved" && (
          <div className={styles.kycCreatedEmail}>
            <div className={styles.inputs}>
              <div
                onClick={() => {
                  this.setState({
                    approved_id_email: !this.state.approved_id_email,
                  });
                }}
              >
                <label>Id:</label>
                <input
                  checked={this.state.approved_id_email}
                  type={"checkbox"}
                />
              </div>

              <div
                onClick={() => {
                  this.setState({
                    approved_address_email: !this.state.approved_address_email,
                  });
                }}
              >
                <label>Address:</label>
                <input
                  checked={this.state.approved_address_email}
                  type={"checkbox"}
                />
              </div>

              <div
                onClick={() => {
                  this.setState({
                    approved_card_email: !this.state.approved_card_email,
                  });
                }}
              >
                <label>Credit card:</label>
                <input
                  checked={this.state.approved_card_email}
                  type={"checkbox"}
                />
              </div>

              <div
                onClick={() => {
                  this.setState({
                    approved_bank_email: !this.state.approved_bank_email,
                  });
                }}
              >
                <label>Bank IBAN:</label>
                <input
                  checked={this.state.approved_bank_email}
                  type={"checkbox"}
                />
              </div>
              <div
                onClick={() => {
                  this.setState({
                    approved_proof_of_deposit:
                      !this.state.approved_proof_of_deposit,
                  });
                }}
              >
                <label>Proof of deposit</label>
                <input
                  checked={this.state.approved_proof_of_deposit}
                  type={"checkbox"}
                />
              </div>
              <div
                onClick={() => {
                  this.setState({
                    approved_source_of_funds:
                      !this.state.approved_source_of_funds,
                  });
                }}
              >
                <label>Source of Funds</label>
                <input
                  checked={this.state.approved_source_of_funds}
                  type={"checkbox"}
                />
              </div>
            </div>

            <button
              onClick={() => {
                let res = window.confirm(
                  `Are you sure you want to email this user?`
                );
                if (res) {
                  let props = {
                    status: "approved",
                    id_type: this.state.approved_id_email,
                    address_type: this.state.approved_address_email,
                    card_type: this.state.approved_card_email,
                    iban_type: this.state.approved_bank_email,
                    proof_of_deposit: this.state.approved_proof_of_deposit,
                    source_of_funds: this.state.approved_source_of_funds,
                  };

                  Object.keys(props).forEach((prop) => {
                    if (!props[prop] || !KYC_ACCEPTED.includes(prop)) {
                      delete props[prop];
                    }
                  });

                  this.props.notifyKYCVerification(user_id, props);
                }
              }}
            >
              Send approved email
            </button>
          </div>
        )}

        {tab === "declined" && (
          <div className={"kyc-status-notice"}>
            <div>
              <label>Type:</label>
              <Select
                name="type"
                value={this.state.type}
                onChange={(option) =>
                  this.setState({ type: option, reasons: {} })
                }
                options={KYC_NOTICE_TYPES.map((m) => {
                  return {
                    value: m,
                    label: m,
                  };
                })}
              />
            </div>

            {this.state.type && (
              <div>
                <label>Reasons</label>
                <div style={{ display: "flex" }}>
                  {KYC_INVALID_REASONS[this.state.type.value].map((r) => {
                    let label = r.split("_").join(" ");
                    return (
                      <div
                        style={{ marginRight: 15 }}
                        onClick={() => {
                          this.setState({
                            reasons: {
                              ...(this.state.reasons || {}),
                              [r]: !this.state.reasons[r],
                            },
                          });
                        }}
                      >
                        <label style={{ marginRight: 10 }}>{label}:</label>
                        <input
                          checked={this.state.reasons[r]}
                          type={"checkbox"}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <button
              disabled={
                Object.keys(this.state.reasons).filter(
                  (r) => this.state.reasons[r]
                ).length <= 0 || !this.state.type
              }
              onClick={() => {
                let res = window.confirm(
                  `Are you sure you want to email this user?`
                );
                if (res) {
                  let props = {
                    status: "declined",
                    reasons: this.state.reasons,
                    type: this.state.type.value,
                  };

                  Object.keys(props.reasons).forEach((reason) => {
                    if (
                      !props.reasons[reason] ||
                      !KYC_INVALID_REASONS[props.type].includes(reason)
                    ) {
                      delete props.reasons[reason];
                    }
                  });

                  this.props.notifyKYCVerification(user_id, props);
                }
              }}
            >
              Send declined email
            </button>
          </div>
        )}

        {tab === "email_confirmation" && (
          <div className={"kyc-status-notice"}>

            <button
              onClick={() => {
                let res = window.confirm(
                  `Are you sure you want to email this user?`
                );
                if (res) this.props.resendEmailConfirmation(user_id);
                
                }}
            >
              Send email
            </button>
          </div>
        )}

        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
  notifyKYCVerification: (user_id, props) => {
    dispatch(notifyKYCVerification(user_id, props));
  },
  resendEmailConfirmation: (user_id) => {
    resendEmailConfirmation(user_id);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentNotices);
