import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./app/login";
import configureStore from "./app/store";
import { ReactQueryDevtools } from "react-query/devtools";

import "./app/api";
import { clientConfig } from "./app/config";
import { QueryClientProvider } from "react-query";
import { QueryClient } from "react-query";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#64687c",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#1d1e25",
      paper: "#1d1e25",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

console._log = console.log;
if (clientConfig.environment === "production") {
  console.log = () => {};
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
