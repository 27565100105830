import { createStore, applyMiddleware,compose } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from 'redux-thunk';
import reducers from './reducers';

const logger = createLogger({
  collapsed: true
});

export default configureStore => {

  const middleware = [
    thunk
  ];

  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }
  const enhancers = [
    process.env.NODE_ENV=== "development"
      ? composeWithDevTools(applyMiddleware(...middleware))
      : applyMiddleware(...middleware),
  ];

  return createStore(
    reducers,
    compose(...enhancers)
  );
}