import React from "react";
import { NavLink } from "react-router-dom";

export const StatusLink = () => (
  <NavLink exact to={`/status/current`}>
    Current Status
  </NavLink>
);

export const BalanceLink = () => (
  <NavLink exact to={`/status/balance`}>
    Balance
  </NavLink>
);