import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./challenges.scss";

import styles from "./tabChallenges.module.scss";
import CreateChallenges from "./createChallenges";
import ListChallenges from "./listChallenges";
const TabChallenges = () => {
  const [tab, setTab] = useState("ListChallenges");
  return (
    <div>
      <div className={styles.tabbedNav}>
        <div
          className={tab === "ListChallenges" ? styles.selected : undefined}
          onClick={() => {
            setTab("ListChallenges");
          }}
        >
          List Challenges
        </div>
        <div
          className={tab === "createChallenge" ? styles.selected : undefined}
          onClick={() => {
            setTab("createChallenge");
          }}
        >
          Create Challenge
        </div>
      </div>
      {tab === "ListChallenges" && <ListChallenges />}

      {tab === "createChallenge" && <CreateChallenges />}
    </div>
  );
};

export default withRouter(TabChallenges);
