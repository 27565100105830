import { useEffect } from "react";
import { Link } from "react-router-dom";

const Logout = () => {
  useEffect(() => {
    window.api.clearAuth();
  }, []);
  return (
    <div>
      <h1>You are logged out</h1>
      <Link to="/">Go to login</Link>
    </div>
  );
};

export default Logout;
