import React, { useEffect, useState } from "react";
import Pagination from "../shared/pagination";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import bettingApi from "../app/http-betting-api";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const CRASH_II_ROOM_ID = 1;

const UserCrashRoomBets = (props) => {
  const [data, setData] = useState({ bets: [], total_pages: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetch(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetch = async (currentPage) => {
    setIsLoading(true);
    try {
      const { total_pages, records } = await bettingApi.get(
        `/admin/user/${props.match.params.id}/crash_bets?page=${currentPage}&room_id=${CRASH_II_ROOM_ID}`
      );
      setData({ total_pages, bets: records });
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  };

  return (
    <div style={{ position: "relative", "min-height": "500px" }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait...</h2>
          <div className="loader" />
        </div>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>Round ID</Th>
            <Th>Game Started At</Th>
            <Th>Game Settled At</Th>
            <Th>Game Crash Point</Th>
            <Th>Bet Placed At</Th>
            <Th>Bet Settled At</Th>
            <Th>Amount</Th>
            <Th>Autocashout At</Th>
            <Th>Cashed Out At</Th>
            <Th>Free</Th>
            <Th>Is bonus round</Th>
            <Th>Winnings</Th>
            <Th>Status</Th>
            <Th>Profit</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.bets.map((r, i) => {
            return (
              <Tr key={i}>
                <Td>
                  {r.free_bet ? (
                    <span>{r.round_id}</span>
                  ) : (
                    <Link
                      to={`/casino/game-provider-rounds/${r.remote_round_id}`}
                    >
                      {r.remote_round_id}
                    </Link>
                  )}
                </Td>
                <Td>{format.withSeconds(r.game_started_at)}</Td>
                <Td>{format.withSeconds(r.game_settled_at)}</Td>
                <Td>{r.game_crash_point}</Td>
                <Td>{format.withSeconds(r.bet_placed_at)}</Td>
                <Td>{format.withSeconds(r.bet_settled_at)}</Td>
                <Td>
                  {r.amount} {r.currency_type}
                </Td>
                <Td>{r.auto_cashout_at}x</Td>
                <Td>{r.cashed_out_at}x</Td>
                <Td>{r.free_bet ? "yes" : "no"}</Td>
                <Td>{r.is_bonus_round ? "yes" : "no"}</Td>
                <Td className={r.win_amount > 0 ? "good" : "bad"}>
                  {r.win_amount} {r.currency_type}
                </Td>
                <Td>{r.status}</Td>
                <Td className={r.profit > 0 ? "good" : "bad"}>
                  {r.profit} {r.currency_type}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Pagination
        total_pages={data.total_pages}
        update={(page) => setPage(page)}
      />
    </div>
  );
};

export default UserCrashRoomBets;
