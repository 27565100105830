import React from 'react';
import TooltipWithInfoIcon from './TooltipWithInfoIcon';

const TooltipRoundIDExplanation = ({ children }) => {
  return (
    <TooltipWithInfoIcon title="The unique identifier of the round bet. Could vary depending of the case. If is free round, then will display Gaming Services Round ID, if not then will display the Server Round ID.">
        {children}
    </TooltipWithInfoIcon>
  );
};

export default TooltipRoundIDExplanation;
