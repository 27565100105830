

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateWheels, createWheel, deleteWheel, updateWheel, updateBox } from "./reward-actions";
import Pagination from '../shared/pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Table, Td, Th, Thead, Tr } from 'react-super-responsive-table';

class MysteryBox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      page: 1
    }
  }

  componentDidMount() {
    this.props.update(this.props.match.params.id);
  }

  render() {

    console.log(this.props);

    return (
      <div>
        {
          Object.keys(this.props.mystery_box).map(key => {
            const value = this.props.mystery_box[key];
            if (typeof value === 'object') return null;
            return (
              <div>
                <span>{key}: </span>
                <span>{value}</span>
              </div>
            );
          })
        }
        <hr />
        <h2>Win Metrics</h2>
        <pre>
          {JSON.stringify(this.props.mystery_box.win_metrics)}
        </pre>
        <hr />
        <h3>Items</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Image</Th>
              <Th>€ Value</Th>
              <Th>Chance</Th>
              <Th>Min Roll (1-1m)</Th>
              <Th>Max Roll (1-1m)</Th>
            </Tr>
          </Thead>

          <tbody>
            {
              this.props.mystery_box.rewards.map((r, i) => {
                return (
                  <Tr key={i}>
                    <Td>
                      <Link to={`/rewards/${r.id}`}>
                        {r.id}
                      </Link>
                    </Td>
                    <Td>
                      <Link to={`/rewards/${r.id}`}>
                        {r.name}
                      </Link>
                    </Td>
                    <Td>
                      {r.type}
                    </Td>
                    <Td>
                      <img src={r.image_url} alt="" />
                    </Td>
                    <Td>
                      € {r.eur_value}
                    </Td>
                    <Td>
                      {(+r.manifest.chance * 100).toFixed(6)}
                    </Td>
                    <Td>
                      {r.manifest.minimum_roll}
                    </Td>
                    <Td>
                      {r.manifest.maximum_roll}
                    </Td>
                  </Tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mystery_box: state.rewards.mystery_box
});

const mapDispatchToProps = dispatch => ({
  update: id => dispatch(updateBox(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(MysteryBox);