

import React, { useEffect, useState } from 'react';
import Pagination from '../shared/pagination';
import { permissionsConstants, PermissionsFragment } from "../app/permissions-fragment";
import { toast } from "react-toastify";
import { format } from '../shared/utils/dates';
import moment from 'moment';
import { downloadFile } from '../shared/utils/files';
import ExportDataWithDateRangeModal from '../shared/export-data-with-date-range-modal';
import ExportDataButton from '../shared/export-data-button';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

const UserDeposits = (props) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openExportDataModal, setOpenExportDataModal] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);

  const fetchDeposits = () => {
    setIsLoading(true);
    window.api.get(`/admin/users/${props.match.params.id}/deposits`, {
      params: {
        page,
      }
    }).then(
      ({ meta, records }) => {
        setDeposits(records);
        setTotalPages(meta.total_pages);
      }
    ).finally(() => setIsLoading(false));
  };

  const downloadReport = (exportTo, startDate, endDate) => {
    setDownloadingReport(true);
    require('axios')(
      {
        url: `/api/admin/users/${props.match.params.id}/deposits/export/${exportTo}`,
        method: 'GET',
        responseType: 'blob', // important,
        params: {
          page,
          startDate,
          endDate,
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'X-RateLimit-Bypass': '1'
        }
      })
      .then((response) => {
        const extension = exportTo === 'xls' ? 'xlsx' : exportTo;
        downloadFile(response.data, extension);
      })
      .finally(() => setDownloadingReport(false));
  }

  useEffect(fetchDeposits, [page]);

  const creditDeposit = (id) => {
    window.api.put(`/admin/payments/deposits/${id}/credit`).then(
      () => {
        toast.success(`Deposit credited`);
        fetchDeposits();
      }
    );
  };

  const requestCreditDeposit = (id) => {

    const staff_note = window.prompt('Please enter a note for this credit.');

    window.api.put(`/admin/payments/deposits/${id}/request_credit`, { staff_note }).then(
      () => {
        toast.success(`Deposit Credit Requested`);
        fetchDeposits();
      }
    );
  };

  return (
    <div style={{ position: 'relative', 'min-height': '500px' }}>

      {isLoading &&
        <div className={"loading-overlay"}>
          <h2>Searching, please wait ...</h2>
          <div className="loader" />
        </div>
      }

      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>ID</Th>
            <Th>Type</Th>
            <Th>Amount</Th>
            <Th>Status</Th>
            <Th>Action</Th>
            <Th>Transaction ID</Th>
            <Th>Note</Th>
          </Tr>
        </Thead>

        <Tbody>
          {
            deposits.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                  <Td>{r.id}</Td>
                  <Td>{r.type}</Td>
                  <Td className="good">{r.currency_type} {r.amount}</Td>
                  <Td className={r.status === 'complete' ? 'good' : ''}>{r.status}</Td>
                  <Td>
                    {r.status !== 'complete' && (
                      <PermissionsFragment feature={permissionsConstants.DEPOSITS_CREDIT_DEPOSIT}>
                        <button className="small" onClick={() => {
                          creditDeposit(r.id);
                        }}>Credit</button>
                      </PermissionsFragment>
                    )}

                    {r.status !== 'complete' && (
                      <PermissionsFragment feature={permissionsConstants.REQUEST_DEPOSITS_CREDIT_DEPOSIT}>
                        <button className="small" onClick={() => {
                          requestCreditDeposit(r.id);
                        }}>Request Credit</button>
                      </PermissionsFragment>
                    )}
                  </Td>
                  <Td>{r.transaction_id}</Td>
                  <Td>{r.note}</Td>
                </Tr>
              )
            })
          }
        </Tbody>
      </Table>
      <Pagination
        total_pages={totalPages}
        update={page => {
          setPage(page);
        }}
      />
      <ExportDataButton onClick={() => setOpenExportDataModal(true)} />
      <ExportDataWithDateRangeModal
        isOpen={openExportDataModal}
        onClose={() => setOpenExportDataModal(false)}
        downloadingReport={downloadingReport}
        onExport={(params) => {
          downloadReport(params.format, params.startDate, params.endDate);
        }}
      ></ExportDataWithDateRangeModal>
    </div>
  )
}

export default UserDeposits;