

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateFingerprints } from "./actions";
import Pagination from '../shared/pagination';
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
class UserFingerprints extends Component {

  constructor(props) {
    super();

    this.state = {
      page: 1
    }
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.updateFingerprints(this.props.match.params.id, this.state.page)
  }

  render() {
    return (
      <div>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Country</Th>
              <Th>IP</Th>
              <Th>IPIntel Score</Th>
              <Th>Mobile IP</Th>
              <Th>User Agent</Th>
              <Th>Hash</Th>
              <Th>Client Version</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              this.props.records.map((r, i) => {
                return (
                  <Tr key={i}>
                    <Td>{format.withMinutes(r.created_at)}</Td>
                    <Td>{r.country}</Td>
                    <Td>{r.ip}</Td>
                    <Td>{r.ipintel_score}</Td>
                    <Td>{r.mobile ? 'true' : 'false'}</Td>
                    <Td>{r.user_agent}</Td>
                    <Td>{r.hash}</Td>
                    <Td>{r.client_version}</Td>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>


        <Pagination
          total_pages={this.props.meta.total_pages}
          update={page => {
            this.setState({ page }, () => this.update());
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.users.fingerprints
});

const mapDispatchToProps = dispatch => ({
  updateFingerprints: (user_id, page) => dispatch(updateFingerprints(user_id, page))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFingerprints);