import React, { Component, Fragment } from 'react';
import Select from "react-select";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';


class UserPaymentBlacklists extends Component {

  constructor(props) {
    super(props);

    this.state = {
      records: [],
      methods: [],
      method: null,
      type: 'blacklist'
    }
  }

  componentDidMount() {

    this.update()
  }


  async update() {
    return window.api.get(`/admin/users/${this.props.match.params.id}/payment_method_blacklists`).then(data => {
      this.setState(data)
    });
  }


  async create() {
    await window.api.post(`/admin/payments/user-blacklists`, {
      user_id: this.props.match.params.id,
      payment_method_id: this.state.method,
      type: this.state.type,
    })

    this.update();

  }

  async disable(method_id) {
    await window.api.put(`/admin/users/${this.props.match.params.id}/payment_method_blacklists`, {
      payment_method_id: method_id,
    })

    this.update();

  }

  render() {


    return (
      <Fragment>
        <h4>Payment Method Settings</h4>

        <hr />

        <div style={{ display: "flex" }}>

          <Select
            placeholder={"Payment Method"}
            style={{ width: 400, marginRight: 30 }}
            name="type"
            value={this.state.method}
            onChange={option => {
              this.setState(
                { method: option && option.value }
              )
            }}
            options={
              this.state.methods.map(r => ({
                label: r.type,
                value: r.id
              }))
            }
          />

          <Select
            placeholder={""}
            name="type"
            style={{ width: 300 }}
            value={this.state.type}
            onChange={option => {
              this.setState(
                { type: option && option.value }
              )
            }}
            options={
              ['blacklist', 'whitelist'].map(r => ({
                label: r,
                value: r
              }))
            }
          />
        </div>


        <button disabled={!this.state.method} onClick={() => {
          this.create();
        }}>Create</button>

        <hr />

        <Table>
          <Thead>
            <Tr>
              <Th>MeThod</Th>
              <Th>Type</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.records.map(record => {

              return (
                <Tr>
                  <Td>{record.type}</Td>
                  <Td>{record.status}</Td>
                  <Td>
                    <button onClick={() => {
                      this.disable(record.payment_method_id);
                    }}>Disable</button>
                  </Td>
                </Tr>
              )

            })}
          </Tbody>
        </Table>

      </Fragment>
    )
  }
}

export default UserPaymentBlacklists;
