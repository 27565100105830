import React from "react";
import { NavLink } from "react-router-dom";

export const FindUsersLink = () => (
  <NavLink exact to={`/users/find`}>
    Find users
  </NavLink>
);
export const CreateUsersLink = () => (
  <NavLink exact to={`/users/create`}>
    Create users
  </NavLink>
);
