import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import api from "./api";
import "./racesList.scss";

const ListRacesIterations = (props) => {
  const id = props.match.params.id;

  const [listRacesIterations, setListRacesIterations] = useState();
  const getData = useCallback(async () => {
    try {
      const data = await api.getRaceIterationsById(id);
      setListRacesIterations(data);
    } catch (e) { }
  }, [id]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <React.Fragment>
      <div className="box-selects"></div>
      <Table>
        <Thead>
          <Tr>
            <Th>Current Iteration</Th>
            <Th>Start Date</Th>
            <Th>End Date</Th>
            <Th>Status</Th>
            <Th>Options</Th>
          </Tr>
        </Thead>

        <Tbody className="referrals-body">
          {listRacesIterations?.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{item.iteration}</Td>
                <Td>
                  {" "}
                  {new Date(item.current_iteration_start_date).toLocaleString()}
                </Td>
                <Td>
                  {new Date(item.current_iteration_end_date).toLocaleString()}
                </Td>

                <Td>{item.status}</Td>

                <Td>
                  <div style={{ display: "flex" }}>
                    <div>
                      <button
                        onClick={() => {
                          props.history.push(
                            `/casino/race/iterations/winners/${item.id}`
                          );
                        }}
                      >
                        winners
                      </button>
                    </div>
                  </div>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </React.Fragment>
  );
};

export default withRouter(ListRacesIterations);
