import { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { toast } from "react-toastify";
import api from "./api";
import "./challengesList.scss";
import { SelectSort } from "./SelectSort";
import { SelectStatus } from "./SelectStatus";

const ListChallenges = (props) => {
  const [listChallenges, setListChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagesTotal, setPagesTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [statusChallenge, setStatusChallenge] = useState("");
  useEffect(() => {
    getListChallengesGames();
  }, [page, sort, statusChallenge]);

  useEffect(() => {
    setPage(1);
  }, [sort, statusChallenge]);

  const getListChallengesGames = async () => {
    const res = await api.getChallenges(page, statusChallenge, sort);
    const { challenges, total_pages } = res;
    setLoading(false);
    setListChallenges(challenges);
    setPagesTotal(total_pages);
  };
  const handleDelete = async (id) => {
    await api.deleteChallenge(id);
    toast.success(`The challenge ${id} was deleted succesfully`);
    await getListChallengesGames(page);
  };

  return (
    <div>
      <div className="box-selects">
        <div className="box-select">
          <h1 className="text-box-select">Filter Status</h1>
          <SelectStatus
            select={statusChallenge}
            setSelect={setStatusChallenge}
          />
        </div>
        <div className="box-select">
          <h1 className="text-box-select">Sort by</h1>
          <SelectSort select={sort} setSelect={setSort} />
        </div>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>Challenge ID</Th>
            <Th>Start Date</Th>
            <Th>Expires Date</Th>
            <Th>Multiplier</Th>
            <Th>Status</Th>
            <Th>Winning user id</Th>
            <Th>Reward Name</Th>
            <Th>Game Name</Th>
            <Th>Eur Minimum Wager</Th>
            <Th>Round ID</Th>

            <Th>Options</Th>
          </Tr>
        </Thead>

        {loading && (
          <div className={"loading-overlay"}>
            <h2>Fetching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}

        <Tbody className="referrals-body">
          {listChallenges?.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{item.id}</Td>
                <Td>{new Date(item.starts_at).toLocaleString()}</Td>
                <Td>{new Date(item.expires_at).toLocaleString()}</Td>
                <Td>{item.min_multiplier}</Td>
                <Td>{item.status}</Td>
                <Td onClick={() => {}}>
                  <Link to={`/users/${item.winning_user_id}/info`}>
                    {item.winning_user_id || "-"}
                  </Link>
                </Td>
                <Td>{item.reward_name}</Td>
                <Td>{item.game_name === null ? "any game" : item.game_name}</Td>
                <Td>{item.eur_min_wagered}</Td>
                <Td>
                  <Link to={`/casino/game-provider-rounds/${item.round_id}`}>
                    {item.round_id || "-"}
                  </Link>
                </Td>
                <Td>
                  <button
                    onClick={() => {
                      props.history.push(`/casino/challenge/view/${item.id}`);
                    }}
                  >
                    View
                  </button>
                </Td>
                <Td>
                  {(item.status === "ongoing" || item.status === "created") && (
                    <button onClick={() => handleDelete(item.id)}>
                      Cancel
                    </button>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <div className="pagination">
        <button
          className="btn-pagination"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <span>{"<"}</span>
        </button>
        <div className="pagination_page">
          Page {page} of {pagesTotal}
        </div>
        <button
          className="btn-pagination"
          onClick={() => setPage(page + 1)}
          disabled={page >= pagesTotal}
        >
          <span>{">"}</span>
        </button>
      </div>
    </div>
  );
};

export default withRouter(ListChallenges);
