import * as types from './types';

const initial = {
  data: {
    meta: {
      total_pages: 1
    },
    records: [],

    schema: {
      types: [],
      statuses: []
    }
  },

  redeem_data: {
  meta: {
    total_pages: 1
  },
  records: [],

    schema: {
    types: [],
      statuses: []
  }
},

  selected: {

  }

};

export default (state = initial, action) => {
  switch (action.type) {
    case types.PROMO_UPDATE:
      return {...state, data: action.payload};

    case types.GET_PROMO:
      return {
        ...state,
        selected: action.payload
      };

    case types.GET_REDEEMS:
      return {...state, redeem_data: action.payload};
  }

  return state;
}