import { NavLink } from "react-router-dom";
import {
  GroupPermissionsFragment,
  permissionGroups,
} from "app/permissions-fragment";

export const WithdrawalsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.WITHDRAWALS}>
    <NavLink exact to={`/payments/withdrawals`}>
      Withdrawals
    </NavLink>
  </GroupPermissionsFragment>
);
export const PixWithdrawalsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.PIX_WITHDRAWALS}>
    <NavLink exact to={`/payments/withdrawals/pix`}>
      Pix Withdrawals
    </NavLink>
  </GroupPermissionsFragment>
);
export const StuckWithdrawalsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.STUCK_WITHDRAWALS}>
    <NavLink exact to={`/payments/withdrawals/stuck`}>
      Stuck Withdrawals
    </NavLink>
  </GroupPermissionsFragment>
);

export const AutomatedWithdrawalsLink = () => (
  <GroupPermissionsFragment
    group={permissionGroups.PAYMENTS.AUTOMATED_WITHDRAWALS}
  >
    <NavLink exact to={`/payments/withdrawals/automated`}>
      Automated Withdrawals
    </NavLink>
  </GroupPermissionsFragment>
);

export const KYCVerificationsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.KYC_VERIFICATIONS}>
    <NavLink to="/payments/kyc-verifications">KYC Verifications</NavLink>
  </GroupPermissionsFragment>
);

export const DepositsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.DEPOSITS}>
    <NavLink exact to={`/payments/deposits`}>
      Deposits
    </NavLink>
  </GroupPermissionsFragment>
);
export const MethodsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.METHODS}>
    <NavLink exact to={`/payments/methods`}>
      Methods
    </NavLink>
  </GroupPermissionsFragment>
);

export const ProvidersLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.PROVIDERS}>
    <NavLink exact to={`/payments/providers`}>
      Providers
    </NavLink>
  </GroupPermissionsFragment>
);

export const CountryOrderingsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.COUNTRY_ORDERINGS}>
    <NavLink exact to={`/payments/country-orderings`}>
      Country Orderings
    </NavLink>
  </GroupPermissionsFragment>
);

export const InfluencerCardLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS.ACCOUNTS}>
    <NavLink exact to={`/payments/influencer-card`}>
      Influencer Card
    </NavLink>
  </GroupPermissionsFragment>
);
