import { connect } from "react-redux";
import {
    suspend,
} from "user/actions.js";

import Suspensions from "./Suspensions";

const mapStateToProps = (state) => ({
    ...state,
    user: { ...state.users.user },
});

const mapDispatchToProps = (dispatch) => ({
    suspend: ({ user_id, duration, reason, note }) => dispatch(suspend({ user_id, duration, reason, note, cancel: false })),
    cancelSuspend: ({ user_id }) => dispatch(suspend({ user_id, cancel: true }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Suspensions);
