import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateRewards } from "../reward-actions";
import "./challenges.scss";
import Select from "react-select";
import api from "./api";
import Currency from "./Currency";
import { toast } from "react-toastify";
import _ from "lodash";
import { PresetLoad } from "./PresetLoad";
import { ADMIN_GAMES_API } from "casino/race/constants";

const calculateDate = (date) => {
  const localTimestamp = new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: false,
    // Add a leading zero to the hour if it is single-digit
    hourCycle: "h23",
  });
  const formattedTimestamp = localTimestamp.replace(" ", "T").replace(",", "");
  return formattedTimestamp.replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
};

const Challenges = ({ rewards, updateRewards }) => {
  const [listGames, setListGames] = useState();
  const [isFeatured, setIsFeatured] = useState(false);
  const [currencyRates, setCurrencyRates] = useState([]);
  const [saveAsPreset, setSaveAsPreset] = useState(false);
  const [formData, setFormData] = useState({
    starts_at: "",
    expires_at: "",
    min_multiplier: "",
    reward_id: "",
    game_id: 0,
    eur_min_wagered: 1,
    currency_rates: [],
    name: "",
  });

  const formRef = useRef(null);
  const debouncedSubmit = useCallback(
    _.debounce(async (formData, currencyRates) => {
      try {
        if (
          formData.get("reward_id") === "" ||
          formData.get("min_multiplier") === ""
        ) {
          toast.error("All the fields needs to completed");
        } else {
          const dataToSend = {
            ...formData,
            min_multiplier: formData.get("min_multiplier"),
            reward_id: formData.get("reward_id"),
            game_id:
              formData.get("game_id") === "0" ? null : formData.get("game_id"),
            eur_min_wagered: formData.get("eur_min_wagered"),
            starts_at: new Date(formData.get("starts_at")).toISOString(),
            expires_at: new Date(formData.get("expires_at")).toISOString(),
            currency_rates: currencyRates,
            is_featured: isFeatured,
            name: formData.get("name"),
            isPreset: saveAsPreset,
          };
          if (saveAsPreset) {
            dataToSend.name = formData.get("name");
          }
          const res = await api.createChallenge(dataToSend);
          toast.success(res?.message);
          setTimeout(() => {
            window.location.href = "/casino/challenges";
          }, 1000);
        }
      } catch (error) {
        toast.error(error?.response?.data?.error?.message);
      }
    }, 500),
    [currencyRates, isFeatured, saveAsPreset]
  );

  function handleCheckboxFeatured(event) {
    setIsFeatured(event.target.checked);
  }
  const handleCreateChallenge = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    debouncedSubmit(formData, currencyRates);
  };

  const handleUpdateField = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getGames = async () => {
    const res = await window.api.get(ADMIN_GAMES_API);

    const games = [{ label: "All games", value: 0 }];
    for (let i = 0; i < res.records.length; i++) {
      const record = res.records[i];
      games.push({
        label: record.name + " - " + record.slug,
        value: record.id,
      });
    }
    setListGames(games);
  };

  const handleSaveAsPreset = (e) => {
    setSaveAsPreset(e.target.checked);
  };

  useEffect(() => {
    updateRewards();
    getGames();
  }, [updateRewards]);

  const {
    game_id,
    expires_at,
    starts_at,
    min_multiplier,
    reward_id,
    eur_min_wagered,
  } = formData;

  return (
    <div className="margin-left-form-challenge">
      <PresetLoad setFormData={setFormData} setIsFeatured={setIsFeatured} />
      <form
        ref={formRef}
        onSubmit={handleCreateChallenge}
        className="form-challenge"
      >
        <div className="row-challenge">
          <div className="column">
            <label htmlFor="preset-save"> Save as preset (Optional)</label>
            <input
              id="preset-save"
              type="checkbox"
              onChange={handleSaveAsPreset}
            />
            <div>
              <h1 className="label-challenge">Name ( This only for admin)</h1>
              <input
                type="text"
                name="name"
                value={formData.name}
                placeholder={"Name"}
                onChange={handleUpdateField}
              />
            </div>
          </div>
        </div>
        <div className="row-challenge">
          <div className="column">
            <h1 className="label-challenge ">Start Date</h1>
            <input
              value={calculateDate(starts_at)}
              name="starts_at"
              type={"datetime-local"}
              onChange={handleUpdateField}
            />
          </div>
          <div className="column">
            <h1 className="label-challenge ">Expires Date</h1>
            <input
              value={calculateDate(expires_at)}
              name="expires_at"
              type={"datetime-local"}
              onChange={handleUpdateField}
            />
          </div>
          <div className="column">
            <h1 className="label-challenge padding-left-challenge">
              Multiplier
            </h1>
            <input
              type="number"
              min="0,1"
              name="min_multiplier"
              value={min_multiplier}
              placeholder={"Multiplier"}
              onChange={handleUpdateField}
            />
          </div>
          <div className="column">
            <h1 className="label-challenge padding-left-challenge">
              Minimum Wagered (EUR)
            </h1>
            <input
              type={"number"}
              name="eur_min_wagered"
              value={eur_min_wagered}
              onChange={handleUpdateField}
            />
          </div>
        </div>

        <span className="time-challenges"></span>
        <div className="row-challenge">
          <div className="column">
            <label>Games</label>
            <Select
              name="game_id"
              value={game_id}
              onChange={(e) => {
                setFormData({ ...formData, game_id: e.value });
              }}
              style={{ width: "300px" }}
              options={listGames}
            />
          </div>
          <div className="column">
            <label>Reward</label>
            <Select
              name="reward_id"
              value={reward_id}
              onChange={(e) => {
                setFormData({ ...formData, reward_id: e.value });
              }}
              className="rewardSelect"
              options={rewards.map((reward) => ({
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      alt=""
                      style={{ width: "30px" }}
                      src={reward.image_url}
                    />
                    (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
                  </div>
                ),
                value: reward.id,
              }))}
              onInputChange={newValue => {
                updateRewards(newValue)
              }}
            />
          </div>
          <div className="column featured-column">
            <label>Is featured?</label>
            <input
              className="featured-input"
              type="checkbox"
              checked={isFeatured}
              onChange={handleCheckboxFeatured}
            />
          </div>
        </div>
        <div className="row-challenge">
          <div className="column">
            <Currency
              currencyRates={currencyRates}
              setCurrencyRates={setCurrencyRates}
              disabled={false}
            />
          </div>
        </div>
        <button type="submit" className="create-challenge-button">
          Create Challenge
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rewards: state.rewards.items.records,
});
const mapDispatchToProps = (dispatch) => ({
  updateRewards: (id) => dispatch(updateRewards(1, 1, null, id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Challenges)
);
