import {
  AdminAnalyticsLink,
  AnnouncementsLink,
  GroupsManagementLink,
  RaffleManagerLink,
  RouteMappingsLink,
  SettingsLink,
  StaffManagerLink,
  WaitListManagementLink,
  WeeklyCashbackManagerLink,
} from "system/tab-system/links.js";
import {
  RequestsLink,
  RefundRequestsLink,
} from "system/tab-support-manager/links";
import { SystemLink } from "app/panel.js";
import { ListLink, CreateLink } from "system/tab-announcements/links";

const systemNodes = {
  key: "system",
  label: "System",
  icon: "",
  component: () => <SystemLink />,
  nodes: [
    {
      key: "settings",
      label: "Settings",
      icon: "",
      component: () => <SettingsLink />,
      nodes: [],
    },
    {
      key: "weekly-cashback-manager",
      label: "Weekly Cashback Manager",
      icon: "",
      component: () => <WeeklyCashbackManagerLink />,
      nodes: [],
    },
    {
      key: "wait-list-management",
      label: "Wait List Management",
      icon: "",
      component: () => <WaitListManagementLink />,
      nodes: [],
    },
    {
      key: "groups",
      label: "Groups",
      icon: "",
      component: () => <GroupsManagementLink />,
      nodes: [],
    },
    {
      key: "raffle-manager",
      label: "Raffle Manager",
      icon: "",
      component: () => <RaffleManagerLink />,
      nodes: [],
    },
    {
      key: "announcements",
      label: "Announcements",
      icon: "",
      component: () => <AnnouncementsLink />,
      nodes: [
        {
          key: "list-announcements",
          label: "List",
          icon: "",
          component: () => <ListLink />,
          nodes: [],
        },
        {
          key: "create-announcements",
          label: "Create",
          icon: "",
          component: () => <CreateLink />,
          nodes: [],
        },
      ],
    },
    {
      key: "route-mappings",
      label: "Route Mappings",
      icon: "",
      component: () => <RouteMappingsLink />,
      nodes: [],
    },
    {
      key: "staff-manager",
      label: "Staff Manager",
      icon: "",
      component: () => <StaffManagerLink />,
      nodes: [
        {
          key: "requests",
          label: "Requests",
          icon: "",
          component: () => <RequestsLink />,

          nodes: [],
        },
        {
          key: "refund-request",
          label: "Refund Request",
          icon: "",
          component: () => <RefundRequestsLink />,
          nodes: [],
        },
      ],
    },
    {
      key: "admin-analytics",
      label: "Admin Analytics",
      icon: "",
      component: () => <AdminAnalyticsLink />,
      nodes: [],
    },
  ],
};

export default systemNodes;
