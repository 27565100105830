import { BIG_WINS_LOADED, LOADING_BIG_WINS } from './types';

export const loadBigWins = ({ page = 1, limit = 50, userId, startDate, endDate, gameId, gameSlug }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_BIG_WINS });
    window.api.get('/big_wins', {
      params: {
        page,
        limit,
        userId,
        startDate,
        endDate,
        gameId,
        gameSlug,
      }
    })
      .then(data => {
        const bigWins = data;
        dispatch({
          type: BIG_WINS_LOADED,
          payload: bigWins,
        })
      })
      .catch(() => dispatch({
        type: BIG_WINS_LOADED,
        payload: [],
      }));
  }
}
