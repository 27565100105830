import { AffiliatesLink } from "app/panel";

import {
  AffiliatesSystemLink,
  AffiliatesFindUsersLink,
} from "affiliates/tab-affiliates-root/links";

import {
  CreateCampaignLink,
  UpdateCampaignLink,
  AffiliateManagerLink,
  UpdatePostbackUrlLink,
} from "affiliates/system-tabs/links";

const affiliatesNodes = {
  key: "affiliates",
  label: "Affiliates",
  icon: "",
  component: () => <AffiliatesLink />,
  nodes: [
    {
      key: "find-users",
      label: "Find users",
      icon: "",
      component: () => <AffiliatesFindUsersLink />,
      nodes: [],
    },
    {
      key: "affiliates-system",
      label: "System",
      icon: "",
      component: () => <AffiliatesSystemLink />,
      nodes: [
        {
          key: "create-campaign",
          label: "Create Campaign",
          icon: "",
          component: () => <CreateCampaignLink />,
          nodes: [],
        },
        {
          key: "update-campaign",
          label: "Update Campaign",
          icon: "",
          component: () => <UpdateCampaignLink />,
          nodes: [],
        },
        {
          key: "affiliate-manager",
          label: "Affiliate Manager",
          icon: "",
          component: () => <AffiliateManagerLink />,
          nodes: [],
        },
        {
          key: "update-postback-url",
          label: "Update Postback URL",
          icon: "",
          component: () => <UpdatePostbackUrlLink />,
          nodes: [],
        },
      ],
    },
  ],
};

export default affiliatesNodes;
