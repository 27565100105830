export const RACE_STATS = {
    deviationAmount: 'deviation_amount_wagered'
}

export const listDuration = [
    {
        label: "hour",
        value: "hour",
    },
    {
        label: "day",
        value: "day",
    },
];

export const listRecurring = [
    {
        label: "hour",
        value: "hour",
    },
    {
        label: "day",
        value: "day",
    },
    {
        label: "week",
        value: "week",
    },
    {
        label: "month",
        value: "month",
    },
    {
        label: "year",
        value: "year",
    },
];

export const ADMIN_GAMES_API = "/admin/games?enabled=true&newlyReleased=false&paginated=false";
