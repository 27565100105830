import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {getGamingServiceSettings, saveGamingServiceSettings} from "../originals/originals-games-api";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

export const useGetSettings = () => {
  return useQuery('settings', async () => {
    const response = await getGamingServiceSettings();
    return response;
  });
};

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(
      async (updatedSettings) => {
        await saveGamingServiceSettings(updatedSettings);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('settings');
        },
        onError: (err) => {
          toast.error(err.message);
        }
      }
  );
};

const GamingServiceSettings = () => {
  const [localSettings, setLocalSettings] = useState({});
  const { data: settings, isLoading, isError } = useGetSettings();
  const updateSettingsMutation = useUpdateSettings();

  const handleInputChange = (e, key) => {
    const updatedSettings = { ...localSettings, [key]: e.target.value };
    setLocalSettings(updatedSettings);
  };

  const handleSaveSettings = async () => {
    await updateSettingsMutation.mutateAsync(localSettings);
  };

  if (isError) {
    return (
        <div>
          <p>Error fetching settings</p>
        </div>
    );
  }

  return (
      <div>
        <h3>Settings</h3>

        {isLoading ? (
            <p>Loading...</p>
        ) : (
            settings.map((setting, index) => {
              const val = localSettings[setting.key] || setting.value;
              const isSupportManager = window.roles.includes('support_manager');

              if (!isSupportManager) {
                return (
                    <div key={index}>
                      <label>{setting.key}</label>
                      <br />
                      <br />
                      <input
                          style={{ width: '100%' }}
                          value={val}
                          onChange={(e) => handleInputChange(e, setting.key)}
                      />
                      <hr />
                    </div>
                );
              }

              return null;
            })
        )}

        <button onClick={handleSaveSettings}>Save</button>
      </div>
  );
};

export default GamingServiceSettings;
