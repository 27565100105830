import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";
import ParentPath from "./ParentPath";

const LEVEL_SPACE = 0.75;

const MenuItem = (props) => {
  const {
    hasNodes = false,
    isOpen = false,
    level = 0,
    onClick,
    toggleNode,
    active,
    focused,
    openedIcon = "less",
    closedIcon = "more",
    style = {},
    parent,
    searchTerm,
    divider = undefined,
  } = props;

  return (
    <li
      className={cx(
        "rstm-tree-item",
        `rstm-tree-item-level${level}`,
        styles.item,
        { "rstm-tree-item--active": active },
        { "rstm-tree-item--focused": focused },
        { [styles[`${divider}Divider`]]: divider }
      )}
      style={{
        paddingLeft: `${level * LEVEL_SPACE}rem`,
        ...style,
      }}
      role="button"
      aria-pressed={active}
      onClick={onClick}
    >
      {searchTerm && parent && <ParentPath parent={parent} />}
      {React.createElement(props.component)}
      {hasNodes && (
        <img
          src={`/images/icons/expand_${isOpen ? openedIcon : closedIcon}.svg`}
          alt=""
          className={styles.icon}
          onClick={(e) => {
            hasNodes && toggleNode && toggleNode();
            e.stopPropagation();
          }}
        />
      )}
    </li>
  );
};

export default MenuItem;
