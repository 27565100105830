import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./style.css";

const initialState = {
  codes: []
};

const Cellxpert = () => {
  const [state, setState] = useState(initialState);
  const [codeInput, setInput] = useState("");

  const fetch = async () => {
    try {
      const records = await window.api.get(
        `/admin/cellxpert`
      );

      setState({
        codes: records,
      });
    } catch (err) {
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const create = async (code = "") => {
    try {
      const records = await window.api.post(
        `/admin/cellxpert`,
        {code}
      );

      fetch();
    } catch (err) {
    }
  }

  return (
    <div>

      <h3>Cellxpert Promo Codes</h3>

      <div>
        <input value={codeInput} onChange={ev => setInput(ev.target.value)}/>
        <button onClick={() => {
          create(codeInput)
        }} disabled={codeInput.length <= 0}>Create</button>
      </div>

      <hr/>
      <br/>

      <div>
        <ul>
          { state.codes.map(({code}) => {

            return (
              <li key={code}>{code}</li>
            )

          })}
        </ul>
      </div>
    </div>
  );
};

export default Cellxpert;
