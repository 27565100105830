import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  updateBoxes,
  updateRewards,
  createReward,
  deleteReward,
} from "../reward-actions";
import Pagination from "../../shared/pagination";

import { Link } from "react-router-dom";
import Select from "react-select";
import { updateDepositBonuses } from "../actions";
import {
  permissionsConstants,
  PermissionsFragment,
  checkPermissions,
} from "../../app/permissions-fragment";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import {
  emptyPattern,
  patternWithoutNumbersOnly,
  validPattern,
} from "../validations";

const LIST_GAMES = [
  { label: "Crash", value: "crash" },
  { label: "Crash II", value: "crash-v2" },
  { label: "Crash Rewired", value: "crash-rewired" },
  { label: "Crash Neymar", value: "neymar-crash" },
  { label: "Roulette", value: "roulette" },
  { label: "Mines", value: "mines" },
  { label: "Plinko", value: "plinko" },
  { label: "Limbo", value: "limbo" },
  { label: "Dice", value: "dice" },
  { label: "Tower", value: "tower" },
  { label: "Hilo", value: "hilo" },
  { label: "Coin flip", value: "coin-flip" },
  { label: "Slide", value: "slide" },
  { label: "Fruits", value: "fruits" },
  { label: "Fortune Double", value: "fortune_double" },
  { label: "Fruit Slice", value: "fruitSlice" },
].sort((a, b) => a.label.localeCompare(b.label));

class ViewRewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {},
      page: 1,
      all: 0,
      metadata: {},
      loading: false,
      name_translation: {},
      description_translation: {},
      mystery_manifest_description_translation: {},
      sellable: false,
    };

    this.renderBetByBonus = this.renderBetByBonus.bind(this);
    this.renderDepositBonus = this.renderDepositBonus.bind(this);
    this.renderDepositBonusForSports =
      this.renderDepositBonusForSports.bind(this);
    this.renderFreeBet = this.renderFreeBet.bind(this);
    this.renderXPBonus = this.renderXPBonus.bind(this);
    this.renderCashback = this.renderCashback.bind(this);
    this.renderSoftswissFreeSpins = this.renderSoftswissFreeSpins.bind(this);
    this.renderGameIntegrationFreeSpins =
      this.renderGameIntegrationFreeSpins.bind(this);
    this.renderRakeback = this.renderRakeback.bind(this);
    this.renderMetadataInputUi = this.renderMetadataInputUi.bind(this);
    this.renderWelcomeBonus = this.renderWelcomeBonus.bind(this);
  }
  

  componentDidMount() {
    this.update(this.state.page);
    if (checkPermissions(permissionsConstants.BONUSES_VIEW_LIST)) {
      this.props.updateBonuses();
    }

    if (checkPermissions(permissionsConstants.MYSTERY_BOXES_VIEW_LIST)) {
      this.props.updateBoxes();
    }
  }

  onSearch(e) {
    if (e.key === 'Enter') {
      this.update();
    }
  }

  update(page) {
    this.props.update(page || this.state.page, this.state.all, this.state.query.name, this.state.query.id);
  }

  updateMetadata(updates) {
    this.setState({
      metadata: {
        ...this.state.metadata,
        ...updates,
      },
    });
  }

  renderBetByBonus() {
    return (
      <Fragment>
        <h4>Bonus Type</h4>
        <Select
          name="type"
          value={this.state.metadata.bonus_type}
          required
          onChange={(option) =>
            this.updateMetadata({ bonus_type: option?.value })
          }
          options={[
            { label: "Freebet", value: "free_bet" },
            { label: "Comboboost", value: "combo_boost" },
          ]}
        />
        {this.state.metadata.bonus_type &&
          this.state.metadata.bonus_type === "free_bet" && (
            <>
              <h4>Amount</h4>
              <input
                placeholder="Enter the amount."
                required
                type="number"
                min={1}
                onChange={(e) =>
                  this.updateMetadata({
                    amount_of_free_bet: e.target.value,
                  })
                }
              />
            </>
          )}
        <h4>Betby Template ID</h4>
        <input
          placeholder="Enter Betby Template ID"
          required
          onChange={(e) =>
            this.updateMetadata({
              template_id: e.target.value,
            })
          }
        />
      </Fragment>
    );
  }

  renderDepositBonusForSports() {
    return (
      <>
        <Fragment>
          <h4>Select Deposit Bonus for Sports</h4>
          <Select
            name="type"
            value={this.state.metadata.bonus_id}
            required
            onChange={(option) =>
              this.updateMetadata({
                bonus_id: option?.value,
              })
            }
            options={this.props.bonuses
              .filter((d) => d.type === "deposit_sports")
              .map((bonus) => {
                return {
                  label: bonus.name,
                  value: bonus.id,
                };
              })}
          />
          <h4>Bonus Type</h4>
          <Select
            name="type"
            value={this.state.metadata.bonus_type}
            required
            onChange={(option) =>
              this.updateMetadata({ bonus_type: option?.value })
            }
            options={[
              { label: "Freebet", value: "free_bet" },
              { label: "Comboboost", value: "combo_boost" },
            ]}
          />
          {this.state.metadata.bonus_type &&
            this.state.metadata.bonus_type === "free_bet" && (
              <>
                <h4>Amount</h4>
                <input
                  placeholder="Enter the amount."
                  required
                  onChange={(e) =>
                    this.updateMetadata({
                      amount_of_free_bet: e.target.value,
                    })
                  }
                />
              </>
            )}
          <h4>Betby Template ID</h4>
          <input
            placeholder="Enter Betby Template ID"
            required
            onChange={(e) =>
              this.updateMetadata({
                template_id: e.target.value,
              })
            }
          />
        </Fragment>
      </>
    );
  }

  renderDepositBonus() {
    return (
      <Fragment>
        <h4>Select Deposit Bonus</h4>
        <Select
          name="type"
          value={this.state.metadata.bonus_id}
          required
          onChange={(option) =>
            this.updateMetadata({
              bonus_id: option?.value,
            })
          }
          options={this.props.bonuses
            .filter((d) => d.type !== "deposit_sports")
            .map((bonus) => {
              return {
                label: bonus.name,
                value: bonus.id,
              };
            })}
        />
      </Fragment>
    );
  }

  renderWelcomeBonus() {
    return (
      <Fragment>
        <h4>Welcome Bonus</h4>
        <Select
          name="type"
          value={this.state.metadata.bonus_id}
          required
          onChange={(option) =>
            this.updateMetadata({
              bonus_id: option?.value,
            })
          }
          options={this.props.bonuses
            .filter((d) => d.type === "welcome_bonus")
            .map((bonus) => {
              return {
                label: bonus.name,
                value: bonus.id,
              };
            })}
        />
      </Fragment>
    );
  }

  renderFreeBet() {
    return (
      <Fragment>
        <h4>Select Free Bet Type</h4>
        <Select
          name="type"
          value={this.state.metadata.free_bet_type}
          required
          onChange={(option) =>
            this.updateMetadata({ free_bet_type: option?.value })
          }
          options={LIST_GAMES}
        />
        <br />
        <h4>Enter Free Bet Rounds</h4>
        <input
          value={this.state.metadata.free_bet_rounds}
          required
          type="number"
          min={1}
          onChange={(e) =>
            this.updateMetadata({
              free_bet_rounds: e.target.value,
            })
          }
        />
      </Fragment>
    );
  }

  renderXPBonus() {
    return (
      <Fragment>
        <h4>
          Enter XP Bonus Multiplier (ex. 1.2 will give 120xp when user gains
          100xp)
        </h4>
        <input
          value={this.state.metadata.xp_bonus_percentage}
          required
          type="number"
          min={1}
          onChange={(e) =>
            this.updateMetadata({
              xp_bonus_percentage: e.target.value,
            })
          }
        />
        <br />
        <h4>Enter XP Bonus Expiry Time (minutes)</h4>
        <input
          value={this.state.metadata.expiry_minutes}
          required
          type="number"
          min={1}
          onChange={(e) =>
            this.updateMetadata({
              expiry_minutes: e.target.value,
            })
          }
        />
      </Fragment>
    );
  }

  renderCashback() {
    return (
      <Fragment>
        <h4>
          Enter Minutes before payout (ex. 5 will wait 5 minutes, and give
          cashback based on losses between start and end time)
        </h4>
        <input
          value={this.state.metadata.minutes_before_payout}
          required
          onChange={(e) =>
            this.updateMetadata({
              minutes_before_payout: e.target.value,
            })
          }
        />
        <br />
        <h4>
          Enter Cashback % (ex. 0.05 will give a user a 5% cashback at the end
          of the period)
        </h4>
        <input
          value={this.state.metadata.percentage}
          required
          onChange={(e) =>
            this.updateMetadata({
              percentage: e.target.value,
            })
          }
        />
      </Fragment>
    );
  }

  renderSoftswissFreeSpins() {
    return (
      <Fragment>
        <h4>Game Slug</h4>
        <input
          required
          onChange={(ev) => this.updateMetadata({ game_slug: ev.target.value })}
        />

        <h4>Number of spins</h4>
        <input
          required
          onChange={(ev) =>
            this.updateMetadata({ free_spins: ev.target.value })
          }
          type="number"
        />

        <h4>Amount per bet</h4>
        <input
          required
          onChange={(ev) => this.updateMetadata({ bet_level: ev.target.value })}
          type="number"
        />

        <h4>
          Lifespan (how long the free spins are valid for after claim in
          minutes)
        </h4>
        <input
          required
          onChange={(ev) => this.updateMetadata({ lifespan: ev.target.value })}
          type="number"
        />
      </Fragment>
    );
  }

  renderGameIntegrationFreeSpins() {
    return (
      <Fragment>
        <h4>Game Slug</h4>
        <i>
          It only supports games from our custom integrations, at the moment
          PG-Soft, Pragmatic-Play
        </i>
        <input
          required
          onChange={(ev) => this.updateMetadata({ game_slug: ev.target.value })}
        />

        <h4>Free Spin Name (how the user will see it in the game)</h4>
        <input
          required
          onChange={(ev) => this.updateMetadata({ label: ev.target.value })}
        />

        <h4>Number of spins</h4>
        <input
          required
          onChange={(ev) =>
            this.updateMetadata({ free_spins: ev.target.value })
          }
          type="number"
        />

        <h4>
          Lifespan (how long the free spins are valid for after claim in
          minutes)
        </h4>
        <input
          required
          onChange={(ev) => this.updateMetadata({ lifespan: ev.target.value })}
          type="number"
        />
      </Fragment>
    );
  }

  renderRakeback() {
    return (
      <Fragment>
        <h4>Game Slug (optional)</h4>
        <input
          required
          type="number"
          min={1}
          onChange={(ev) => this.updateMetadata({ game_slug: ev.target.value })}
        />
        <br />
        <h4>
          Enter Minutes before payout (ex. 5 will wait 5 minutes, and give
          rakeback based on losses/wins between start and end time)
        </h4>
        <input
          value={this.state.metadata.minutes_before_payout}
          required
          type="number"
          min={1}
          onChange={(e) =>
            this.updateMetadata({
              minutes_before_payout: e.target.value,
            })
          }
        />
        <br />
        <h4>
          Enter Rakeback % (ex. 0.05 will give a user a 5% rakeback at the end
          of the period)
        </h4>
        <input
          value={this.state.metadata.percentage}
          required
          type="number"
          min={1}
          onChange={(e) =>
            this.updateMetadata({
              percentage: e.target.value,
            })
          }
        />
      </Fragment>
    );
  }

  renderMetadataInputUi() {
    const mapRewardTypeToRenderer = {
      betby_bonus: this.renderBetByBonus,
      deposit_bonus: this.renderDepositBonus,
      deposit_bonus_sports: this.renderDepositBonusForSports,
      free_bet: this.renderFreeBet,
      xp_bonus: this.renderXPBonus,
      cashback: this.renderCashback,
      softswiss_free_spins: this.renderSoftswissFreeSpins,
      game_integration_free_spins: this.renderGameIntegrationFreeSpins,
      rakeback: this.renderRakeback,
      welcome_bonus: this.renderWelcomeBonus,
    };

    const renderRewardType = mapRewardTypeToRenderer[this.state.type];

    return renderRewardType ? renderRewardType() : null;
  }

  validateInput(evt) {
    const inputTarget = evt.target;
    const inputValue = inputTarget.value.trim();

    const regExpWithoutNumbersOnly = new RegExp(patternWithoutNumbersOnly);
    const regExpValidPattern = new RegExp(validPattern);
    const isEmptyValue =
      emptyPattern.test(inputValue) || inputValue.length === 0;

    if (regExpValidPattern.test(inputValue)) inputTarget.setCustomValidity("");

    if (inputValue.length < 2 || inputValue.length > 255)
      inputTarget.setCustomValidity(
        "The value of this field must be between 2 and 255 characters."
      );

    if (regExpWithoutNumbersOnly.test(inputValue))
      inputTarget.setCustomValidity("The value must not contain only numbers.");

    if (isEmptyValue)
      inputTarget.setCustomValidity("The value must not be empty.");

    inputTarget.reportValidity();
  }

  onSubmitReward = (evt) => {
    evt.preventDefault();

    const rewardData = {
      name: this.name.value,
      description: this.description.value,
      eur_value: this.eur_value.value,
      type: this.state.type,
      metadata: this.state.metadata,
      file: this.state.file,
      name_translation: this.state.name_translation,
      description_translation: this.state.description_translation,
      mystery_manifest_description_translation:
        this.state.mystery_manifest_description_translation,
      sellable: this.state.sellable,
      expiry_minutes: this.expiry_minutes.value,
      currency_rates: this.props.currencyRates,
    };

    this.props.create(rewardData);
  };

  render() {
    return (
      <div className="rewards-wrapper-container">
        <div className="rewards-search-row">
          <input
            value={this.state.query.id}
            onChange={e => {
              this.setState({ query: {...this.state.query, id: e.target.value} });
            }}
            type="number"
            onKeyDown={e => this.onSearch(e)}
            placeholder="Type a Reward id"
          />
          
          <input
            value={this.state.query.name}
            onChange={e => {
              this.setState({ query: {...this.state.query, name: e.target.value} });
            }}
            onKeyDown={e => this.onSearch(e)}
            placeholder="Type a Reward name"
          />

          <button
            disabled={this.state.isLoading}
            className="red"
            onClick={() => this.update()}
          >
            {this.state.isLoading ? 'Loading...' : 'Search'}
          </button>
        </div>

        <PermissionsFragment feature={permissionsConstants.REWARDS_VIEW_LIST}>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th>Image</Th>
                <Th>Value</Th>
                <Th>Metadata</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {this.props.records.map((r, i) => {
                return (
                  <Tr key={i}>
                    <Td>
                      <Link to={`/casino/rewards/${r.id}`}>{r.id}</Link>
                    </Td>
                    <Td>{r.name}</Td>
                    <Td>{r.type}</Td>
                    <Td>
                      <img style={{ width: "20px" }} src={r.image_url} alt="" />
                    </Td>
                    <Td>€ {r.eur_value}</Td>
                    <Td>{JSON.stringify(r.metadata, null, 4)}</Td>
                    <Td>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.props.deleteReward(r.id)}
                        className="fas fa-trash"
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <Pagination
            total_pages={this.props.meta.total_pages}
            update={(page) => {
              this.setState({ page }, () => this.update(page));
            }}
          />
        </PermissionsFragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.rewards.items,
  bonuses: state.bonuses.search_results.records,
  boxes: state.rewards.mystery_boxes,
  languages: state.app.languages,
  currencyRates: state.rewards.currency_rates,
});

const mapDispatchToProps = (dispatch) => ({
  updateBonuses: (page) => dispatch(updateDepositBonuses(page)),
  updateBoxes: (page) => dispatch(updateBoxes(page)),
  update: (page, all, name, id) => dispatch(updateRewards(page, all, name, id)),
  create: (props) => dispatch(createReward(props)),
  deleteReward: (id) => dispatch(deleteReward(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRewards);
