import React, { Component } from "react";
import { connect } from "react-redux";
import { updatePaymentMethods, editMethod } from "../actions";
import Pagination from "../../shared/pagination";
import { format } from "../../shared/utils/dates";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DownloadCSVButton from "shared/DownloadCSVButton";

class MethodFinder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      processing: {},
      records: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.props.update(this.state.page);
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "1rem",
          }}
        >
          <DownloadCSVButton records={this.state.records} />
        </div>
        <Table>
          <Thead>
            <Tr>
              <Th>Created</Th>
              <Th>ID</Th>
              <Th>Type</Th>
              <Th>Icon URL</Th>
              <Th>Currency Type</Th>
              <Th>Enabled</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.props.records.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                  <Td>{r.id}</Td>
                  <Td>{r.type}</Td>
                  <Td>{r.icon_url}</Td>
                  <Td>{r.currency_type}</Td>

                  <Td>{r.enabled ? <i className="fas fa-check" /> : null}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.props.meta.total_pages}
          update={(page) => {
            this.setState({ page }, () => this.update());
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.payments.method_search_results,
});

const mapDispatchToProps = (dispatch) => ({
  update: (page) => dispatch(updatePaymentMethods(100, page)),
  edit: (id, props) => dispatch(editMethod(id, props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MethodFinder);
