import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormGroup,
  Radio,
  Select,
  RadioGroup,
  Typography,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

const RULES_NAMES = {
  disableForFTD: "disableForFTD",
  disableForNonFTD: "disableForNonFTD",
  hasVipRole: "hasVipRole",
  hasNoVipRole: "hasNoVipRole",
  depositCountEqGreaterThan: "depositCountEqGreaterThan",
  signUpCode: "signUpCode",
  signUpChannel: "signUpChannel",
  enableIfSignUpCodeIsNotIncluded: "enableIfSignUpCodeIsNotIncluded",
  enableIfUserChannelNotInList: "enableIfUserChannelNotInList",
};

const SIGNUP_CHANNEL = {
  ORGANIC: "ORGANIC",
  CELLXPERT: "CELLXPERT",
  AFFILIATE: "AFFILIATE",
};

export const ProviderRulesForm = ({ values, onSetRules }) => {
  const [rules, setRules] = useState({});
  const [condition, setCondition] = useState("AND");

  useEffect(() => {
    if (!values) {
      return;
    }
    setRules(values?.rules ?? {});
    setCondition(values.condition ?? "AND");
  }, [values]);

  const setRuleValue = (name, value) => {
    setRules({
      ...rules,
      [name]: value,
    });
  };

  const handleClearRules = () => {
    setRules({});
    setCondition("AND");
  };

  const handleSetRules = () => {
    Object.keys(rules).forEach((name) => {
      if (!rules[name]) {
        delete rules[name];
      }
    });

    onSetRules({
      rules,
      condition,
      version: 1,
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Depositor filter rules
        </Typography>

        <RadioGroup
          // row
          sx={{ mb: 3 }}
          name="condition"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
        >
          <FormControlLabel
            value="AND"
            control={<Radio />}
            label="Condition AND"
          />
          <FormControlLabel
            value="OR"
            control={<Radio />}
            label="Condition OR"
          />
        </RadioGroup>

        <FormGroup sx={{ mb: 3 }}>
          <FormControlLabel
            label="Disable for First Time Deposit"
            control={
              <Checkbox
                checked={!!rules[RULES_NAMES.disableForFTD]}
                onChange={(e) =>
                  setRuleValue(RULES_NAMES.disableForFTD, e.target.checked)
                }
              />
            }
          />
          <FormControlLabel
            label="Disable for non First Time Deposit"
            control={
              <Checkbox
                checked={!!rules[RULES_NAMES.disableForNonFTD]}
                onChange={(e) =>
                  setRuleValue(RULES_NAMES.disableForNonFTD, e.target.checked)
                }
              />
            }
          />
          <FormControlLabel
            label="Enable when user has VIP role"
            control={
              <Checkbox
                checked={!!rules[RULES_NAMES.hasVipRole]}
                onChange={(e) =>
                  setRuleValue(RULES_NAMES.hasVipRole, e.target.checked)
                }
              />
            }
          />
          <FormControlLabel
            label="Enable when user has no VIP role"
            control={
              <Checkbox
                checked={!!rules[RULES_NAMES.hasNoVipRole]}
                onChange={(e) =>
                  setRuleValue(RULES_NAMES.hasNoVipRole, e.target.checked)
                }
              />
            }
          />
        </FormGroup>

        <FormGroup sx={{ mb: 3 }}>
          <InputLabel>Enable when deposits are more than count</InputLabel>
          <input
            type="number"
            value={rules[RULES_NAMES.depositCountEqGreaterThan] ?? ""}
            onChange={(e) =>
              setRuleValue(
                RULES_NAMES.depositCountEqGreaterThan,
                e.target.value
              )
            }
          />
        </FormGroup>

        <FormGroup sx={{ mb: 3 }}>
          <InputLabel>Enable for signed up code</InputLabel>
          <input
            value={rules[RULES_NAMES.signUpCode] ?? ""}
            onChange={(e) =>
              setRuleValue(RULES_NAMES.signUpCode, e.target.value)
            }
          />
        </FormGroup>

        <FormGroup sx={{ mb: 3 }}>
          <InputLabel>
            Enable for users without these signed up codes
          </InputLabel>
          <input
            placeholder="code1,code2,code2"
            value={rules[RULES_NAMES.enableIfSignUpCodeIsNotIncluded] ?? ""}
            onChange={(e) =>
              setRuleValue(
                RULES_NAMES.enableIfSignUpCodeIsNotIncluded,
                e.target.value
              )
            }
          />
        </FormGroup>

        <FormGroup sx={{ mb: 3 }}>
          <InputLabel>
            Enable for users without these signed up channels [ORGANIC,
            AFFILIATE, CELLXPERT]
          </InputLabel>
          <input
            placeholder="AFFILIATE,CELLXPERT,ORGANIC"
            value={rules[RULES_NAMES.enableIfUserChannelNotInList] ?? ""}
            onChange={(e) =>
              setRuleValue(
                RULES_NAMES.enableIfUserChannelNotInList,
                e.target.value
              )
            }
          />
        </FormGroup>

        <FormGroup sx={{ mb: 3 }}>
          <InputLabel>Enable for signed up method</InputLabel>
          <Select
            value={rules[RULES_NAMES.signUpChannel] ?? ""}
            onChange={(e) =>
              setRuleValue(RULES_NAMES.signUpChannel, e.target.value)
            }
          >
            <MenuItem key={"empty"} value="">
              {" "}
            </MenuItem>
            <MenuItem value={SIGNUP_CHANNEL.ORGANIC}>
              {SIGNUP_CHANNEL.ORGANIC}
            </MenuItem>
            <MenuItem value={SIGNUP_CHANNEL.CELLXPERT}>
              {SIGNUP_CHANNEL.CELLXPERT}
            </MenuItem>
            <MenuItem value={SIGNUP_CHANNEL.AFFILIATE}>
              {SIGNUP_CHANNEL.AFFILIATE}
            </MenuItem>
          </Select>
        </FormGroup>

        <br />
      </CardContent>
      <CardActions>
        <button onClick={handleClearRules}>Clear Rules</button>
        <button onClick={handleSetRules}>Save Rules</button>
      </CardActions>
    </Card>
  );
};
