import axios from "axios";
import querystring from "querystring";

const isProd = process.env.NODE_ENV === "production";
let BASE_URL = "http://127.0.0.1:23001/originals";
if (isProd) {
  BASE_URL = "/api/singleplayer-originals/originals";
}

class API {
  constructor(base) {
    this.API_BASE = base;

    const config = {
      baseURL: this.API_BASE,
      timeout: 30000,
      withCredentials: true,
      headers: {
        common: {},
      },
    };

    if (localStorage.getItem("x-block-bypass")) {
      config.headers["X-Block-Bypass"] = "1";
    }

    const token = `Bearer ${localStorage.getItem("access_token")}`;
    config.headers["Authorization"] = token;
    this.instance = axios.create(config);
  }

  setAuthHeader(authToken) {
    const token = `Bearer ${authToken}`;
    this.instance.defaults.headers.common["Authorization"] = token;
  }

  extractData(result) {
    return result.data;
  }

  handleFailure(err) {
    if (err.response) {
      throw err.response.data.error;
    } else {
      //throw new Error(err, TIMED_OUT);
    }
  }

  async get(url, args = {}) {
    return this.instance
      .get(url, { params: args })
      .then((result) => {
        return this.extractData(result);
      })
      .catch((err) => {
        this.handleFailure(err);
      });
  }

  async post(url, args, query = {}) {
    if (Object.keys(query).length > 0) {
      url += "?" + querystring.stringify(query);
    }

    return this.instance
      .post(url, args)
      .then((result) => {
        return this.extractData(result);
      })
      .catch((err) => {
        this.handleFailure(err);
      });
  }

  async put(url, args, query = {}) {
    if (Object.keys(query).length > 0) {
      url += "?" + querystring.stringify(query);
    }

    return this.instance
      .put(url, args)
      .then((result) => {
        return this.extractData(result);
      })
      .catch((err) => {
        this.handleFailure(err);
      });
  }

  async delete(url, args, query = {}) {
    if (Object.keys(query).length > 0) {
      url += "?" + querystring.stringify(query);
    }

    return this.instance
      .delete(url, args)
      .then((result) => {
        return this.extractData(result);
      })
      .catch((err) => {
        this.handleFailure(err);
      });
  }
}

const bettingApi = new API(BASE_URL);

if (window?.bettingApi) {
  window.bettingApi = bettingApi;
}

export default bettingApi;
