import React from "react";
import { NavLink } from "react-router-dom";
import {
  permissionGroups,
  GroupPermissionsFragment,
} from "app/permissions-fragment.js";

export const CreateCampaignLink = () => (
  <GroupPermissionsFragment group={permissionGroups.AFFILIATES.CAMPAIGNS}>
    <NavLink to={"/affiliates/system/create-campaign"}>Create Campaign</NavLink>
  </GroupPermissionsFragment>
);
export const UpdateCampaignLink = () => (
  <GroupPermissionsFragment group={permissionGroups.AFFILIATES.CAMPAIGNS}>
    <NavLink to={"/affiliates/system/update-campaign"}>Update Campaign</NavLink>
  </GroupPermissionsFragment>
);

export const AffiliateManagerLink = () => (
  <GroupPermissionsFragment group={permissionGroups.AFFILIATES.GENERAL}>
    <NavLink to={"/affiliates/system/affiliate-manager"}>
      Affiliate Manager
    </NavLink>
  </GroupPermissionsFragment>
);

export const UpdatePostbackUrlLink = () => (
  <GroupPermissionsFragment group={permissionGroups.AFFILIATES.GENERAL}>
    <NavLink to={"/affiliates/system/set-postback"}>
      Update Postback URL
    </NavLink>
  </GroupPermissionsFragment>
);
