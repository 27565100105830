const PAYMENT_METHODS_UPDATE = 'payments/METHODS/UPDATE';
const PAYMENT_COUNTRY_ORDERINGS_UPDATE = 'payments/COUNTRY_ORDERINGS/UPDATE';
const PAYMENT_METHOD_UPDATE = 'payments/METHOD/UPDATE';

const PAYMENT_PROVIDERS_UPDATE = 'payments/PROVIDERS/UPDATE';
const PAYMENT_PROVIDER_UPDATE = 'payments/PROVIDER/UPDATE';

const PAYMENT_ACCOUNTS_UPDATE = 'payments/ACCOUNTS/UPDATE';
const PAYMENT_COUNTRY_ORDERING_UPDATE = 'payments/COUNTRY_ORDERING/UPDATE';

export {
  PAYMENT_METHODS_UPDATE,
  PAYMENT_METHOD_UPDATE,
  PAYMENT_PROVIDERS_UPDATE,
  PAYMENT_PROVIDER_UPDATE,
  PAYMENT_COUNTRY_ORDERINGS_UPDATE,
  PAYMENT_ACCOUNTS_UPDATE,
  PAYMENT_COUNTRY_ORDERING_UPDATE
}
