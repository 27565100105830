import React, { Component } from "react";
import { format } from "../shared/utils/dates";
import moment from "moment";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {downloadFile} from "../shared/utils/files";

class Cashback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,

      page: 1,
      periods: [],

      details: {},
      details_loading: 0,
    };
  }

  async fetch(page = this.state.page) {
    window.api
      .get("/admin/weekly-cashback-management/periods?page=" + page)
      .then((data) => {
        this.setState({
          page,
          periods: data,
        });
      });
  }

  async showDetails(id) {
    this.setState({
      details_loading: id,
    });

    window.api
      .get("/admin/weekly-cashback-management/details/" + id)
      .then((data) => {
        this.setState({
          detailsId: id,
          details: {
            ...this.state.details,
            [id]: data,
          },
          details_loading: 0,
        });
      })
      .catch(() => {
        this.setState({
          details_loading: 0,
        });
      });
  }



  async invalidate(id) {
    window.api
      .post("/admin/weekly-cashback-management/periods/invalidate", {loyaltyPeriodId: id})
      .then(() => {
        this.fetch()
      })
  }


  componentDidMount() {
    this.fetch();

    this.count = 0;
    this.interval = setInterval(() => {
      this.fetch();

      this.count++;
      // once a minute refresh details
      if (this.count % 6 === 0) {
        let keys = Object.keys(this.state.details);
        keys.forEach(key => {
          this.showDetails(key)
        })
      }

    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <h3>Cashback</h3>

        <button
          style={{
            marginRight: 15,
          }}
          onClick={() => {
            window.api
              .post("/admin/weekly-cashback-management/periods/next")
              .then(() => {
                this.fetch();
              });
          }}
        >
          Create Next Period
        </button>

        <a
          target={"_blank"}
          href={
            "https://app.datadoghq.eu/apm/traces?query=%40_top_level%3A1%20env%3Aproduction%20service%3Aadmin-api%20operation_name%3Aexpress.request%20resource_name%3A%22POST%20%2Fadmin%2Fweekly-cashback-management%2Fretry%22&cols=core_service%2Ccore_resource_name%2Clog_duration%2Clog_http.method%2Clog_http.status_code&historicalData=false&messageDisplay=inline&sort=desc&start=1661149938848&end=1661150838848&paused=false"
          }
        >
          <button>Monitor</button>
        </a>

        <br />
        <br />

        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Status</Th>
              <Th>Starts At</Th>
              <Th>Ends At</Th>
              <Th>Claimable At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.periods.map((p) => {
              const details = this.state.details[p.id];

              return (
                <>
                  <Tr>
                    <Td>#{p.id}</Td>
                    <Td>{p.status}</Td>
                    <Td>{format.withMinutes(p.starts_at)}</Td>
                    <Td>{format.withMinutes(p.ends_at)}</Td>
                    <Td>
                      {p.claimable_at
                        ? format.withMinutes(p.claimable_at)
                        : "unset"}
                    </Td>
                    <Td>
                      {["created", "failed_to_query_data"].includes(p.status) &&
                        moment(p.ends_at).diff(moment()) < 0 && (
                          <button
                            onClick={() => {
                              const res = window.confirm(
                                "Warning! this request can take over 5 minutes to process. Do not spam click this button."
                              );

                              if (res) {
                                window.api
                                  .post(
                                    "/admin/weekly-cashback-management/retry",
                                    {
                                      loyaltyPeriodId: p.id,
                                    }
                                  )
                                  .then(() => {
                                    this.fetch();
                                  });
                              }
                            }}
                          >
                            Retry calculation
                          </button>
                        )}

                      {["issuing_rewards", "credited"].includes(p.status) && (
                        <button
                          disabled={this.state.details_loading === p.id}
                          onClick={() => {
                            this.showDetails(p.id);
                          }}
                        >
                          Show Details
                        </button>
                      )}

                      {p.status === 'issuing_rewards' && (
                        <button
                          onClick={() => {
                            const res = window.confirm(`Are you sure you want to cancel period ${p.id}? This will set the status to bugged as well as all of its rewards issued so far. Confirm the rewards are no longer being issued before pressing this!`);
                            if (res) {
                              this.invalidate(p.id);
                            }
                          }}
                        >
                          Cancel
                        </button>

                      )}

                      {p.status === 'credited' && (
                        <button
                          onClick={() => {
                            require('axios')(
                              {
                                url: "/api/admin/weekly-cashback-management/download_cio_events/" + p.id,
                                method: 'GET',
                                responseType: 'blob', // important,
                                params: {},
                                headers: {
                                  'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                                  'X-RateLimit-Bypass': '1'
                                }
                              })
                              .then((response) => {
                                const data = response.data;
                                const url = window.URL.createObjectURL(new Blob([data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                  "download",
                                  `weekly-cashback-${p.id}-cio-events.csv`
                                );
                                document.body.appendChild(link);
                                link.click();
                              })
                          }}
                        >
                          Export to Customer IO
                        </button>

                      )}
                    </Td>
                  </Tr>

                  {details && (
                    <Tr>
                      <Td colSpan={2}>
                        <div>Claimed</div>
                        <div>
                          Amount:{" "}
                          {(+(details.credited || {}).eur_amount || 0).toFixed(
                            2
                          )}{" "}
                          EUR
                        </div>
                        <div>
                          Distinct Users: {(details.credited || {}).count || 0}
                        </div>
                      </Td>
                      <Td colSpan={2}>
                        <div>Available To Claim</div>
                        <div>
                          Amount:{" "}
                          {(+(details.created || {}).eur_amount || 0).toFixed(
                            2
                          )}{" "}
                          EUR
                        </div>
                        <div>
                          Distinct Users: {(details.created || {}).count || 0}
                        </div>
                      </Td>
                      <Td colSpan={2}>
                        <div>Insufficient Amount</div>
                        <div>
                          Amount:{" "}
                          {(
                            +(details.insufficient_amount || {}).eur_amount || 0
                          ).toFixed(2)}{" "}
                          EUR
                        </div>
                        <div>
                          Distinct Users:{" "}
                          {(details.insufficient_amount || {}).count || 0}
                        </div>
                      </Td>
                    </Tr>
                  )}
                </>
              );
            })}
          </Tbody>
        </Table>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div
            onClick={() => {
              this.fetch(this.state.page - 1);
            }}
          >
            {this.state.page > 1 && <a>&#60; Back</a>}
          </div>

          <div
            onClick={() => {
              this.fetch(this.state.page + 1);
            }}
          >
            <a>Next &#62;</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Cashback;
