import {Table} from "@mui/material";
import React from "react";
import {useQuery, useMutation, useQueryClient} from "react-query";
import {
    getMultiplayerGamesRooms, updateMultiplayerGamesRooms,
} from "./originals-games-api";
import {toast} from "react-toastify";
import {Thead, Tr, Th, Tbody, Td} from "react-super-responsive-table";

const MultiplayerGamesRooms = () => {
    const queryClient = useQueryClient();

    const {data: rooms, isLoading, isError} = useQuery("multiplayerGamesRooms", getMultiplayerGamesRooms);

    const updateRoomMutation = useMutation(updateMultiplayerGamesRooms,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('multiplayerGamesRooms')
            },
            onError: (error) => {
                toast.error(error)
            },
        });

    const toggleRoomStatus = (room) => {
        const isActive = room.active;
        const action = isActive ? "activate" : "deactivate";
        const res = window.confirm(`Warning! Are you sure to ${action} the room ?`);

        if (res) {
            const updatedData = {active: !isActive};
            updateRoomMutation.mutate({roomId: room.id, updatedData});
        }
    };

    if (isError) {
        return <div><h1>Something went wrong while fetching data</h1></div>;
    }

    if (isLoading) {
        return <div><h1>Loading...</h1></div>;
    }

    return (<Table className="responsiveTable">
        <Thead>
            <Tr>
                <Th>Room ID</Th>
                <Th>Name</Th>
                <Th>Active</Th>
                <Th>Actions</Th>
            </Tr>
        </Thead>
        <Tbody>
            {rooms?.map((room) => (<Tr key={room.id}>
                <Td>{room.id}</Td>
                <Td>{room.game}</Td>
                <Td>
                    {room.active ? (<i className="fas fa-check" style={{color: "green"}}/>) : (
                        <i className="fas fa-times" style={{color: "lightgray"}}/>)}
                </Td>
                <Td>
                    <button onClick={() => toggleRoomStatus(room)}>
                        {room.active ? "Deactivate" : "Activate"}
                    </button>
                </Td>
            </Tr>)) || <Tr><Td colSpan={4}>No rooms found</Td></Tr>}
        </Tbody>
    </Table>);
};

export default MultiplayerGamesRooms;
