import React from "react";
import Select from "react-select";

import "./styles/SelectType.scss";
const listOptions = [
  {
    id: "",
    label: "all",
  },
  {
    id: "external",
    label: "external",
  },
  {
    id: "internal",
    label: "internal",
  },
];
const SelectType = ({ select, setSelect }) => {
  return (
    <div className="selecttype">
      <Select
        className="selectsort__select"
        classNamePrefix="selectsort__select"
        options={listOptions}
        value={
          listOptions.find((option) => option.id === select) || listOptions[0]
        }
        onChange={({ id }) => {
          setSelect(id);
        }}
        placeholder="filter by service"
        clearable={false}
      />
    </div>
  );
};

export default SelectType;
